import React from "react";
import { format, startOfWeek, addDays } from "date-fns";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, Views, dateFnsLocalizer } from "react-big-calendar";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};

const localizer = dateFnsLocalizer({
  format,
  parse: (str, format, fallback) => {
    const parsed = format ? new Date(str) : new Date(fallback);
    return isNaN(parsed) ? fallback : parsed;
  },
  startOfWeek: startOfWeek,
  getDay: Date.prototype.getDay,
  locales,
});

class CustomToolbar extends React.Component {
  render() {
    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button type="button" onClick={() => this.navigate("TODAY")}>
            Today
          </button>
          <button type="button" onClick={() => this.navigate("PREV")}>
            Back
          </button>
          <button type="button" onClick={() => this.navigate("NEXT")}>
            Next
          </button>
        </span>
      </div>
    );
  }

  navigate(action) {
    this.props.onNavigate(action);
  }
}

class AgendaOnly extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: props.events ? props.events : [], // Initialize with an empty event array or pass events via props
    };
  }

  render() {
    return (
      <div>
        <Calendar
          localizer={localizer}
          events={this.state.events}
          defaultView="agenda"
          views={["agenda"]}
          toolbar
          components={{
            toolbar: CustomToolbar,
          }}
          onSelectEvent={(event) => this.handleSelectEvent(event)}
        />
      </div>
    );
  }

  handleSelectEvent(event) {
    // Handle event selection (e.g., display details, navigate to a different page, etc.)
    console.log("Selected event:", event);
  }
}

export default AgendaOnly;
