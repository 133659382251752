import React, { useContext } from "react";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
import CycleLoT from "../../lottie-files/CycleLoT";
import lottie from "lottie-web";

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
};

const newSteps = [
  {
    id: "shep-s1",
    //attachTo:{element:'.sme-journey-col', on:'right'},
    // beforeShowPromise:function(){
    //   return new Promise (function (resolve){
    //     setTimeout(function(){
    //       window.scrollTo(0,0);
    //       resolve();
    //     }, 500);
    //   });
    // },
    buttons: [
      {
        classes: "shepard-button-secondary lcn-button-orange",
        text: "Exit",
        type: "cancel",
      },
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Next",
        type: "next",
      },
    ],
    classes: "shepard-step",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    title: "Welcome to Lacoona Legal! Get a centralised overview of your practice",
    text: [
      "Welcome to Lacoona Legal Lawyer Dashboard! Here you can view your earnings, the number of sessions you’ve opened, booked sessions. You can also change your personal, company information as well as what your rates are.<br/>Your dashboard is your go-to for an overview of your activity.",
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
  {
    id: "shep-s2",
    attachTo: { element: "#dashnav-lawyer-dashboard", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Next",
        action() {
          const selector = document.getElementById("dashnav-schedule-lawyer");
          selector.click();
          return this.next();
        },
      },
    ],
    classes: "shepard-step custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    title: "This is your dashboard for centralised action",
    text: [
      "Click here to come back to your central dashboard whenever you need.",
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
  {
    id: "shep-s3",
    attachTo: { element: "#dashnav-schedule-lawyer", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Back",
        action() {
          const selector = document.getElementById("dashnav-lawyer-dashboard");
          selector.click();
          return this.back();
        },
      },
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Next",
        action() {
          const selector = document.getElementById("dashnav-appointments-lawyer");
          selector.click();
          return this.next();
        },
      },
    ],
    classes: "shepard-step custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    title: "Set up your schedule here",
    text: [
      "Manage your availability and sessions with ease.<br/>Create slots and set your availability; keep in mind that we limit these slots to 30 or 60 minute sessions.<br/>Always remember to save your times by clicking “Save Schedule”!",
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
  {
    id: "shep-s4",
    attachTo: { element: "#dashnav-appointments-lawyer", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Back",
        action() {
          const selector = document.getElementById("dashnav-schedule-lawyer");
          selector.click();
          return this.back();
        },
      },
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Next",
        action() {
          const selector = document.getElementById("dashnav-payments-lawyer");
          selector.click();
          return this.next();
        },
      },
    ],
    classes: "shepard-step custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    title: "View your appointments",
    text: [
      "View all your available slots as well as confirmed upcoming bookings.<br/>Keep track of your schedule and never miss an appointment.",
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
  {
    id: "shep-s5",
    attachTo: { element: "#dashnav-payments-lawyer", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Back",
        action() {
          const selector = document.getElementById("dashnav-appointments-lawyer");
          selector.click();
          return this.back();
        },
      },
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Next",
        action() {
          const selector = document.getElementById("dashnav-ai-assistant-lawyer");
          selector.click();
          return this.next();
        },
      },
    ],
    classes: "shepard-step custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    title: "View your payment overview and history",
    text: [
      "The Payments page provides you with a comprehensive view of your earnings.<br/>See how much you've earned through Lacoona Legal, when your next payout is, and keep track of all your past payouts.",
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
  {
    id: "shep-s6",
    attachTo: { element: "#dashnav-ai-assistant-lawyer", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Back",
        action() {
          const selector = document.getElementById("dashnav-payments-lawyer");
          selector.click();
          return this.back();
        },
      },
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Next",
        action() {
          const selector = document.getElementById("dashnav-lawyer-dashboard");
          selector.click();
          return this.next();
        },
      },
    ],
    classes: "shepard-step custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    title:
      "Confirm you opinion with Jinx",
    text: [
      "Got a legal question? Ask Jinx, our South African Legal AI Advisor tailored for lawyers.<br/>Jinx is always here to help you with legal advice and insights.",
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
  {
    id: "shep-s6.5",
    attachTo: { element: ".side-dashboard-user-welcome", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Back",
        action() {
          const selector = document.getElementById("dashnav-ai-assistant-lawyer");
          selector.click();
          return this.back();
        },
      },
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Next",
        type:"next",
      },
    ],
    classes: "shepard-step custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    title: "Manage our account information here.",
    text: [
      "Click here to update the information associated with your profile but which is not outward facing. ",
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
  {
    id: "shep-s7",
    attachTo: { element: "#lawyer-details-dash", on: "left" },
    buttons: [
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Back",
        type:"back",
      },
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Next",
        type:"next",
      },
    ],
    classes: "shepard-step custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    title: "Update your professional details here",
    text: [
      "Need to update your account details or profile information?<br/>Head back to the Dashboard and access all your profile information in one convenient location.<br/>Stay up to date with your activity and never miss a beat.",
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
  {
    id: "shep-s8",
    attachTo: { element: ".LandbotLivechat", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "End Tour",
        type: "cancel",
      },
    ],
    classes: "shepard-step custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    title: "We’re here to listen and support you",
    text: [
      "If you need help, technical support, or want to suggest a feature, we have several options available to you.<br>We're here for any guidance, suggestions or if you would like to make a complaint.",
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
];

function TourButton() {
  const tour = useContext(ShepherdTourContext);

  function start() {
    tour.start();
  }

  return (
    <div
      className="tour-button"
      onMouseEnter={() => lottie.play("cycle-tour-lottie")}
      onMouseLeave={() => lottie.stop("cycle-tour-lottie")}
      onClick={start}
    >
      <CycleLoT aniName="cycle-tour-lottie" />
      Start Tour
    </div>
  );
}

function LawyerShepTour() {
  return (
    <div className="tour-parent tour-desktop">
      <ShepherdTour steps={newSteps} tourOptions={tourOptions}>
        <TourButton />
      </ShepherdTour>
    </div>
  );
}

export default LawyerShepTour;
