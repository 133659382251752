import { SET_VALIDATOR_USED } from "../constants/validatorConstants";
import { USER_LOGOUT } from "../constants/userConstants";

const initialState = {
  validator_used: false
};

export const validatorReducer = (state = initialState, action) => {
  switch(action.type) {
    case SET_VALIDATOR_USED:
      return {
        ...state,
        validator_used: true
      };
    case USER_LOGOUT:
      return {
        ...state,
        validator_used: false
      };
    default:
      return state;
  }
};