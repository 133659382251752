import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector } from "react-redux";
/* import { logout } from "../actions/userActions"; */

import Layers from "../lottie-files/Layers";
import lottie from "lottie-web";
import ArchiveLoT from "../lottie-files/ArchiveLoT";
import ScalesLoT from "../lottie-files/ScalesLoT";
import LawyerLoT from "../lottie-files/LawyerLoT";
import LegalByteLoT from "../lottie-files/LegalbyteLoT";
import MediFileLoT from "../lottie-files/MediFileLoT";
import BrainLoT from "../lottie-files/BrainLoT";
import BulbLoT from "../lottie-files/BulbLoT";
import DocumentLoT from "../lottie-files/DocumentLoT";

function LawyerMobileDashNav() {
  const userLogin = useSelector((state) => state.userLogin);
  /*  const dispatch = useDispatch(); */
  const { userInfo } = userLogin;
  /*   let navigate = useNavigate(); */

  const [menuMoreClicked, setMenuMoreClicked] = useState(false);

  /* ESLint says this is not being used.
  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
  }; */
  // const [expanded, setExpanded] = useState(false);

  return (
    <Row className="ll-dash-mobile-nav">
      <div
        id="ll-mobile-see-more"
        className="ll-mobile-see-more"
        onClick={() => setMenuMoreClicked((prevState) => !prevState)}
      >
        {menuMoreClicked === false ? <span>+</span> : <span>-</span>}
      </div>
      <LinkContainer to={"/"}>
        <div className="ll-mobile-go-home">
          <i className="fa-solid fa-house"></i>
        </div>
      </LinkContainer>
      <Row
        className={
          menuMoreClicked
            ? "ll-dash-mobile-nav ll-dash-mobile-height-expand"
            : "ll-dash-mobile-nav ll-dash-mobile-height"
        }
      >
        <Row
          align="center"
          id="ll-dash-mobile-nav-items"
          className="ll-dash-mobile-nav-items"
        >
          {userInfo?.role === "lawyer" && (
            <LinkContainer
              to={"/lawyer-dashboard/lawyer-dash"}
              // onClick={() => setExpanded(false)}
            >
              <Col
                align="center"
                id="dashnav-lawyer-dashboard-mobi"
                className="dash-mobilepanel-link"
                onMouseEnter={() => lottie.play("layers-dash-sn-mobi")}
                onMouseLeave={() => lottie.stop("layers-dash-sn-mobi")}
              >
                <Row
                  align="center"
                  className="dash-mobilepanel-link-icon-parent"
                >
                  <Layers aniName="layers-dash-sn-mobi" />
                </Row>
                <Row className="dash-mobilepanel-link-text-container">
                  <p className="dash-mobilepanel-link-text">Dashboard</p>
                </Row>
              </Col>
            </LinkContainer>
          )}
          {/* {userInfo?.role === "incubator" && (
            <LinkContainer
              to={"/dashboard/incubator-profile"}
              // onClick={() => setExpanded(false)}
            >
              <Col
                className="dash-mobilepanel-link"
                onMouseEnter={() => lottie.play("layers-dash-sn-mobi")}
                onMouseLeave={() => lottie.stop("layers-dash-sn-mobi")}
              >
                <Row className="dash-mobilepanel-link-icon-parent">
                  <Layers aniName="layers-dash-sn-mobi" />
                </Row>
                <Row className="dash-mobilepanel-link-text-container">
                  <p className="dash-mobilepanel-link-text">Dashboard</p>
                </Row>
              </Col>
            </LinkContainer>
          )} */}
          {/* {userInfo?.role === "ecosystem" && (
            <LinkContainer
              to={"/dashboard/ecosystem-profile"}
              // onClick={() => setExpanded(false)}
            >
              <Col
                className="dash-mobilepanel-link"
                onMouseEnter={() => lottie.play("layers-dash-sn-mobi")}
                onMouseLeave={() => lottie.stop("layers-dash-sn-mobi")}
              >
                <Row className="dash-mobilepanel-link-icon-parent">
                  <Layers aniName="layers-dash-sn-mobi" />
                </Row>
                <Row className="dash-mobilepanel-link-text-container">
                  <p className="dash-mobilepanel-link-text">Dashboard</p>
                </Row>
              </Col>
            </LinkContainer>
          )} */}
          {/* {userInfo && (
            <LinkContainer
              to={"/dashboard/ai-assistant"}
              // onClick={() => setExpanded(false)}
            >
              <Col
                className="dash-mobilepanel-link"
                id="dashnav-ai-assistant-mobile"
                onMouseEnter={() => lottie.play("ll-brain-mobile-icon")}
                onMouseLeave={() => lottie.stop("ll-brain-mobile-icon")}
              >
                <Row className="dash-mobilepanel-link-icon-parent">
                  <BrainLoT aniName="ll-brain-mobile-icon" />
                </Row>
                <Row className="dash-mobilepanel-link-text-container">
                  <p className="dash-mobilepanel-link-text">Ask Jinx</p>
                </Row>
              </Col>
            </LinkContainer>
          )} */}

          {/* {userInfo?.role === "sme" && (
            <LinkContainer
              to={"/dashboard/health-check"}
              // onClick={() => setExpanded(false)}
            >
              <Col
                className="dash-mobilepanel-link"
                id="dashnav-legal-checks-mobile"
                onMouseEnter={() => lottie.play("medi-file-sn-mobi")}
                onMouseLeave={() => lottie.stop("medi-file-sn-mobi")}
              >
                <Row className="dash-mobilepanel-link-icon-parent">
                  <MediFileLoT aniName="medi-file-sn-mobi" />
                </Row>
                <Row className="dash-mobilepanel-link-text-container">
                  <p className="dash-mobilepanel-link-text">Health</p>
                </Row>
              </Col>
            </LinkContainer>
          )} */}
          {/* <LinkContainer
            to={"/dashboard/legal-products"}
            // onClick={() => setExpanded(false)}
          >
            <Col
              className="dash-mobilepanel-link"
              id="dashnav-legal-products-mobile"
              onMouseEnter={() => lottie.play("scales-just-sn-mobi")}
              onMouseLeave={() => lottie.stop("scales-just-sn-mobi")}
            >
              <Row className="dash-mobilepanel-link-icon-parent">
                <ScalesLoT aniName="scales-just-sn-mobi" />
              </Row>
              <Row className="dash-mobilepanel-link-text-container">
                <p className="dash-mobilepanel-link-text">Products</p>
              </Row>
            </Col>
          </LinkContainer> */}
          {/* <LinkContainer
            to={"/dashboard/feedback"}
            // onClick={() => setExpanded(false)}
          >
            <Col
              className="dash-mobilepanel-link"
              id="dash-help-mobile"
              onMouseEnter={() => lottie.play("scales-just-sn-mobi")}
              onMouseLeave={() => lottie.stop("scales-just-sn-mobi")}
            >
              <Row className="dash-mobilepanel-link-icon-parent">
                <BulbLoT aniName="ll-bulb-mobile-icon" />
              </Row>
              <Row className="dash-mobilepanel-link-text-container">
                <p className="dash-mobilepanel-link-text">Help</p>
              </Row>
            </Col>
          </LinkContainer> */}
          {/* {userInfo && (
            <LinkContainer
              to={"/dashboard/documents"}
              // onClick={() => setExpanded(false)}
            >
              <Col
                className="dash-mobilepanel-link"
                id="dashnav-documents-mobile"
                onMouseEnter={() => lottie.play("Archive-docs-sn-mobi")}
                onMouseLeave={() => lottie.stop("Archive-docs-sn-mobi")}
              >
                <Row className="dash-mobilepanel-link-icon-parent">
                  <ArchiveLoT aniName="Archive-docs-sn-mobi" />
                </Row>
                <Row className="dash-mobilepanel-link-text-container">
                  <p className="dash-mobilepanel-link-text">Documents</p>
                </Row>
              </Col>
            </LinkContainer>
          )} */}
          <LinkContainer
            to={"/lawyer-dashboard/schedule"}
            // onClick={() => setExpanded(false)}
          >
            <Col
              className="dash-mobilepanel-link"
              id="dashnav-schedule-lawyer-mobi"
              onMouseEnter={() => lottie.play("legalbyte-sn-mobi lb")}
              onMouseLeave={() => lottie.stop("legalbyte-sn-mobi lb")}
            >
              <Row className="dash-mobilepanel-link-icon-parent">
                <LegalByteLoT aniName="legalbyte-sn-mobi lb" />
              </Row>
              <Row className="dash-mobilepanel-link-text-container">
                <p className="dash-mobilepanel-link-text">Schedule</p>
              </Row>
            </Col>
          </LinkContainer>
          <LinkContainer
            to={"/lawyer-dashboard/appointments"}
            // onClick={() => setExpanded(false)}
          >
            <Col
              className="dash-mobilepanel-link"
              id="dashnav-appointments-lawyer-mobi"
              onMouseEnter={() => lottie.play("scales-sn-mobi")}
              onMouseLeave={() => lottie.stop("scales-sn-mobi")}
            >
              <Row className="dash-mobilepanel-link-icon-parent">
                <ScalesLoT aniName="scales-sn-mobi" />
              </Row>
              <Row className="dash-mobilepanel-link-text-container">
                <p className="dash-mobilepanel-link-text">Appt.</p>
              </Row>
            </Col>
          </LinkContainer>


          {/* <LinkContainer
            to={"/lawyer-dashboard/ask-jinx-for-lawyers"}
            // onClick={() => setExpanded(false)}
          >
            <Col
                className="dash-mobilepanel-link"
                id="dashnav-ai-assistant-mobile"
                onMouseEnter={() => lottie.play("ll-brain-mobile-icon")}
                onMouseLeave={() => lottie.stop("ll-brain-mobile-icon")}
              >
                <Row className="dash-mobilepanel-link-icon-parent">
                  <BrainLoT aniName="ll-brain-mobile-icon" />
                </Row>
              <Row className="dash-mobilepanel-link-text-container">
                <p className="dash-mobilepanel-link-text">Ask Jinx</p>
              </Row>
            </Col>
          </LinkContainer> */}
          {/* <LinkContainer
            to={"/dashboard/faqs"}
            // onClick={() => setExpanded(false)}
          >
            <Col
              className="dash-mobilepanel-link"
              id="dashnav-legal-faqs-mobile"
              onMouseEnter={() => lottie.play("legalbyte-sn-mobi")}
              onMouseLeave={() => lottie.stop("legalbyte-sn-mobi")}
            >
              <Row className="dash-mobilepanel-link-icon-parent">
                <LegalByteLoT aniName="legalbyte-sn-mobi" />
              </Row>
              <Row className="dash-mobilepanel-link-text-container">
                <p className="dash-mobilepanel-link-text">Bytes</p>
              </Row>
            </Col>
          </LinkContainer> */}

          {/* Unlogged in user sees 'Dashboard' feature availability */}
          {!userInfo && (
            <LinkContainer
              to={"/login"}
              // onClick={() => setExpanded(false)}
            >
              <Col
                align="center"
                className="dash-mobilepanel-link"
                onMouseEnter={() => lottie.play("layers-dash-sn-mobi")}
                onMouseLeave={() => lottie.stop("layers-dash-sn-mobi")}
              >
                <Row
                  align="center"
                  className="dash-mobilepanel-link-icon-parent"
                >
                  <Layers aniName="layers-dash-sn-mobi" />
                </Row>
                <Row className="dash-mobilepanel-link-text-container">
                  <p className="dash-mobilepanel-link-text ll-grey-colour">
                    Dash
                  </p>
                </Row>
              </Col>
            </LinkContainer>
          )}
          
          {/* Unlogged in user sees 'Health Checks' feature availability */}
          {/* {!userInfo && (
            <LinkContainer
              className="col"
              to={"/login"}
              // onClick={() => setExpanded(false)}
            >
              <Col
                className="dash-mobilepanel-link"
                onMouseEnter={() => lottie.play("medi-file-sn-mobi")}
                onMouseLeave={() => lottie.stop("medi-file-sn-mobi")}
              >
                <Row className="dash-mobilepanel-link-icon-parent">
                  <MediFileLoT aniName="medi-file-sn-mobi" />
                </Row>
                <Row className="dash-mobilepanel-link-text-container">
                  <p className="dash-mobilepanel-link-text ll-grey-colour">
                    Health
                  </p>
                </Row>
              </Col>
            </LinkContainer>
          )} */}
          
          <LinkContainer
            to={"/lawyer-dashboard/payments"}
            // onClick={() => setExpanded(false)}
          >
            <Col
              className="dash-mobilepanel-link"
              id="dashnav-payments-lawyer-mobi"
              onMouseEnter={() => lottie.play("legalbyte-sn-mobi")}
              onMouseLeave={() => lottie.stop("legalbyte-sn-mobi")}
            >
              <Row className="dash-mobilepanel-link-icon-parent">
                <DocumentLoT aniName="legalbyte-sn-mobi" />
              </Row>
              <Row className="dash-mobilepanel-link-text-container">
                <p className="dash-mobilepanel-link-text">Payments</p>
              </Row>
            </Col>
          </LinkContainer>

          <LinkContainer
            to={"/lawyer-dashboard/ask-jinx-for-lawyers"}
            // onClick={() => setExpanded(false)}
          >
            <Col
                className="dash-mobilepanel-link"
                id="dashnav-ai-assistant-lawyer-mobi"
                onMouseEnter={() => lottie.play("ll-brain-mobile-icon")}
                onMouseLeave={() => lottie.stop("ll-brain-mobile-icon")}
              >
                <Row className="dash-mobilepanel-link-icon-parent">
                  <BrainLoT aniName="ll-brain-mobile-icon" />
                </Row>
              <Row className="dash-mobilepanel-link-text-container">
                <p className="dash-mobilepanel-link-text">Ask Jinx</p>
              </Row>
            </Col>
          </LinkContainer>
          {/* Unlogged in user sees 'Ask Jinx (AI)' feature availability */}
          {/* {!userInfo && (
            <LinkContainer
              className="col"
              to={"/login"}
              // onClick={() => setExpanded(false)}
            >
              <Col
                className="dash-mobilepanel-link"
                onMouseEnter={() => lottie.play("ll-brain-mobile-icon")}
                onMouseLeave={() => lottie.stop("ll-brain-mobile-icon")}
              >
                <Row className="dash-mobilepanel-link-icon-parent">
                  <BrainLoT aniName="ll-brain-mobile-icon" />
                </Row>
                <Row className="dash-mobilepanel-link-text-container">
                  <p className="dash-mobilepanel-link-text ll-grey-colour">
                    Ask Jinx
                  </p>
                </Row>
              </Col>
            </LinkContainer>
          )} */}
          {/* Unlogged in user sees 'Documents' feature availability */}
          {!userInfo && (
            <LinkContainer
              to={"/login"}
              // onClick={() => setExpanded(false)}
            >
              <Col
                className="dash-mobilepanel-link"
                onMouseEnter={() => lottie.play("Archive-docs-sn-mobi")}
                onMouseLeave={() => lottie.stop("Archive-docs-sn-mobi")}
              >
                <Row className="dash-mobilepanel-link-icon-parent">
                  <ArchiveLoT aniName="Archive-docs-sn-mobi" />
                </Row>
                <Row className="dash-mobilepanel-link-text-container">
                  <p className="dash-mobilepanel-link-text ll-grey-colour">
                    Documents
                  </p>
                </Row>
              </Col>
            </LinkContainer>
          )}
        </Row>
      </Row>
    </Row>
  );
}

export default LawyerMobileDashNav;
