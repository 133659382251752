/* eslint-disable */

import React from "react";
import lottie from "lottie-web";
import key from "./key-lottie-lacoona.json";
import { useEffect } from "react";

function KeyLoT(props) {
  const aniName = props.aniName;

  const animationContainer = React.createRef();

  useEffect(() => {
      lottie.loadAnimation({
      name: aniName,
      container: animationContainer.current,
      animationData: key,
      autoplay: false,
      loop: true,
    });
  }, []);

  return <div className={aniName} ref={animationContainer}></div>;
}

export default KeyLoT;
