import React, { useEffect, useState } from "react";
import { Row, Col, Container, Table, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Loader from "../components/Loader";

import axios from "axios";
import { useNavigate } from "react-router-dom";

function CheckoutScreen() {
  let navigate = useNavigate();
  const [radioOne, setRadioOne] = useState(false);
  const [radioTwo, setRadioTwo] = useState(false);

  const { cartItems } = useSelector((state) => state.cart);
  const myCartItems = cartItems[0];

  const [myData, setMyData] = useState("");
  const { userInfo } = useSelector((state) => state.userLogin);
  // setTimeout(setLoading(false), 2000);
  const handleOnChange1 = () => {
    setRadioOne(!radioOne);
  };
  const handleOnChange2 = () => {
    setRadioTwo(!radioTwo);
  };

  function back() {
    navigate(-1);
  }

  useEffect(() => {
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo?.token}`,
      },
    };
    // eslint-disable-next-line
    const { data } = axios
      .get("/api/orders/get-order/", config)
      .then(function (response) {
        console.log(response);
        console.log("response data", response.data);
        setMyData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);

  console.log("state data", myData);

  // console.log("cartItems", cartItems);
  const paymentId = myData?.id?.toString();
  const orderValue = myData?.orderValue?.toString();
  const productName = myData?.productName?.toString();

  // sandbox params below

  // const params = new URLSearchParams({
  //   merchant_id: "10027595",
  //   merchant_key: "tvx6wyasabeop",
  //   return_url: "https://7f6d-165-0-184-218.sa.ngrok.io/checkout/success",
  //   cancel_url: "https://7f6d-165-0-184-218.sa.ngrok.io/checkout/cancel",
  //   notify_url: "https://7f6d-165-0-184-218.sa.ngrok.io/api/payments/notify/",
  //   m_payment_id: paymentId,
  //   amount: orderValue,
  //   item_name: productName,
  //   subscription_type: "1",
  //   frequency: "3",
  //   cycles: "12",
  //   passphrase: "MyS4ltfr4z3kkr6k_",
  // });

  const params = new URLSearchParams({
    merchant_id: "15816925",
    merchant_key: "q1tfaqdlsu5nc",
    return_url: "https://www.lacoona.legal/checkout/success",
    cancel_url: "https://www.lacoona.legal/checkout/cancel",
    notify_url: "https://www.lacoona.legal/api/payments/notify/",
    m_payment_id: paymentId,
    amount: orderValue,
    item_name: productName,
    subscription_type: "1",
    frequency: "3",
    cycles: "12",
    passphrase: "LegalFundi.payfast-101-security",
  });

  // Create an MD5 signature of it.
  var md5 = require("md5");
  console.log("radioone", radioOne);

  const MD5Signature = md5(params.toString());
  return (
    <div>
      <Header />
      {myData ? (
        <Container className="full-screen-container-2 mt-lg-5 pt-lg-5">
          <Row className="mt-lg-5">
            <Col lg={7} align="center">
              <Row>
                <Col className="mt-5 mt-md-0">
                  <button
                    className="lcn-button-orange px-3 py-2"
                    onClick={back}
                  >
                    Go back
                  </button>
                </Col>
              </Row>
              <Row className="mt-2">
                <div>
                  <img
                    className="checkout-image"
                    alt="legal-roadmap"
                    src={
                      "https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Your-Legal-roadmap-Compressed.png"
                    }
                  />
                </div>
              </Row>
            </Col>
            <Col className="mt-3 mt-md-0">
              <Row>
                <h3 className="serious-font">Your order</h3>
              </Row>
              <Row>
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong>{myCartItems?.name}</strong>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Subtotal</td>
                      <td>{myCartItems?.cost}</td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td>{myCartItems?.cost}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Recurring costs</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Subtotal</td>
                      <td>{myCartItems?.cost}</td>
                    </tr>
                    <tr>
                      <td>Recurring total</td>
                      <td>{myCartItems?.cost}</td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
              <Row>
                <Col align="center">
                  <Form>
                    <Form.Check
                      onChange={handleOnChange1}
                      checked={radioOne}
                      type={"checkbox"}
                    />
                  </Form>
                </Col>
                <Col lg={10}>
                  Your personal data will be used to process your order, support
                  your experience throughout the website, and for other purposes
                  described in our Privacy Policy
                </Col>
              </Row>
              <Row className="mt-3"></Row>
              <Row className="mt-3">
                <Col align="center">
                  <Form>
                    <Form.Check
                      onChange={handleOnChange2}
                      checked={radioTwo}
                      type={"checkbox"}
                    />
                  </Form>
                </Col>
                <Col lg={10}>
                  I have read and agreed to the website Terms of Service
                </Col>
              </Row>

              <form
                action="https://www.payfast.co.za/eng/process"
                method="post"
              >
                <input
                  type="hidden"
                  name="merchant_id"
                  value="15816925"
                ></input>
                <input
                  type="hidden"
                  name="merchant_key"
                  value="q1tfaqdlsu5nc"
                ></input>
                <input
                  type="hidden"
                  name="return_url"
                  value="https://www.lacoona.legal/checkout/success"
                ></input>
                <input
                  type="hidden"
                  name="cancel_url"
                  value="https://www.lacoona.legal/checkout/cancel"
                ></input>
                <input
                  type="hidden"
                  name="notify_url"
                  value="https://www.lacoona.legal/api/payments/notify/"
                ></input>
                <input
                  type="hidden"
                  name="m_payment_id"
                  value={paymentId}
                ></input>
                <input type="hidden" name="amount" value={orderValue}></input>
                <input
                  type="hidden"
                  name="item_name"
                  value={productName}
                ></input>
                <input type="hidden" name="subscription_type" value="1"></input>
                <input type="hidden" name="frequency" value="3"></input>
                <input type="hidden" name="cycles" value="12"></input>
                <input type="hidden" name="signature" value={MD5Signature} />
                {radioOne && radioTwo && (
                  <div className="subscribe-button-container">
                    <input
                      className="lcn-button-orange px-3 py-2 mt-2"
                      type="submit"
                      value="Subscribe"
                    ></input>
                  </div>
                )}
              </form>
            </Col>
          </Row>
        </Container>
      ) : (
        <div>
          <Row className="divider"></Row>
          <Row className="checkout-loader-row">
            <Loader />
          </Row>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default CheckoutScreen;
