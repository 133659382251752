import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import {Helmet} from 'react-helmet-async';

import Header from "../components/Header";
import Footer from "../components/Footer";


function ForIncubatorsScreen() {
  // eslint-disable-next-line no-lone-blocks
  {
    /* React form meta */
  }
  const [formStep, setFormStep] = useState(1);

  const [formData, setFormData] = useState({
    formType: "for_incubators",
    firstname: "",
    lastname: "",
    emailAddress: "",
    orgName: "",
    featuredlink: "",
    numberOfSMEs: "",
    numberOfCohorts: "",
    currentSupport: [],
    aidOffers: [],
  });

  const currentSupportOptions = [
    { name: "All operational documents", id: 1 },
    { name: "All founding and regulation documents and processes", id: 2 },
    { name: "Lawyer Access", id: 3 },
    { name: "Legal Process access", id: 4 },
    { name: "Capital raise legal processes and documents", id: 5 },
    { name: "Company Secretarial service", id: 6 },
    { name: "Online and Data privacy", id: 7 },
    { name: "Other", id: 8 },
  ];

  const aidOffersOptions = [
    { name: "Access to affortable lawyers", id: 1 },
    { name: "Stakeholder reporting", id: 2 },
    { name: "Monitoring and Evaluation of SMEs", id: 3 },
    { name: "Affordable and tangible services", id: 4 },
    { name: "Other", id: 5 },
  ];

  function onSelectSupportOptions(e) {
    const areasArray = [];
    e.map((item) => areasArray.push(item.name));
    setFormData((formData) => ({
      ...formData,
      currentSupport: areasArray,
    }));
  }

  function onRemoveSupportOptions(e) {
    const areasArray = [];
    e.map((item) => areasArray.push(item.name));
    setFormData((formData) => ({
      ...formData,
      currentSupport: areasArray,
    }));
  }

  function onSelectOffersOptions(e) {
    const areasArray = [];
    e.map((item) => areasArray.push(item.name));
    setFormData((formData) => ({
      ...formData,
      aidOffers: areasArray,
    }));
  }

  function onRemoveOffersOptions(e) {
    const areasArray = [];
    e.map((item) => areasArray.push(item.name));
    setFormData((formData) => ({
      ...formData,
      aidOffers: areasArray,
    }));
  }

  function submitFormStep(e) {
    console.log("logged");
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: e.target.value,
    }));
  }

  function preventSubmit(e) {
    e.preventDefault();
  }

  function setNextFormStep() {
    setFormStep((formStep) => formStep + 1);
  }

  function setPreviousFormStep() {
    setFormStep((formStep) => formStep - 1);
  }

  function submitForm() {
    setFormStep((formStep) => formStep + 1);

    try {
      // eslint-disable-next-line
      const resp = axios.post(
        "https://hook.eu1.make.com/c52sie4s11h7el6990susp17dx0854zl",
        { formData }
      );
      console.log("Response");
    } catch (error) {
      console.log("error.response");
    }
  }

  return (
    <div>
        <Helmet>
        <title>For Incubators</title>
        <meta name="description" content="Empowerment shouldn't be expensive. That's why we aren't. Empowering solutions for your cohort."/>
        <link rel="canonical" href="/for-incubators"/>
      </Helmet>
      <Header />
      <Container align="center" className="page-container front-page-container">
        {/* This is the Navigation divider for the front end of our pages */}
        <Row className="divider-parent">
          <Col align="center" className="utility-divider-lines"></Col>
          <Col align="center" className="util-icon-parent">
            <i className="fa-regular fa-circle-dot fa-1x divider-icon"></i>
          </Col>
          <Col align="center" className="utility-divider-lines"></Col>
        </Row>
        {/* Page Hero starts here */}
        <Row className="hero-container lcn-section-spacing lcn-page-width-section">
          {/* Floating images placed here so that we can use absolute as the position prop */}
          <img
            id="for-inc-split-ball"
            className="lcn-bounce"
            src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Split-ball.svg"
            alt="Background images"
          ></img>
          <img
            id="for-inc-ring"
            className="lcn-swing mt-5"
            src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/ring.svg"
            alt="Background images"
          ></img>
          <img
            id="for-inc-skwigle"
            className="lcn-slide"
            src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/skwigle.svg"
            alt="Background images"
          ></img>
          <Row align="center" className="content-center">
            <div className="my-5">
              <h1 className="serious-font hero-header-center">
                Empowerment shouldn't be expensive.
                <br /> That's why we aren't. Empowering solutions for your
                cohort.
              </h1>
              <p className="hero-header-blurb">
                Everything you need to manage your cohort and support them in
                formalising their businesses and unlocking growth.
              </p>
            </div>
            {/* CTA Section */}
            <Row className="hero-button-parent">
              <Col className="my-2">
                <LinkContainer
                to={'/register'}> 
                <button className="lcn-button-orange px-4 py-3">
                  Start your journey
                </button>
                </LinkContainer>
               
              </Col>
              <Col className="my-2">
                <a href = "https://calendar.google.com/calendar/appointments/schedules/AcZssZ2cWDUQamVqaMkDqTF3Pus3dFx1aIJE1rQ9NdF6A1p8_4WkI5h32BSPSOkk3N4FLh4IiwmfaI1V"><button className="lcn-button-orange-outline  px-4 py-3">
                  Speak to us
                </button></a>
                
              </Col>
            </Row>
          </Row>
        </Row>
        {/* Tooling Section */}
        <Row className="lcn-section-spacing lcn-page-width-section">
          <Col className="ll-frontend-content-block ll-section-1 ">
            <Row className="ll-front-content-block-text-parent">
              <h4 className="ll-pre-title">Tooling</h4>
              <h3 className="ll-subheading">
                Supplemental and affordable tools for your cohort.
              </h3>
              <p className="ll-body">
                A single portal allowing CIPC business registration, UIF
                management, B-BBEE management, corporate governance
                documentation, employee management and documentation, web
                management, supplier documentation and product legals all
                delivered through simple language choice and step-by-step
                walk-throughs of legal processes.
              </p>
              <a href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2cWDUQamVqaMkDqTF3Pus3dFx1aIJE1rQ9NdF6A1p8_4WkI5h32BSPSOkk3N4FLh4IiwmfaI1V" className="ll-cta-icon-parent">
                <strong>
                  <p>Find Out More</p>
                </strong>
                <i className="fa-solid fa-arrow-right ll-cta-icon"></i>
              </a>
            </Row>
            <Row>
              <Col className="ll-mini-card">
                <div className="ll-mini-card-inner">
                  <h1 className="ll-mini-card-title">Step-by-Step Guidance</h1>
                </div>
              </Col>
              <Col className="ll-mini-card">
                <div className="ll-mini-card-inner">
                  <h1 className="ll-mini-card-title">Document Automation</h1>
                </div>
              </Col>
              <LinkContainer to={'/dashboard/legal-products'}>
              <Col className="ll-mini-card clickable">
                  <i className="fa-solid fa-circle-plus ll-cta-icon"></i>
                  <strong>
                    <p>Much more</p>
                  </strong>
                </Col>
                </LinkContainer> 
            </Row>
          </Col>
          <Col className="ll-section-2">
            <img
              className="ll-content-image"
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Reece-Tools-compressed.png"
              alt="Developer Floating with Tools"
            ></img>
          </Col>
        </Row>
        {/* Cohort oversight section */}
        <Row className="lcn-section-spacing lcn-page-width-section ll-flex-col-reverse">
          <Col className="ll-section-2">
            <img
              className="ll-content-image "
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Oversight-bot-tools.png"
              alt="Oversight Tooling Bot"
            ></img>
          </Col>
          <Col className="ll-frontend-content-block ll-section-1">
            <Row className="ll-front-content-block-text-parent">
              <h4 className="ll-pre-title">Cohort oversight</h4>
              <h3 className="ll-subheading">
                Oversight, distribution and communication.
              </h3>
              <p className="ll-body">
                We provide a single portal for both the incubator administrator
                as well as the business. Administrators have control over access
                points and can view any company within their cohort having
                oversight of their system usage and documents if granted
                permission. Parent incubator partners have the ability to view
                who needs further support and where resources can be best
                applied via a visual administrator dashboard.
              </p>
              <a href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2cWDUQamVqaMkDqTF3Pus3dFx1aIJE1rQ9NdF6A1p8_4WkI5h32BSPSOkk3N4FLh4IiwmfaI1V" className="ll-cta-icon-parent">
                <strong>
                  <p>Speak to us</p>
                </strong>
                <i className="fa-solid fa-arrow-right ll-cta-icon"></i>
              </a>
            </Row>
          </Col>
        </Row>
        {/* Organisation-level reporting section */}
        <Row className="lcn-section-spacing lcn-page-width-section">
          <Col className="ll-frontend-content-block ll-section-1">
            <Row className="ll-front-content-block-text-parent">
              <h4 className="ll-pre-title">Organisation-level reporting</h4>
              <h3 className="ll-subheading">
                Report generation for external stakeholders.
              </h3>
              <p className="ll-body">
                Lacoona provides tools for business incubators who need to show
                demonstrable results to funders and external stakeholders. By
                providing the tools to startups, our framework generates reports
                on their progress into the formalised economy where they can
                thrive. Over and above the administration and communication
                functions, report access and generation is available aligned to
                any parameters.
              </p>
              <a href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2cWDUQamVqaMkDqTF3Pus3dFx1aIJE1rQ9NdF6A1p8_4WkI5h32BSPSOkk3N4FLh4IiwmfaI1V" className="ll-cta-icon-parent">
                <strong>
                  <p>Schedule an information session</p>
                </strong>
                <i className="fa-solid fa-arrow-right ll-cta-icon"></i>
              </a>
            </Row>
          </Col>
          <Col className="ll-section-2">
            <img
              className="ll-content-image"
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/compu-bot-tools.png"
              alt="Computer showing Tools"
            ></img>
          </Col>
        </Row>
        <Row className="lcn-section-spacing ll-dark-section py-5 lcn-fullwidth-section">
        <Row className="lcn-page-width-section">
          {" "}
          {/* Form Section */}
          <Col className="ll-frontend-content-block">
            <Row className="ll-front-content-block-text-parent-dark">
              <h3 className="ll-subheading">
                Support your next cohort with ease!
              </h3>
              <p className="ll-body">
                Lacoona offers incubator support functionality for parent
                organisations, ecosystems and accelerators. This allows for the
                effective distribution, communication and reporting of
                business-cohort use showing tangible support and impact. All of
                their legal needs met with reporting to show.
              </p>
              <a href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2cWDUQamVqaMkDqTF3Pus3dFx1aIJE1rQ9NdF6A1p8_4WkI5h32BSPSOkk3N4FLh4IiwmfaI1V" className="ll-cta-icon-parent">
                <strong>
                  <p>Schedule an information session</p>
                </strong>
                <i className="fa-solid fa-arrow-right ll-cta-icon"></i>
              </a>
            </Row>
          </Col>
          <Col className="">
            {/* Form Attempt */}
            <Row align="center" className="find-a-lawyer-form-container incubator-form">
              {/* First Question */}
              {formStep === 1 && (
                <Col className="form-step" lg={8}>
                  <Row>
                    <h4 className="form-step-heading-dark">
                      Please tell us your First Name
                    </h4>
                  </Row>
                  <Row>
                    <Form onSubmit={preventSubmit}>
                      <Form.Control
                        type="input"
                        className="mb-3"
                        onChange={submitFormStep}
                        name="firstname"
                      />
                    </Form>
                  </Row>
                  <Row>
                    <Col className="ll-form-back-btn">
                      <button
                        className="lcn-button-orange  py-2 px-4"
                        onClick={setPreviousFormStep}
                      >
                        Back
                      </button>
                    </Col>
                    <Col className="form-step-next-button-container">
                      <button
                        className="lcn-button-orange py-2 px-4"
                        onClick={setNextFormStep}
                      >
                        Next
                      </button>
                    </Col>
                  </Row>
                </Col>
              )}
              {/*  Second Question */}
              {formStep === 2 && (
                <Col className="form-step" lg={8}>
                  <Row>
                    <h4 className="form-step-heading-dark">
                      Please tell us your Last Name
                    </h4>
                  </Row>
                  <Row>
                    <Form onSubmit={preventSubmit}>
                      <Form.Control
                        type="input"
                        className="mb-3"
                        onChange={submitFormStep}
                        name="lastname"
                      />
                    </Form>
                  </Row>
                  <Row>
                    <Col className="ll-form-back-btn">
                      <button
                        className="lcn-button-orange py-2 px-4"
                        onClick={setPreviousFormStep}
                      >
                        Back
                      </button>
                    </Col>
                    <Col className="form-step-next-button-container">
                      <button
                        className="lcn-button-orange py-2 px-4"
                        onClick={setNextFormStep}
                      >
                        Next
                      </button>
                    </Col>
                  </Row>
                </Col>
              )}
              {/* Third Question */}
              {formStep === 3 && (
                <Col className="form-step" lg={8}>
                  <Row>
                    <h4 className="form-step-heading-dark">Email</h4>
                  </Row>
                  <Row>
                    <Form onSubmit={preventSubmit}>
                      <Form.Control
                        type="input"
                        className="mb-3"
                        onChange={submitFormStep}
                        name="emailAddress"
                      />
                    </Form>
                  </Row>
                  <Row>
                    <Col className="ll-form-back-btn">
                      <button
                        className="lcn-button-orange py-2 px-4"
                        onClick={setPreviousFormStep}
                      >
                        Back
                      </button>
                    </Col>
                    <Col className="form-step-next-button-container">
                      <button
                        className="lcn-button-orange py-2 px-4"
                        onClick={setNextFormStep}
                      >
                        Next
                      </button>
                    </Col>
                  </Row>
                </Col>
              )}
              {/* Fourth Question */}
              {formStep === 4 && (
                <Col className="form-step" lg={8}>
                  <Row>
                    <h4 className="form-step-heading-dark">
                      Please state the name of the organisation, corporate,
                      incubator or accelerator who you are submitting this form
                      on behalf of.
                    </h4>
                  </Row>
                  <Row>
                    <Form onSubmit={preventSubmit}>
                      <Form.Control
                        type="input"
                        className="mb-3"
                        onChange={submitFormStep}
                        name="orgName"
                      />
                    </Form>
                  </Row>
                  <Row>
                    <Col className="ll-form-back-btn">
                      <button
                        className="lcn-button-orange py-2 px-4"
                        onClick={setPreviousFormStep}
                      >
                        Back
                      </button>
                    </Col>
                    <Col className="form-step-next-button-container">
                      <button
                        className="lcn-button-orange py-2 px-4"
                        onClick={setNextFormStep}
                      >
                        Next
                      </button>
                    </Col>
                  </Row>
                </Col>
              )}
              {/* Fifth Question */}
              {formStep === 5 && (
                <Col className="form-step" lg={8}>
                  <Row>
                    <h4 className="form-step-heading-dark">
                      Please provide a link to your website, LinkedIn or
                      Facebook page.
                    </h4>
                  </Row>
                  <Row>
                    <Form onSubmit={preventSubmit}>
                      <Form.Control
                        type="input"
                        className="mb-3"
                        onChange={submitFormStep}
                        name="featuredlink"
                      />
                    </Form>
                  </Row>
                  <Row>
                    <Col className="ll-form-back-btn">
                      <button
                        className="lcn-button-orange py-2 px-4"
                        onClick={setPreviousFormStep}
                      >
                        Back
                      </button>
                    </Col>
                    <Col className="form-step-next-button-container">
                      <button
                        className="lcn-button-orange py-2 px-4"
                        onClick={setNextFormStep}
                      >
                        Next
                      </button>
                    </Col>
                  </Row>
                </Col>
              )}
              {/* Sixth Question */}
              {formStep === 6 && (
                <Col className="form-step" lg={8}>
                  <Row>
                    <h4 className="form-step-heading-dark">
                      How many SMEs are you currently supporting annually?
                    </h4>
                  </Row>
                  <Row>
                    <Form onSubmit={preventSubmit}>
                      <Form.Control
                        type="input"
                        className="mb-3"
                        onChange={submitFormStep}
                        name="numberOfSMEs"
                      />
                    </Form>
                  </Row>
                  <Row>
                    <Col className="ll-form-back-btn">
                      <button
                        className="lcn-button-orange py-2 px-4"
                        onClick={setPreviousFormStep}
                      >
                        Back
                      </button>
                    </Col>
                    <Col className="form-step-next-button-container">
                      <button
                        className="lcn-button-orange py-2 px-4"
                        onClick={setNextFormStep}
                      >
                        Next
                      </button>
                    </Col>
                  </Row>
                </Col>
              )}
              {/* Seventh Question */}
              {formStep === 7 && (
                <Col className="form-step" lg={8}>
                  <Row>
                    <h4 className="form-step-heading-dark">
                      How many cohorts is this broken up into?
                    </h4>
                  </Row>
                  <Row>
                    <Form onSubmit={preventSubmit}>
                      <Form.Control
                        type="input"
                        className="mb-3"
                        onChange={submitFormStep}
                        name="numberOfCohorts"
                      />
                    </Form>
                  </Row>
                  <Row>
                    <Col className="ll-form-back-btn">
                      <button
                        className="lcn-button-orange py-2 px-4"
                        onClick={setPreviousFormStep}
                      >
                        Back
                      </button>
                    </Col>
                    <Col className="form-step-next-button-container">
                      <button
                        className="lcn-button-orange py-2 px-4"
                        onClick={setNextFormStep}
                      >
                        Next
                      </button>
                    </Col>
                  </Row>
                </Col>
              )}
              {/*  Eighth Question */}
              {formStep === 8 && (
                <Col className="form-step" lg={8}>
                  <Row>
                    <h4 className="form-step-heading-dark">
                      How are you currently supporting your ecosystem with
                      regards to legal access? You can select multiple.
                    </h4>
                  </Row>
                  <Row>
                    <Multiselect
                      options={currentSupportOptions} // Options to display in the dropdown
                      selectedValues={currentSupportOptions[1]} // Preselected value to persist in dropdown
                      onSelect={onSelectSupportOptions} // Function will trigger on select event
                      onRemove={onRemoveSupportOptions} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                    />
                  </Row>
                  <Row className="mt-3">
                    <Col className="ll-form-back-btn">
                      <button
                        className="lcn-button-orange py-2 px-4"
                        onClick={setPreviousFormStep}
                      >
                        Back
                      </button>
                    </Col>
                    <Col className="form-step-next-button-container">
                      <button
                        className="lcn-button-orange py-2 px-4"
                        onClick={setNextFormStep}
                      >
                        Next
                      </button>
                    </Col>
                  </Row>
                </Col>
              )}
              {/*  Eigth Question */}
              {formStep === 9 && (
                <Col className="form-step" lg={8}>
                  <Row>
                    <h4 className="form-step-heading-dark">
                      Which of the following would aid you in performing your
                      role as a support agency? You can select multiple.
                    </h4>
                  </Row>
                  <Row>
                    <Multiselect
                      options={aidOffersOptions} // Options to display in the dropdown
                      selectedValues={aidOffersOptions[1]} // Preselected value to persist in dropdown
                      onSelect={onSelectOffersOptions} // Function will trigger on select event
                      onRemove={onRemoveOffersOptions} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                    />
                  </Row>
                  <Row>
                    <Col>
                      <button
                        className="lcn-button-orange py-2 px-4"
                        onClick={setPreviousFormStep}
                      >
                        Back
                      </button>
                    </Col>
                    <Col className="form-step-next-button-container">
                      <button
                        className="lcn-button-orange py-2 px-4"
                        onClick={submitForm}
                      >
                        Submit
                      </button>
                    </Col>
                  </Row>
                </Col>
              )}
              {formStep === 10 && (
                <Col className="form-step" lg={8}>
                  <Row>
                    <h4 className="form-step-heading-dark">
                      Thank you for completing the form, we will be in touch
                      shortly!
                    </h4>
                  </Row>
                </Col>
              )}
            </Row>
            {/* End of Form Attemp */}
          </Col>
          </Row>
        </Row>

        {/* Page Tagline Section */}
        <Row className="ll-dark-section py-5 lcn-fullwidth-section">
          <h1 className="ll-title-light">Supporting the supporter</h1>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default ForIncubatorsScreen;
