import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Col, Container, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import {  useSelector } from "react-redux";
import lottie from "lottie-web";

import "../dancss.css";
import ScalesLoT from "../lottie-files/ScalesLoT";
import Layers from "../lottie-files/Layers";
import JourneyLoT from "../lottie-files/JourneyLoT";

function RegisterThankYouScreen() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <div>
      <Header />
      <Container className="screen-container">
        <Row className="title-ty-parent">
          <h1 className="ll-feat-font">Welcome to Lacoona</h1>
          <h4>Where would you like to begin?</h4>
        </Row>
        <Row>
          {userInfo?.role === "sme" && (
            <LinkContainer to={"/dashboard/sme-profile"}>
              <Col>
                <div
                  className="ll-card-interact ll-thankyou-nav-card ll-ty-legalproducts p-5"
                  onMouseEnter={() => lottie.play("layer-ty")}
                  onMouseLeave={() => lottie.stop("layer-ty")}
                >
                  <h1>Your Dashboard</h1>
                  <p>
                    Stay up to date with the health, performance and activities
                    of your company with our all in on company dashboard.
                  </p>
                  <Layers aniName="layer-ty" />
                  <div className="lcn-button-brown ll-ty-go-now-btn ">
                    <span>Go now</span>
                  </div>
                </div>
              </Col>
            </LinkContainer>
          )}
          {userInfo?.role === "incubator" && (
            <LinkContainer to={"/dashboard/incubator-profile"}>
              <Col>
                <div
                  className="ll-card-interact ll-thankyou-nav-card ll-ty-legalproducts p-5"
                  onMouseEnter={() => lottie.play("layer-ty")}
                  onMouseLeave={() => lottie.stop("layer-ty")}
                >
                  <h1>Your Dashboard</h1>
                  <p>
                    Stay up to date with the health, performance and activities
                    of your company with our all in on company dashboard.
                  </p>
                  <Layers aniName="layer-ty" />
                  <div className="lcn-button-brown ll-ty-go-now-btn ">
                    <span>Go now</span>
                  </div>
                </div>
              </Col>
            </LinkContainer>
          )}
          {userInfo?.role === "ecosystem" && (
            <LinkContainer to={"/dashboard/ecosystem-profile"}>
              <Col>
                <div
                  className="ll-card-interact ll-thankyou-nav-card ll-ty-legalproducts p-5"
                  onMouseEnter={() => lottie.play("layer-ty")}
                  onMouseLeave={() => lottie.stop("layer-ty")}
                >
                  <h1>Your Dashboard</h1>
                  <p>
                    Stay up to date with the health, performance and activities
                    of your company with our all in on company dashboard.
                  </p>
                  <Layers aniName="layer-ty" />
                  <div className="lcn-button-brown ll-ty-go-now-btn ">
                    <span>Go now</span>
                  </div>
                </div>
              </Col>
            </LinkContainer>
          )}
          <Col>
            <LinkContainer to={"/dashboard/legal-products"}>
              <div
                className="ll-card-interact ll-thankyou-nav-card ll-ty-legalproducts p-5"
                onMouseEnter={() => lottie.play("scales-ty")}
                onMouseLeave={() => lottie.stop("scales-ty")}
              >
                <h1>Legal Products</h1>
                <p>
                  Browse our wide range of chatbot guided Legal Products to help
                  you grow, maintain and deal with all your business and legal
                  related matters
                </p>
                <ScalesLoT aniName="scales-ty" />
                <div className="lcn-button-brown ll-ty-go-now-btn ">
                  <span>Go now</span>
                </div>
              </div>
            </LinkContainer>
          </Col>
          <Col>
            <div
              className="ll-card-interact ll-thankyou-nav-card ll-ty-legalproducts p-5"
              onMouseEnter={() => lottie.play("journey-ty")}
              onMouseLeave={() => lottie.stop("journey-ty")}
            >
              <h1>Guided Onboarding</h1>
              <p>
                Let us show you around...treat you right. The law can be scary,
                so we wont be.
              </p>
              <JourneyLoT aniName="journey-ty" />
              <div className="lcn-button-brown ll-ty-go-now-btn ">
                <span>Coming Soon</span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default RegisterThankYouScreen;
