import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Row, Col, Container } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { removeFromCart } from "../actions/cartActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function PreCheckoutScreen() {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  function back() {
    dispatch(removeFromCart());
    navigate(-1);
  }
  return (
    <div>
      <Header />
      <Container className="full-screen-container mt-lg-5 pt-lg-5 px-5">
        <Row className="mt-5 pt-5">
          <Col>
            <Row className="pre-checkout-feature-container mt-2 mx-3 py-3 px-5">
              <Row align="center">
                <h1 className="serious-font ll-grey-colour">Your order:</h1>
              </Row>
              <Row align="center">
                <h3 className="serious-font">
                  All-in-One subscription (R250 pm)
                </h3>
              </Row>
              <Col className="pre-checkout-bot-container">
                <img
                  className="bot-image-pre-checkout"
                  src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Oversight-bot-tools.png"
                  alt="Oversight Tooling Bot"
                ></img>
              </Col>
              <Col>
                <Row>
                  <h4 className="serious-font ll-grey-colour">
                    Unlimited access to
                  </h4>
                </Row>
                <ul>
                  <li>Legal Health Checks</li>
                  <li>AI Virtual legal assistant</li>
                  <li>Document access and Automation</li>
                  <li>Process Guidance</li>
                  <li>Find a Lawyer</li>
                  <li>Business Legal 101 Masterclass</li>
                </ul>
                {/* <Row>Legal Health Checks</Row>
                <Row>AI Virtual legal assistant</Row>
                <Row>Document access and Automation</Row>
                <Row>Process Guidance</Row>
                <Row>Find a Lawyer</Row>
                <Row>Business Legal 101 Masterclass</Row> */}
              </Col>
            </Row>
          </Col>
          <Col className="pre-checkout-proceed mt-3 mt-md-0" align="center">
            <Row>
              <h3 className="serious-font">Next steps:</h3>
            </Row>
            <Row className="pre-checkout-next-steps mx-3 py-3 px-3">
              <span>
                After clicking proceed, you'll be redirected to a checkout page
                with an invoice for your subscription. On that page, you'll
                finalize your subscription and be redirected to PayFast, where
                you will enter your account details and make your first monthly
                payment. From that point on, you'll be billed monthly until the
                point you unsubscribe.
              </span>
            </Row>
            <Row className="mt-3">
              <Col>
                <button onClick={back} className="lcn-button-orange py-2 px-2">
                  Go Back
                </button>
              </Col>
              <Col className="pre-checkout-brown-button-col">
                <LinkContainer to={"/checkout"}>
                  <button className="lcn-button-brown-no-padding py-2 px-2">
                    Proceed
                  </button>
                </LinkContainer>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default PreCheckoutScreen;
