import React, { useState } from "react";
import { Container, Row, Col, Button, Accordion, Navbar, } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { MendableInPlace } from "@mendable/search";
import { useRef } from 'react';


function WeitaVinnyProducer() {
    //Header Stuff
    const [header, setHeader] = useState(false);

    function changeBackground() {
      if (window.scrollY >= 5) {
        setHeader(true);
      } else {
        setHeader(false);
      }
    }
    window.addEventListener("scroll", changeBackground);



  const mendable_style = { darkMode: false, accentColor: "#003963", backgroundColor:"#e4ebf3" }

  const paragraphcheck=document.querySelectorAll('p');
  
  paragraphcheck.forEach(p=>{
     if(p.textContent.includes('Powered by')){
       p.classList.add('hide-mendable');
     }
  })

  const ref = useRef(null);

  const handleClickToJinx = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div>
      <Helmet>
      <title>Weita Producer Assistant</title>
      <meta
        name="description"
        content="Weita Worker AI assistant by Legal Fundi."
      />
      <link rel="canonical" href="/weita-worker" />
      </Helmet>

      {/* <Header /> */}
      <header>
      <Navbar
        className={header ? "navbar-custom active " : "navbar-custom ll-org-clear-nav"}
        expand="lg"
      >
        <a href="https://www.lacoona.legal/" target="_blank" rel="noreferrer">
          <Navbar.Brand href="#">
            <img
              className="lcn-nav-logo pt-3 pb-3"
              alt="Lacoona Legal Chatbots"
              src="https://legalfundi.com/wp-content/uploads/2022/03/LegalFundi-light-1.svg"
            ></img>
          </Navbar.Brand>
        </a>
        </Navbar>
        </header>
        <Container align="center" className="page-container weita-bg front-page-container pt-5">
        <Row className="lcn-section-spacing for-lawyers--row lcn-page-width-section">
          {/* Header areaa */}
          <Row align="center">
            <Col lg={2}></Col>
            <Col lg={8}>
            <h1 className="lf-hero-font mb-5">Meet Vinny your personal legal guide</h1>
            <p className="lf-body-font">Navigating the complex landscape of agricultural policies can be daunting. With Vinny by your side, you'll have a trusted AI companion, meticulously trained on every policy a producer needs. From land use regulations to crop insurance intricacies, Vinny ensures you're always making informed decisions on the farm. Step into the field with confidence. Let Vinny handle the legalese.</p>
            </Col>
            <Col lg={2}></Col>
          </Row>
          <Row align="center" className="pt-4">
            <Col lg={3}></Col>
            <Col align="center" lg={3}>
            <a
              className="for-lawyers--schedule"
              href="https://wa.me/message/PLORCOJSM353M1"
              target="_blank"
              rel="noreferrer"
            ><button className="mobile-wrap lf-button px-4 my-1 py-3">Use Vinny on Whatsapp</button></a></Col>
            <Col align="center" lg={3}><a className="for-lawyers--schedule"
              href="/worker-assistant"
              target="blank"><button className="mobile-wrap lf-button my-1 px-4 py-3">Are you a Worker?</button></a></Col>
            <Col lg={3}></Col>
          </Row>
        </Row>
        {/* Try */}

      <Row id="try-jinx-corp-demo lf-dots-bg" ref={ref} className="lf-dots-bg">
        <h2 className="  lf-hero-font">Try Vinny Now</h2>
        <hr className="mt-4 mb-4 lf-burg-short-rule ll-hr-center"></hr>
        <Row className="mb-5 ms-auto me-auto p-0 lf-mendable">
          <MendableInPlace anon_key='83ba9a44-d616-46cd-8f27-9ed64b72b5f2' style={mendable_style} welcomeMessage={"Hi, Vinny here. What can I help you with today?"} botIcon={<img src="https://legalfundi.com/wp-content/uploads/2022/08/LegalFundi-Badge-og-2.png"/>} hintText={"My employee has not arrived at work and I’m unsure of what to do."}
          messageSettings={{
          hideSources:true
        }}
      // privacyDisclaimer={"The information provided by Jinx is for general information purposes only. While we try to keep the information up to date and correct, we cannot guarantee that it will be accurate. Anyone using this information does so at their own risk."}
        />
        <p className="mt-4">By using Vinny you agree to our <a className="lf-blue"target="_blank" href="/privacy-policy#:~:text=info%40lacoona.legal-,Using%20Jinx,-The%20information%20provided">Disclaimer</a></p>
      </Row>     
      
      </Row>
      
      {/* FAQs and other Content */}
      <Row align="center" className="lf-blue-background pt-5">
      <Accordion className="lf-accordion-super-parent">
          <Accordion.Item
            eventKey="0"
            className="lf-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
             Who is Vinny?
            </Accordion.Header>
            <Accordion.Body>
            Vinny is your AI-powered legal guide, specially trained to help producers navigate the world of agricultural policies and regulations.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="1"
            className="lf-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            How does Vinny work?
            </Accordion.Header>
            <Accordion.Body>
            Vinny provides instant answers about farm rules and policies, making sure you always take informed steps in your farming activities.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="4"
            className="lf-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            Do I still need a lawyer if I have Vinny?
            </Accordion.Header>
            <Accordion.Body>
            While Vinny provides guidance on legal matters, it's always a good idea to consult with a human legal expert for specific, complex situations or decisions.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="7"
            className="lf-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            How do I sign up?
            </Accordion.Header>
            <Accordion.Body>
            You can use Vinny on the web above or on whatsapp by clicking <a rel="noreferrer" target="_blank" href="https://wa.me/message/PLORCOJSM353M1">click here.</a>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="9"
            className="lf-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            How secure is the data stored in Vinny?
            </Accordion.Header>
            <Accordion.Body>
            Absolutely. Vinny values your data's security, ensuring it's encrypted, well-protected, and only accessed when needed to assist you.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="10"
            className="lf-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            Is Vinny compliant with data privacy regulations?
            </Accordion.Header>
            <Accordion.Body>
            Yes, Vinny is compliant with various data privacy regulations, such as GDPR and CCPA, ensuring your data is processed and stored securely and responsibly.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        </Row>
        </Container>
    </div>
  );
}

export default WeitaVinnyProducer;
