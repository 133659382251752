import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Accordion, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DashHeader from "./DashHeader";
import { LinkContainer } from "react-router-bootstrap";
import { Helmet } from "react-helmet-async";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import LawyerDashHeader from "./LawyerDashHeader";
import LawyerValidator from "./LawyerValidator";

function LawyerAppointments({ myEvents, localizer, scrollToTime, userInfo }) {
  //element.style.color = '#f19601';
  //element.style.fontWeight = 'bold';
  function applyCustomStyles() {
    const bookingElements = document.querySelectorAll(".rbc-agenda-event-cell");

    bookingElements.forEach((element) => {
      if (element.textContent === "Booking") {
        //Adds styling to the block that contains Booking
        element.style.color = "#f19601";
        element.style.fontWeight = "bold";
        const timeField = element.previousElementSibling;
        if (timeField && timeField.classList.contains("rbc-agenda-time-cell")) {
          const dateField = timeField.previousElementSibling;
          if (
            dateField &&
            dateField.classList.contains("rbc-agenda-date-cell")
          ) {
            //Adds styling to the date field
            dateField.style.color = "#f19601";
            dateField.style.fontWeight = "bold";
          }
        }

        if (timeField) {
          //Adds styling to the sibling  time field.
          timeField.style.color = "#f19601";
          timeField.style.fontWeight = "bold";
        }
      }
    });
  }

  useEffect(() => {
    const navButtons = document.querySelectorAll(".rbc-btn-group > button");

    const handleButtonClick = () => {
      // Ensures small delay before applying styles to load calendar
      setTimeout(() => {
        applyCustomStyles();
      }, 100);
    };

    navButtons.forEach((button) => {
      button.addEventListener("click", handleButtonClick);
    });

    setTimeout(() => {
      applyCustomStyles();
    }, 2000);
    // Cleans up event listeners when the component is unmounted
    return () => {
      navButtons.forEach((button) => {
        button.removeEventListener("click", handleButtonClick);
      });
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Lacoona Legal Lawyer Dashboard</title>
        <meta
          name="description"
          content="You centalised Legal Dashboard for managing your business on Lacoona Legal"
        />
        <link rel="canonical" href={"/dashboard/sme-profile"} />
      </Helmet>
      {userInfo?.profile === 4 && (
        <Col className="dashboard-scrollable-container" lg={9}>
          <LawyerDashHeader pageTitle={"Appointments"} />
          <Row className="dashboard-content-container">
            <Row className="dashboard-content-divider"></Row>

            <Col className="px-4">
              {/* Reece Alt Dashboard UI */}
              {/* Title */}
              <Row className="mb-3">
                <Col xs={6} md={8} lg={10}>
                  <h2 className="ll-subtitle ll-feat-font">Your coming week</h2>
                </Col>
              </Row>
              {/* Body Content */}
              <Row className="ll-dash-body-content">
                <Row>
                  {/* <LawyerCalAgenda /> */}
                  <Calendar
                    localizer={localizer}
                    events={myEvents}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    selectable
                    defaultView="agenda"
                    scrollToTime={scrollToTime}
                    views={["agenda"]}

                    // toolbar={false}
                  />
                </Row>
                <Row className="mt-5">
                  <LinkContainer to={"/lawyer-dashboard/schedule"}>
                    <button className="lcn-button-brown">
                      Create more sessions
                    </button>
                  </LinkContainer>
                </Row>
              </Row>
            </Col>
          </Row>
        </Col>
      )}

      {!userInfo ? (
        <LawyerValidator />
      ) : (
        userInfo?.profile === 0 && <LawyerValidator />
      )}
    </>
  );
}

export default LawyerAppointments;
