import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Modal } from "react-bootstrap";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import "react-big-calendar/lib/css/react-big-calendar.css";
import axios from "axios";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const myEventsList = [];

function LawyerDashScreen() {
  const lawyerDetails = useSelector((state) => state.userLawyerDetails);
  const { lawyer } = lawyerDetails;
  const { userInfo } = useSelector((state) => state.userLogin);

  const [myEvents, setMyEvents] = useState(myEventsList);
  const [selectedEvent, setSelectedEvent] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [eventsLength, setEventsLength] = useState(0);
  const [lawyerReady, setLawyerReady] = useState(false);
  // set events that populate the calendar based on lawyer's schedule

  // useEffect(() => {
  //   setLawyerReady(true);
  // }, [lawyer]);

  useEffect(() => {
    // console.log("lawyer.schedule", lawyer.schedule);
    console.log("lawyer", lawyer);
    if (lawyer && lawyer.appointments && lawyer.appointments.data.length > 0) {
      const appointmentsArray = JSON.parse(
        lawyer.appointments.data.replace(/'/g, '"')
      );
      // console.log("JSON.parse", arr);

      const convertedEvents = appointmentsArray.map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
      }));
      setMyEvents((old) => convertedEvents);
    }
  }, [lawyer]);

  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      // console.log("start", start);
      const title = "Opening";
      // let index = eventsLength;
      // console.log("index", index);
      // console.log("eventsLength in function", eventsLength);
      const randomNumber = Math.floor(Math.random() * 90000) + 10000;
      const status = "Opening";
      if (title) {
        setMyEvents((prev) => [
          ...prev,
          { start, end, title, id: randomNumber, status: status },
        ]);
      }
      // setEventsLength((old) => old + 1);
    },
    [setMyEvents, setEventsLength, eventsLength]
  );

  const handleSelectEvent = useCallback((event) => {
    setModalShow(true);
    setSelectedEvent(event);
    // window.alert(event.title);
  }, []);

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(2015, 3, 12),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  );

  // Modal functions for viewing events and deleting them
  function handleModalClose() {
    setModalShow(false);
  }
  function deleteSelectedEvent(id) {
    // console.log("index in delete event", id);
    // setEvents((prev) => prev.filter((_, i) => i.id !== id));
    setMyEvents((prev) => prev.filter((item) => item.id !== id));
  }

  // Function for saving event openings to db
  function saveOpenings() {
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const eventsString = JSON.stringify(myEvents);

    axios
      .post("/api/users/save-lawyer-openings/", eventsString, config)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log("myEvents", myEvents);
  }
  console.log("events", myEvents);
  // console.log("modalShow", modalShow);
  // console.log("selectedEvent", selectedEvent);
  // console.log("eventsLength", eventsLength);

  return (
    <div>
      <Row>LawyerDashScreen</Row>
      <Row>
        <Col>
          {" "}
          <button onClick={saveOpenings}>save</button>
        </Col>
      </Row>

      <Row>
        <Col>
          <LinkContainer to={"/"}>
            <button>home</button>
          </LinkContainer>
        </Col>
      </Row>
      <div>
        <Calendar
          localizer={localizer}
          events={myEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          selectable
          scrollToTime={scrollToTime}
        />
      </div>
      <div>
        <Modal show={modalShow} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedEvent?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <button onClick={() => deleteSelectedEvent(selectedEvent?.id)}>
              Delete event
            </button>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default LawyerDashScreen;
