import React, { useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import MediFileLoT from "../lottie-files/MediFileLoT";
import BrainLoT from "../lottie-files/BrainLoT";
import HammerOfJusticeLoT from "../lottie-files/HammerOfJusticeLoT";
import DocumentLoT from "../lottie-files/DocumentLoT";
import LegalbyteLoT from "../lottie-files/LegalbyteLoT";
import LawyerLoT from "../lottie-files/LawyerLoT";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Loader from "../components/Loader";

function CartScreenNew() {
  let navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.userLogin);
  const [loader, setLoader] = useState(false);

  // const cartItemsFromStorage = localStorage.getItem("cartItems")
  //   ? JSON.parse(localStorage.getItem("cartItems"))
  //   : [];
  const { cartItems } = useSelector((state) => state.cart);
  const myCartItems = cartItems[0];

  var date = new Date().toISOString().slice(0, 10);

  // console.log("cartItems", cartItems);
  console.log("mycartItems", myCartItems);
  // console.log("date", date);

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo?.token}`,
    },
  };

  //eslint-disable-nextline
  const submitHandler = (e) => {
    e.preventDefault();
    setLoader(true);
    axios
      .post(
        "/api/orders/add-order/",
        {
          productName: myCartItems.name,
          productType: "subscription",
          orderValue: myCartItems.cost,
          paymentCycle: myCartItems.type,
          recurringPaymentDate: date,
        },
        config
      )
      .then(function (response) {
        console.log("Response Received");
        navigate("/checkout");
      })
      .catch(function (error) {
        console.log("Error Occured");
      });
  };
  return (
    <div>
      <Helmet>
        <title>Pricing</title>
        <meta
          name="description"
          content="Find out about our standard and premium services"
        />
        <link rel="canonical" href="/pricing" />
      </Helmet>
      <Header />

      <Container align="center" className="page-container front-page-container">
        {/* This is the Navigation divider for the front end of our pages */}
        <Row className="divider-parent">
          <Col align="center" className="utility-divider-lines"></Col>
          <Col align="center" className="util-icon-parent">
            <i className="fa-regular fa-circle-dot fa-1x divider-icon"></i>
          </Col>
          <Col align="center" className="utility-divider-lines"></Col>
        </Row>

        {/* Page Hero starts here */}
        <Row className="hero-container lcn-section-spacing lcn-page-width-section">
          {/* Floating images placed here so that we can use absolute as the position prop */}
          <img
            id="for-inc-split-ball"
            className="lcn-bounce"
            src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Split-ball.svg"
            alt="Background images"
          ></img>
          <img
            id="for-inc-ring"
            className="lcn-swing"
            src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/ring.svg"
            alt="Background images"
          ></img>
          <img
            id="for-inc-skwigle"
            className="lcn-slide"
            src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/skwigle.svg"
            alt="Background images"
          ></img>
          <Row align="center" className="content-center">
            <div className="my-5">
              <h1 className="serious-font hero-header-center">
                Affordable, accessible, understood Legal access
              </h1>
              <p>
                With our innovative platform, you have access to a suite of
                tools designed to simplify and streamline the legal process. Our
                all-in-one legal solution is designed to streamline and automate
                the legal process, making it more accessible and efficient for
                businesses and individuals alike. Get 3 free tokens when you
                register an account today and experience the future of legal
                technology.
              </p>
            </div>
            {/* CTA Section */}
            <Row className="hero-button-parent">
              <Col>
                {userInfo && userInfo?.profile === 1 && (
                  <button className="lcn-button-orange-outline px-3 py-2">
                    You're on the Free Trial
                  </button>
                )}
                {userInfo && userInfo?.profile === 0 && (
                  <LinkContainer to={"/dashboard/validate"}>
                    <button className="lcn-button-orange-outline  px-4 py-3">
                      Get free access now
                    </button>
                  </LinkContainer>
                )}
                {!userInfo && (
                  <LinkContainer to={"/register"}>
                    <button className="lcn-button-orange-outline  px-4 py-3">
                      Get free access now
                    </button>
                  </LinkContainer>
                )}
              </Col>
            </Row>
          </Row>
        </Row>

        <Row className="tiled-background-pricing"></Row>
        <Row className="tiled-background-pricing"></Row>
        <Row className="tiled-background-pricing"></Row>

        <Row
          id="premium-subscription-container-id"
          className="premium-subscription-container cream-background px-5 py-5"
        >
          <Col>
            <Row>
              <Col></Col>
              <Col lg={6}>
                <h1 className="serious-font">Premium Subscription </h1>
                <h3 className="serious-font">R250.00 pm</h3>
              </Col>
              <Col></Col>
            </Row>

            <Row className="mt-5">
              <Col className="cart-subscribe-parent">
                {/* incubators and ecosystems dont see anything */}
                {/* smes see upgrade */}
                {/* {userInfo && userInfo?.profile === 1 && (
                  <LinkContainer to={"/cart"}>
                    <button
                      className="lcn-button-orange-outline px-3 py-2"
                      //   onClick={addToCartHandler}
                    >
                      Subscribe for unlimited access
                    </button>
                  </LinkContainer>
                )} */}
                {!loader ? (
                  <LinkContainer to={"/pre-checkout"}>
                    <button
                      className="lcn-button-orange-outline px-3 py-2"
                      onClick={submitHandler}
                    >
                      Subscribe for full access
                    </button>
                  </LinkContainer>
                ) : (
                  <Loader />
                )}
              </Col>
            </Row>

            <Row className="pricing-feature-container-white px-3 py-3 mt-5">
              <Col className="pricing-feature-col">
                <MediFileLoT aniName="mediafilelot-pricing" />
                <h4>Legal Health Checks</h4>
              </Col>
              <Col className="pricing-feature-col">
                <span>
                  We'll start by asking you a few questions and then calculate
                  the legal health of your business. Watch the metrics in your
                  dashboard update and then let us offer tools and advice to
                  help you increase it.
                </span>
              </Col>
            </Row>

            <Row className="pricing-feature-container-white px-3 py-3 mt-4">
              <Col className="pricing-feature-col">
                <BrainLoT aniName="brainlot-pricing" />
                <h4>AI Virtual legal assistant</h4>
              </Col>
              <Col className="pricing-feature-col">
                <span>
                  Enjoy expert guidance via Jinx, our GPT-powered chatbot
                  indexed on South African legislation. Ask Jinx any question
                  and have it generate an answer written in easy-to-understand
                  language.
                </span>
              </Col>
            </Row>

            <Row className="pricing-feature-container-white px-3 py-3 mt-4">
              <Col className="pricing-feature-col">
                <DocumentLoT aniName="documentlot-pricing" />
                <h4>Document access and Automation</h4>
              </Col>
              <Col className="pricing-feature-col">
                <span>
                  Chat to one of our dedicated bots and generate a custom legal
                  contract tailored to match the information you provided. After
                  your conversation, your document will arrive in your dashboard
                  inbox and be stored for later use.
                </span>
              </Col>
            </Row>

            <Row className="pricing-feature-container-white px-3 py-3 mt-4">
              <Col className="pricing-feature-col">
                <LegalbyteLoT aniName="legalbytelot-pricing" />
                <h4>Process Guidance</h4>
              </Col>
              <Col className="pricing-feature-col">
                <span>
                  Need advice on a legal process you need to conduct in your
                  business? We've got you covered! Speak to a chatbot and be
                  guided through the given process in a step-by-step fashion.
                </span>
              </Col>
            </Row>

            <Row className="pricing-feature-container-white px-3 py-3 mt-4">
              <Col className="pricing-feature-col">
                <LawyerLoT aniName="lawyerlot-pricing" />
                <h4>Find a Lawyer</h4>
              </Col>
              <Col className="pricing-feature-col">
                <span>
                  If you find that your specific use-case is not met by one of
                  our tools, you can use our solution to generate a case and get
                  connected with exactly the type of lawyer you'd like to work
                  with.
                </span>
              </Col>
            </Row>

            <Row className="pricing-feature-container-white px-3 py-3 mt-4">
              <Col className="pricing-feature-col">
                <HammerOfJusticeLoT aniName="hammerofjusticelot-pricing" />
                <h4>Business Legal 101 Masterclass</h4>
              </Col>
              <Col className="pricing-feature-col">
                <span>
                  We want our users to be as legally informed as possible, and
                  thus have designed a custom course that will get you up to
                  speed on all the areas of law that you need to understand to
                  create a thriving business.
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default CartScreenNew;
