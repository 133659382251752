/* eslint-disable */

import React from "react";
import lottie from "lottie-web";
import layers from "../lottie-files/layers-lottie.json";
import { useEffect } from "react";

function Layers(props) {
  const aniName = props.aniName;

  const animationContainers = React.useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      name: aniName,
      container: animationContainers.current,
      animationData: layers,
      autoplay: false,
      loop: true,
    });

    // return () => {
    //   lottie.destroy();
    // };
  }, []);

  return <div className={aniName} ref={animationContainers}></div>;
}

export default Layers;
