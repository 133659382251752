import Header from "../components/Header";
import Footer from "../components/Footer";
import { Container, Row, Col } from "react-bootstrap";

function CustomerComplaintPolicyScreen() {
  return (
    <div>
      <Header />
      <Container align="center" className="page-container">
        {/* This is the Navigation divider for the front end of our pages */}
        <Row className="divider-parent ">
          <Col align="center" className="utility-divider-lines"></Col>
          <Col align="center" className="util-icon-parent">
            <i className="fa-regular fa-circle-dot fa-1x divider-icon"></i>
          </Col>
          <Col align="center" className="utility-divider-lines"></Col>
        </Row>

        <Row className="standard-header mt-5">
          <h1 className="serious-font mt-5">Customer Complaint Policy</h1>
        </Row>
        <Row>
          <Col align="left" className="util-red-border py-4 px-4">
            <p>
              At Lacoona Legal, we are committed to providing the highest level
              of customer satisfaction and support. In the unlikely event that
              you are not satisfied with our services, we encourage you to bring
              your concerns to our attention so that we can resolve the issue as
              quickly and efficiently as possible.
            </p>
            <p>
              Our customer complaint policy outlines the steps you can take to
              register a complaint and the process we will follow to resolve the
              issue.
            </p>
            <p>How to file a complaint:</p>
            <ul>
              <li>
                Visit our website&nbsp;
                <a
                  href="https://www.lacoona.legal/dashboard/feedback"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://www.lacoona.legal/dashboard/feedback
                </a>
                .
              </li>
              <li>
                Select the&nbsp;<em>Complaints</em> button and tell us about
                your complaint.
              </li>
              <li>
                Provide us with as much detail as possible about the issue you
                are facing, including your name, contact information, and the
                nature of the complaint.
              </li>
              <li>
                We will acknowledge receipt of your complaint within 2 business
                days.
              </li>
              <li>
                If you haven&rsquo;t received a response to your complaint
                within 48 hours, please contact us on our email at
                info@lacoona.legal.&nbsp;
              </li>
            </ul>
            <p>Resolution process:</p>
            <ul>
              <li>
                Our customer support team will investigate your complaint and
                work to resolve the issue as quickly as possible.
              </li>
              <li>
                We will keep you informed of the progress of the investigation
                and the steps we are taking to resolve the issue.
              </li>
              <li>
                If we are unable to resolve the issue to your satisfaction, we
                will escalate the matter to a senior member of our management
                team for review.
              </li>
            </ul>
            <p>
              We take all customer complaints seriously and will do everything
              in our power to resolve the issue in a fair and transparent
              manner. If you have any further questions or concerns, please do
              not hesitate to contact us.
            </p>
            <p>Thank you for choosing Lacoona Legal!</p>
            <p>
              Last Updated: This Customer Complaint Policy was last updated on
              21/02/2023
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default CustomerComplaintPolicyScreen;
