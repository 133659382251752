import React from "react";
import lottie from "lottie-web";
import brain from "./lacoona-legal-ai-brain.json";
import { useEffect } from "react";

function BrainLoT(props) {
  const aniName = props.aniName;

  const animationContainer = React.createRef();

  useEffect(() => {
    lottie.loadAnimation({
      name: aniName,
      container: animationContainer.current,
      animationData: brain,
      autoplay: false,
      loop: true,
    });
  }, []);

  return <div className={aniName} ref={animationContainer}></div>;
}

export default BrainLoT;
