import React from "react";
import { Row, Col, Container, Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions";
import { useNavigate } from "react-router-dom";
import Layers from "../lottie-files/Layers";
import lottie from "lottie-web";
import ArchiveLoT from "../lottie-files/ArchiveLoT";
import ScalesLoT from "../lottie-files/ScalesLoT";
import LawyerLoT from "../lottie-files/LawyerLoT";
import LegalByteLoT from "../lottie-files/LegalbyteLoT";
import KeyLoT from "../lottie-files/KeyLoT";
import DocumentLoT from "../lottie-files/DocumentLoT";
import MediFileLoT from "../lottie-files/MediFileLoT";
import BrainLoT from "../lottie-files/BrainLoT";
import NewMatterLoT from "../lottie-files/NewMatterLoT";

function LawyersSideNav() {
  const userLogin = useSelector((state) => state.userLogin);
  const dispatch = useDispatch();
  const { userInfo } = userLogin;
  let navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <Col className="sidenav-container-outer" lg={3} md={12}>
      <div
        align="center"
        className="sidenav-container-inner ll-custom-scrollbar"
      >
        <div className="pt-4">
          <LinkContainer to={"/"}>
            <Row className="sidenav-logo-container pt-4 px-2">
              <img
                className="lcn-sidenav-logo"
                alt="lacoona-legal-logo"
                src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Lacoona-legal-logo-and-wordmark.svg"
              ></img>
            </Row>
          </LinkContainer>
          {/* This is the Navigation divider for the front end of our pages */}
          <Row className="divider-parent-mini">
            <Col align="center" className="utility-divider-lines"></Col>
            <Col align="center" className="util-icon-parent">
              <i className="fa-regular fa-circle-dot fa-1x divider-icon"></i>
            </Col>
            <Col align="center" className="utility-divider-lines"></Col>
          </Row>
        </div>
        {/* Conditional profile */}
        {userInfo && (
          <Col className="avatar-column lawyers-avatar">
            <Row className="side-dashboard-user-welcome">
              {/* <Row className="avatar-wrapper-side-panel px-1 py-1 mx-1">
                  <img
                    className="avatar-image"
                    src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Group_115.png"
                  alt="Lacoona Legal Logo with wordmark"></img>
                </Row> */}
              <LinkContainer to={"/lawyer-dashboard/user-dash"}>
                <Row>
                  <h4 className="side-dash-welcome-text">Welcome back,</h4>
                  <h3 className="side-dash-name-text">{userInfo?.name}</h3>
                </Row>
              </LinkContainer>
            </Row>
          </Col>
        )}

        {/* Main Side nav starts here */}
        <Col id="dash-sidenav-column" className="" lg={12}>
          <Navbar id="dash-sidenav" expand="lg">
            <Container className="sidenav-container">
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                // onClick={() => setExpanded((prev) => !prev)}
              />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav align="center" className="me-auto">
                  {/* {userInfo?.role === "sme" && ( */}
                  <LinkContainer
                    to={"/lawyer-dashboard/lawyer-dash"}
                    // onClick={() => setExpanded(false)}
                  >
                    <Nav.Link
                      id="dashnav-lawyer-dashboard"
                      className="dash-sidepanel-link"
                      onMouseEnter={() => lottie.play("layers-dash-sn")}
                      onMouseLeave={() => lottie.stop("layers-dash-sn")}
                    >
                      <Col className="dash-sidepanel-link-icon-parent" lg={4}>
                        <Layers aniName="layers-dash-sn" />
                      </Col>
                      <Col
                        className="dash-sidepanel-link-text-container"
                        lg={8}
                      >
                        <h3 className="dash-sidepanel-link-text">Dashboard</h3>
                      </Col>
                    </Nav.Link>
                  </LinkContainer>
                  {/* )} */}

                  <LinkContainer
                    to={"/lawyer-dashboard/schedule"}
                    // onClick={() => setExpanded(false)}
                  >
                    <Nav.Link
                      id="dashnav-schedule-lawyer"
                      className="dash-sidepanel-link"
                      onMouseEnter={() => lottie.play("legalbyte-sn")}
                      onMouseLeave={() => lottie.stop("legalbyte-sn")}
                    >
                      <Col className="dash-sidepanel-link-icon-parent" lg={4}>
                        <LegalByteLoT aniName="legalbyte-sn" />
                      </Col>
                      <Col
                        className="dash-sidepanel-link-text-container"
                        lg={8}
                      >
                        <h3 className="dash-sidepanel-link-text">Schedule</h3>
                      </Col>
                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer
                    to={"/lawyer-dashboard/appointments"}
                    // onClick={() => setExpanded(false)}
                  >
                    <Nav.Link
                      id="dashnav-appointments-lawyer"
                      className="dash-sidepanel-link"
                      onMouseEnter={() => lottie.play("scales-just-sn")}
                      onMouseLeave={() => lottie.stop("scales-just-sn")}
                    >
                      <Col className="dash-sidepanel-link-icon-parent" lg={4}>
                        <ScalesLoT aniName="scales-just-sn" />
                      </Col>
                      <Col
                        className="dash-sidepanel-link-text-container"
                        lg={8}
                      >
                        <h3 className="dash-sidepanel-link-text">
                          Appointments
                        </h3>
                      </Col>
                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer
                    to={"/lawyer-dashboard/payments"}
                    // onClick={() => setExpanded(false)}
                  >
                    <Nav.Link
                      id="dashnav-payments-lawyer"
                      className="dash-sidepanel-link"
                      onMouseEnter={() => lottie.play("document-ql")}
                      onMouseLeave={() => lottie.stop("document-ql")}
                    >
                      <Col className="dash-sidepanel-link-icon-parent" lg={4}>
                        <DocumentLoT aniName="document-ql" />
                      </Col>
                      <Col
                        className="dash-sidepanel-link-text-container"
                        lg={8}
                      >
                        <h3 className="dash-sidepanel-link-text">Payments</h3>
                      </Col>
                    </Nav.Link>
                  </LinkContainer>

                  <LinkContainer
                    to={"/lawyer-dashboard/open-matters"}
                    // onClick={() => setExpanded(false)}
                  >
                    <Nav.Link
                      id="dashnav-matters-lawyer"
                      className="dash-sidepanel-link"
                      onMouseEnter={() => lottie.play("new-matter-ql")}
                      onMouseLeave={() => lottie.stop("new-matter-ql")}
                    >
                      <Col className="dash-sidepanel-link-icon-parent" lg={4}>
                        <NewMatterLoT aniName="new-matter-ql"/>
                      </Col>
                      <Col
                        className="dash-sidepanel-link-text-container"
                        lg={8}
                      >
                        <h3 className="dash-sidepanel-link-text">Matters</h3>
                      </Col>
                    </Nav.Link>
                  </LinkContainer>

                  {/* Unlogged in user sees Dashboard feature availability */}
                  {/* {!userInfo && (
                    <LinkContainer to={"/dashboard/validate"}>
                      <Nav.Link
                        className="dash-sidepanel-link"
                        onMouseEnter={() => lottie.play("layers-dash-sn")}
                        onMouseLeave={() => lottie.stop("layers-dash-sn")}
                      >
                        <Col className="dash-sidepanel-link-icon-parent" lg={4}>
                          <Layers aniName="layers-dash-sn" />
                        </Col>
                        <Col
                          className="dash-sidepanel-link-text-container"
                          lg={8}
                        >
                          <h3 className="dash-sidepanel-link-text ll-grey-colour">
                            Dashboard
                          </h3>
                        </Col>
                      </Nav.Link>
                    </LinkContainer>
                  )} */}

                  {/* Unlogged in user sees 'Ask Jinx (AI)' feature availability */}
                  {userInfo && (
                    <LinkContainer
                      to={"/lawyer-dashboard/ask-jinx-for-lawyers"}
                      // onClick={() => setExpanded(false)}
                    >
                      <Nav.Link
                        id="dashnav-ai-assistant-lawyer"
                        className="dash-sidepanel-link"
                        onMouseEnter={() => lottie.play("brain-sn")}
                        onMouseLeave={() => lottie.stop("brain-sn")}
                      >
                        <Col className="dash-sidepanel-link-icon-parent" lg={4}>
                          <BrainLoT aniName="brain-sn" />
                        </Col>
                        <Col
                          className="dash-sidepanel-link-text-container"
                          lg={8}
                        >
                          <h3 className="dash-sidepanel-link-text">Ask Jinx</h3>
                        </Col>
                      </Nav.Link>
                    </LinkContainer>
                  )}

                  {/* <LinkContainer
                    to={"/dashboard/legal-products"}
                    // onClick={() => setExpanded(false)}
                  >
                    <Nav.Link
                      id="dashnav-legal-products"
                      className="dash-sidepanel-link"
                      onMouseEnter={() => lottie.play("scales-just-sn")}
                      onMouseLeave={() => lottie.stop("scales-just-sn")}
                    >
                      <Col className="dash-sidepanel-link-icon-parent" lg={4}>
                        <ScalesLoT aniName="scales-just-sn" />
                      </Col>
                      <Col
                        className="dash-sidepanel-link-text-container"
                        lg={8}
                      >
                        <h3 className="dash-sidepanel-link-text">
                          Legal Products
                        </h3>
                      </Col>
                    </Nav.Link>
                  </LinkContainer> */}

                  {/* {userInfo && (
                    <LinkContainer
                      to={"/dashboard/documents"}
                      // onClick={() => setExpanded(false)}
                    >
                      <Nav.Link
                        id="dashnav-documents"
                        className="dash-sidepanel-link"
                        onMouseEnter={() => lottie.play("Archive-docs-sn")}
                        onMouseLeave={() => lottie.stop("Archive-docs-sn")}
                      >
                        <Col className="dash-sidepanel-link-icon-parent" lg={4}>
                          <ArchiveLoT aniName="Archive-docs-sn" />
                        </Col>
                        <Col
                          className="dash-sidepanel-link-text-container"
                          lg={8}
                        >
                          <h3 className="dash-sidepanel-link-text">
                            My Documents
                          </h3>
                        </Col>
                      </Nav.Link>
                    </LinkContainer>
                  )} */}

                  {/* {!userInfo && (
                    <LinkContainer
                      to={"/dashboard/documents"}
                      // onClick={() => setExpanded(false)}
                    >
                      <Nav.Link
                        id="dashnav-documents-loggedout"
                        className="dash-sidepanel-link"
                        onMouseEnter={() => lottie.play("Archive-docs-sn")}
                        onMouseLeave={() => lottie.stop("Archive-docs-sn")}
                      >
                        <Col className="dash-sidepanel-link-icon-parent" lg={4}>
                          <ArchiveLoT aniName="Archive-docs-sn" />
                        </Col>
                        <Col
                          className="dash-sidepanel-link-text-container"
                          lg={8}
                        >
                          <h3 className="dash-sidepanel-link-text ll-grey-colour">
                            My Documents
                          </h3>
                        </Col>
                      </Nav.Link>
                    </LinkContainer>
                  )} */}

                  {/* <LinkContainer
                    to={"/dashboard/find-a-lawyer"}
                    // onClick={() => setExpanded(false)}
                  >
                    <Nav.Link
                      id="dashnav-fal"
                      className="dash-sidepanel-link"
                      onMouseEnter={() => lottie.play("lawyer-sn")}
                      onMouseLeave={() => lottie.stop("lawyer-sn")}
                    >
                      <Col className="dash-sidepanel-link-icon-parent" lg={4}>
                        <LawyerLoT aniName="lawyer-sn" />
                      </Col>
                      <Col
                        className="dash-sidepanel-link-text-container"
                        lg={8}
                      >
                        <h3 className="dash-sidepanel-link-text">
                          Find a Lawyer
                        </h3>
                      </Col>
                    </Nav.Link>
                  </LinkContainer> */}

                  {/* <LinkContainer
                    to={"/dashboard/faqs"}
                    // onClick={() => setExpanded(false)}
                  >
                    <Nav.Link
                      id="dashnav-legal-faqs"
                      className="dash-sidepanel-link"
                      onMouseEnter={() => lottie.play("legalbyte-sn")}
                      onMouseLeave={() => lottie.stop("legalbyte-sn")}
                    >
                      <Col className="dash-sidepanel-link-icon-parent" lg={4}>
                        <LegalByteLoT aniName="legalbyte-sn" />
                      </Col>
                      <Col
                        className="dash-sidepanel-link-text-container"
                        lg={8}
                      >
                        <h3 className="dash-sidepanel-link-text">Legal FAQs</h3>
                      </Col>
                    </Nav.Link>
                  </LinkContainer> */}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Col>
        {/* Bottom Side Nav Starts Here */}
        <Row align="center" className="dash-sidepanel-section-bottom pb-4 px-4">
          {userInfo ? (
            <Row
              className="dash-sidepanel-link nav-link px-0"
              onClick={logoutHandler}
              onMouseEnter={() => lottie.play("key-sn")}
              onMouseLeave={() => lottie.stop("key-sn")}
            >
              <Col className="dash-sidepanel-link-icon-parent p-0" lg={4}>
                <KeyLoT aniName="key-sn" />
              </Col>
              <Col className="dash-sidepanel-link-text-container p-0" lg={8}>
                <h3 className="dash-sidepanel-link-text">Sign-Out</h3>
              </Col>
            </Row>
          ) : (
            <LinkContainer to={"/register"}>
              <Row
                className="dash-sidepanel-link nav-link px-0"
                onMouseEnter={() => lottie.play("reg-lot")}
                onMouseLeave={() => lottie.stop("reg-lot")}
              >
                <Col className="dash-sidepanel-link-icon-parent p-0" lg={4}>
                  <DocumentLoT aniName="reg-lot" />
                </Col>
                <Col className="dash-sidepanel-link-text-container p-0" lg={8}>
                  <h3 className="dash-sidepanel-link-text">Register</h3>
                </Col>
              </Row>
            </LinkContainer>
          )}
        </Row>
        {/* For when CSS Containers get full support */}
        {/* <Row className="short-scroll">
          <LeftArrowLoT/>
        </Row> */}
      </div>
    </Col>
  );
}

export default LawyersSideNav;
