
import { Container, Row, Col} from "react-bootstrap";
/* import { LinkContainer } from "react-router-bootstrap";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown"; */
import {Helmet} from "react-helmet-async";

import Header from "../components/Header";
import Footer from "../components/Footer";
import ToolTip from "../components/ToolTip";
import { LinkContainer } from "react-router-bootstrap";

function FindALawyerScreen() {
  return (
    <div>
      <Helmet>
        <title>Find A Lawyer</title>
        <meta name="description" content="Find the Right Lawyer for Your Business at No Cost"/>
        <link rel="canonical" href="/find-a-lawyer"/>
      </Helmet>
      <Header />
      <Container align="center" className="page-container front-page-container">
        {/* This is the Navigation divider for the front end of our pages */}
        <Row className="divider-parent">
          <Col align="center" className="utility-divider-lines"></Col>
          <Col align="center" className="util-icon-parent">
            <i className="fa-regular fa-circle-dot fa-1x divider-icon"></i>
          </Col>
          <Col align="center" className="utility-divider-lines"></Col>
        </Row>
        {/* Page Hero starts here */}
        <Row className="hero-container lcn-section-spacing lcn-page-width-section">
          {/* Floating images placed here so that we can use absolute as the position prop */}
          <img
            id="for-inc-split-ball"
            className="lcn-bounce"
            src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Split-ball.svg"
            alt="Background images"
          ></img>
          <img
            id="for-inc-ring"
            className="lcn-swing"
            src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/ring.svg"
            alt="Background images"
          ></img>
          <img
            id="for-inc-skwigle"
            className="lcn-slide"
            src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/skwigle.svg"
            alt="Background images"
          ></img>
          <Row align="center" className="content-center">
            <div className="my-5">
              <h1 className="serious-font hero-header-center">
              Find the Right Lawyer for Your Business at No Cost
              </h1>
              <p>
              Get fixed cost solutions and free quotes from vetted lawyers for any legal or compliance needs. Whether it's a single query or a larger project, we make it easy to find a lawyer near you. Our network of lawyers is ready to help. Get the legal support you need with ease.    
              </p>
            </div>
            {/* CTA Section */}
            <Row className="hero-button-parent">
              {/* <Col>
              <ToolTip
                                tooltip="Feature coming soon"
                                id="tooltip-reg-comp"
                                placement="bottom"
                              >
             <button className="mobile-wrap lcn-button-orange m-2 px-4 py-3">
              I have a specific questions to ask 
                </button>
             </ToolTip>
                
              </Col> */}
              <Col>
              <LinkContainer
              to={"/dashboard/find-a-lawyer"}><button className=" mobile-wrap lcn-button-orange-outline  px-4 py-3">
                  I have an open ended matter
                </button>
                </LinkContainer>
                
              </Col>
            </Row>
          </Row>
        </Row>
        {/* Tooling Section */}
        <Row className="lcn-section-spacing lcn-page-width-section">
          <Col className="ll-frontend-content-block ll-section-1">
            <Row className="ll-front-content-block-text-parent">
              <h3 className="ll-subheading">
              Clear, Affordable Answers with Fixed-Price Access
              </h3>
              <p className="ll-body">
              Get clear and affordable legal answers for your business with Lacoona Legal. Our fixed-price facilitation of lawyers simplifies legal regulations, documents, and compliance for founders and business owners. Strengthen your operations and governance to unlock opportunities like loan approvals, capital raising, and effective supplier management.
              </p>
               {/* <a href="dashboard/legal-products" className="ll-cta-icon-parent">
                <strong>
                  <p>Find Out More</p>
                </strong>
                <i className="fa-solid fa-arrow-right ll-cta-icon"></i>
              </a>  */}
            </Row>
          </Col>
          <Col className="ll-section-2">
            <img
              className="ll-content-image"
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Reece-Tools-compressed.png"
              alt="Developer Floating with Tools"
            ></img>
          </Col>
        </Row>
        {/* Cohort oversight section */}
        <Row className="lcn-section-spacing lcn-page-width-section ll-flex-col-reverse">
          <Col className="ll-section-2">
            <img
              className="ll-content-image"
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Oversight-bot-tools.png"
              alt="Oversight Tooling Bot"
            ></img>
          </Col>
          <Col className="ll-frontend-content-block ll-section-1" >
            <Row className="ll-front-content-block-text-parent">
              <h3 className="ll-subheading">
              Find the Perfect Lawyer for Your Job</h3>
              <p className="ll-body">
              Find the right lawyer for your job or matter with ease. Post your needs and let us connect you with our network of legal experts and business coaches. Benefit from human-centered counseling, reassurance, and tailored solutions for your business success with no hidden costs. Let us help you understand your needs and find the perfect professional match for you.
              </p>
              {/* <a href="https://calendar.app.google/HtdcRwx6bURGCHH88" className="ll-cta-icon-parent">
                <strong>
                  <p>Speak to us</p>
                </strong>
                <i className="fa-solid fa-arrow-right ll-cta-icon"></i>
              </a> */}
            </Row>
          </Col>
        </Row>
        {/* Organisation-level reporting section */}
        <Row className="lcn-section-spacing lcn-page-width-section">
          <Col className="ll-frontend-content-block ll-section-1">
            <Row className="ll-front-content-block-text-parent">
              <h3 className="ll-subheading">
              Expert Legal Contract Review with professionals.</h3>
              <p className="ll-body">
              Get your legal contracts reviewed by vetted professionals with our platform. Our network of legal and funding experts can help you raise money, grow your business and ensure compliance with corporate governance standards. Get support with document creation, cap table management and investor communication while maintaining full control over the process.
              </p>
              {/* <a href="/dashboard/legal-products" className="ll-cta-icon-parent">
                <strong>
                  <p>Explore more</p>
                </strong>
                <i className="fa-solid fa-arrow-right ll-cta-icon"></i>
              </a> */}
            </Row>
          </Col>
          <Col className="ll-section-2">
            <img
              className="ll-content-image"
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/frontend-images/Invest-magnet-lacoona-legal.png"
              alt="Computer showing Tools"
            ></img>
          </Col>
        </Row>

        {/* How it works */}
        <Row className="lcn-section-spacing lcn-page-width-section full-version">
          <h3 className="ll-subheading mb-5" >How it works</h3>
          <Row>
          <Col lg="5">
            <h4 className="ll-subheading">If you have a specific  question which you need guidance on</h4>
            <strong className="highlight-title-small">Let us guide you to your lawyer</strong>
            <hr/>
            </Col>
            <Col lg="2"></Col>
            <Col lg="5">
            <h4 className="ll-subheading">If you have a specific  question which you need guidance on</h4>
            <strong className="highlight-title-small">Post a matter for bids here</strong>
            <hr/>
            </Col>
          </Row>
          <Row>
          <Col lg="5">
            <div align="left" className="py-4">
            <h4 className="ll-subheading" >Tell us about your issue</h4>
            <p>Tell us about your project and we will help you figure out what you need. Lacoona Legal will post your project in our lawyer marketplace.</p>
            </div>
          </Col>
          <Col lg="2"><span className="fal-focus-number">1</span></Col>
          <Col lg="5">
            <div align="left" className="py-4">
            <h4 className="ll-subheading" >Post Your Project, It's Free</h4>
            <p>Tell us about your project and we will help you figure out what you need. Lacoona Legal will post your project in our lawyer marketplace.</p>
            </div>
          </Col>
          </Row>
          <Row>
          <Col lg="5">
            <div align="left" className="py-4">
            <h4 className="ll-subheading" >Let us guide you to the right lawyer</h4>
            <p>Tell us about your project and we will help you figure out what you need. Lacoona Legal will post your project in our lawyer marketplace.</p>
            </div>
          </Col>
          <Col lg="2"><span className="fal-focus-number">2</span></Col>
          <Col lg="5">
            <div align="left" className="py-4">
            <h4 className="ll-subheading" >Schedule time directly into both calendars to meet</h4>
            <p>Tell us about your project and we will help you figure out what you need. Lacoona Legal will post your project in our lawyer marketplace.</p>
            </div>
          </Col>
          </Row>
          <Row>
          <Col lg="5">
            <div align="left" className="py-4">
            <h4 className="ll-subheading" >Tell us about your issue</h4>
            <p>Tell us about your project and we will help you figure out what you need. Lacoona Legal will post your project in our lawyer marketplace.</p>
            </div>
            <Row className="hero-button-parent">
              <Col>
              {/* <ToolTip
                                tooltip="Feature coming soon"
                                id="tooltip-reg-comp"
                                placement="bottom"
                              > */}
                              <LinkContainer to={`/dashboard/find-a-lawyer`}>
              <button className="lcn-button-orange px-4 py-3">
              Find a lawyer now 
                </button>
                </LinkContainer>
              {/* </ToolTip> */}
                
              </Col>
          </Row>
          </Col>
            <Col lg="2"><span className="fal-focus-number">3</span></Col>
            <Col className="fal-action-steps-last" lg="5">
            <div align="left" className="py-4">
            <h4 className="ll-subheading" >Hire with Confidence</h4>
            <p>Pay a flat-fee through our platform, and feel confident you are getting a good deal from a qualified lawyer.</p>
            </div>
            <Row className="hero-button-parent">
              <Col>
              <LinkContainer to={`/dashboard/find-a-lawyer`}>
              <button className="lcn-button-orange px-4 py-3">
              Post a matter for lawyers to bid
                </button>
                </LinkContainer>
                
              </Col>
          </Row>
          </Col>
          </Row>
        </Row>

        {/* HIW Mobile */}
        <Row className="lcn-section-spacing lcn-page-width-section mobile-version">
          <h3 className="ll-subheading mb-5" >How it works</h3>
          
          <Row>
            <h4 className="ll-subheading">If you have a specific  question which you need guidance on</h4>
            <strong className="highlight-title-small">Let us guide you to your lawyer</strong>
            <hr/>
            </Row>
            <Row lg="2"><span className="fal-focus-number">1</span></Row>
            <Row lg="5">
            <div align="center" className="py-4">
            <h4 className="ll-subheading" >Tell us about your issue</h4>
            <p>Tell us about your project and we will help you figure out what you need. Lacoona Legal will post your project in our lawyer marketplace.</p>
            </div>
            </Row>
            <Row><span className="fal-focus-number">2</span></Row>
            <Row lg="5">
            <div align="center" className="py-4">
            <h4 className="ll-subheading" >Let us guide you to the right lawyer</h4>
            <p>Tell us about your project and we will help you figure out what you need. Lacoona Legal will post your project in our lawyer marketplace.</p>
            </div>
          </Row>
          <Row><span className="fal-focus-number">3</span></Row>
          <Row>
            <div align="center" className="py-4">
            <h4 className="ll-subheading" >Tell us about your issue</h4>
            <p>Tell us about your project and we will help you figure out what you need. Lacoona Legal will post your project in our lawyer marketplace.</p>
            </div>
            <Row align="center" className="faf-button-parent hero-button-parent mb-5">
              <Col>
              <ToolTip
                                tooltip="Feature coming soon"
                                id="tooltip-reg-comp"
                                placement="bottom"
                              ><button className="mobile-wrap lcn-button-orange px-4 py-3">
              Find a lawyer now 
                </button>
              </ToolTip>
              </Col>
          </Row>
          

            
            <Row>
            <h4 className="ll-subheading">If you have a specific  question which you need guidance on</h4>
            <strong className="highlight-title-small">Post a matter for bids here</strong>
            <hr/>
            </Row>
            <Row><span className="fal-focus-number">1</span></Row>
            <Row>
              <div align="center" className="py-4">
              <h4 className="ll-subheading" >Post Your Project, It's Free</h4>
              <p>Tell us about your project and we will help you figure out what you need. Lacoona Legal will post your project in our lawyer marketplace.</p>
              </div>
            </Row>
            <Row><span className="fal-focus-number">2</span></Row>
            <Row>
            <div align="center" className="py-4">
            <h4 className="ll-subheading" >Schedule time directly into both calendars to meet</h4>
            <p>Tell us about your project and we will help you figure out what you need. Lacoona Legal will post your project in our lawyer marketplace.</p>
            </div>
            </Row>
            <Row><span className="fal-focus-number">3</span></Row>
            <Row className="fal-action-steps-last" lg="5">
            <div align="center" className="py-4">
            <h4 className="ll-subheading" >Hire with Confidence</h4>
            <p>Pay a flat-fee through our platform, and feel confident you are getting a good deal from a qualified lawyer.</p>
            </div>
            <Row align="center" className="faf-button-parent hero-button-parent mb-5">
              <Col>
              <LinkContainer to={`/dashboard/find-a-lawyer`}><button className="mobile-wrap lcn-button-orange px-4 py-3">
              Post a matter for lawyers to bid
                </button>
              </LinkContainer>
                
              </Col>
          </Row>
          </Row>
          </Row>
        

{/* Old */}
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default FindALawyerScreen;
