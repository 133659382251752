import React from "react"
import ContentLoader from "react-content-loader"

const ProdCardLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={500}
    height={124}
    viewBox="0 0 600 124"
    backgroundColor="#f3f3f3"
    foregroundColor="#832626"
    {...props}
  >
    <rect x="48" y="8" rx="3" ry="3" width="40%" height="6" /> 
    <rect x="48" y="26" rx="3" ry="3" width="20%" height="6" /> 
    <rect x="0" y="56" rx="3" ry="3" width="90%" height="6" /> 
    <rect x="0" y="72" rx="3" ry="3" width="85%" height="6" /> 
    <rect x="0" y="88" rx="3" ry="3" width="50%" height="6" /> 
    <circle cx="20" cy="20" r="20" />
  </ContentLoader>
)

export default ProdCardLoader;

