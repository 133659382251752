import React, { useState } from "react";
import { Container, Row, Accordion, Col, Form, Nav, Navbar, } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { useFormik } from "formik";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from 'react-scroll';
import VideoComponent from "../components/VideoComponent";
import { MendableInPlace } from "@mendable/search"
import { useRef } from 'react';


function MeetJinxScreen() {
  const [showModal, toggleShowModal] = React.useState(false);

  function handleShow() {
    toggleShowModal(true);
    setFormStep(1);
  }
  /* const handleShow = () => toggleShowModal(true) */
  const handleHide = () => toggleShowModal(false);

  const formik = useFormik({
    initialValues: {
      formType: "for_lawyers",
      firstName: "",
      surname: "",
      firmName: "",
      firmSize: "",
      lawyerOperation: "",
      lawyerEmail: "",
      lawyerType: [],
    },
    onSubmit: (values) => {
      setFormStep((formStep) => formStep + 1);
      formik.resetForm();
      try {
        // eslint-disable-next-line
        const resp = axios.post(
          "https://hook.eu1.make.com/mysxrp19c3l91eoa65otxc3jo06syc45",
          { values }
        );
        console.log("Response");
      } catch (error) {
        console.log("Error");
      }
    },
  });
  const [formStep, setFormStep] = useState(1);

  const [cardState, setCardState] = useState(1);
  const [card2State, setCard2State] = useState(1);
  const [card3State, setCard3State] = useState(1);

  function setNextFormStep() {
    setFormStep((formStep) => formStep + 1);
  }

  function setPreviousFormStep() {
    setFormStep((formStep) => formStep - 1);
  }

  function preventSubmit(e) {
    e.preventDefault();
  }

  //Header Stuff
  const [header, setHeader] = useState(false);

  function changeBackground() {
    if (window.scrollY >= 5) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  }
  window.addEventListener("scroll", changeBackground);

  const mendable_style = { darkMode: false, accentColor: "#832626", backgroundColor:"#fafaed" }

  const paragraphcheck=document.querySelectorAll('p');
  
  paragraphcheck.forEach(p=>{
    if(p.textContent.includes('Powered by')){
      p.classList.add('hide-mendable');
    }
  })

  const ref = useRef(null);

  const handleClickToJinx = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      <Helmet>
        <title>Enterprise</title>
        <meta
          name="description"
          content="Meet Jinx, your intelligent AI business assistant by Lacoona Legal. Boost employee efficiency, get instant answers, and streamline company processes with a chatbot tailored to your organisation. Secure, scalable, and multilingual, Jinx enhances productivity and communication across your team."
        />
        <link rel="canonical" href="/enterprise" />
      </Helmet>
      {/* <Header /> */}
      <header>
      <Navbar
        className={header ? "navbar-custom active " : "navbar-custom ll-org-clear-nav"}
        expand="lg"
      >
        <a href="https://www.lacoona.legal/" target="_blank" rel="noreferrer">
          <Navbar.Brand href="#">
            <img
              className="lcn-nav-logo"
              alt="Lacoona Legal Chatbots"
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Lacoona-legal-logo-and-wordmark.svg"
            ></img>
          </Navbar.Brand>
        </a>
        </Navbar>
        </header>
      <Container align="center" className="page-container enteprise-bg front-page-container pt-5">
        {/* This is the Navigation divider for the front end of our pages */}


        <Row className="lcn-section-spacing for-lawyers--row lcn-page-width-section">
          <h1 className="for-lawyers--header ll-feat-font p-3">
          Meet Jinx: The A.I. Assistant trained on your Organisation
          </h1>
          <h5 className="for-lawyers--header ll-feat-font p-3">
          Powered by <a className="ll-underlinedlink" href="https://www.lacoona.legal/" target="_blank" rel="noreferrer">Lacoona Legal</a>
          </h5>
          <p className="p-3">
          Jinx is an intelligent AI-Assistant delivered in a chatbot format with the benefit of being trained to know your organisation, your team, your policies, your client database, and more. With a few easy clicks, you can use Jinx to support your team, answer questions, provide access to company policies, and troubleshoot issues. 
          </p>
          <Row> 
            <Col align="right">
                  <button
                    id="homepage-generate-legal-docs-button"
                    className="lcn-button-orange-outline py-3 px-4"
                    onClick={handleClickToJinx}
                  >
                    Try Jinx now
                  </button></Col>
          <Col align="left">
            <a
              className="for-lawyers--schedule"
              href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2cWDUQamVqaMkDqTF3Pus3dFx1aIJE1rQ9NdF6A1p8_4WkI5h32BSPSOkk3N4FLh4IiwmfaI1V"
              target="blank"
            >
              <button className="mobile-wrap lcn-button-orange px-4 py-3">
                Schedule a demo now
              </button>
            </a>
          </Col>
          </Row>
        </Row>

        <Row className="tiled-background-pricing align-vid"><div className="org-video-parent"><VideoComponent videoId="Qyv4W-nWr8k" /></div></Row>

        <Row className="lcn-page-width-section mt-5">
          <Col>
            <div className="ll-flash-cards">
            <h3>Communication</h3>
            </div>
          </Col>
          <Col>
            <div className="ll-flash-cards">
            <h3>Data</h3>
            </div>
          </Col>
          <Col>
            <div className="ll-flash-cards">
            <h3>Compliance</h3>
            </div>
          </Col>
        </Row>

        {/* Jinx in nutshell */}
        <Row className="lcn-section-spacing ll-center ll-flex-col-reverse">
          <Col className="ll-ent-section-2">
            {/* <img
              className="ll-content-image"
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Oversight-bot-tools.png"
              alt="Oversight Tooling Bot"
            ></img> */}
            <div className="jinx-image"></div>
          </Col>
          <Col className="ll-frontend-content-block p-5 ll-ent-section-1">
            <Row className="ll-front-content-block-text-parent">
              <h2 className=" ll-feat-font mb-4">Jinx in a nutshell</h2>
              <hr className="mt-4 mb-4 ll-burg-short-rule"></hr>
              <h5 className=" ll-feat-font mb-4 mt-4">Allow Instant Answers To Oranisation Questions for Your Workforce and Stakeholders</h5>
              <p className="ll-body">
              No longer spend valuable time searching for manuals or going through a large number of documents. With Jinx, you can quickly get the answers you need. Jinx analyzes all the documents and acts as an expert on your company processes, allowing you to get the answer you need in just a matter of seconds.
              </p>
              {/* <a href="https://calendar.app.google/HtdcRwx6bURGCHH88" className="ll-cta-icon-parent">
                <strong>
                  <p>Speak to us</p>
                </strong>
                <i className="fa-solid fa-arrow-right ll-cta-icon"></i>
              </a> */}
            </Row>
          </Col>
          <Link to="ll-getstarted-org" smooth={true} duration={500} offset={-100}>
                  <div className="homepage-health-assessment">
                    Save time and money now!
                  </div>
          </Link>
        </Row>
        
        {/* Not Convinced yet */}
        <Row align="center" className="p-5 lcn-page-width-section">
        <h2 className=" ll-feat-font mt-5 ">Discover the power of Jinx</h2>
        <hr className="mt-5 mb-5 ll-burg-short-rule ll-hr-center"></hr>
          <Row>
            <Col>
            <div className="ll-burg-card">
              <h3>Upload Any Document & Build Your Unique Knowledge Base</h3>
              <p>Securely upload anything from Word documents to PDFs to instantly integrate information and customize Jinx for your company. Jinx will search the database you provide and use it to craft an intelligent answer.</p>
              </div>
            </Col>
            <Col>
            <div className="ll-burg-card">
              <h3>Provide Immediate Sources For Every Answer</h3>
              <p>To ensure peace of mind, Jinx offers transparency in its work. It provides sources for every answer, whether it's from one source or multiple sources. This feature allows you to verify the accuracy and reliability of Jinx's responses, giving you added confidence in using the platform.</p>
              </div>
            </Col>
            <Col>
            <div className="ll-burg-card">
              <h3>Boost Employee Efficiency</h3>
              <p>With instant answers, ideas, and ready-made work, your team can work smarter, not harder. Use Jinx to empower employees.</p>
            </div>
            </Col>
          </Row>
          <Row>
            <Col>
             <div className="ll-burg-card">
              <h3>Provide Support & Troubleshoot Issues</h3>
              <p>Jinx offers both internal and external access, allowing authorised employees to access the chatbot from anywhere at any time. This feature enables seamless communication and information sharing among teams or supplier, regardless of their location.</p>
              </div>
            </Col>
            <Col>
            <div className="ll-burg-card">
              <h3>Brainstorm Ideas & Give Suggestions</h3>
              <p>Jinx can be an excellent tool to bring new perspectives and insights to the table, even if you're unsure of where to start. Thanks to its ability to remember conversations and contextual history, Jinx can provide ideas and advice that can help you move forward.</p>
            </div>
            </Col>
            <Col>
            <div className="ll-burg-card">
              <h3>Data as a means for growth and understanding</h3>
              <p>We believe that data should be the basis behind decision making and one of the reasons we exist is to allow you access to the more intricate issues and concerns in your company for advanced strategising.</p>
            </div>
            </Col>
          </Row>
        </Row>

        {/* Stat Sell */}
        <Row className="m-5">
          <Col>
          <div className="ll-lifted-card">
            <h2>Jinx has supported over <br/> 50’000 messages already...</h2>
            <h5>Lacoona Legal allows you to be at the forefront of organisational empowerment</h5>
          </div>
          </Col>
        </Row>
        
        {/* How it works */}
        <Row className="m-5">
        <h2 className="  ll-feat-font">How it works</h2>
        <hr className="mt-4 mb-4 ll-burg-short-rule ll-hr-center"></hr>
              <h5 className="ll-body mt-5 mb-5 brown">
              Providing this as a tool to your employees or external users is a matter of a few decisions, uploading the <br/> desired files sources and giving the code to your developers. 
              </h5>
          <Row className="mb-5">
            <Col>
            <div className="ll-burg-card">
              <h4>Teach It What It Needs To Know</h4>
              <p>Upload the documents you want Jinx to rely on and control which sources it has access to in every chat.</p>
             </div> 
            </Col>
            <Col>
            <div className="ll-burg-card">
              <h4>Select your delivery method</h4>
              <p>There are a number of ways in which we can provide access; this includes WhatsApp, as a backend integration or as a widget on your site.</p>
              </div>
            </Col>
            <Col>
            <div className="ll-burg-card">
              <h4>Customise Jinx For Different Uses</h4>
              <p>Control permissions and access. Ensuring the right people see the right content. Control what source documents it uses in every chat.</p>
            </div>
            </Col>
            <Col>
            <div className="ll-burg-card">
              <h4>Ask Jinx</h4>
              <p>Ask all your questions, train your team, and use Jinx to get work done to save time, money and empower your employees!</p>
            </div>
            </Col>
            <Col>
            <div className="ll-burg-card">
              <h4>Only pay for what you use</h4>
              <p>Our pricing model is based on a pay per use basis which means you only pay for the messages sent and received.</p>
            </div>
            </Col>
          </Row>
          {/* <h5 className="m-5 ll-tagline">For a small fee, let us help you setup for a quick turnaround</h5> */}
        </Row>
        {/* Try */}

        <Row id="try-jinx-corp-demo" ref={ref} className="m-5">
        <h2 className="  ll-feat-font">Try Jinx Now</h2>
        <hr className="mt-4 mb-4 ll-burg-short-rule ll-hr-center"></hr>
        <Row className="mb-5">
        <MendableInPlace anon_key='bb13026e-2939-4389-9d03-f8bf6b097a23' style={mendable_style} welcomeMessage={"Hi, Jinx here. What can I help you with today?"} botIcon={<img src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Lacoona-logo-medallion.svg"/>} hintText={"My employee has not arrived at work and I’m unsure of what to do."}
        messageSettings={{
          hideSources:true
        }}
        // privacyDisclaimer={"The information provided by Jinx is for general information purposes only. While we try to keep the information up to date and correct, we cannot guarantee that it will be accurate. Anyone using this information does so at their own risk."}
         />
         <p className="mt-4">By using Jinx you agree to our <a className="brown-icon"target="_blank" href="/privacy-policy#:~:text=info%40lacoona.legal-,Using%20Jinx,-The%20information%20provided">Disclaimer</a></p>
        </Row>     
        </Row>

        <Row id="ll-getstarted-org" className="tiled-background-pricing mt-5 mb-5">
          <Col>
          <div className="ll-lifted-card ll-card-up">
            <h2>Get started today and take your communication into the 22nd century...</h2>
            <h5 className="mt-4 mb-4">Not yet a believer, click here to let us show you the magic of A.I.</h5>
            <a
              className="for-lawyers--schedule"
              href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2cWDUQamVqaMkDqTF3Pus3dFx1aIJE1rQ9NdF6A1p8_4WkI5h32BSPSOkk3N4FLh4IiwmfaI1V"
              target="blank"
            >
              <button className="mobile-wrap lcn-button-orange px-4 py-3">
                Schedule a demo now
              </button>
            </a>
          </div>
          </Col>
          </Row>

        <Row className="ll-ent-faq">
          <Row>
          <Col>
        <h2 className="  ll-feat-font mt-5">FAQs</h2>
        <hr className="mt-4 mb-4 ll-burg-short-rule"></hr>
        <h5 className="m-4 ll-serious-font">We understand that A.I. can be confusing and potentially scary; let’s answer  some of the question which we get. </h5>
        </Col>
        </Row>
        <Row align="center">
        <Accordion className="ll-accordion-super-parent">
          <Accordion.Item
            eventKey="0"
            className="ll-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
                Who is Jinx?
            </Accordion.Header>
            <Accordion.Body>
            Jinx is an AI-powered virtual employee that can assist your business in various tasks, such as answering questions, completing tasks, onboarding new hires, providing support and troubleshooting, and bringing new ideas and insights.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="1"
            className="ll-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            How does Jinx work?
            </Accordion.Header>
            <Accordion.Body>
            Jinx searches all of your accumulated company data, including articles, word docs, and PDFs, and sources the most relevant documents to create an answer to your question in seconds.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="2"
            className="ll-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            Can I give Lacoona Legal various formats of documents to upload to Jinx?
            </Accordion.Header>
            <Accordion.Body>
            Yes, you can upload various types of documents to Jinx, such as Word, PowerPoint, and PDFs, and Jinx will instantly learn all that information and use it to provide answers to your questions.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="3"
            className="ll-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            Can I train Jinx on my specific business processes?
            </Accordion.Header>
            <Accordion.Body>
            Yes, you can train Jinx on your specific business processes, such as support documentation, policies and strategy documentation; allowing your team to quickly troubleshoot any issues.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="4"
            className="ll-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            Is Jinx a replacement for human employees?
            </Accordion.Header>
            <Accordion.Body>
            No, Jinx is not a replacement for human employees. Instead, it is designed to assist and augment your team, allowing them to focus on more important tasks and work more efficiently.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="5"
            className="ll-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            How would my team engage with Jinx?
            </Accordion.Header>
            <Accordion.Body>
            You can use the chatbot by typing your questions or statements into the chat window once it has been built. The chatbot will then generate a response based on the information it has been programmed to understand.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="6"
            className="ll-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            How does costing work?
            </Accordion.Header>
            <Accordion.Body>
            Our monthly membership includes access to a chatbot and a predetermined amount of messages which will be sent to Jinx. There may be additional fees for certain advanced features or services and should your users of the bot send more messages than expected, we will merely bill you accordingly.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="7"
            className="ll-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            How do I sign up?
            </Accordion.Header>
            <Accordion.Body>
            To find out more about how to get started <a rel="noreferrer" target="_blank" href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2cWDUQamVqaMkDqTF3Pus3dFx1aIJE1rQ9NdF6A1p8_4WkI5h32BSPSOkk3N4FLh4IiwmfaI1V">click here.</a>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="8"
            className="ll-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            How does delivery of the tool work? I.e. How will my users be able to access the bot.
            </Accordion.Header>
            <Accordion.Body>
            Our bot effortlessly integrates with popular platforms like WhatsApp, Facebook, and intranets, or can be embedded as a customizable widget. This versatile accessibility enables users to experience seamless communication and support on their preferred channel, promoting user satisfaction and consistent interaction.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="9"
            className="ll-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            How secure is the data stored in Jinx?
            </Accordion.Header>
            <Accordion.Body>
            Jinx prioritizes data security and employs encryption, access controls, and regular security audits to ensure your data is secure and protected against unauthorised access.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="10"
            className="ll-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            Is Jinx compliant with data privacy regulations?
            </Accordion.Header>
            <Accordion.Body>
            Yes, Jinx is compliant with various data privacy regulations, such as GDPR and CCPA, ensuring your data is processed and stored securely and responsibly.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="11"
            className="ll-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            How long does it take to set up Jinx for my organisation?
            </Accordion.Header>
            <Accordion.Body>
            The setup time for Jinx depends on the complexity of your organization's processes and the volume of data to be ingested. Typically, the implementation on average takes 3 weeks.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="12"
            className="ll-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            Can Jinx handle an increase in the volume of queries and data as my business grows?
            </Accordion.Header>
            <Accordion.Body>
            Yes, Jinx is designed to scale with your business. Its AI architecture allows it to manage an increasing volume of queries and data, providing consistent performance as your organization grows.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="13"
            className="ll-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            Can Jinx handle data from multiple sources and in different languages?
            </Accordion.Header>
            <Accordion.Body>
            Yes, Jinx can process data from multiple sources and supports various languages, ensuring a seamless experience for users in different regions. Whilst maintaining consistency of company standards.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="14"
            className="ll-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            In which languages is Jinx available?
            </Accordion.Header>
            <Accordion.Body>
            Jinx is available in multiple languages, with continuous improvements and additions to its language capabilities. Please contact our sales team for the latest information on supported languages.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="15"
            className="ll-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            What kind of customer support is available for Jinx users?
            </Accordion.Header>
            <Accordion.Body>
            We offer various support options, including email, phone, and live chat, to ensure your concerns are addressed promptly. Our support team is available to assist with troubleshooting, training, and any other questions you may have.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="16"
            className="ll-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            Can I whitelabel Jinx to match my company's branding?
            </Accordion.Header>
            <Accordion.Body>
            Yes, Jinx can be whitelabeled, allowing you to customize its appearance and user experience to align with your organization's branding and maintain a consistent look across your communication channels.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        </Row>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default MeetJinxScreen;
