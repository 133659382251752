import React, { useRef, useEffect } from "react";

/* There was lots of commented out code that i've removed to increase ease of reading.  I've stored it in Notion in case it's needed later. */
export default function MyLandbot({
  url,
  userId,
  companyId,
  role,
  email,
  name,
  userName,
  botHistory,
}) {
  const containerRef = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line
    const _landbot = new Landbot.Container({
      container: containerRef.current,
      configUrl: url,
      customData: {
        userId: userId,
        company_id: email,
        company_name: name,
        user_name: userName,
        bot_history: botHistory,
      },
    });
    return () => _landbot.destroy();
    // eslint-disable-next-line
  }, [url, containerRef, userId]);

  return <div className="MyLandbot" ref={containerRef} />;
}
