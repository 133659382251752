import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { useFormik } from "formik";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { LinkContainer } from "react-router-bootstrap";

function ForLawyersScreen() {
  const [showModal, toggleShowModal] = React.useState(false);

  function handleShow() {
    toggleShowModal(true);
    setFormStep(1);
  }
  /* const handleShow = () => toggleShowModal(true) */
  const handleHide = () => toggleShowModal(false);

  const formik = useFormik({
    initialValues: {
      formType: "for_lawyers",
      firstName: "",
      surname: "",
      firmName: "",
      firmSize: "",
      lawyerOperation: "",
      lawyerEmail: "",
      lawyerType: [],
    },
    onSubmit: (values) => {
      setFormStep((formStep) => formStep + 1);
      formik.resetForm();
      try {
        // eslint-disable-next-line
        const resp = axios.post(
          "https://hook.eu1.make.com/mysxrp19c3l91eoa65otxc3jo06syc45",
          { values }
        );
        console.log("Response");
      } catch (error) {
        console.log("Error");
      }
    },
  });
  const [formStep, setFormStep] = useState(1);

  const [cardState, setCardState] = useState(1);
  const [card2State, setCard2State] = useState(1);
  const [card3State, setCard3State] = useState(1);

  function setNextFormStep() {
    setFormStep((formStep) => formStep + 1);
  }

  function setPreviousFormStep() {
    setFormStep((formStep) => formStep - 1);
  }

  function preventSubmit(e) {
    e.preventDefault();
  }

  return (
    <div>
      <Helmet>
        <title>For Lawyers</title>
        <meta
          name="description"
          content="A modernised approach to your lead sourcing"
        />
        <link rel="canonical" href="/for-lawyers" />
      </Helmet>
      <Header />
      <Container align="center" className="page-container front-page-container">
        {/* This is the Navigation divider for the front end of our pages */}
        <Row className="divider-parent">
          <Col align="center" className="utility-divider-lines"></Col>
          <Col align="center" className="util-icon-parent">
            <i className="fa-regular fa-circle-dot fa-1x divider-icon"></i>
          </Col>
          <Col align="center" className="utility-divider-lines"></Col>
        </Row>

        <Row className="lcn-section-spacing for-lawyers--row lcn-page-width-section mt-5">
          <h3 className="for-lawyers--header ll-feat-font p-3">
            Find clients and build your practice
          </h3>
          <p className="p-3">
          Unlock cost-effective business opportunities and expand your legal practice with Africa's premier online marketplace. Sign up for a free profile and only pay when you secure your first client. Get access to potential clients and project leads for a small monthly subscription.
          </p>
          <Col>
            <a
              className="for-lawyers--schedule"
              href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2cWDUQamVqaMkDqTF3Pus3dFx1aIJE1rQ9NdF6A1p8_4WkI5h32BSPSOkk3N4FLh4IiwmfaI1V"
              target="blank"
            >
              <button className="mobile-wrap lcn-button-orange mt-2 px-4 py-3">
                Speak to our success team now
              </button>
            </a>
          </Col>
          <Col>
            <div
              className="modal show"
              style={{ display: "block", position: "initial", height: 0 }}
            >
              <Modal show={showModal} onHide={handleHide}>
                <Modal.Header closeButton>
                  <Modal.Title>Lawyer Signup</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div>
                    <Row className="join-our-network-form-container">
                      {/* **************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************** */}

                      {formStep === 1 && (
                        <Col
                          className="form-step for-lawyers--step-container"
                          lg={8}
                        >
                          <Row>
                            <h4 className="form-step-heading-dark">
                              What is your name and surname?
                            </h4>
                          </Row>
                          <Row>
                            <Form onSubmit={preventSubmit}>
                              <Form.Control
                                type="input"
                                className="mb-3"
                                name="firstName"
                                onChange={formik.handleChange}
                                value={formik.values.firstName}
                                placeholder="First Name"
                              />
                              <Form.Control
                                type="input"
                                className="mb-3"
                                name="surname"
                                onChange={formik.handleChange}
                                value={formik.values.surname}
                                placeholder="Surname"
                              />
                            </Form>
                          </Row>

                          <Row className="text-center">
                            <Col className="form-step-next-button-container justify-content-center">
                              <button
                                className="lcn-button-orange py-2 px-4"
                                onClick={setNextFormStep}
                              >
                                Next
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      )}
                      {/*  Second Question */}
                      {formStep === 2 && (
                        <Col className="form-step" lg={8}>
                          <Row>
                            <h4 className="form-step-heading-dark">
                              What is the name and size of your firm or
                              practice?
                            </h4>
                          </Row>
                          <Row>
                            <Form onSubmit={preventSubmit}>
                              <Form.Control
                                type="input"
                                className="mb-3"
                                name="firmName"
                                onChange={formik.handleChange}
                                value={formik.values.firmName}
                                placeholder="Firm Name"
                              />
                              <Form.Control
                                type="input"
                                className="mb-3"
                                name="firmSize"
                                onChange={formik.handleChange}
                                value={formik.values.firmSize}
                                placeholder="Firm Size"
                              />
                            </Form>
                          </Row>

                          <Row>
                            <Col>
                              <button
                                className="lcn-button-orange py-2 px-4"
                                onClick={setPreviousFormStep}
                              >
                                Back
                              </button>
                            </Col>
                            <Col className="form-step-next-button-container">
                              <button
                                className="lcn-button-orange py-2 px-4"
                                onClick={setNextFormStep}
                              >
                                Next
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      )}
                      {/* Third Question */}
                      {formStep === 3 && (
                        <Col className="form-step" lg={8}>
                          <Row>
                            <h4 className="form-step-heading-dark">
                              What is your email address?
                            </h4>
                          </Row>
                          <Row>
                            <Form onSubmit={preventSubmit}>
                              <Form.Control
                                type="input"
                                className="mb-3"
                                name="lawyerEmail"
                                onChange={formik.handleChange}
                                value={formik.values.lawyerEmail}
                              />
                            </Form>
                          </Row>
                          <Row>
                            <Col>
                              <button
                                className="lcn-button-orange py-2 px-4"
                                onClick={setPreviousFormStep}
                              >
                                Back
                              </button>
                            </Col>
                            <Col className="form-step-next-button-container">
                              <button
                                className="lcn-button-orange py-2 px-4"
                                onClick={setNextFormStep}
                              >
                                Next
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      )}
                      {/* Fourth Question */}
                      {formStep === 4 && (
                        <Col className="form-step" lg={8}>
                          <Row>
                            <h4 className="form-step-heading-dark">
                              Where do you operate?
                            </h4>
                          </Row>
                          <Row>
                            <Form onSubmit={preventSubmit}>
                              <Form.Control
                                type="input"
                                className="mb-3"
                                name="lawyerOperation"
                                onChange={formik.handleChange}
                                value={formik.values.lawyerOperation}
                              />
                            </Form>
                          </Row>
                          <Row>
                            <Col>
                              <button
                                className="lcn-button-orange py-2 px-4"
                                onClick={setPreviousFormStep}
                              >
                                Back
                              </button>
                            </Col>
                            <Col className="form-step-next-button-container">
                              <button
                                className="lcn-button-orange py-2 px-4"
                                onClick={setNextFormStep}
                              >
                                Next
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      )}

                      {formStep === 5 && (
                        <Col className="form-step" lg={8}>
                          <Row>
                            <h4 className="form-step-heading-dark">
                              Which of these describe you best?
                            </h4>
                          </Row>

                          <div role="group" className="for-lawyer-checkbox p-4">
                            <label className="for-lawyers-label">
                              <Form.Check
                                type="checkbox"
                                name="lawyerType"
                                label="Attorney"
                                value="Attorney"
                                onChange={formik.handleChange}
                              />
                            </label>
                            <label>
                              <Form.Check
                                type="checkbox"
                                name="lawyerType"
                                label="Advocate"
                                value="Advocate"
                                onChange={formik.handleChange}
                              />
                            </label>
                            <label>
                              <Form.Check
                                type="checkbox"
                                name="lawyerType"
                                label="Legal Consultant"
                                value="Legal Consultant"
                                onChange={formik.handleChange}
                              />
                            </label>
                            <label>
                              <Form.Check
                                type="checkbox"
                                name="lawyerType"
                                label="Other"
                                value="Other"
                                onChange={formik.handleChange}
                              />
                            </label>
                          </div>

                          <Row className="mt-3">
                            <Col>
                              <button
                                className="lcn-button-orange py-2 px-4"
                                onClick={setPreviousFormStep}
                              >
                                Back
                              </button>
                            </Col>
                            <Col className="form-step-next-button-container">
                              <button
                                className="lcn-button-orange py-2 px-4"
                                onClick={formik.handleSubmit}
                                type="submit"
                              >
                                Submit
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      )}

                      {/* Seventh Question */}
                      {formStep === 6 && (
                        <Col className="form-step" lg={8}>
                          <Row>
                            <h4 className="form-step-heading-dark">
                              Thank you for completing the form, we will be in
                              touch shortly!
                            </h4>
                            <button
                              className="lcn-button-orange lawyer-signup"
                              variant="secondary"
                              onClick={handleHide}
                            >
                              Close
                            </button>
                          </Row>
                          <Row></Row>
                        </Col>
                      )}
                    </Row>
                  </div>
                </Modal.Body>
              </Modal>
            </div>

            {/* **************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************************** */}
            <button
              onClick={handleShow}
              className="mobile-wrap lcn-button-orange mt-2 px-4 py-3"
            >
              Apply now to join others in your industry
            </button>
          </Col>
        </Row>

        {/* Tooling Section */}
        <Row className="lcn-section-spacing lcn-page-width-section">
          <Col className="ll-frontend-content-block ll-section-1">
            <Row className="ll-front-content-block-text-parent">
              <h3 className="ll-subheading">
                Get seen and hired faster through our marketplace
              </h3>
              <p className="ll-body">
                Maximise your visibility and increase your chances of being
                hired by utilising our online marketplace for legal service
                providers. Our technology streamlines the connection process
                between lawyers and clients, making collaboration and
                transactions secure and efficient.
              </p>
              {/* <a href="dashboard/legal-products" className="ll-cta-icon-parent">
                <strong>
                  <p>Find Out More</p>
                </strong>
                <i className="fa-solid fa-arrow-right ll-cta-icon"></i>
              </a>  */}
            </Row>
          </Col>
          <Col className="ll-section-2">
            <img
              className="ll-content-image"
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Reece-Tools-compressed.png"
              alt="Developer Floating with Tools"
            ></img>
          </Col>
        </Row>
        {/* Cohort oversight section */}
        <Row className="lcn-section-spacing lcn-page-width-section ll-flex-col-reverse">
          <Col className="ll-section-2">
            <img
              className="ll-content-image"
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Oversight-bot-tools.png"
              alt="Oversight Tooling Bot"
            ></img>
          </Col>
          <Col className="ll-frontend-content-block ll-section-1">
            <Row className="ll-front-content-block-text-parent">
              <h3 className="ll-subheading">Boost your business development</h3>
              <p className="ll-body">
                Grow your business and increase opportunities by joining our
                online bidding platform for legal professionals. Select clients
                and projects of interest and be chosen by clients from a curated
                list of proposals. All for a low monthly subscription fee.
              </p>
              {/* <a href="https://calendar.app.google/HtdcRwx6bURGCHH88" className="ll-cta-icon-parent">
                <strong>
                  <p>Speak to us</p>
                </strong>
                <i className="fa-solid fa-arrow-right ll-cta-icon"></i>
              </a> */}
            </Row>
          </Col>
        </Row>
        {/* Organisation-level reporting section */}
        <Row className="lcn-section-spacing lcn-page-width-section">
          <Col className="ll-frontend-content-block ll-section-1">
            <Row className="ll-front-content-block-text-parent">
              <h3 className="ll-subheading">
                The only marketing you’ll need to do
              </h3>
              <p className="ll-body">
                Efficiency and results-driven client sourcing available through
                our technology-based platform. Utilise our automated matching
                system, retainer setup and lead capturing bot to increase
                business opportunities and streamline interactions with future
                clients.
              </p>
              {/* <a href="/dashboard/legal-products" className="ll-cta-icon-parent">
                <strong>
                  <p>Explore more</p>
                </strong>
                <i className="fa-solid fa-arrow-right ll-cta-icon"></i>
              </a> */}
            </Row>
          </Col>
          <Col className="ll-section-2">
            <img
              className="ll-content-image"
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/frontend-images/Invest-magnet-lacoona-legal.png"
              alt="Computer showing Tools"
            ></img>
          </Col>
        </Row>

        <Row className="lcn-section-spacing lcn-page-width-section">
          <h3 className="ll-subheading mb-5">How it works</h3>
          <Row align="center">
            <Col lg="4">
              {/* Card */}
              <div
                className="my-3"
                onMouseEnter={() => setCardState(2)}
                onMouseLeave={() => setCardState(1)}
              >
                {cardState === 1 && (
                  <div className="for-lawyers-cards-front legal-product-container ll-card-interact p-4 animated-1s fadeInUp animatedFadeInUp">
                    <Row className="for-lawyers-cards-front-inner">
                      <Col className="fl-card-title-parent" align="center">
                        <h4 className="for-lawyer-card-title">
                          Individuals & Ideas
                        </h4>
                      </Col>
                    </Row>
                  </div>
                )}
                {cardState === 2 && (
                  <div className="legal-product-content">
                    <Row>
                      <p className="ll-cookie-consent-content">
                        Both first-time and seasoned entrepreneurs use Lacoona
                        Legal to form legal entities, draft initial business
                        contracts, and generally ensure their companies get off
                        the ground with a solid legal framework.
                      </p>
                    </Row>
                  </div>
                )}
              </div>
            </Col>
            <Col lg="4">
              {/* Card */}
              <div
                className="my-3"
                onMouseEnter={() => setCard2State(2)}
                onMouseLeave={() => setCard2State(1)}
              >
                {card2State === 1 && (
                  <div className="for-lawyers-cards-front legal-product-container ll-card-interact px-4 py-4 pe-5 animated-1s fadeInUp animatedFadeInUp">
                    <Row className="for-lawyers-cards-front-inner">
                      <Col className="fl-card-title-parent" align="center">
                        <h4 className="legal-product-title">Startups</h4>
                      </Col>
                    </Row>
                  </div>
                )}
                {card2State === 2 && (
                  <div className="legal-product-content">
                    <Row>
                      <p className="ll-cookie-consent-content">
                        Our startup clients use Lacoona Legal to manage both
                        their day-to-day needs (contracts, employment, corporate
                        maintenance) as well as more specialised projects like
                        patents, governance and venture financing.
                      </p>
                    </Row>
                  </div>
                )}
              </div>
            </Col>
            <Col lg="4">
              {/* Card */}
              <div
                className="my-3"
                onMouseEnter={() => setCard3State(2)}
                onMouseLeave={() => setCard3State(1)}
              >
                {card3State === 1 && (
                  <div className="for-lawyers-cards-front legal-product-container ll-card-interact px-4 py-4 pe-5 animated-1s fadeInUp animatedFadeInUp">
                    <Row className="for-lawyers-cards-front-inner">
                      <Col className="fl-card-title-parent" align="center">
                        <h4 className="legal-product-title">SMEs</h4>
                      </Col>
                    </Row>
                  </div>
                )}
                {card3State === 2 && (
                  <div className="legal-product-content">
                    <Row>
                      <p className="ll-cookie-consent-content">
                        Our larger SME clients often have dedicated legal teams
                        but use Lacoona Legal to keep up with general overflow
                        work or tap into our broad network for projects that
                        require a very specific degree of expertise.
                      </p>
                    </Row>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Row>

        {/* Page Tagline Section */}
        <Row className="ll-dark-section py-5 mt-5 ">
          <h3 className="highlight-title-small mb-5">Your marketing partner and evolution into technology</h3>
          <Row align="center">
            <Col>
              <a
                href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2cWDUQamVqaMkDqTF3Pus3dFx1aIJE1rQ9NdF6A1p8_4WkI5h32BSPSOkk3N4FLh4IiwmfaI1V"
                target="blank"
              >
                <button className="mobile-wrap lcn-button-orange px-4 py-3">
                  I have a specific questions to ask
                </button>
              </a>
            </Col>
          </Row>
        </Row>

        {/* How it works section */}
        <Row className="lcn-section-spacing lcn-page-width-section">
          <h3 className="ll-subheading mb-5">How it works</h3>
          <Row className="row-reverse-medium">
            <Col lg="5">
              <div align="left" className="py-4">
                <h4 className="ll-subheading">Apply to join</h4>
                <p>
                Fill out your, or your firms details, including education, employment and legal licensing information.
                </p>
              </div>
            </Col>
            <Col lg="2" className="focus-num-parent">
              <span className="fal-focus-number">1</span>
            </Col>
            <Col lg="5"></Col>
          </Row>
          <Row>
            <Col lg="5"></Col>
            <Col lg="2" className="focus-num-parent">
              <span className="fal-focus-number">2</span>
            </Col>
            <Col lg="5">
              <div align="left" className="py-4">
                <h4 className="ll-subheading">
                Vetting process
                </h4>
                <p>
                Go through our screening process so we can learn about your expertise and clients of interest.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="row-reverse-medium">
            <Col lg="5">
              <div align="left" className="py-4">
                <h4 className="ll-subheading">Start receiving clients & offering proposals</h4>
                <p>
                Once cleared, start offering proposals for projects in our marketplace that fit your skill set.
                </p>
              </div>
            </Col>
            <Col lg="2" className="focus-num-parent">
              <span className="fal-focus-number">3</span>
            </Col>
            <Col className="fal-action-steps-last" lg="5"></Col>
          </Row>
          <Row align="center">
            <hr />
            <Col>
              <LinkContainer to={"/register-lawyer-user"}>
                <button className="mobile-wrap lcn-button-orange px-4 py-3">
                Create your account today
                </button>
              </LinkContainer>
            </Col>
          </Row>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default ForLawyersScreen;
