import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import lottie from "lottie-web";
import SearchLoT from "../lottie-files/SearchLoT";


function SearchBox(props) {
  const [keyword, setKeyword] = useState("");
  let navigate = useNavigate();
  let classnameprop = props.classnameprop;

  //Code for using dynamic Search query hidden due to refresh error : code fails on second check useParams()
  // let param=useParams();
  // let searchres=param[Object.keys(param)[0]];
  // let searchitem="Search";
  // console.log("Search Params:", searchres);
  // if(!param){
  //   searchitem=searchres.split("/")[1];
  // }


  // console.log("Search Params:", keyword);


  
  function submitHandler(e) {
    e.preventDefault();
    navigate(`/dashboard/search/${keyword}`);
  }
  return (
    <div className={classnameprop}>
      <Form onSubmit={submitHandler}>
        <Form.Control
          type="text"
          placeholder="Search Everything"
          name="q"
          onChange={(e) => setKeyword(e.target.value)}
        ></Form.Control>
        <div
          className="ll-search-submitbutton"
          onMouseEnter={() => lottie.play("search-icon")}
          onMouseLeave={() => lottie.stop("search-icon")}
          type="submit"
        >
          <SearchLoT aniName="search-icon" />
        </div>
      </Form>
    </div>
  );
}

export default SearchBox;
