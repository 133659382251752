import React, { Fragment, useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Modal } from "react-bootstrap";
import { Calendar, Views, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import "react-big-calendar/lib/css/react-big-calendar.css";

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const myEventsList = [];

function LawyerCal() {
  const lawyerDetails = useSelector((state) => state.userLawyerDetails);
  const { lawyer } = lawyerDetails;
  const [myEvents, setEvents] = useState(myEventsList);
  const [selectedEvent, setSelectedEvent] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [eventsLength, setEventsLength] = useState(0);

  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      const title = "Opening";
      // let index = eventsLength;
      // console.log("index", index);
      console.log("eventsLength in function", eventsLength);
      if (title) {
        setEvents((prev) => [...prev, { start, end, title, id: eventsLength }]);
      }
      setEventsLength((old) => old + 1);
    },
    [setEvents, setEventsLength, eventsLength]
  );

  const handleSelectEvent = useCallback((event) => {
    setModalShow(true);
    setSelectedEvent(event);
    // window.alert(event.title);
  }, []);

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(2015, 3, 12),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  );

  // Modal function
  function handleModalClose() {
    setModalShow(false);
  }
  function deleteSelectedEvent(id) {
    console.log("index in delete event", id);
    // setEvents((prev) => prev.filter((_, i) => i.id !== id));
    setEvents((prev) => prev.filter((item) => item.id !== id));
  }
  // console.log("events", myEvents);
  // console.log("modalShow", modalShow);
  // console.log("selectedEvent", selectedEvent);
  // console.log("eventsLength", eventsLength);

  return (
    <div>
      <div className="lacoona-cal">
        <Calendar
          localizer={localizer}
          events={myEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          selectable
          scrollToTime={scrollToTime}
        />
      </div>
      <div>
        <Modal show={modalShow} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedEvent?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <button onClick={() => deleteSelectedEvent(selectedEvent?.id)}>
              Delete event
            </button>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default LawyerCal;
