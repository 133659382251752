import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../actions/userActions";
import { Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import SearchBox from "./SearchBox";
import LawyerSearchBox from "./LawyerSearchBox";

function LawyerDashHeader({ pageTitle }) {
  const { userInfo } = useSelector((state) => state.userLogin);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <Row className="dashboard-header-container px-2">
      <Col className="dashboard-title-container">
        <Row className="dash-search-hero">
          {userInfo && (
            <div>
              <div
                className="ll-mobile-sign-out ll-card-interact"
                onClick={logoutHandler}
              >
                <i className="fa-solid fa-arrow-right-from-bracket"></i>
              </div>
              <LinkContainer to={"/lawyer-dashboard/user-dash"}>
                <div className="ll-mobile-profile ll-card-interact">
                  <i class="fa-solid fa-user"></i>
                </div>
              </LinkContainer>
            </div>
          )}
          <Row>
            <h4 className="dashboard-header">{pageTitle}</h4>
          </Row>
          <Row className="p-0">
            <LawyerSearchBox classnameprop="ll-dash-mobile-search" />
          </Row>
        </Row>
      </Col>
    </Row>
  );
}

export default LawyerDashHeader;
