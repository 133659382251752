import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
function CheckoutNotifyScreen() {
  return (
    <div>
      <Header />
      <Container align="center" className="page-container">
        {/* This is the Navigation divider for the front end of our pages */}
        <Row className="divider-parent">
          <Col align="center" className="utility-divider-lines"></Col>
          <Col align="center" className="util-icon-parent">
            <i className="fa-regular fa-circle-dot fa-1x divider-icon"></i>
          </Col>
          <Col align="center" className="utility-divider-lines"></Col>
        </Row>
        {/* Page Hero starts here */}
        <Row className="hero-container lcn-section-spacing">
          <Row align="center" className="content-center">
            <div className="my-5">
              <h1 className="serious-font hero-header-center">Notify</h1>
              <p>
                Everything you need to manage your cohort and support them in
                formalising their businesses and unlocking growth.
              </p>
            </div>
            {/* CTA Section */}
            <Row className="hero-button-parent">
              <Col>
                <button className="lcn-button-orange px-4 py-3">
                  Start your journey
                </button>
              </Col>
              <Col>
                <button className="lcn-button-orange-outline  px-4 py-3">
                  Speak to us
                </button>
              </Col>
            </Row>
          </Row>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default CheckoutNotifyScreen;
