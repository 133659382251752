import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { LinkContainer } from "react-router-bootstrap";

function ForSmesScreen() {
  return (
    <div>
      <Helmet>
        <title>For businesses</title>
        <meta
          name="description"
          content="Manage your company legalities easily & online"
        />
        <link rel="canonical" href="/for-smes" />
      </Helmet>
      <Header />
      <Container align="center" className="page-container front-page-container">
        {/* This is the Navigation divider for the front end of our pages */}
        <Row className="divider-parent">
          <Col align="center" className="utility-divider-lines"></Col>
          <Col align="center" className="util-icon-parent">
            <i className="fa-regular fa-circle-dot fa-1x divider-icon"></i>
          </Col>
          <Col align="center" className="utility-divider-lines"></Col>
        </Row>
        {/* Page Hero starts here */}
        <Row className="hero-container lcn-section-spacing lcn-page-width-section">
          {/* Floating images placed here so that we can use absolute as the position prop */}
          <img
            id="for-inc-split-ball"
            className="lcn-bounce"
            src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Split-ball.svg"
            alt="Background images"
          ></img>
          <img
            id="for-inc-ring"
            className="lcn-swing"
            src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/ring.svg"
            alt="Background images"
          ></img>
          <img
            id="for-inc-skwigle"
            className="lcn-slide"
            src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/skwigle.svg"
            alt="Background images"
          ></img>
          <Row align="center" className="content-center">
            <div className="my-5">
              <h1 className="serious-font hero-header-center">
                Manage your company legalities easily & online
              </h1>
              <p>
                We know that starting and running a business takes a huge amount
                of effort. By simplifying and automating business formation and
                everyday legal work, we help small businesses start and thrive.
              </p>
            </div>
            {/* CTA Section */}
            <Row className="hero-button-parent">
              <Col>
                <LinkContainer to={`/dashboard/legal-products`}>
                  <button className="lcn-button-orange px-4 py-3">
                    See our products
                  </button>
                </LinkContainer>
              </Col>
              <Col>
                <LinkContainer to={"/register"}>
                  <button className="lcn-button-orange-outline  px-4 py-3">
                    Subscribe for free
                  </button>
                </LinkContainer>
              </Col>
            </Row>
          </Row>
        </Row>
        {/* Tooling Section */}
        <Row className="lcn-section-spacing lcn-page-width-section">
          <Col className="ll-frontend-content-block ll-section-1">
            <Row className="ll-front-content-block-text-parent">
              <h3 className="ll-subheading">
                Supporting the legal structures of your business.
              </h3>
              <p className="ll-body">
                Lacoona offers founders and business owners affordable,
                convenient and ‘legalese-simple’ access to legal regulations,
                business documentation and ongoing compliance. Supporting a
                strong set-up, operations and corporate governance foundation
                which unlocks opportunities including accessing bank loans,
                raising capital and efficient supplier and provider management.
              </p>
              <a href="dashboard/legal-products" className="ll-cta-icon-parent">
                <strong>
                  <p>Find Out More</p>
                </strong>
                <i className="fa-solid fa-arrow-right ll-cta-icon"></i>
              </a>
            </Row>
          </Col>
          <Col className="ll-section-2">
            <img
              className="ll-content-image"
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Reece-Tools-compressed.png"
              alt="Developer Floating with Tools"
            ></img>
          </Col>
        </Row>
        {/* Cohort oversight section */}
        <Row className="lcn-section-spacing lcn-page-width-section">
          <Col className="ll-section-2">
            <img
              className="ll-content-image"
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Oversight-bot-tools.png"
              alt="Oversight Tooling Bot"
            ></img>
          </Col>
          <Col className="ll-frontend-content-block ll-section-1">
            <Row className="ll-front-content-block-text-parent">
              <h3 className="ll-subheading">
                Find business support and legal counsel.
              </h3>
              <p className="ll-body">
                While our intention is to support you however we can, sometimes
                a warm body is good for some human-centered counselling for more
                intricate scenarios or just for a bit of reassurance. We have
                sourced a network of legal experts and business coaches who are
                here to support you on your journey. Giving your business the
                best possible opportunity for success by understanding your
                needs and sourcing an appropriate professional. No hidden
                running costs or fees.
              </p>
              <a
                href="https://calendar.app.google/HtdcRwx6bURGCHH88"
                className="ll-cta-icon-parent"
              >
                <strong>
                  <p>Speak to us</p>
                </strong>
                <i className="fa-solid fa-arrow-right ll-cta-icon"></i>
              </a>
            </Row>
          </Col>
        </Row>
        {/* Organisation-level reporting section */}
        <Row className="lcn-section-spacing lcn-page-width-section">
          <Col className="ll-frontend-content-block ll-section-1">
            <Row className="ll-front-content-block-text-parent">
              <h3 className="ll-subheading">
                Get investment-ready and unlock opportunities.
              </h3>
              <p className="ll-body">
                Our network of legal and funding experts have helped
                entrepreneurs raise money and grow their businesses. We
                facilitate the investment process from document creation,
                corporate governance assurance and cap table management. With
                your permission and should your business so choose, we will
                allow investors to demonstrate interest in your business and
                request access to components in your staggered data room. This
                can also contribute towards grant reporting and investor
                communication. Full control, no frills.
              </p>
              <a
                href="/dashboard/legal-products"
                className="ll-cta-icon-parent"
              >
                <strong>
                  <p>Explore more</p>
                </strong>
                <i className="fa-solid fa-arrow-right ll-cta-icon"></i>
              </a>
            </Row>
          </Col>
          <Col className="ll-section-2">
            <img
              className="ll-content-image"
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/frontend-images/Invest-magnet-lacoona-legal.png"
              alt="Computer showing Tools"
            ></img>
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default ForSmesScreen;
