import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import UserValidator from "../components/UserValidator";
import SearchBox from "../components/SearchBox";
import MobileDashNav from "../components/MobileDashNav";
import FeedbackQuestionLoT from "../lottie-files/FeedbackQuestion";
import LawyerOverview from "../components/LawyerOverview";
import LawyersSideNav from "../components/LawyersSideNav";
import LawyerSchedule from "../components/LawyerSchedule";
import LawyerAppointments from "../components/LawyerAppointments";
import LawyerPayments from "../components/LawyerPayments";
import UserDash from "../components/UserDash";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import { getISOWeek, isSameISOWeek } from "date-fns";
import { getUserLawyerDetails } from "../actions/userActions";
import LawyerMobileDashNav from "../components/LawyerMobileDashNav";
import { setNestedObjectValues } from "formik";
import LawyerAskJinx from "../components/LawyerAskJinx";
import LawyerSearchResultsScreen from "../components/LawyerSearchResultsDash";
import LawyerSearchBox from "../components/LawyerSearchBox";
import LawyerUserDash from "../components/LawyerUserDash";
import LawyerJobBoard from "../components/LawyerJobBoard";

function LawyerDashboard() {
  // date stuff for calendar
  const locales = {
    "en-US": enUS,
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  // Get all relevant Lawyer info
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const myEventsList = [];
  const lawyerDetails = useSelector((state) => state.userLawyerDetails);
  const { lawyer } = lawyerDetails;

  // State items for Lawyer dash functionality
  const [myEvents, setMyEvents] = useState(myEventsList);
  const [numBookings, setNumBookings] = useState(0);
  const [numOpenings, setNumOpenings] = useState(0);
  const [eventsLength, setEventsLength] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const [saveModalShow, setSaveModalShow] = useState(false);
  const [saveModalToShow, setSaveModalToShow] = useState(false);
  const [badEventModalShow, setBadEventModalShow] = useState(false);
  const [noEventsRepeatModal, setNoEventsRepeatModal] = useState(false);
  const [repeatModal, setRepeatModal] = useState(false);
  const [weekDropdown, setWeekDropdown] = useState(1);

  // Query Lawyer details after userInfo has been fetched
  useEffect(() => {
    dispatch(getUserLawyerDetails());
  }, [userInfo]);

  // Convert Lawyer's appointments json into array with valid dates etc and
  // save in myEvents state, which populates calendar
  useEffect(() => {
    let bookings = 0;
    let openings = 0;
    let currentDate = new Date();

    if (lawyer && lawyer.appointments && lawyer.appointments.data.length > 0) {
      const appointmentsArray = JSON.parse(
        lawyer.appointments.data.replace(/'/g, '"')
      );

      const convertedEvents = appointmentsArray.map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
      }));
      setMyEvents((old) => convertedEvents);
      for (var i = 0; i < convertedEvents.length; i++) {
        if (convertedEvents[i].status === "Reservation") {
          bookings += 1;
        }
        if (convertedEvents[i].end >= currentDate) {
          openings += 1;
        }
      }
      setNumBookings(bookings);
      setNumOpenings(openings);
    }
  }, [lawyer]);

  //  Create new slot on calendar click
  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      const title = "Opening";
      const randomNumber = Math.floor(Math.random() * 90000) + 10000;
      const status = "Opening";

      setMyEvents((prev) => [
        ...prev,
        { start, end, title, id: randomNumber, status: status },
      ]);

      // This state controls whether to show the "Remember to save modal"
      // More info in the below useEffect
      setSaveModalToShow(true);
    },
    [setMyEvents, setEventsLength, eventsLength]
  );

  // This useEffect triggers "remember to save" modal only ONCE after lawyer first attempts to create a new calendar event.
  // It only runs once because once saveModalToShow (this useEffect's dependency) has been set to true in handleSelectSlot function above,
  // it remains true, thus does not change when set to true again, thus this useeffect only runs once
  useEffect(() => {
    if (saveModalToShow) {
      setSaveModalShow(true);
    }
  }, [saveModalToShow]);

  // Default date function provided by React-big-calendar (not sure what it does really)
  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(2015, 3, 12),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  );

  // Modal functions for viewing events and deleting them

  // Show modal on event select and save selected event to state
  const handleSelectEvent = useCallback((event) => {
    setModalShow(true);
    setSelectedEvent(event);
  }, []);

  function handleModalClose() {
    setModalShow(false);
  }

  function deleteSelectedEvent(id) {
    setMyEvents((prev) => prev.filter((item) => item.id !== id));
    handleModalClose();
  }

  function handleSaveModalClose() {
    setSaveModalShow(false);
  }

  function handleBadEventModalClose() {
    setBadEventModalShow(false);
  }

  function handleNoEventsRepeatModalClose() {
    setNoEventsRepeatModal(false);
  }

  function handleWeekDropDown(e) {
    setWeekDropdown(e.target.value);
  }

  function handleRepeatModalClose() {
    setRepeatModal(false);
  }
  function handleRepeatModalOpen() {
    setRepeatModal(true);
  }

  // Event repeating functions below

  // Function for getting the date of the sunday of the coming week so we can warn users not
  // to repeat this week's events if they have events saved for next week.
  function getNextSunday() {
    var today = new Date();
    var day = today.getDay(); // 0 (Sunday) through 6 (Saturday)
    var daysUntilNextSunday = 7 - day;

    // Set the date to the next Sunday
    today.setDate(today.getDate() + daysUntilNextSunday);

    return today;
  }

  // Sort function for myEvents
  function sortByDate(arr) {
    for (let i = 1; i < arr.length; i++) {
      let current = arr[i];
      let j = i - 1;

      while (j >= 0 && arr[j].start > current.start) {
        arr[j + 1] = arr[j];
        j--;
      }

      arr[j + 1] = current;
    }

    return arr;
  }

  // Repeat events for a week
  function repeat() {
    const sundayOfCurrentWeek = getNextSunday();
    // console.log("sundayOfCurrentWeek", sundayOfCurrentWeek);

    const unsortedEvents = myEvents;
    const hasEventsAfterThisWeek = unsortedEvents.some(
      (event) => event.start > sundayOfCurrentWeek
    );
    // console.log("hasEventsAfterThisWeek", hasEventsAfterThisWeek);
    if (hasEventsAfterThisWeek) {
      setRepeatModal(false);
      setNoEventsRepeatModal(true);
    } else {
      setRepeatModal(false);
      const sortedEvents = sortByDate(unsortedEvents);
      const currentDate = new Date();
      let thisWeeksEvents = [];
      let nextWeeksEvents = [];
      let nextWeeksEventsFinal = [];

      // get only the events from the current week
      for (let i of sortedEvents) {
        if (isSameISOWeek(i.start, currentDate)) {
          thisWeeksEvents.push(i);
        }
      }

      // make a new array that replicates this weeks events next week
      for (let n = 1; n <= weekDropdown; n++) {
        for (let i of thisWeeksEvents) {
          let newEvent = {};
          let currentStart = i.start;
          let currentEnd = i.end;
          let nextStart = new Date(currentStart);
          let nextEnd = new Date(currentEnd);
          const randomNumber = Math.floor(Math.random() * 90000) + 10000;
          // nextDate.setDate(currentDate.getDate() + 7);
          nextStart.setDate(currentStart.getDate() + 7 * n);
          nextEnd.setDate(currentEnd.getDate() + 7 * n);
          newEvent = {
            ...i,
            start: nextStart,
            end: nextEnd,
            id: randomNumber,
            status: "Opening",
            title: "Opening",
          };
          if (newEvent.hasOwnProperty("client")) {
            delete newEvent.client;
          }
          if (newEvent.hasOwnProperty("clientEmail")) {
            delete newEvent.clientEmail;
          }
          if (newEvent.hasOwnProperty("lawyerEmail")) {
            delete newEvent.lawyerEmail;
          }
          if (newEvent.hasOwnProperty("scenario")) {
            delete newEvent.scenario;
          }

          nextWeeksEvents.push(newEvent);
        }
      }

      // rebuild next weeks events by merging bookings into openings
      nextWeeksEventsFinal = [nextWeeksEvents[0]];
      for (let i = 0; i < nextWeeksEvents.length; i++) {
        const currentEvent = nextWeeksEvents[i];
        const lastMergedEvent =
          nextWeeksEventsFinal[nextWeeksEventsFinal.length - 1];
        if (currentEvent.start - lastMergedEvent.end === 0) {
          lastMergedEvent.end = currentEvent.end;
          // console.log("lastMergedEvent", lastMergedEvent);
          nextWeeksEventsFinal[nextWeeksEventsFinal.length - 1] =
            lastMergedEvent;
        } else if (currentEvent.start > lastMergedEvent.end) {
          nextWeeksEventsFinal.push(currentEvent);
        }
      }
      // const date = new Date("May 6, 2023");
      setMyEvents((old) => [...old, ...nextWeeksEventsFinal]);
      setSaveModalShow(true);
      // console.log("thisWeeksEvents", thisWeeksEvents);
      // console.log("nextWeeksEventsFinal", nextWeeksEventsFinal);
    }
  }

  //Landbot Feedback Widget
  window.addEventListener("mouseover", initLandbot, { once: true });
  window.addEventListener("touchstart", initLandbot, { once: true });
  var myLandbot;
  function initLandbot() {
    if (!myLandbot) {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.addEventListener("load", function () {
        // eslint-disable-next-line
        var myLandbot = new Landbot.Livechat({
          configUrl:
            "https://storage.googleapis.com/landbot.pro/v3/H-1417040-N5VP7XT8YOWTRG20/index.json",
        });
      });
      s.src = "https://cdn.landbot.io/landbot-3/landbot-3.0.0.js";
      var x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    }
  }

  return (
    <Row className="dashboard-container">
      <LawyerSearchBox classnameprop={"dash-search"} />
      <a
        className="ll-feedback"
        target="_blank"
        href="https://legalfundi.notion.site/For-Lawyers-FAQ-9beeb4a89df541a1866ab8bbf75dfff5"
        rel="noreferrer"
      >
        <FeedbackQuestionLoT aniName="feedback-lottie-dash" />
      </a>
      <LawyersSideNav />
      <Routes>
        <Route path="/validate" element={<UserValidator />} />
        <Route
          path="/lawyer-dash"
          element={
            <LawyerOverview
              localizer={localizer}
              myEvents={myEvents}
              handleSelectEvent={handleSelectEvent}
              handleSelectSlot={handleSelectSlot}
              scrollToTime={scrollToTime}
              handleModalClose={handleModalClose}
              deleteSelectedEvent={deleteSelectedEvent}
              numBookings={numBookings}
              numOpenings={numOpenings}
              modalShow={modalShow}
              selectedEvent={selectedEvent}
            />
          }
        />
        <Route
          path="/schedule"
          element={
            <LawyerSchedule
              localizer={localizer}
              myEvents={myEvents}
              handleSelectEvent={handleSelectEvent}
              handleSelectSlot={handleSelectSlot}
              scrollToTime={scrollToTime}
              handleModalClose={handleModalClose}
              deleteSelectedEvent={deleteSelectedEvent}
              numBookings={numBookings}
              numOpenings={numOpenings}
              modalShow={modalShow}
              selectedEvent={selectedEvent}
              saveModalShow={saveModalShow}
              handleSaveModalClose={handleSaveModalClose}
              badEventModalShow={badEventModalShow}
              handleBadEventModalClose={handleBadEventModalClose}
              repeat={repeat}
              noEventsRepeatModal={noEventsRepeatModal}
              handleNoEventsRepeatModalClose={handleNoEventsRepeatModalClose}
              weekDropdown={weekDropdown}
              handleWeekDropDown={handleWeekDropDown}
              repeatModal={repeatModal}
              handleRepeatModalClose={handleRepeatModalClose}
              handleRepeatModalOpen={handleRepeatModalOpen}
            />
          }
        />
        <Route
          path="/appointments"
          element={
            <LawyerAppointments
              localizer={localizer}
              myEvents={myEvents}
              scrollToTime={scrollToTime}
              userInfo={userInfo}
            />
          }
        />
        <Route
          path="/payments"
          element={<LawyerPayments userInfo={userInfo} />}
        />
        <Route path="/user-dash" element={<LawyerUserDash />} />

        <Route
          path="/ask-jinx-for-lawyers"
          element={<LawyerAskJinx userInfo={userInfo} />}
        />
        <Route
          path="/search/:keyword"
          element={<LawyerSearchResultsScreen />}
        />
        <Route
          path="/open-matters"
          element={<LawyerJobBoard userInfo={userInfo} />}
        />
      </Routes>
      <LawyerSearchBox classnameprop={"dash-search"} />
      <LawyerMobileDashNav />
    </Row>
  );
}

export default LawyerDashboard;
