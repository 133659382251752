import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { updateUserProfileField } from "../actions/userActions";

function IncubatorRegister() {
  const [incubatorName, setIncubatorName] = useState("");
  const [incubatorType, setIncubatorType] = useState("Seed");
  const [registered, setRegistered] = useState(false);
  const { link } = useSelector((state) => state.link);

  const { userInfo } = useSelector((state) => state.userLogin);
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo?.token}`,
    },
  };

  const submitHandler = (e) => {
    e.preventDefault();
    axios
      .post(
        "api/users/register-incubator/",
        {
          name: incubatorName,
          type: incubatorType,
        },
        config
      )
      .then(function (response) {
        console.log("Response Received");
        setRegistered(true);
      })
      .catch(function (error) {
        console.log("Error Occurred");
      });
  };

  useEffect(() => {
    if (registered) {
      dispatch(updateUserProfileField({ profile: 2 }));
      if (link !== "") {
        navigate(link);
      } else {
        navigate("/dashboard/incubator-profile");
      }
    }
  }, [registered]);

  return (
    <div>
      <Header />
      <Container className="full-screen-container-2">
        <Row className="login-register-container py-5">
          <Col className="login-form-background-left"></Col>
          <Col className="login-form-column px-5 py-3">
            <div className="login-form-container px-5 py-4">
              <Row align="center">
                <h1 className="serious-font">Register your Incubator</h1>
              </Row>
              <Form onSubmit={submitHandler}>
                <Form.Group className="mb-3" controlId="incubatorName">
                  <Form.Label>Incubator Name</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="Enter Incubator name"
                    value={incubatorName}
                    onChange={(e) => setIncubatorName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="incubatorType">
                  <Form.Label>Incubator Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => setIncubatorType(e.target.value)}
                  >
                    <option value="Seed">Seed</option>
                    <option value="Accelerator">Accelerator</option>
                    <option value="Scale">Scale</option>
                  </Form.Select>
                </Form.Group>
                <Row>
                  <Col align="center">
                    <button
                      className="lcn-button-grey px-5 py-3"
                    >
                      Contact for more infromation
                    </button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>

          {/* Commented out to match orange/cream styling of other register screens. */}
          {/* <Col className="login-register-hero-container px-5 py-3">
            <Row className="login-register-hero px-3 py-5">
              <img
                id="how-to-image"
                src={
                  "https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Your-Legal-roadmap-Compressed.png"
                }
              />
            </Row>
          </Col> */}
          <Col className="login-form-background-right"></Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default IncubatorRegister;
