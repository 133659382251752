import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function CheckoutSuccessScreen() {
  const { userInfo } = useSelector((state) => state.userLogin);

  return (
    <div>
      <Header />
      <Container align="center" className="page-container">
        {/* This is the Navigation divider for the front end of our pages */}
        <Row className="divider-parent">
          <Col align="center" className="utility-divider-lines"></Col>
          <Col align="center" className="util-icon-parent">
            <i className="fa-regular fa-circle-dot fa-1x divider-icon"></i>
          </Col>
          <Col align="center" className="utility-divider-lines"></Col>
        </Row>
        {/* Page Hero starts here */}
        <Row className="hero-container lcn-section-spacing">
          <Row align="center" className="content-center">
            <div className="my-5">
              <h1 className="serious-font hero-header-center">Success</h1>
              <p>
                Thank you for subscribing! Feel free to head over to your
                dashboard and enjoy unlimited access to our legal products and
                services.
              </p>
            </div>
            {/* CTA Section */}
            <Row>
            {userInfo?.role === "sme" && (
              <Col className="hero-button-parent">
                <Col>
                  <Link to={"/dashboard/sme-profile"}>
                    <button className="lcn-button-orange px-4 py-3">
                      Go to Dashboard
                    </button>
                  </Link>
                </Col>
              </Col>
            )}
            {userInfo?.role === "lawyer" && (
              <Col className="hero-button-parent">
                <Col>
                  <Link to={"/lawyer-dashboard/lawyer-dash"}>
                    <button className="lcn-button-orange px-4 py-3">
                      Go to Dashboard
                    </button>
                  </Link>
                </Col>
              </Col>
            )}
            
            <Col className="hero-button-parent">
              <Col>
                <Link to={"/"}>
                  <button className="lcn-button-orange px-4 py-3">
                    Return Home
                  </button>
                </Link>
              </Col>
            </Col>
            </Row>
          </Row>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default CheckoutSuccessScreen;
