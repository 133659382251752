import React, { useState, useEffect, useRef } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Row, Col, Form } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";
import * as Yup from "yup";
import { useFormik } from "formik";
import CryptoJS from "crypto-js";
import emailjs from "@emailjs/browser";
import ToolTip from "../components/ToolTip";
import { Modal } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";

function RegisterScreen() {
  let navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [goodPass, setGoodPass] = useState(true);
  const [link, setLink] = useState("");
  const [linkReady, setLinkready] = useState(false);
  const [registered, setRegistered] = useState(false);
  const error = false;
  const form = useRef();

  /*Modal for incubators*/
  const [formStep, setFormStep] = useState(1);

  const [formData, setFormData] = useState({
    formType: "for_incubators",
    firstname: "",
    lastname: "",
    emailAddress: "",
    orgName: "",
    featuredlink: "",
    numberOfSMEs: "",
    numberOfCohorts: "",
    currentSupport: [],
    aidOffers: [],
  });

  const currentSupportOptions = [
    { name: "All operational documents", id: 1 },
    { name: "All founding and regulation documents and processes", id: 2 },
    { name: "Lawyer Access", id: 3 },
    { name: "Legal Process access", id: 4 },
    { name: "Capital raise legal processes and documents", id: 5 },
    { name: "Company Secretarial service", id: 6 },
    { name: "Online and Data privacy", id: 7 },
    { name: "Other", id: 8 },
  ];

  const aidOffersOptions = [
    { name: "Access to affortable lawyers", id: 1 },
    { name: "Stakeholder reporting", id: 2 },
    { name: "Monitoring and Evaluation of SMEs", id: 3 },
    { name: "Affordable and tangible services", id: 4 },
    { name: "Other", id: 5 },
  ];

  function onSelectSupportOptions(e) {
    const areasArray = [];
    e.map((item) => areasArray.push(item.name));
    setFormData((formData) => ({
      ...formData,
      currentSupport: areasArray,
    }));
  }

  function onRemoveSupportOptions(e) {
    const areasArray = [];
    e.map((item) => areasArray.push(item.name));
    setFormData((formData) => ({
      ...formData,
      currentSupport: areasArray,
    }));
  }

  function onSelectOffersOptions(e) {
    const areasArray = [];
    e.map((item) => areasArray.push(item.name));
    setFormData((formData) => ({
      ...formData,
      aidOffers: areasArray,
    }));
  }

  function onRemoveOffersOptions(e) {
    const areasArray = [];
    e.map((item) => areasArray.push(item.name));
    setFormData((formData) => ({
      ...formData,
      aidOffers: areasArray,
    }));
  }

  function submitFormStep(e) {
    console.log("logged");
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: e.target.value,
    }));
  }

  function setNextFormStep() {
    setFormStep((formStep) => formStep + 1);
  }

  function setPreviousFormStep() {
    setFormStep((formStep) => formStep - 1);
  }

  function submitForm() {
    setFormStep((formStep) => formStep + 1);

    try {
      // eslint-disable-next-line
      const resp = axios.post(
        "https://hook.eu1.make.com/c52sie4s11h7el6990susp17dx0854zl",
        { formData }
      );
      console.log("Response");
    } catch (error) {
      console.log("error.response");
    }
  }

  const [showModal, toggleShowModal] = React.useState(false);

  function handleShow() {
    toggleShowModal(true);
    /*setFormStep(1)*/
  }
  /* const handleShow = () => toggleShowModal(true) */
  const handleHide = () => toggleShowModal(false);

  /*End Incubator handler*/

  /*Modal for Ecosystem---------------------------------------------------------------------------------------------------------*/
  const [formStepEco, setFormStepEco] = useState(1);

  const [formDataEco, setFormDataEco] = useState({
    formType: "for_incubators",
    firstname: "",
    lastname: "",
    emailAddress: "",
    orgName: "",
    featuredlink: "",
    numberOfSMEs: "",
    numberOfCohorts: "",
    currentSupport: [],
    aidOffers: [],
  });

  const currentSupportOptionsEco = [
    { name: "All operational documents", id: 1 },
    { name: "All founding and regulation documents and processes", id: 2 },
    { name: "Lawyer Access", id: 3 },
    { name: "Legal Process access", id: 4 },
    { name: "Capital raise legal processes and documents", id: 5 },
    { name: "Company Secretarial service", id: 6 },
    { name: "Online and Data privacy", id: 7 },
    { name: "Other", id: 8 },
  ];

  const aidOffersOptionsEco = [
    { name: "Access to affortable lawyers", id: 1 },
    { name: "Stakeholder reporting", id: 2 },
    { name: "Monitoring and Evaluation of Incubators", id: 3 },
    { name: "Affordable and tangible services", id: 4 },
    { name: "Other", id: 5 },
  ];

  function onSelectSupportOptionsEco(e) {
    const areasArrayEco = [];
    e.map((item) => areasArrayEco.push(item.name));
    setFormDataEco((formDataEco) => ({
      ...formDataEco,
      currentSupport: areasArrayEco,
    }));
  }

  function onRemoveSupportOptionsEco(e) {
    const areasArrayEco = [];
    e.map((item) => areasArrayEco.push(item.name));
    setFormDataEco((formDataEco) => ({
      ...formDataEco,
      currentSupport: areasArrayEco,
    }));
  }

  function onSelectOffersOptionsEco(e) {
    const areasArrayEco = [];
    e.map((item) => areasArrayEco.push(item.name));
    setFormDataEco((formDataEco) => ({
      ...formDataEco,
      aidOffers: areasArrayEco,
    }));
  }

  function onRemoveOffersOptionsEco(e) {
    const areasArrayEco = [];
    e.map((item) => areasArrayEco.push(item.name));
    setFormDataEco((formDataEco) => ({
      ...formDataEco,
      aidOffers: areasArrayEco,
    }));
  }

  function submitFormStepEco(e) {
    console.log("logged");
    setFormDataEco((formDataEco) => ({
      ...formDataEco,
      [e.target.name]: e.target.value,
    }));
  }

  function preventSubmit(e) {
    e.preventDefault();
  }

  function setNextFormStepEco() {
    setFormStepEco((formStepEco) => formStepEco + 1);
  }

  function setPreviousFormStepEco() {
    setFormStepEco((formStepEco) => formStepEco - 1);
  }

  function submitFormEco() {
    setFormStepEco((formStepEco) => formStepEco + 1);

    try {
      // eslint-disable-next-line
      const resp = axios.post(
        "https://hook.eu1.make.com/15qrx4e36tjbfxeipkh4l12mw99dcj3c",
        { formDataEco }
      );
      console.log("Response");
    } catch (error) {
      console.log("error.response");
    }
  }

  const [showModalEco, toggleShowModalEco] = React.useState(false);

  function handleShowEco() {
    toggleShowModalEco(true);
    /*setFormStep(1)*/
  }
  /* const handleShow = () => toggleShowModal(true) */
  const handleHideEco = () => toggleShowModalEco(false);

  /*End Ecosystem handler*/

  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      fullName: "",
      email: "",
      password: "",
      confirmPassword: "",
      userType: "sme",
    },
    onSubmit: (values) => {
      const passphraseInt = Math.floor(Math.random() * (9999 - 1000)) + 1000;
      const passphrase = passphraseInt.toString();
      formik.values.fullName = formik.values.name + " " + formik.values.surname;
      if (goodPass === false) {
        setMessage("Please ensure that your password is secure");
      } else {
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(formik.values),
          passphrase
        ).toString();
        const uriEncoded = encodeURIComponent(encryptedData);
        // comment the below line in for testing
        // navigate(`/confirm-email/${uriEncoded}/${passphrase}`);
        // navigate(`/confirm-email/${uriEncoded}/${passphrase}`);

        // comment the below lines out for testing
        setRegistered((unregistered) => true);
        setLink(
          (link) =>
            link +
            "https://www.lacoona.legal/confirm-email" +
            "/" +
            uriEncoded +
            "/" +
            passphrase
        );
        setLinkready(true);

        // console.log("formikvalues", formik.values);
      }
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Please enter a valid email address"),
    }),
  });

  // comment this useeffect out for testing
  useEffect(() => {
    if (linkReady) {
      emailjs
        .sendForm(
          "service_pen011h",
          "template_1ykfojp",
          form.current,
          "nDn25pehV2cf7nY3j"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  }, [linkReady]);

  console.log("formikvalues", formik.values);
  // console.log("link", link);

  // ***************
  // pre email confirmation registration form
  // const formik = useFormik({
  //   initialValues: {
  //     name: "",
  //     surname: "",
  //     fullName: "",
  //     email: "",
  //     password: "",
  //     confirmPassword: "",
  //     userType: "sme",
  //   },
  //   onSubmit: (values) => {
  //     formik.values.fullName = formik.values.name + " " + formik.values.surname;
  //     console.log();
  //     console.log("GoodPass Value:", goodPass);
  //     if (goodPass === false) {
  //       setMessage("Please ensure that your password is secure");
  //     } else {
  //       dispatch(
  //         register(
  //           formik.values.fullName,
  //           formik.values.email,
  //           formik.values.password,
  //           formik.values.userType
  //         )
  //       );
  //       console.log("registersuccess");
  //     }
  //   },
  //   validationSchema: Yup.object({
  //     email: Yup.string().email("Please enter a valid email address"),
  //   }),
  // });
  // **********************

  // **********************
  // pre email confirmation registration redirect
  // const userRegister = useSelector((state) => state.userRegister);
  // const { error, userInfo } = userRegister;

  // useEffect(() => {
  //   if (error) {
  //     console.log("A user with this email address already exists");
  //   } else if (userInfo) {
  //     console.log("User Type: ", formik.values.userType);
  //     formik.values.userType === "sme"
  //       ? navigate("/register-sme")
  //       : formik.values.userType === "ecosystem"
  //       ? navigate("/register-ecosystem")
  //       : navigate("/register-incubator");
  //   }
  //   // eslint-disable-next-line
  // }, [userRegister]);
  // ********************

  function passGood() {
    setGoodPass(!goodPass);
  }

  return (
    <div>
      <Header />
      <Container className="full-screen-container">
        <Row className="login-register-container">
          <Col className="login-form-background-left"></Col>
          <Col className="login-form-column register-column px-5 py-3">
            <Col className="login-form-container register-container px-5 py-4 w-45">
              {registered ? (
                <h3>
                  Thanks for signing up. You can complete registration from the
                  link in your email.
                </h3>
              ) : (
                <div>
                  <Row className="login-form-container-header mb-4">
                    <Col className="col-9 p-3">
                      <h3 className="serious-font">Welcome to Lacoona Legal</h3>
                      <h1 className="serious-font">Register as an...</h1>
                    </Col>
                    <Col>
                      <p>Have an account?</p>
                      <LinkContainer to={"/login"}>
                        <p className="login-form-container-register">
                          <strong>Log In</strong>
                        </p>
                      </LinkContainer>
                    </Col>
                  </Row>

                  <Form onSubmit={formik.handleSubmit}>
                    {message && (
                      <div className="error-message">
                        <strong>{message}</strong>
                      </div>
                    )}
                    {error && (
                      <div className="error-message">
                        <strong>{error}</strong>
                      </div>
                    )}
                    <Row className="my-2 reg-selector-parent">
                      <Col className="p-3">
                        <div className="ll-reg-dummy-selected ll-reg-brown m-1 p-3">
                          SME
                        </div>
                      </Col>
                      <Col className="p-3">
                        <LinkContainer to={"/register-lawyer-user"}>
                          <div className="ll-reg-dummy-selected-hover m-1">
                            Lawyer
                          </div>
                        </LinkContainer>
                      </Col>
                      <Col>
                        <ToolTip
                          tooltip="Apply to be an Incubator now"
                          id="tooltip-reg-comp-incu"
                          placement="top"
                        >
                          {/* Modal */}
                          <Modal show={showModal} onHide={handleHide}>
                            <Modal.Header closeButton>
                              <Modal.Title>Incubator Application</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Row
                                align="center"
                                className="find-a-lawyer-form-container "
                              >
                                {/* First Question */}
                                {formStep === 1 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        Please tell us your First Name
                                      </h4>
                                    </Row>
                                    <Row>
                                      <Form onSubmit={preventSubmit}>
                                        <Form.Control
                                          type="input"
                                          className="mb-3"
                                          onChange={submitFormStep}
                                          name="firstname"
                                        />
                                      </Form>
                                    </Row>
                                    <Row>
                                      <Col className="ll-form-back-btn"></Col>
                                      <Col className="form-step-next-button-container">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setNextFormStep}
                                        >
                                          Next
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                                {/*  Second Question */}
                                {formStep === 2 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        Please tell us your Last Name
                                      </h4>
                                    </Row>
                                    <Row>
                                      <Form onSubmit={preventSubmit}>
                                        <Form.Control
                                          type="input"
                                          className="mb-3"
                                          onChange={submitFormStep}
                                          name="lastname"
                                        />
                                      </Form>
                                    </Row>
                                    <Row>
                                      <Col className="ll-form-back-btn">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setPreviousFormStep}
                                        >
                                          Back
                                        </button>
                                      </Col>
                                      <Col className="form-step-next-button-container">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setNextFormStep}
                                        >
                                          Next
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                                {/* Third Question */}
                                {formStep === 3 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        Email
                                      </h4>
                                    </Row>
                                    <Row>
                                      <Form onSubmit={preventSubmit}>
                                        <Form.Control
                                          type="input"
                                          className="mb-3"
                                          onChange={submitFormStep}
                                          name="emailAddress"
                                        />
                                      </Form>
                                    </Row>
                                    <Row>
                                      <Col className="ll-form-back-btn">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setPreviousFormStep}
                                        >
                                          Back
                                        </button>
                                      </Col>
                                      <Col className="form-step-next-button-container">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setNextFormStep}
                                        >
                                          Next
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                                {/* Fourth Question */}
                                {formStep === 4 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        Please state the name of the
                                        organisation, corporate, incubator or
                                        accelerator who you are submitting this
                                        form on behalf of.
                                      </h4>
                                    </Row>
                                    <Row>
                                      <Form onSubmit={preventSubmit}>
                                        <Form.Control
                                          type="input"
                                          className="mb-3"
                                          onChange={submitFormStep}
                                          name="orgName"
                                        />
                                      </Form>
                                    </Row>
                                    <Row>
                                      <Col className="ll-form-back-btn">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setPreviousFormStep}
                                        >
                                          Back
                                        </button>
                                      </Col>
                                      <Col className="form-step-next-button-container">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setNextFormStep}
                                        >
                                          Next
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                                {/* Fifth Question */}
                                {formStep === 5 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        Please provide a link to your website,
                                        LinkedIn or Facebook page.
                                      </h4>
                                    </Row>
                                    <Row>
                                      <Form onSubmit={preventSubmit}>
                                        <Form.Control
                                          type="input"
                                          className="mb-3"
                                          onChange={submitFormStep}
                                          name="featuredlink"
                                        />
                                      </Form>
                                    </Row>
                                    <Row>
                                      <Col className="ll-form-back-btn">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setPreviousFormStep}
                                        >
                                          Back
                                        </button>
                                      </Col>
                                      <Col className="form-step-next-button-container">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setNextFormStep}
                                        >
                                          Next
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                                {/* Sixth Question */}
                                {formStep === 6 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        How many SMEs are you currently
                                        supporting annually?
                                      </h4>
                                    </Row>
                                    <Row>
                                      <Form onSubmit={preventSubmit}>
                                        <Form.Control
                                          type="input"
                                          className="mb-3"
                                          onChange={submitFormStep}
                                          name="numberOfSMEs"
                                        />
                                      </Form>
                                    </Row>
                                    <Row>
                                      <Col className="ll-form-back-btn">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setPreviousFormStep}
                                        >
                                          Back
                                        </button>
                                      </Col>
                                      <Col className="form-step-next-button-container">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setNextFormStep}
                                        >
                                          Next
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                                {/* Seventh Question */}
                                {formStep === 7 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        How many cohorts is this broken up into?
                                      </h4>
                                    </Row>
                                    <Row>
                                      <Form onSubmit={preventSubmit}>
                                        <Form.Control
                                          type="input"
                                          className="mb-3"
                                          onChange={submitFormStep}
                                          name="numberOfCohorts"
                                        />
                                      </Form>
                                    </Row>
                                    <Row>
                                      <Col className="ll-form-back-btn">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setPreviousFormStep}
                                        >
                                          Back
                                        </button>
                                      </Col>
                                      <Col className="form-step-next-button-container">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setNextFormStep}
                                        >
                                          Next
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                                {/*  Eighth Question */}
                                {formStep === 8 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        How are you currently supporting your
                                        ecosystem with regards to legal access?
                                        You can select multiple.
                                      </h4>
                                    </Row>
                                    <Row>
                                      <Multiselect
                                        options={currentSupportOptions} // Options to display in the dropdown
                                        selectedValues={
                                          currentSupportOptions[1]
                                        } // Preselected value to persist in dropdown
                                        onSelect={onSelectSupportOptions} // Function will trigger on select event
                                        onRemove={onRemoveSupportOptions} // Function will trigger on remove event
                                        displayValue="name" // Property name to display in the dropdown options
                                      />
                                    </Row>
                                    <Row className="mt-3">
                                      <Col className="ll-form-back-btn">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setPreviousFormStep}
                                        >
                                          Back
                                        </button>
                                      </Col>
                                      <Col className="form-step-next-button-container">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setNextFormStep}
                                        >
                                          Next
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                                {/*  Eigth Question */}
                                {formStep === 9 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        Which of the following would aid you in
                                        performing your role as a support
                                        agency? You can select multiple.
                                      </h4>
                                    </Row>
                                    <Row>
                                      <Multiselect
                                        options={aidOffersOptions} // Options to display in the dropdown
                                        selectedValues={aidOffersOptions[1]} // Preselected value to persist in dropdown
                                        onSelect={onSelectOffersOptions} // Function will trigger on select event
                                        onRemove={onRemoveOffersOptions} // Function will trigger on remove event
                                        displayValue="name" // Property name to display in the dropdown options
                                      />
                                    </Row>
                                    <Row className="mt-3">
                                      <Col>
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setPreviousFormStep}
                                        >
                                          Back
                                        </button>
                                      </Col>
                                      <Col className="form-step-next-button-container">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={submitForm}
                                        >
                                          Submit
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                                {formStep === 10 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        Thank you for completing the form, we
                                        will be in touch shortly!
                                      </h4>
                                    </Row>
                                  </Col>
                                )}
                              </Row>
                            </Modal.Body>
                          </Modal>
                          <div
                            onClick={handleShow}
                            className="ll-reg-dummy-unselected"
                          >
                            Incubator
                          </div>
                        </ToolTip>
                      </Col>
                      <Col>
                        <ToolTip
                          tooltip="Apply to be an Ecosystem now"
                          id="tooltip-reg-comp-eco"
                          placement="top"
                        >
                          {/* Modal */}
                          <Modal show={showModalEco} onHide={handleHideEco}>
                            <Modal.Header closeButton>
                              <Modal.Title>Ecosystem Application</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Row
                                align="center"
                                className="find-a-lawyer-form-container "
                              >
                                {/* First Question */}
                                {formStepEco === 1 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        Please tell us your First Name
                                      </h4>
                                    </Row>
                                    <Row>
                                      <Form onSubmit={preventSubmit}>
                                        <Form.Control
                                          type="input"
                                          className="mb-3"
                                          onChange={submitFormStepEco}
                                          name="firstname"
                                        />
                                      </Form>
                                    </Row>
                                    <Row>
                                      <Col className="ll-form-back-btn"></Col>
                                      <Col className="form-step-next-button-container">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setNextFormStepEco}
                                        >
                                          Next
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                                {/*  Second Question */}
                                {formStepEco === 2 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        Please tell us your Last Name
                                      </h4>
                                    </Row>
                                    <Row>
                                      <Form onSubmit={preventSubmit}>
                                        <Form.Control
                                          type="input"
                                          className="mb-3"
                                          onChange={submitFormStepEco}
                                          name="lastname"
                                        />
                                      </Form>
                                    </Row>
                                    <Row>
                                      <Col className="ll-form-back-btn">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setPreviousFormStepEco}
                                        >
                                          Back
                                        </button>
                                      </Col>
                                      <Col className="form-step-next-button-container">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setNextFormStepEco}
                                        >
                                          Next
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                                {/* Third Question */}
                                {formStepEco === 3 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        Email
                                      </h4>
                                    </Row>
                                    <Row>
                                      <Form onSubmit={preventSubmit}>
                                        <Form.Control
                                          type="input"
                                          className="mb-3"
                                          onChange={submitFormStepEco}
                                          name="emailAddress"
                                        />
                                      </Form>
                                    </Row>
                                    <Row>
                                      <Col className="ll-form-back-btn">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setPreviousFormStepEco}
                                        >
                                          Back
                                        </button>
                                      </Col>
                                      <Col className="form-step-next-button-container">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setNextFormStepEco}
                                        >
                                          Next
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                                {/* Fourth Question */}
                                {formStepEco === 4 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        Please state the name of the
                                        organisation, corporate, incubator or
                                        accelerator who you are submitting this
                                        form on behalf of.
                                      </h4>
                                    </Row>
                                    <Row>
                                      <Form onSubmit={preventSubmit}>
                                        <Form.Control
                                          type="input"
                                          className="mb-3"
                                          onChange={submitFormStepEco}
                                          name="orgName"
                                        />
                                      </Form>
                                    </Row>
                                    <Row>
                                      <Col className="ll-form-back-btn">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setPreviousFormStepEco}
                                        >
                                          Back
                                        </button>
                                      </Col>
                                      <Col className="form-step-next-button-container">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setNextFormStepEco}
                                        >
                                          Next
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                                {/* Fifth Question */}
                                {formStepEco === 5 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        Please provide a link to your website,
                                        LinkedIn or Facebook page.
                                      </h4>
                                    </Row>
                                    <Row>
                                      <Form onSubmit={preventSubmit}>
                                        <Form.Control
                                          type="input"
                                          className="mb-3"
                                          onChange={submitFormStepEco}
                                          name="featuredlink"
                                        />
                                      </Form>
                                    </Row>
                                    <Row>
                                      <Col className="ll-form-back-btn">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setPreviousFormStepEco}
                                        >
                                          Back
                                        </button>
                                      </Col>
                                      <Col className="form-step-next-button-container">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setNextFormStepEco}
                                        >
                                          Next
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                                {/* Sixth Question */}
                                {formStepEco === 6 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        How many Incubators are you currently
                                        supporting annually?
                                      </h4>
                                    </Row>
                                    <Row>
                                      <Form onSubmit={preventSubmit}>
                                        <Form.Control
                                          type="input"
                                          className="mb-3"
                                          onChange={submitFormStepEco}
                                          name="numberOfSMEs"
                                        />
                                      </Form>
                                    </Row>
                                    <Row>
                                      <Col className="ll-form-back-btn">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setPreviousFormStepEco}
                                        >
                                          Back
                                        </button>
                                      </Col>
                                      <Col className="form-step-next-button-container">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setNextFormStepEco}
                                        >
                                          Next
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                                {/* Seventh Question */}
                                {formStepEco === 7 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        How many cohorts is this broken up into?
                                      </h4>
                                    </Row>
                                    <Row>
                                      <Form onSubmit={preventSubmit}>
                                        <Form.Control
                                          type="input"
                                          className="mb-3"
                                          onChange={submitFormStepEco}
                                          name="numberOfCohorts"
                                        />
                                      </Form>
                                    </Row>
                                    <Row>
                                      <Col className="ll-form-back-btn">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setPreviousFormStepEco}
                                        >
                                          Back
                                        </button>
                                      </Col>
                                      <Col className="form-step-next-button-container">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setNextFormStepEco}
                                        >
                                          Next
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                                {/*  Eighth Question */}
                                {formStepEco === 8 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        How are you currently supporting your
                                        ecosystem with regards to legal access?
                                        You can select multiple.
                                      </h4>
                                    </Row>
                                    <Row>
                                      <Multiselect
                                        options={currentSupportOptionsEco} // Options to display in the dropdown
                                        selectedValues={
                                          currentSupportOptionsEco[1]
                                        } // Preselected value to persist in dropdown
                                        onSelect={onSelectSupportOptionsEco} // Function will trigger on select event
                                        onRemove={onRemoveSupportOptionsEco} // Function will trigger on remove event
                                        displayValue="name" // Property name to display in the dropdown options
                                      />
                                    </Row>
                                    <Row className="mt-3">
                                      <Col className="ll-form-back-btn">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setPreviousFormStepEco}
                                        >
                                          Back
                                        </button>
                                      </Col>
                                      <Col className="form-step-next-button-container">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setNextFormStepEco}
                                        >
                                          Next
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                                {/*  Eigth Question */}
                                {formStepEco === 9 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        Which of the following would aid you in
                                        performing your role as a support
                                        agency? You can select multiple.
                                      </h4>
                                    </Row>
                                    <Row>
                                      <Multiselect
                                        options={aidOffersOptionsEco} // Options to display in the dropdown
                                        selectedValues={aidOffersOptionsEco[1]} // Preselected value to persist in dropdown
                                        onSelect={onSelectOffersOptionsEco} // Function will trigger on select event
                                        onRemove={onRemoveOffersOptionsEco} // Function will trigger on remove event
                                        displayValue="name" // Property name to display in the dropdown options
                                      />
                                    </Row>
                                    <Row>
                                      <Col>
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={setPreviousFormStepEco}
                                        >
                                          Back
                                        </button>
                                      </Col>
                                      <Col className="form-step-next-button-container">
                                        <button
                                          className="lcn-button-orange py-2 px-4"
                                          onClick={submitFormEco}
                                        >
                                          Submit
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                                {formStepEco === 10 && (
                                  <Col className="form-step" lg={8}>
                                    <Row>
                                      <h4 className="form-step-heading-dark">
                                        Thank you for completing the form, we
                                        will be in touch shortly!
                                      </h4>
                                    </Row>
                                  </Col>
                                )}
                              </Row>
                            </Modal.Body>
                          </Modal>
                          <div
                            onClick={handleShowEco}
                            className="ll-reg-dummy-unselected"
                          >
                            Ecosystem
                          </div>
                        </ToolTip>
                      </Col>
                    </Row>
                    <Form.Group className="mb-3">
                      <Form.Label>Full Name</Form.Label>
                      <Row>
                        <Col>
                          {" "}
                          <Form.Control
                            controlId="name"
                            required
                            name="name"
                            type="input"
                            placeholder="Name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                          />
                        </Col>

                        <Col>
                          <Form.Control
                            controlId="surname"
                            required
                            name="surname"
                            type="input"
                            placeholder="Surname"
                            value={formik.values.surname}
                            onChange={formik.handleChange}
                          />
                        </Col>
                      </Row>

                      {formik.errors.name ? <p>{formik.errors.name}</p> : null}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        required
                        type="email"
                        placeholder="Enter Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.email ? (
                        <p>{formik.errors.email}</p>
                      ) : null}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        placeholder="Enter Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="confirmPassword">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        placeholder="Confirm Password"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    {/* <Form.Group className="mb-3" controlId="userType">
                      <Form.Label>User Type</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={formik.handleChange}
                        name="userType"
                        defaultValue={"DEFAULT"}
                      >
                        <option value="DEFAULT" disabled>
                          Select...
                        </option>
                        <option value="sme">SME</option>
                        <option value="incubator">Incubator</option>
                        <option value="ecosystem">Ecosystem</option>
                      </Form.Select>
                    </Form.Group> */}

                    <PasswordChecklist
                      rules={[
                        "minLength",
                        "specialChar",
                        "number",
                        "capital",
                        "match",
                      ]}
                      minLength={5}
                      value={formik.values.password}
                      valueAgain={formik.values.confirmPassword}
                      onChange={(isValid) => {
                        passGood();
                      }}
                    />
                    <Row align="center" className="mt-3">
                      <p>
                        By clicking 'Register' below, you agree to our{" "}
                        <a href="/privacy-policy">Privacy Policy.</a>
                      </p>
                    </Row>
                    <Row align="center">
                      <Col align="center">
                        <button
                          className="lcn-button-brown px-5 py-3"
                          type="submit"
                        >
                          Register
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              )}
            </Col>
            <form className="forgot-pass-form" ref={form}>
              <label>Name</label>
              <input
                type="text"
                name="to_name"
                defaultValue={formik.values.name}
              />
              <label>Email</label>
              <input
                type="email"
                name="user_email"
                defaultValue={formik.values.email}
              />
              <label>Message</label>
              <textarea name="message" />
              <input type="text" name="link" defaultValue={link} />

              <input type="submit" value="Send" />
            </form>
          </Col>
          <Col className="login-form-background-right"></Col>
          {/* <Col id="login-register-hero-container">
            <img
              className="login-register-image"
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Group_376.png"
            ></img>
            <Row className="login-register-hero px-3 py-3">
              <Row align="center">
                <h1 className="serious-font">One more step</h1>
              </Row>
              <Row align="center">
                <p>
                  Welcome to the Lacoona Login portal. Whether you are a
                  Startup, Incubator, or Ecosystem, you are just one step away
                  for your Lacoona Dashboard.
                </p>
              </Row>
              <Row align="center">
                <h5>
                  If you are yet to subscribe or apply, here are some helpful
                  links
                </h5>
              </Row>
              <Row align="center">
                <Col className="login-register-hero-button-left-container">
                  <button className="lcn-button-orange login-hero-button">
                    For Startups
                  </button>
                </Col>
                <Col className="login-register-hero-button-right-container">
                  <button className="lcn-button-orange login-hero-button">
                    For Incubators
                  </button>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="login-register-hero-button-left-container">
                  <button className="lcn-button-orange login-hero-button">
                    For Investors
                  </button>
                </Col>
                <Col className="login-register-hero-button-right-container">
                  <button className="lcn-button-orange login-hero-button">
                    For Lawyers
                  </button>
                </Col>
              </Row>
            </Row>
          </Col> */}
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default RegisterScreen;
