import React, { useState, useEffect, useRef } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Row, Col, Form } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../actions/userActions";
import { useNavigate } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";
import * as Yup from "yup";
import { useFormik } from "formik";

function LawyerUserRegisterScreen() {
  const [goodPass, setGoodPass] = useState(true);
  const [message, setMessage] = useState("");
  const [registered, setRegistered] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRegister = useSelector((state) => state.userRegister);
  const { error, userInfo } = userRegister;
  const [legalPracticeCouncil, setLegalPracticeCouncil] = useState(false);
  const [llbDegree, setLlbDegree] = useState(false);
  const [admittedAttourney, setAdmittedAttourney] = useState(false);

  const handleLegalPracticeOptionChange = (e) => {
    setLegalPracticeCouncil(e.target.value === "yes"); // update state based on selected value
  };

  const handleLlbOptionChange = (e) => {
    setLlbDegree(e.target.value === "yes"); // update state based on selected value
  };

  const handleAdmittedAttourneyOptionChange = (e) => {
    setAdmittedAttourney(e.target.value === "yes"); // update state based on selected value
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      fullName: "",
      email: "",
      password: "",
      confirmPassword: "",
      type: "lawyer",
    },
    onSubmit: (values) => {
      formik.values.fullName = formik.values.name + " " + formik.values.surname;
      if (goodPass === false) {
        setMessage("Please ensure that your password is secure");
      } else {
        dispatch(
          register(
            formik.values.fullName,
            formik.values.email,
            formik.values.password,
            formik.values.type
          )
        );
      }
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Please enter a valid email address"),
    }),
  });

  useEffect(() => {
    if (error) {
      console.log("A user with this email address already exists");
    }
    if (userInfo) {
      navigate("/register-lawyer");
    }
    // eslint-disable-next-line
  }, [userRegister]);

  function passGood() {
    setGoodPass(!goodPass);
  }
  return (
    <div>
      <Header />
      <Container className="full-screen-container">
        <Row className="login-register-container">
          <Col className="login-form-background-left"></Col>
          <Col className="login-form-column register-column px-5 py-3">
            <Col className="login-form-container register-container px-5 py-4 w-45">
              <div>
                <Row className="login-form-container-header mb-4">
                  <Col className="col-9 p-3">
                    <h3 className="serious-font">Welcome to Lacoona Legal</h3>
                    <h1 className="serious-font">Register as a Lawyer</h1>
                  </Col>
                  <Col>
                    <p>Have an account?</p>
                    <LinkContainer to={"/login"}>
                      <p className="login-form-container-register">
                        <strong>Log In</strong>
                      </p>
                    </LinkContainer>
                  </Col>
                </Row>

                <Form onSubmit={formik.handleSubmit}>
                  {message && (
                    <div className="error-message">
                      <strong>{message}</strong>
                    </div>
                  )}
                  {error && (
                    <div className="error-message">
                      <strong>{error}</strong>
                    </div>
                  )}

                  <Form.Group className="mb-3">
                    <Form.Label>Full Name</Form.Label>
                    <Row>
                      <Col>
                        {" "}
                        <Form.Control
                          controlId="name"
                          required
                          name="name"
                          type="input"
                          placeholder="Name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                        />
                      </Col>

                      <Col>
                        <Form.Control
                          controlId="surname"
                          required
                          name="surname"
                          type="input"
                          placeholder="Surname"
                          value={formik.values.surname}
                          onChange={formik.handleChange}
                        />
                      </Col>
                    </Row>

                    {formik.errors.name ? <p>{formik.errors.name}</p> : null}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder="Enter Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.email ? <p>{formik.errors.email}</p> : null}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      placeholder="Enter Password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="confirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      placeholder="Confirm Password"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Are you registered with the Legal Practice Council?
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={legalPracticeCouncil ? "yes" : "no"}
                      onChange={handleLegalPracticeOptionChange}
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>
                      Do you have an LLB degree from a University?
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={llbDegree ? "yes" : "no"}
                      onChange={handleLlbOptionChange}
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Are you an admitted attourney?</Form.Label>
                    <Form.Control
                      as="select"
                      value={admittedAttourney ? "yes" : "no"}
                      onChange={handleAdmittedAttourneyOptionChange}
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </Form.Control>
                  </Form.Group>

                  <PasswordChecklist
                    rules={[
                      "minLength",
                      "specialChar",
                      "number",
                      "capital",
                      "match",
                    ]}
                    minLength={5}
                    value={formik.values.password}
                    valueAgain={formik.values.confirmPassword}
                    onChange={(isValid) => {
                      passGood();
                    }}
                  />
                  <Row align="center" className="mt-3">
                    <p>
                      By clicking 'Register' below, you agree to our{" "}
                      <a href="/privacy-policy">Privacy Policy.</a>
                    </p>
                  </Row>
                  {legalPracticeCouncil && llbDegree && admittedAttourney && (
                    <Row align="center">
                      <Col align="center">
                        <button
                          className="lcn-button-brown px-5 py-3"
                          type="submit"
                        >
                          Register as a Lawyer
                        </button>
                      </Col>
                    </Row>
                  )}
                </Form>
              </div>
            </Col>
          </Col>
          <Col className="login-form-background-right"></Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default LawyerUserRegisterScreen;
