import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import { getUserDetails, updateUserProfile } from "../actions/userActions";
import { useNavigate } from "react-router-dom";
/* import { LinkContainer } from "react-router-bootstrap"; */
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";
import DashHeader from "./DashHeader";
import LawyerDashHeader from "./LawyerDashHeader";

function LawyerUserDash() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [updatePersonalInfoButton, setUpdatePersonalInfoButton] = useState(1);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  /*   const addToCartHandler = () => {
    dispatch(addToCart());
  }; */

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  useEffect(() => {
    // if user not logged in,  navigate to login screen
    if (!userInfo) {
      // navigate("/login");
      console.log("not logged in");
      // if they are, but their details arent in redux, get them
    } else {
      if (!user || !user.name || success) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(getUserDetails("profile"));
        // then set state
      } else {
        setName(user.name);
        setEmail(user.email);
      }
    }
  }, [dispatch, userInfo, user, success, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      dispatch(
        updateUserProfile({
          id: user.id,
          name: name,
          email: email,
          password: password,
        })
      );
      setUpdatePersonalInfoButton(2);
      setMessage("");
    }
  };

  return (
    <Col className="dashboard-scrollable-container" lg={9}>
      <LawyerDashHeader pageTitle={"Profile"} />
      <Row className="dashboard-content-container">
        <Col className="px-5 mt-5">
          {/* Title */}
          <Row>
            <h2 className="ll-subtitle ll-feat-font">Your Profile</h2>
          </Row>
          <Row className="dashboard-content-divider"></Row>
          {/* Reece Mod */}
          <Row className="user-details-form">
            <Col lg={9}>
              <Form
                onSubmit={submitHandler}
                onChange={() => setUpdatePersonalInfoButton(1)}
              >
                {message && message}
                <Form.Group className="mb-3" controlId="registerName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    required
                    type="name"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="registerEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="registerPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="registerConfirmPassword"
                >
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Group>

                <Row align="start" className="my-3">
                  {updatePersonalInfoButton === 1 ? (
                    <Col>
                      <button
                        className="lcn-button-brown px-5 py-3"
                        type="submit"
                      >
                        Update
                      </button>
                    </Col>
                  ) : (
                    <Col align="center">
                      <h3>Updated!</h3>
                    </Col>
                  )}
                </Row>
              </Form>
            </Col>
            {/* <Col lg={3} className="px-5">
              <Row className="user-profile-pic-container" align="center">
                <div className="user-profile-pic my-4">
                  <i className="fa-solid fa-user fa-4x"></i>
                </div>
              </Row>
              <Row className="py-2">
              <LinkContainer to={"/cart"}>
                  <button
                    className="lcn-button-brown"
                    onClick={addToCartHandler}
                  >
                    Upload Profile Picture
                  </button>
                </LinkContainer>
              </Row>
              <Row align="center" className="sub-section py-2">
                <Row className="sub-parent">
                <h5 className="mb-3">Become a Subscriber to unlock all of our pro features and tools</h5>
                <LinkContainer to={"/cart"}>
                  <button
                    className="lcn-button-brown"
                    onClick={addToCartHandler}
                  >
                    Subscribe
                  </button>
                </LinkContainer>
                </Row>
              </Row> 
            </Col> */}
          </Row>
          {/* End Mod */}
        </Col>
      </Row>
    </Col>
  );
}

export default LawyerUserDash;
