import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import lottie from "lottie-web";
import LeftArrowLoT from "../lottie-files/LeftArrowLoT";

import emailjs from "@emailjs/browser";
import { LinkContainer } from "react-router-bootstrap";

function ForgotPasswordScreen() {
  const [email, setEmail] = useState("");
  const [userExists, setUserExists] = useState(null);
  const [link, setLink] = useState("");
  const [message, setMessage] = useState("");

  const form = useRef();

  function submitHandler(e) {
    e.preventDefault();
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    axios
      .post(
        "/api/users/request-password-reset/",
        {
          email: email,
        },
        config
      )
      .then(function (response) {
        const objectResponse = JSON.parse(response.data);
        console.log("objectResponse");
        if (objectResponse["userExists"] === true) {
          setLink(objectResponse["link"]);
          setUserExists(true);
        } else if (objectResponse["userExists"] === false) {
          setUserExists(false);
        }
      })
      .catch(function (error) {
        console.log("error");
      });
  }

  useEffect(() => {
    if (userExists !== null) {
      if (userExists === true) {
        console.log("user exists");
        setMessage("Thank you, we have sent you an email");
        emailjs
          .sendForm(
            "service_pen011h",
            "template_5u00wdo",
            form.current,
            "nDn25pehV2cf7nY3j"
          )
          .then(
            (result) => {
              console.log("result.text");
            },
            (error) => {
              console.log("error.text");
            }
          );
      } else {
        console.log("does not exist");
        setMessage(
          "Unfortunately, we cannot find a user with the email you entered."
        );
      }
    }
  }, [userExists]);

  console.log("email");
  console.log("userExists");

  return (
    <div>
      <Header />
      <Container className="full-screen-container">
        <Row className="login-register-container">
          <Col className="login-form-background-left"></Col>
          <Col className="login-form-column px-5 py-3">
            <Col className="login-form-container login-screen px-4 py-4">
              <Row>
                <Col className="ll-prod-button-container" lg={3}>
                  <LinkContainer
                    to={`/login`}
                    onMouseEnter={() => lottie.play("ll-prod-back")}
                    onMouseLeave={() => lottie.stop("ll-prod-back")}
                  >
                    <button className="ll-back-button-orange pe-3 ps-1">
                      <LeftArrowLoT aniName="ll-prod-back" />
                      Back
                    </button>
                  </LinkContainer>
                </Col>
                <Col align="left" className="ll-prod-title mt-2" lg={9}>
                  <h2 className="serious-font">Forgot Your Password?</h2>
                </Col>
              </Row>
              {/* <Row className="login-form-container-header mb-4">
                <Col className="col-9 p-3 forgot-password--header">
                  <LinkContainer to={"/login"}>
                    <button className ="lcn-button-orange ">Back</button>
                  </LinkContainer>
                  <h1 className="serious-font">Forgot your password?</h1>
                </Col>
                <Col></Col>
              </Row> */}

              {message && (
                <div className="error-message mb-2">
                  <strong>{message}</strong>
                </div>
              )}

              <Form onSubmit={submitHandler}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Enter your email address and we'll send you a link to reset
                    your password
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>

                <Row align="center">
                  <Col align="center">
                    <button
                      className="lcn-button-brown px-5 py-3"
                      type="submit"
                    >
                      Send
                    </button>
                  </Col>
                  {/* <Col align="center">
                    <LinkContainer to={"/register"}>
                      <button className="lcn-button-brown px-5 py-3">
                        Register
                      </button>
                    </LinkContainer>
                  </Col> */}
                </Row>
              </Form>
            </Col>
          </Col>
          <Col className="login-form-background-right"></Col>
        </Row>
        <form className="forgot-pass-form" ref={form}>
          <label>Name</label>
          <input type="text" name="user_name" />
          <label>Email</label>
          <input type="email" name="user_email" defaultValue={email} />
          <label>Message</label>
          <textarea name="message" />
          <input type="text" name="link" defaultValue={link} />

          <input type="submit" value="Send" />
        </form>
      </Container>
      <Footer />
    </div>
  );
}

export default ForgotPasswordScreen;

// function ForgotPasswordScreen(location) {
//   const [email, setEmail] = useState("");

//   const submitHandler = (e) => {
//     e.preventDefault();
//     axios
//       .post("/api/users/request-password-reset/", { email: email })
//       .then(function (response) {
//         console.log(response);
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };

//   return (
//     <div>
//       <Header />
//       <Container className="full-screen-container">
//         <Row className="login-register-container">
//           <Col className="login-form-background-left"></Col>
//           <Col className="login-form-column px-5 py-3">
//             <Col className="login-form-container login-screen px-4 py-4">
//               <Row className="login-form-container-header mb-1">
//                 <Col className="col-9 p-3">
//                   <h3 className="serious-font">Forgot your Password?</h3>
//                   <p>
//                     Please enter the email you signed up with below and we will
//                     send you an email with a link to reset your password.
//                   </p>
//                 </Col>
//                 <Col></Col>
//               </Row>

//               <Form onSubmit={submitHandler}>
//                 <Form.Group className="mb-3" controlId="formBasicEmail">
//                   <Form.Label>Email address</Form.Label>
//                   <Form.Control
//                     type="email"
//                     placeholder="Enter email"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                   />
//                   <Form.Text className="text-muted">
//                     We'll never share your email with anyone else.
//                   </Form.Text>
//                 </Form.Group>

//                 <Row align="center">
//                   <Col align="center">
//                     <button
//                       className="lcn-button-brown px-5 py-3"
//                       type="submit"
//                     >
//                       Send link
//                     </button>
//                   </Col>

//                 </Row>
//               </Form>
//             </Col>
//           </Col>
//           <Col className="login-form-background-right"></Col>

//         </Row>
//       </Container>
//       <Footer />
//     </div>
//   );
// }

// export default ForgotPasswordScreen;
