import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import PasswordChecklist from "react-password-checklist";

function PasswordResetScreen() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [responseSuccessMessage, setResponseSuccessMessage] = useState("");
  const [responseErrorMessage, setResponseErrorMessage] = useState("");

  const [submitButton, setSubmitButton] = useState(false);

  function setSubmit() {
    setSubmitButton((oldSubmit) => true);
  }
  let param = useParams();

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      axios
        .post(
          "/api/users/reset-password/",
          {
            password: password,
            id: param.id,
            code: param.code,
          },
          config
        )
        .then(function (response) {
          console.log("response");
          setResponseSuccessMessage(response.data);
        })
        .catch(function (error, response) {
          console.log("error");
          setResponseErrorMessage(response.data);
        });
      setSubmitButton((oldSubmit) => false);
    }
  };

  console.log("param");
  console.log("code");
  console.log("sub");

  return (
    <div>
      <Header />
      <Container className="full-screen-container">
        <Row className="login-register-container">
          <Col className="login-form-background-left"></Col>
          <Col className="login-form-column px-5 py-3">
            <Col className="login-form-container login-screen px-4 py-4">
              <Row className="login-form-container-header mb-4">
                <Col className="col-9 p-3">
                  <h3 className="serious-font">Reset your password</h3>
                </Col>
                <Col></Col>
              </Row>

              {message && (
                <div className="error-message mb-2">
                  <strong>{message}</strong>
                </div>
              )}
              {responseErrorMessage && (
                <div className="error-message mb-2">
                  <strong>{responseErrorMessage}</strong>
                </div>
              )}
              {responseSuccessMessage && (
                <div className="success-message mb-2">
                  <strong>{responseSuccessMessage}</strong>
                </div>
              )}

              <Form onSubmit={submitHandler}>
                <Form.Group className="mb-3" controlId="registerPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="registerConfirmPassword"
                >
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Group>
                {submitButton === true && (
                  <Row align="center">
                    <Col align="center">
                      <button
                        className="lcn-button-brown px-5 py-3"
                        type="submit"
                      >
                        Submit
                      </button>
                    </Col>
                  </Row>
                )}
                <PasswordChecklist
                  rules={[
                    "minLength",
                    "specialChar",
                    "number",
                    "capital",
                    "match",
                  ]}
                  minLength={5}
                  value={password}
                  valueAgain={confirmPassword}
                  onChange={(isValid) => {
                    setSubmit();
                  }}
                />
              </Form>
            </Col>
          </Col>
          <Col className="login-form-background-right"></Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default PasswordResetScreen;
