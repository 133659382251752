import React, { useContext } from "react";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
import CycleLoT from "../../lottie-files/CycleLoT";
import lottie from "lottie-web";

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
};

const newSteps = [
  {
    id: "shep-s1",
    //attachTo:{element:'.sme-journey-col', on:'right'},
    // beforeShowPromise:function(){
    //   return new Promise (function (resolve){
    //     setTimeout(function(){
    //       window.scrollTo(0,0);
    //       resolve();
    //     }, 500);
    //   });
    // },
    buttons: [
      {
        classes: "shepard-button-secondary lcn-button-orange",
        text: "Exit",
        type: "cancel",
      },
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Next",
        type: "next",
      },
    ],
    classes: "shepard-step",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    title: "Welcome to Lacoona Legal!",
    text: [
      "We are here to help small and medium-sized enterprises (SMEs) get their legal ducks in a row and solve their legal problems.<br>This short solution walk-through is designed to make your experience as smooth and efficient as possible so follow along for a few moments to see where you can find benefit!",
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
  {
    id: "shep-s2",
    attachTo: { element: "#dashnav-sme-dashboard", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Next",
        action() {
          const selector = document.getElementById("dashnav-ai-assistant");
          selector.click();
          return this.next();
        },
      },
    ],
    classes: "shepard-step custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    title: "This is your dashboard for centralised action",
    text: [
      "This is where you'll get an overview of your business and some actionable steps",
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
  {
    id: "shep-s3",
    attachTo: { element: "#dashnav-ai-assistant", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Back",
        action() {
          const selector = document.getElementById("dashnav-sme-dashboard");
          selector.click();
          return this.back();
        },
      },
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Next",
        action() {
          const selector = document.getElementById("dashnav-legal-checks");
          selector.click();
          return this.next();
        },
      },
    ],
    classes: "shepard-step custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    title: "A Virtual Lawyer for those quick legal questions you have",
    text: [
      "Try out our A.I. virtual lawyer, Jinx. It has the ability to understand your concern and apply it to a vast body of knowledge.",
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
  {
    id: "shep-s4",
    attachTo: { element: "#dashnav-legal-checks", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Back",
        action() {
          const selector = document.getElementById("dashnav-ai-assistant");
          selector.click();
          return this.back();
        },
      },
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Next",
        action() {
          const selector = document.getElementById("dashnav-legal-products");
          selector.click();
          return this.next();
        },
      },
    ],
    classes: "shepard-step custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    title: "Come here for legal assessments and reports for your company",
    text: [
      "These legal checks will enable you to assess your business' legal health and to understand what you need to do to improve your legal standing.",
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
  {
    id: "shep-s5",
    attachTo: { element: "#dashnav-legal-products", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Back",
        action() {
          const selector = document.getElementById("dashnav-legal-checks");
          selector.click();
          return this.back();
        },
      },
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Next",
        action() {
          const selector = document.getElementById("dashnav-documents");
          selector.click();
          return this.next();
        },
      },
    ],
    classes: "shepard-step custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    title: "Find automated documents and process guidance",
    text: [
      "Our legal chatbots generate documents and explain legal processes all at your own pace and on-demand for when you need.",
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
  {
    id: "shep-s6",
    attachTo: { element: "#dashnav-documents", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Back",
        action() {
          const selector = document.getElementById("dashnav-legal-products");
          selector.click();
          return this.back();
        },
      },
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Next",
        action() {
          const selector = document.getElementById("dashnav-fal");
          selector.click();
          return this.next();
        },
      },
    ],
    classes: "shepard-step custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    title:
      "This is where you find and action documents which you have previously created.",
    text: [
      "Here you'll see documents which you have previously completed. You can also upload documents here that you can send for review to a lawyer at a fixed cost.",
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
  {
    id: "shep-s7",
    attachTo: { element: "#dashnav-fal", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Back",
        action() {
          const selector = document.getElementById("dashnav-documents");
          selector.click();
          return this.back();
        },
      },
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Next",
        action() {
          const selector = document.getElementById("dashnav-legal-faqs");
          selector.click();
          return this.next();
        },
      },
    ],
    classes: "shepard-step custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    title: "There will be a time when you need to speak to a lawyer.",
    text: [
      "In a simple 3-step process we will assist you in finding the most applicable lawyer for your matter.",
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
  {
    id: "shep-s8",
    attachTo: { element: "#dashnav-legal-faqs", on: "right" },
    buttons: [
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Back",
        action() {
          const selector = document.getElementById("dashnav-fal");
          selector.click();
          return this.back();
        },
      },
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "next",
        action() {
          const selector = document.getElementById("dashnav-sme-dashboard");
          selector.click();
          return this.next();
        },
      },
    ],
    classes: "shepard-step custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    title:
      "Looking to upskill, understand or some light reading. This is your spot.",
    text: [
      "We're all about empowerment and believe education is a necessity. Find a collection of SME and legal information for a deeper understanding.",
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
  {
    id: "shep-s9",
    attachTo: { element: ".LandbotLivechat", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Back",
        action() {
          const selector = document.getElementById("dashnav-legal-faqs");
          selector.click();
          return this.back();
        },
      },
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "next",
        type: "next",
      },
    ],
    classes: "shepard-step custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    title: "Find help, get technical support or suggest a feature.",
    text: [
      "If you need help, technical support, or want to suggest a feature, we have several options available to you. <br> We're here for any guidance, suggestions or if you would like to make a complaint.",
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
  {
    id: "shep-s10",
    attachTo: { element: ".ll-dash-company-details", on: "bottom" },
    buttons: [
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "Back",
        type: "back",
      },
      {
        classes: "shepherd-button-primary lcn-button-orange",
        text: "End",
        type: "cancel",
      },
    ],
    classes: "shepard-step custom-class-name-2",
    highlightClass: "highlight",
    scrollTo: false,
    cancelIcon: {
      enabled: false,
    },
    title: "Help us help you!",
    text: [
      "We use your company details for nothing more than allowing our A.I. to understand your legal needs. The more info you provide, the better Jinx can be!",
    ],
    when: {
      show: () => {
        console.log("show step");
      },
      hide: () => {
        console.log("hide step");
      },
    },
  },
];

function TourButton() {
  const tour = useContext(ShepherdTourContext);

  function start() {
    tour.start();
  }

  return (
    <div
      className="tour-button"
      onMouseEnter={() => lottie.play("cycle-tour-lottie")}
      onMouseLeave={() => lottie.stop("cycle-tour-lottie")}
      onClick={start}
    >
      <CycleLoT aniName="cycle-tour-lottie" />
      Start Tour
    </div>
  );
}

function ShepTour() {
  return (
    <div className="tour-parent tour-desktop">
      <ShepherdTour steps={newSteps} tourOptions={tourOptions}>
        <TourButton />
      </ShepherdTour>
    </div>
  );
}

export default ShepTour;
