import React from "react";
import { Spinner, Container, Row, Col } from "react-bootstrap";

function FullScreenLoader() {
  return (
    <div>
      <Container align="center" className="full-screen-loader-container">
        <Row className="full-screen-loader-row">
          <Col className="full-screen-loader-col">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default FullScreenLoader;
