import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Row, Col, Accordion, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getUserSMEDetails } from "../actions/userActions";
import DashHeader from "./DashHeader";
import UserValidator from "./UserValidator";
import axios from "axios";
import { LinkContainer } from "react-router-bootstrap";
import lottie from "lottie-web";
import Reload from "../lottie-files/Reload";
import { Helmet } from "react-helmet-async";
import ShepTour from "./Shepard-Tours/IntroTour";
import ShepTourMobi from "./Shepard-Tours/IntroTour-Mobile";
import LawyerCal from "./LawyerCal";
import LawyerCalAgenda from "./LawyerCalAgenda";
import ScalesLoT from "../lottie-files/ScalesLoT";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import Select from "react-select";
import { getUserLawyerDetails } from "../actions/userActions";
import LawyerValidator from "./LawyerValidator";

import "react-big-calendar/lib/css/react-big-calendar.css";
import LawyerShepTour from "./Shepard-Tours/LawyerShepTour";
import LawyerShepTourMobi from "./Shepard-Tours/LawyerShepTour-Mobile";
import LawyerDashHeader from "./LawyerDashHeader";
const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const myEventsList = [];
function LawyerOverview({
  localizer,
  myEvents,
  handleSelectEvent,
  handleSelectSlot,
  scrollToTime,
  handleModalClose,
  deleteSelectedEvent,
  modalShow,
  selectedEvent,
  numBookings,
  numOpenings,
}) {
  const lawyerDetails = useSelector((state) => state.userLawyerDetails);
  const { lawyer } = lawyerDetails;
  const { userInfo } = useSelector((state) => state.userLogin);
  const dispatch = useDispatch();
  const [lawyerName, setLawyerName] = useState("");
  const [lawyerDescription, setLawyerDescription] = useState("");
  const [lawyerSpecialization, setLawyerSpecialization] = useState([]);
  const [lawyerCharacteristics, setLawyerCharacteristics] = useState([]);
  const [lawyerPracticeLocation, setLawyerPracticeLocation] = useState("");
  const [lawyerHourlyRate, setLawyerHourlyRate] = useState("");
  const [lawyerHalfHourlyRate, setLawyerHalfHourlyRate] = useState("");
  const [lawyerContactNumber, setLawyerContactNumber] = useState();
  const [lawyerLinkedIn, setLawyerLinkedIn] = useState("");
  const [lawyerFirmName, setLawyerFirmName] = useState("");
  const [activeKey, setActiveKey] = useState(0);
  const [profileReset, setProfileReset] = useState(false);
  console.log("lawyerCharacteristics", lawyerCharacteristics);
  console.log("lawyerSpecialization", lawyerSpecialization);

  // update lawyer detail accordion close function
  const handleClickAccordion = (eventKey) => {
    setActiveKey(eventKey);
  };

  // update reset lawyer details to state
  useEffect(() => {
    dispatch(getUserLawyerDetails());
  }, [profileReset]);

  useEffect(() => {
    setLawyerName(lawyer?.name);
    setLawyerDescription(lawyer?.description);
    setLawyerSpecialization(lawyer?.specialization);
    setLawyerCharacteristics(lawyer?.characteristics);
    setLawyerPracticeLocation(lawyer?.practiceLocation);
    setLawyerHourlyRate(lawyer?.hourlyRate);
    setLawyerHalfHourlyRate(lawyer?.halfHourlyRate);
    setLawyerContactNumber(lawyer?.contactNumber);
    setLawyerLinkedIn(lawyer?.linkedIn);
    setLawyerFirmName(lawyer?.firmName);
  }, [lawyer]);

  const [payments, setPayments] = useState([]);
  const [paymentsTotal, setPaymentsTotal] = useState(0);

  const characteristics = [
    {
      label: "Compassionate",
      value: "Compassionate",
    },
    {
      label: "Passionate",
      value: "Passionate",
    },
    {
      label: "Aggressive",
      value: "Aggressive",
    },
    {
      label: "Soft-spoken",
      value: "Soft-spoken",
    },
    {
      label: "A strong communicator",
      value: "A strong communicator",
    },
    {
      label: "Widely knowledgable",
      value: "Widely knowledgable",
    },
    {
      label: "Creative",
      value: "Creative",
    },
  ];

  const specialization = [
    {
      label: "Family Law",
      value: "Family Law",
    },
    {
      label: "Arbitration, Concilliation and Mediation",
      value: "Arbitration, Concilliation and Mediation",
    },
    {
      label: "Company Law (Corporate Governance)",
      value: "Company Law (Corporate Governance)",
    },
    {
      label: "Business Law",
      value: "Business Law",
    },
    {
      label: "Labour Law",
      value: "Labour Law",
    },
    {
      label: "Debt Collection",
      value: "Debt Collection",
    },
    {
      label: "Estate Management",
      value: "Estate Management",
    },
    {
      label: "Property Law",
      value: "Property Law",
    },
    {
      label: "Criminal Law",
      value: "Criminal Law",
    },
    {
      label: "SME Advice",
      value: "SME Advice",
    },
    {
      label: "Website Management",
      value: "Website Management",
    },
    {
      label: "IP and Brand",
      value: "IP and Brand",
    },
    {
      label: "Tax and Financial Law",
      value: "Tax and Financial Law",
    },
    {
      label: "Environmental Law",
      value: "Environmental Law",
    },
    {
      label: "Funding and Financing Law",
      value: "Funding and Financing Law",
    },
    {
      label: "Consumer Law",
      value: "Consumer Law",
    },
    {
      label: "Data Management",
      value: "Data Management",
    },
    {
      label: "Non-Profit and Social Enterprise Law",
      value: "Non-Profit and Social Enterprise Law",
    },
    {
      label: "BBBEE Compliance",
      value: "BBBEE Compliance",
    },
    {
      label: "Construction and Engineering Compliance",
      value: "Construction and Engineering Compliance",
    },
  ];

  // set up pieces of state for lawyer details update form

  // query payments from api
  useEffect(() => {
    console.log("making paymebt request");
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo?.token}`,
      },
    };
    axios
      .get("/api/payments/get-lawyer-payments/", config)
      .then(function (response) {
        console.log(response);
        setPayments(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // tally up all payments
  useEffect(() => {
    let total = 0;
    let upcomingPaymentsVar = 0;
    let amountLessLcnCharge = 0;
    if (payments.length >= 0) {
      for (let payment of payments) {
        if (payment.amount >= 1100) {
          amountLessLcnCharge = payment.amount / 1.1;
        } else {
          amountLessLcnCharge = payment.amount - 100;
        }
        total += amountLessLcnCharge;
      }
      setPaymentsTotal(Math.round(total));
    }
  }, [payments]);

  // Function for saving event openings to db
  function saveOpenings() {
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo?.token}`,
      },
    };
    const eventsString = JSON.stringify(myEvents);

    axios
      .post("/api/users/save-lawyer-openings/", eventsString, config)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // function for updating lawyer info
  const submitHandler = (e) => {
    e.preventDefault();

    handleClickAccordion(null);
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${userInfo?.token}`,
      },
    };
    axios
      .put(
        "/api/users/update-lawyer/",
        {
          id: lawyer.id,
          name: lawyerName,
          description: lawyerDescription,
          specialization: lawyerSpecialization,
          characteristics: lawyerCharacteristics,
          practiceLocation: lawyerPracticeLocation,
          hourlyRate: lawyerHourlyRate,
          halfHourlyRate: lawyerHalfHourlyRate,
          contactNumber: lawyerContactNumber,
          linkedIn: lawyerLinkedIn,
          firmName: lawyerFirmName,
        },
        config
      )
      .then(function (response) {
        console.log("Response Received");
        setProfileReset((oldProfile) => !oldProfile);
      })
      .catch(function (error) {
        console.log("Error Occured");
      });
  };

  // update lawyer characteristics
  function updateCharacteristics(values) {
    let newValues = [];
    for (let value of values) {
      newValues.push(value.value);
    }
    setLawyerCharacteristics(newValues);
  }

  // update lawyer specialization
  function updateSpecialization(values) {
    let newValues = [];
    for (let value of values) {
      newValues.push(value.value);
    }
    setLawyerSpecialization(newValues);
  }

  console.log("lawyerFirmName", lawyerFirmName);

  // console.log("events", myEvents);
  // console.log("lawyer", lawyer);
  // console.log("payments", payments);
  return (
    <>
      <Helmet>
        <title>Lacoona Legal Lawyer Dashboard</title>
        <meta
          name="description"
          content="You centalised Legal Dashboard for managing your business on Lacoona Legal"
        />
        <link rel="canonical" href={"/dashboard/sme-profile"} />
      </Helmet>
      {userInfo?.profile === 4 && (
        <Col className="dashboard-scrollable-container" lg={9}>
          <LawyerDashHeader pageTitle={"Welcome " + userInfo?.name} />
          <Row className="dashboard-content-container">
            <Row className="dashboard-content-divider"></Row>

            <Col className="px-4">
              {/* Reece Alt Dashboard UI */}
              {/* Title */}
              <Row className="mb-3">
                <Col xs={6} md={8} lg={10}>
                  <h2 className="ll-subtitle ll-feat-font">Lawyer Dashboard</h2>
                </Col>
                <Col className="tour-bicycle" xs={6} md={4} lg={2}>
                  <LawyerShepTourMobi />
                  <LawyerShepTour />
                </Col>
              </Row>
              {/* Body Content */}
              <Row className="ll-dash-body-content">
                <Row className="ll-dash-top-content">
                  <Col className="ll-dash-metrics-parent">
                    {/* Conditional start your journey */}

                    <Row>
                      {/* <Col
                        className="start-your-journey-parent my-3"
                        onMouseEnter={() => lottie.play("journey-start-dash")}
                        onMouseLeave={() => lottie.stop("journey-start-dash")}
                      >
                        <Col className="sme-journey-col pe-2">
                          <Row>
                            <h6 className="start-you-journey-text">
                              Start finding clients now by opening up some calendar slots.
                            </h6>
                          </Row>
                          <Row className="journey-start-dash-parent">
                            <JourneyLoT aniName="journey-start-dash" />
                          </Row>
                          <Row className="px-3">
                            <LinkContainer to={`/dashboard/health-check`}>
                              <button className="lcn-button-orange px-5">
                                Go
                              </button>
                            </LinkContainer>
                          </Row>
                        </Col>
                      </Col> */}

                      {/* Conditional Cards */}
                      <Col>
                        <Row>
                          <LinkContainer to={"/lawyer-dashboard/appointments"}>
                            <Col className="me-3">
                              <Row
                                className="ll-featured-metric-card ll-card-interact"
                                onMouseEnter={() =>
                                  lottie.play("update-lh sessions-booked")
                                }
                                onMouseLeave={() =>
                                  lottie.stop("update-lh sessions-booked")
                                }
                                role="button"
                              >
                                <Row className="ll-featured-metric-card-top-info">
                                  <Col>
                                    <h4 className="ll-feature-card-subtext">
                                      Sessions Booked
                                    </h4>
                                  </Col>
                                  <Col className="d-flex justify-content-end ll-no-margin px-0 mx-0">
                                    <Reload aniName="update-lh sessions-booked" />
                                  </Col>
                                </Row>
                                <Row className="ll-featured-metric-card-bottom-info">
                                  <Col className="align-content-end">
                                    <Row className="align-text-bottom">
                                      <Col className="ll-featured-card-metric-number ll-feat-font">
                                        {numBookings}
                                        <span className="ll-featured-card-metric-percent ll-feat-font">
                                          {numBookings === 1
                                            ? "Booking"
                                            : "Bookings"}
                                        </span>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Row>
                            </Col>
                          </LinkContainer>
                          <LinkContainer to={"/lawyer-dashboard/payments"}>
                            <Col className="me-3">
                              <Row
                                className="ll-featured-metric-card ll-card-interact"
                                onMouseEnter={() =>
                                  lottie.play("update-lh earnings")
                                }
                                onMouseLeave={() =>
                                  lottie.stop("update-lh earnings")
                                }
                                role="button"
                              >
                                <Row className="ll-featured-metric-card-top-info">
                                  <Col>
                                    <h4 className="ll-feature-card-subtext">
                                      Total Earnings
                                    </h4>
                                  </Col>
                                  <Col className="d-flex justify-content-end ll-no-margin px-0 mx-0">
                                    <Reload aniName="update-lh earnings" />
                                  </Col>
                                </Row>
                                <Row className="ll-featured-metric-card-bottom-info">
                                  <Col className="align-content-end">
                                    <Row className="align-text-bottom">
                                      <Col className="ll-featured-card-metric-number ll-feat-font">
                                        R{paymentsTotal}
                                        <span className="ll-featured-card-metric-percent ll-feat-font">
                                          .00
                                        </span>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Row>
                            </Col>
                          </LinkContainer>
                        </Row>
                        <Row>
                          <LinkContainer to={"/lawyer-dashboard/schedule"}>
                            <Col className="me-3">
                              <Row
                                className="ll-featured-metric-card ll-card-interact"
                                onMouseEnter={() =>
                                  lottie.play("update-lh openings")
                                }
                                onMouseLeave={() =>
                                  lottie.stop("update-lh openings")
                                }
                                role="button"
                              >
                                <Row className="ll-featured-metric-card-top-info">
                                  <Col>
                                    <h4 className="ll-feature-card-subtext">
                                      Total Openings
                                    </h4>
                                  </Col>
                                  <Col className="d-flex justify-content-end ll-no-margin px-0 mx-0">
                                    <Reload aniName="update-lh openings" />
                                  </Col>
                                </Row>
                                <Row className="ll-featured-metric-card-bottom-info">
                                  <Col className="align-content-end">
                                    <Row className="align-text-bottom">
                                      <Col className="ll-featured-card-metric-number ll-feat-font">
                                        {numOpenings}
                                        <span className="ll-featured-card-metric-percent ll-feat-font">
                                          sessions
                                        </span>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Row>
                            </Col>
                          </LinkContainer>
                          <Col
                            align="center"
                            className="me-3"
                            onMouseEnter={() =>
                              lottie.play("lawyer-scales-lottie")
                            }
                            onMouseLeave={() =>
                              lottie.stop("lawyer-scales-lottie")
                            }
                          >
                            {/* <Row
                            className="ll-featured-metric-card ll-card-interact"
                            onMouseEnter={() => lottie.play("update-lh")}
                            onMouseLeave={() => lottie.stop("update-lh")}
                            role="button"
                          >
                            <Row className="ll-featured-metric-card-top-info">
                              <Col>
                                <h4 className="ll-feature-card-subtext">
                                  Cancelled bookings
                                </h4>
                              </Col>
                              <Col className="d-flex justify-content-end ll-no-margin px-0 mx-0">
                                <Reload aniName="update-lh" />
                              </Col>
                            </Row>
                            <Row className="ll-featured-metric-card-bottom-info">
                              <Col className="align-content-end">
                                <Row className="align-text-bottom">
                                  <Col className="ll-featured-card-metric-number ll-feat-font">
                                    0
                                    <span className="ll-featured-card-metric-percent ll-feat-font">
                                      sessions
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Row> */}
                            <ScalesLoT aniName="lawyer-scales-lottie" />
                          </Col>
                        </Row>
                      </Col>
                      {/* Company details section */}
                      <Col
                        lg={4}
                        id="lawyer-details-dash"
                        className="ll-dash-lawyer-details p-2 px-4 left-border"
                      >
                        <Row>
                          <h3 className="ll-feat-font">Company Information</h3>
                        </Row>
                        <Row className="py-4">
                          <Col>
                            <div>
                              <h5>{lawyer?.firmName}</h5>
                            </div>
                            <div className="ll-grey-colour ">
                              <h5 className="ll-font-wt-light">Firm Name</h5>
                            </div>
                          </Col>
                          <Col>
                            <div>
                              <h5>{lawyer?.name}</h5>
                            </div>
                            <div className="ll-grey-colour ">
                              <h5 className="ll-font-wt-light">Lawyer Name</h5>
                            </div>
                          </Col>
                        </Row>
                        <Row className="py-4">
                          <Col>
                            <div>
                              <h5>
                                {lawyer?.specialization?.map((item) => (
                                  <div>{item}</div>
                                ))}
                              </h5>
                            </div>
                            <div className="ll-grey-colour ">
                              <h5 className="ll-font-wt-light">
                                Areas of Practice
                              </h5>
                            </div>
                          </Col>
                        </Row>
                        <Row className="py-4">
                          <Accordion activeKey={activeKey}>
                            <Accordion.Item
                              eventKey="0"
                              className="ll-accordion-parent"
                            >
                              <Accordion.Header
                                onClick={() =>
                                  !activeKey
                                    ? handleClickAccordion("0")
                                    : handleClickAccordion(null)
                                }
                              >
                                Update Lawyer Details
                              </Accordion.Header>
                              <Accordion.Body>
                                <Form onSubmit={submitHandler}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="lawyerName"
                                  >
                                    <Form.Label>Lawyer Name</Form.Label>
                                    <Form.Control
                                      type="input"
                                      placeholder="Enter lawyer name"
                                      value={lawyerName ? lawyerName : ""}
                                      onChange={(e) =>
                                        setLawyerName(e.target.value)
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="lawyerDescription"
                                  >
                                    <Form.Label>Lawyer Description</Form.Label>
                                    <Form.Control
                                      type="input"
                                      placeholder="Enter lawyer description"
                                      value={
                                        lawyerDescription
                                          ? lawyerDescription
                                          : ""
                                      }
                                      onChange={(e) =>
                                        setLawyerDescription(e.target.value)
                                      }
                                    />
                                  </Form.Group>
                                  <Row>
                                    <Col>Characteristics</Col>
                                  </Row>
                                  <Select
                                    defaultValue={"Select..."}
                                    isMulti
                                    name="characteristics"
                                    className="find-a-lawyer-form p-1"
                                    id="characteristics"
                                    options={characteristics}
                                    onChange={(value) =>
                                      updateCharacteristics(value)
                                    }
                                    type="text"
                                  />
                                  <Row>
                                    <Col>Specialization</Col>
                                  </Row>
                                  <Select
                                    defaultValue={"Select..."}
                                    isMulti
                                    name="specialization"
                                    className="find-a-lawyer-form p-1"
                                    id="specialization"
                                    options={specialization}
                                    onChange={(value) =>
                                      updateSpecialization(value)
                                    }
                                    type="text"
                                  />
                                  <Form.Group
                                    className="mb-3"
                                    controlId="lawyerPracticeLocation"
                                  >
                                    <Form.Label>Practice location</Form.Label>
                                    <Form.Control
                                      type="input"
                                      placeholder="Enter practice location"
                                      value={
                                        lawyerPracticeLocation
                                          ? lawyerPracticeLocation
                                          : ""
                                      }
                                      onChange={(e) =>
                                        setLawyerPracticeLocation(
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="lawyerHourlyRate"
                                  >
                                    <Form.Label>Hourly rate</Form.Label>
                                    <Form.Control
                                      type="input"
                                      placeholder="Enter hourly rate"
                                      value={
                                        lawyerHourlyRate ? lawyerHourlyRate : ""
                                      }
                                      onChange={(e) =>
                                        setLawyerHourlyRate(e.target.value)
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="lawyerHalfHourlyRate"
                                  >
                                    <Form.Label>Half-hourly rate</Form.Label>
                                    <Form.Control
                                      type="input"
                                      placeholder="Enter half-hourly rate"
                                      value={
                                        lawyerHalfHourlyRate
                                          ? lawyerHalfHourlyRate
                                          : ""
                                      }
                                      onChange={(e) =>
                                        setLawyerHalfHourlyRate(e.target.value)
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="lawyerContactNumber"
                                  >
                                    <Form.Label>Contact number</Form.Label>
                                    <Form.Control
                                      type="input"
                                      placeholder="Enter contact number"
                                      value={
                                        lawyerContactNumber
                                          ? lawyerContactNumber
                                          : ""
                                      }
                                      onChange={(e) =>
                                        setLawyerContactNumber(e.target.value)
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="lawyerLinkedIn"
                                  >
                                    <Form.Label>LinkedIn</Form.Label>
                                    <Form.Control
                                      type="input"
                                      placeholder="Enter LinkedIn Address"
                                      value={
                                        lawyerLinkedIn ? lawyerLinkedIn : ""
                                      }
                                      onChange={(e) =>
                                        setLawyerLinkedIn(e.target.value)
                                      }
                                    />
                                  </Form.Group>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="lawyerFirmName"
                                  >
                                    <Form.Label>Firm name</Form.Label>
                                    <Form.Control
                                      type="input"
                                      placeholder="Enter the name of your firm"
                                      value={
                                        lawyerFirmName ? lawyerFirmName : ""
                                      }
                                      onChange={(e) =>
                                        setLawyerFirmName(e.target.value)
                                      }
                                    />
                                  </Form.Group>

                                  <Row>
                                    <Col align="end">
                                      <button
                                        className="lcn-button-brown px-5 py-3"
                                        type="submit"
                                      >
                                        Update
                                      </button>
                                    </Col>
                                  </Row>
                                </Form>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </Row>
                        <Row>
                          <LinkContainer to={"/lawyer-dashboard/user-dash"}>
                            <button className="lcn-button-brown">
                              Update user details
                            </button>
                          </LinkContainer>
                        </Row>
                        <Row>
                          <Col className="ll-dash-activity-details"></Col>
                        </Row>
                      </Col>
                      <Row
                        id="divider-under-token"
                        className="dashboard-content-divider"
                      ></Row>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  {/* Calendar & Agenda Section */}
                  <Col lg={4} className="right-border">
                    <Calendar
                      localizer={localizer}
                      events={myEvents}
                      startAccessor="start"
                      endAccessor="end"
                      style={{ height: 500 }}
                      selectable
                      defaultView="agenda"
                      scrollToTime={scrollToTime}
                      views={["agenda"]}
                    />
                  </Col>
                  <Col>
                    <Row className="mb-2">
                      <LinkContainer to={"/lawyer-dashboard/schedule"}>
                        <button className="lcn-button-orange">
                          Add / edit openings
                        </button>
                      </LinkContainer>
                    </Row>
                    <Row className="mb-5">
                      <Calendar
                        localizer={localizer}
                        events={myEvents}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500 }}
                        defaultView="week"
                        scrollToTime={scrollToTime}
                        views={["week"]}
                        toolbar={false}
                      />
                    </Row>
                  </Col>
                </Row>
                {/* More Content Space for later */}
                <Row className="ll-dash-bottom-content"></Row>
              </Row>
              {/* End of Reece Attempt */}
            </Col>
          </Row>
        </Col>
      )}

      {!userInfo ? (
        <LawyerValidator />
      ) : (
        userInfo?.profile === 0 && <LawyerValidator />
      )}
    </>
  );
}

export default LawyerOverview;
