import React, { useState, useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  getUserSMEDetails,
  getUserEcosystemDetails,
  getUserIncubatorDetails,
  getUserLawyerDetails,
} from "../actions/userActions";
import { Container, Row, Col, Form } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";

function LoginScreen(location) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  let navigate = useNavigate();

  // const redirect = location.search ? location.search.split("=")[1] : "/";

  const userLogin = useSelector((state) => state.userLogin);
  const { error, userInfo } = userLogin;

  // useEffect(() => {
  //   if (userInfo) {
  //     history.push(redirect);
  //   }
  // }, [history, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
    // navigate("/");
  };

  useEffect(() => {
    if (userInfo) {
      if (userInfo.role === "sme") {
        dispatch(getUserSMEDetails());
        navigate("/dashboard/sme-profile");
      } else if (userInfo.role === "incubator") {
        dispatch(getUserIncubatorDetails());
        navigate("/dashboard/incubator-profile");
      } else if (userInfo.role === "ecosystem") {
        dispatch(getUserEcosystemDetails());
        navigate("/dashboard/ecosystem-profile");
      } else if (userInfo.role === "lawyer") {
        dispatch(getUserLawyerDetails());
        navigate("/lawyer-dashboard/lawyer-dash");
      }
    }
    // eslint-disable-next-line
  }, [userInfo]);

  return (
    <div>
      <Header />
      <Container className="full-screen-container">
        <Row className="login-register-container">
          <Col className="login-form-background-left"></Col>
          <Col className="login-form-column px-5 py-3">
            <Col className="login-form-container login-screen px-4 py-4">
              <Row className="login-form-container-header mb-4">
                <Col className="col-9 p-3">
                  <h3 className="serious-font">Welcome to Lacoona Legal</h3>
                  <h1 className="serious-font">Sign In</h1>
                </Col>
                <Col>
                  <p>No account?</p>
                  <LinkContainer to={"/register"}>
                    <p className="login-form-container-register">
                      <strong>Register</strong>
                    </p>
                  </LinkContainer>
                  <LinkContainer to={"/forgot-password"}>
                    <p className="login-form-container-register">
                      <strong>Forgot password?</strong>
                    </p>
                  </LinkContainer>
                </Col>
              </Row>

              {error && (
                <div className="error-message mb-2">
                  <strong>{error}</strong>
                </div>
              )}

              <Form onSubmit={submitHandler}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Row align="center">
                  <Col align="center">
                    <button
                      className="lcn-button-brown px-5 py-3"
                      type="submit"
                    >
                      Login
                    </button>
                  </Col>
                  {/* <Col align="center">
                    <LinkContainer to={"/register"}>
                      <button className="lcn-button-brown px-5 py-3">
                        Register
                      </button>
                    </LinkContainer>
                  </Col> */}
                </Row>
              </Form>
            </Col>
          </Col>
          <Col className="login-form-background-right"></Col>
          {/* <Col id="login-register-hero-container">
            <img
              className="login-register-image"
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Group_376.png"
            ></img>
            <Row className="login-register-hero px-3 py-3">
              <Row align="center">
                <h1 className="serious-font">One more step</h1>
              </Row>
              <Row align="center">
                <p>
                  Welcome to the Lacoona Login portal. Whether you are a
                  Startup, Incubator, or Ecosystem, you are just one step away
                  for your Lacoona Dashboard.
                </p>
              </Row>
              <Row align="center">
                <h5>
                  If you are yet to subscribe or apply, here are some helpful
                  links
                </h5>
              </Row>
              <Row align="center">
                <Col className="login-register-hero-button-left-container">
                  <button className="lcn-button-orange login-hero-button">
                    For Startups
                  </button>
                </Col>
                <Col className="login-register-hero-button-right-container">
                  <button className="lcn-button-orange login-hero-button">
                    For Incubators
                  </button>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="login-register-hero-button-left-container">
                  <button className="lcn-button-orange login-hero-button">
                    For Investors
                  </button>
                </Col>
                <Col className="login-register-hero-button-right-container">
                  <button className="lcn-button-orange login-hero-button">
                    For Lawyers
                  </button>
                </Col>
              </Row>
            </Row>
          </Col> */}
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default LoginScreen;
