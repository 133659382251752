import React, { useState } from 'react';

const VideoComponent = ({ videoId }) => {
  const [showVideo, setShowVideo] = useState(false);

  const handleClick = () => {
    setShowVideo(!showVideo);
  };

  return (
    <div className={`video-container${showVideo ? ' video-active' : ''}`}>
      {!showVideo && (
        // <img
        //   className="video-thumbnail"
        //   src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
        //   alt="Video thumbnail"
        //   onClick={handleClick}
        // />
      <div onClick={handleClick} className="play-button">
      <i class="fa-regular fa-circle-play"></i>
    </div>
      )}
      {showVideo && (
              <div className="video-overlay">
              <div className="video-wrapper">
                <iframe
                  className="video-iframe"
                  src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                  allowFullScreen
                  title="Embedded Video"
                />
                <button className="close-button" onClick={handleClick}>
                <i class="fa-solid fa-circle-xmark"></i>
                </button>
              </div>
            </div>
      )}
    </div>
  );
};

export default VideoComponent;
