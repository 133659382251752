import {
  FAQ_LIST_REQUEST,
  FAQ_LIST_SUCCESS,
  FAQ_LIST_FAIL,
  FAQ_DETAILS_REQUEST,
  FAQ_DETAILS_SUCCESS,
  FAQ_DETAILS_FAIL,
} from "../constants/faqConstants";

export const faqListReducer = (state = { faqs: [] }, action) => {
  switch (action.type) {
    case FAQ_LIST_REQUEST:
      return { loading: true, faqs: [] };

    case FAQ_LIST_SUCCESS:
      return { loading: false, faqs: action.payload };

    case FAQ_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const faqDetailsReducer = (state = { faq: {} }, action) => {
  switch (action.type) {
    case FAQ_DETAILS_REQUEST:
      return { loading: true, ...state };

    case FAQ_DETAILS_SUCCESS:
      return { loading: false, faq: action.payload };

    case FAQ_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
