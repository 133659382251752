import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useParams } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";

function SearchResultsScreen() {
  let param = useParams();
  const [searchResults, setSearchResults] = useState("");

  useEffect(() => {
    setSearchResults("");
     // eslint-disable-next-line
    const { data } = axios
      .get(`/api/search/?q=${param.keyword}`)
      .then(function (response) {
        // console.log("response data", response.data);
        setSearchResults(response.data);
      })
      .catch(function (error) {
        console.log("Error occured");
      });
  }, [param]);



  console.log("mysearchresults");

  return (
    <div>
      <Header />
      <Container className="search-results-container px-5">
        <Row>
          <h1 className="serious-font">Results:</h1>
        </Row>
        {searchResults ? (
          <Row>
            <Row>
              <h1 className="serious-font">Bots</h1>
            </Row>
            <Row>
              {searchResults.bots ? (
                searchResults.bots.map((bot) => (
                  <Row>
                    <LinkContainer
                      to={`/dashboard/legal-products/intro/${bot.id}`}
                    >
                      <Col className="search-result mt-4 py-2">
                        <h3>{bot.name}</h3>
                      </Col>
                    </LinkContainer>
                  </Row>
                ))
              ) : (
                <p>no results matched your search</p>
              )}
            </Row>
            <Row>
              <h1 className="serious-font mt-4">FAQs</h1>
            </Row>
            <Row>
              {searchResults.faqs.length > 0 ? (
                searchResults.faqs.map((faq) => (
                  <Row>
                    <LinkContainer to={`/dashboard/faq/${faq.id}`}>
                      <Col className="search-result mt-4 py-2">
                        <h3>{faq.title}</h3>
                      </Col>
                    </LinkContainer>
                  </Row>
                ))
              ) : (
                <p>No results matched your search</p>
              )}
            </Row>
          </Row>
        ) : (
          <Row>
            <Col align="center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        )}
      </Container>

      <Footer />
    </div>
  );
}

export default SearchResultsScreen;
