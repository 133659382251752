import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Form } from "react-bootstrap";
import DashHeader from "./DashHeader";
import { LinkContainer } from "react-router-bootstrap";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import LawyerCal from "./LawyerCal";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import axios from "axios";
import LawyerDashHeader from "./LawyerDashHeader";
import LawyerValidator from "./LawyerValidator";

import ToolTip from "./ToolTip";

// Lots of props being passed here, because event state is kept by LawyerDashboard (parent of LawyerSchedule),
// but LawyerSchedule has to change that state a lot
function LawyerSchedule({
  localizer,
  myEvents,
  handleSelectEvent,
  handleSelectSlot,
  scrollToTime,
  handleModalClose,
  deleteSelectedEvent,
  modalShow,
  selectedEvent,
  saveModalShow,
  handleSaveModalClose,
  badEventModalShow,
  handleBadEventModalClose,
  repeat,
  noEventsRepeatModal,
  handleNoEventsRepeatModalClose,
  weekDropdown,
  handleWeekDropDown,
  repeatModal,
  handleRepeatModalClose,
  handleRepeatModalOpen,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const [formSavedButtonStatus, setFormSavedButtonStatus] = useState(true);

  // Function for saving myEvents to db
  function saveOpenings() {
    setFormSavedButtonStatus((old) => !old);
    setTimeout(() => setFormSavedButtonStatus((old) => !old), 1000);
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const eventsString = JSON.stringify(myEvents);

    axios
      .post("/api/users/save-lawyer-openings/", eventsString, config)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <Helmet>
        <title>Lacoona Legal Lawyer Dashboard</title>
        <meta
          name="description"
          content="You centalised Legal Dashboard for managing your business on Lacoona Legal"
        />
        <link rel="canonical" href={"/dashboard/sme-profile"} />
      </Helmet>
      {userInfo?.profile === 4 && (
        <Col className="dashboard-scrollable-container" lg={9}>
          <LawyerDashHeader pageTitle={"Schedule"} />
          <Row className="dashboard-content-container">
            <Row className="dashboard-content-divider"></Row>

            <Col className="px-4">
              {/* Reece Alt Dashboard UI */}
              {/* Title */}
              <Row className="mb-3">
                <Col xs={6} md={8}>
                  <h2 className="ll-subtitle ll-feat-font">Lawyer Schedule</h2>
                </Col>
                <Col>
                  <Row className="pe-5">
                    <Col className="repeat-button-col">
                      <ToolTip
                        tooltip="Click for more info"
                        id="tooltip-reg-comp"
                        placement="right"
                      >
                        <button
                          onClick={handleRepeatModalOpen}
                          className="lcn-button-brown"
                        >
                          Repeat
                        </button>
                      </ToolTip>
                    </Col>
                    <Col>
                      <button
                        onClick={saveOpenings}
                        className="lcn-button-brown"
                      >
                        {formSavedButtonStatus ? "Save Schedule" : "Saving..."}
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* Body Content */}
              <Row className="ll-dash-body-content">
                <Row>
                  <Calendar
                    localizer={localizer}
                    events={myEvents}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    onSelectEvent={handleSelectEvent}
                    onSelectSlot={handleSelectSlot}
                    selectable
                    defaultView="week"
                    scrollToTime={scrollToTime}
                    views={["month", "week", "day"]}
                  />
                </Row>
                {/* <Row className="mt-3 repeater-row">
                <Col align="center" className="repeater-col">
                  <span className="me-2">Repeat current week by</span>
                  <Form className="lawyer-schedule-numberdropdown">
                    <Form.Group controlId="numberDropdown">
                      <Form.Control
                        onChange={(e) => handleWeekDropDown(e)}
                        as="select"
                      >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                        <option value={11}>11</option>
                        <option value={12}>12</option>
                      </Form.Control>
                    </Form.Group>
                  </Form>
                  <span className="mx-2">weeks</span>

                  <ToolTip
                    tooltip="Use this button to create a schedule of openings for future weeks that replicate the openings in your current week"
                    id="tooltip-reg-comp"
                    placement="right"
                  >
                    <button
                      onClick={handleRepeatModalOpen}
                      className="lcn-button-orange"
                    >
                      Repeat
                    </button>
                  </ToolTip>
                </Col>
              </Row> */}
                <Row>
                  {" "}
                  <div>
                    <Modal show={modalShow} onHide={handleModalClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>
                          Event Title: {selectedEvent?.title}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {selectedEvent?.title === "Opening" && (
                          <Row>
                            <Col className="p-3">
                              You have made this slot available for client
                              bookings.
                            </Col>
                          </Row>
                        )}
                        {selectedEvent?.title !== "Opening" && (
                          <div>
                            <Row>
                              <Col className="p-3">
                                You have a booking with{" "}
                                <strong>{selectedEvent?.client}</strong>{" "}
                                regarding the following matter:
                                <br />
                                <hr />
                                <br />
                                <italics>{selectedEvent?.scenario}</italics>
                              </Col>
                            </Row>
                            <Row>
                              <Col align="center">
                                <a
                                  target="_blank"
                                  href={selectedEvent?.link}
                                  rel="noreferrer"
                                >
                                  <button className="lcn-button-orange mb-2">
                                    Go to Meeting
                                  </button>
                                </a>
                              </Col>
                            </Row>
                          </div>
                        )}
                        {selectedEvent?.title === "Opening" && (
                          <Row align="center">
                            <Col>
                              <button
                                className="lcn-button-orange"
                                onClick={() =>
                                  deleteSelectedEvent(selectedEvent?.id)
                                }
                              >
                                Delete event
                              </button>
                            </Col>
                          </Row>
                        )}
                      </Modal.Body>
                    </Modal>
                  </div>
                </Row>

                <Row>
                  <div>
                    <Modal
                      show={badEventModalShow}
                      onHide={handleBadEventModalClose}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Invalid slot length</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row>
                          <Col>
                            It is only possible to create openings of 30 minutes
                            to one hour.
                          </Col>
                        </Row>
                      </Modal.Body>
                    </Modal>
                  </div>
                </Row>
                <Row>
                  <div>
                    <Modal show={saveModalShow} onHide={handleSaveModalClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Rember to save!</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row>
                          <Col>
                            It looks like you are making some changes to your
                            appointments. Don't forget to click the "Save
                            schedule" button at the top right when you're done!
                          </Col>
                        </Row>
                      </Modal.Body>
                    </Modal>
                  </div>
                </Row>
                <Row>
                  <div>
                    <Modal
                      show={noEventsRepeatModal}
                      onHide={handleNoEventsRepeatModalClose}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Unable to repeat</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row>
                          <Col>
                            It looks like you have openings or meetings
                            scheduled for after this week. If you repeat your
                            events for this week, this will override the events
                            you have already set in the coming weeks.
                          </Col>
                        </Row>
                      </Modal.Body>
                    </Modal>
                  </div>
                </Row>
                <Row>
                  <div>
                    <Modal show={repeatModal} onHide={handleRepeatModalClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Repeat this week</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row>
                          <Col>
                            To repeat your calendar for the current week for a
                            given number of weeks into the future, select the
                            amount of weeks from the dropdown below, and then
                            click the Repeat button.
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col className="repeater-col" align="end">
                            <div className="me-3">Weeks: </div>
                            <Form className="me-3">
                              <Form.Group controlId="numberDropdown">
                                <Form.Control
                                  onChange={(e) => handleWeekDropDown(e)}
                                  as="select"
                                >
                                  <option value={1}>1</option>
                                  <option value={2}>2</option>
                                  <option value={3}>3</option>
                                  <option value={4}>4</option>
                                  <option value={5}>5</option>
                                  <option value={6}>6</option>
                                  <option value={7}>7</option>
                                  <option value={8}>8</option>
                                  <option value={9}>9</option>
                                  <option value={10}>10</option>
                                  <option value={11}>11</option>
                                  <option value={12}>12</option>
                                </Form.Control>
                              </Form.Group>
                            </Form>

                            <button
                              onClick={repeat}
                              className="lcn-button-orange"
                            >
                              Repeat
                            </button>
                          </Col>
                        </Row>
                      </Modal.Body>
                    </Modal>
                  </div>
                </Row>
                {/* <Row className="mt-5">
                <button onClick={saveOpenings} className="lcn-button-brown">
                {formSavedButtonStatus ? "Save Schedule" : "Saving..."}
                </button>
              </Row> */}
              </Row>

              {/* End of Reece Attempt */}
            </Col>
          </Row>
        </Col>
      )}

      {!userInfo ? (
        <LawyerValidator />
      ) : (
        userInfo?.profile === 0 && <LawyerValidator />
      )}
    </>
  );
}

export default LawyerSchedule;
