import { PUSH_LINK_TO_GLOBAL_STATE } from "../constants/linkConstants";

export const linkReducer = (state = { link: "" }, action) => {
  switch (action.type) {
    case PUSH_LINK_TO_GLOBAL_STATE:
      return { link: action.payload };

    default:
      return state;
  }
};
