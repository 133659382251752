/* eslint-disable */

import React from "react";
import lottie from "lottie-web";
import bulb from "./bulb-lottie-lacoona.json";
import { useEffect } from "react";

function BulbLoT(props) {
  const aniName = props.aniName;

  const animationContainer = React.createRef();

  useEffect(() => {
    // eslint-disable-next-line
      lottie.loadAnimation({
      name: aniName,
      container: animationContainer.current,
      animationData: bulb,
      autoplay: false,
      loop: false,
    });
      // eslint-disable-next-line
  }, []);

  return <div className={aniName} ref={animationContainer}></div>;
}

export default BulbLoT;
