import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Row, Col, Form, Modal, Dropdown } from "react-bootstrap";
import DashHeader from "./DashHeader";
import axios from "axios";
import LawyerLoT from "../lottie-files/LawyerLoT";
import lottie from "lottie-web";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import PasswordChecklist from "react-password-checklist";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import "react-big-calendar/lib/css/react-big-calendar.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import ToolTip from "./ToolTip";
import Loader from "./Loader";

function FindALawyer() {
  // FAL functionality:
  // User fills in the form in formstep 1 - 3
  // Clicking "submit" on formStep 3 runs queryLawyersOnClick, which increments formStep and sets state "queryLawyers" to true
  // This triggers Query lawyers useEffect, as it has queryLawyers state as a dependency. This useEffect sends a get request to api,
  // which returns all lawyers. Then it runs function filterLawyersBySpecialization on the lawyers, and sets allLawyers state to the result.
  // JSX maps allLawyers into formStep 4.
  // When user clicks "Select" button on a lawyer, this runs fillCalendarWithEvents function. This function sets selectedLawyer state to given lawyer
  // and sets lawyerSelected state to its opposite boolean. This triggers Populate calendar useEffect, which checks if the lawyer has appointments,
  // and if so filters them by opening and date to get openings between now and two weeks time. Then it sets myEvents state to the filtered events.
  // If the lawyer does not have any events, this useEffect sets myEvents to an empty array. Either way this useEffect also sets checkOpenings state to its opposite boolean.
  // checkOpenins state change triggers Check openings useEffect, which sets availabilityButton state to the given lawyer's id. This shows some JSX for that lawyer that deals with
  // showing either the View Openings button or the "no slots available" notice.  Check openings useEffect also looks if any events in myEvents are
  // openings and sets lawyerHasOpenings to true, which determines whether to show View Openings button or the "no slots available" notice.
  // When user clicks View Openings button this sets formStep to 5, which shows the calendar.
  // When user clicks on an opening, this triggers handleSelectEvent function which runs setModalShow(true) setSelectedEvent(event) setStartTimeState(new Date(event.start));
  // This triggers the modal with the timePicker component in it, where the user chooses the duration of the meeting. When they select the "Reserve button",  this runs reserveSelectedEvent
  // function which splits the given opening into two openings and a reservation, sets myEvents to the updated events, and sets selectedEvent to the reservation. It also sets formStep to 6
  // where the user confirms the booking and redirects to payfast. The proceed button triggers the payfast form to send. This form is represented in html, but it also is associated with payfast param array
  // which gets converted into MD5Signature, a const that is inserted into the html form that payfast uses to check that the form has not been tampered with

  const [formStep, setFormStep] = useState(1);
  const [allLawyers, setAllLawyers] = useState();
  const [selectedLawyer, setSelectedLawyer] = useState();
  const { userInfo } = useSelector((state) => state.userLogin);
  const [queryLawyers, setQueryLawyers] = useState(false);
  const [selectedStartTime, setSelectedStartTime] = useState("12:00");
  const [selectedEndTime, setSelectedEndTime] = useState("12:00");

  const formik = useFormik({
    initialValues: {
      formType: "find_a_lawyer",
      caseType: "",
      name: "",
      communicationType: "",
      emailAddress: "",
      confirmEmailAddress: "",
      cellNumber: "",
      province: "National",
      timeConstraint: "",
      date: new Date(),
      lawAreas: [],
      budget: "",
      concernType: "",
      companyName: "",
      scenario: "",
      traits: [],
      virtualAdvice: "",
    },
    onSubmit: (values) => {
      cleanLawAreaArray();
      cleantraitsArray();
      // console.log("values", values);

      setFormStep((formStep) => formStep + 1);
      // try {
      //   // eslint-disable-next-line
      //   const resp = axios.post(
      //     "https://hook.eu1.make.com/1sihlowqf5teflsnjes3jx1stjmskh9n",
      //     { values }
      //   );
      //   console.log("Response data received");
      // } catch (error) {
      //   console.log("An error has occured");
      // }
    },
    validationSchema: Yup.object({
      name: Yup.string().required(
        "We will need a name and surname to connect you with a lawyer"
      ),
      emailAddress: Yup.string()
        .email("Please enter a valid email address")
        .required("We need a valid email address to connect you with a lawyer"),
      scenario: Yup.string().required(
        "Please complete the description of your scenario for us to find you the right lawyer."
      ),
    }),
  });

  function cleantraitsArray() {
    const traits = [];
    for (let i = 0; i < formik.values.traits.length; i++) {
      traits.push(formik.values.traits[i].value);
    }
    formik.values.traits = traits;
    // console.log(traits);
  }
  function cleanLawAreaArray() {
    const lawAreas = [];
    for (let i = 0; i < formik.values.lawAreas.length; i++) {
      lawAreas.push(formik.values.lawAreas[i].value);
    }
    formik.values.lawAreas = lawAreas;
    // console.log(lawAreas);
  }
  function setNextFormStep() {
    setFormStep((formStep) => formStep + 1);
  }

  function setPreviousFormStep() {
    setFormStep((formStep) => formStep - 1);
  }

  function queryLawyersOnClick() {
    setFormStep(4);
    setQueryLawyers(true);
  }

  // function for filtering lawyers by specialization
  function filterLawyersBySpecialization(objects, filterArray) {
    return objects.filter((obj) => {
      // Check if any specialization in obj matches with the filterArray
      return obj.specialization.some((spec) => filterArray.includes(spec));
    });
  }

  // Query lawyers useEffect
  useEffect(() => {
    if (queryLawyers) {
      axios
        .get("/api/users/query-lawyers/")
        .then((response) => {
          console.log("response", response);
          cleanLawAreaArray();
          // console.log("formik", formik.values.lawAreas);
          const lawyers = response.data;
          const filteredLawyers = filterLawyersBySpecialization(
            lawyers,
            formik.values.lawAreas
          );
          // console.log("filteredLawyers", filteredLawyers);

          setAllLawyers(filteredLawyers);
          setQueryLawyers(false);
        })
        .catch((error) => console.log("error", error));
    }
  }, [queryLawyers]);

  console.log("alllawyers", allLawyers);
  /*  function preventSubmit(e) {
    e.preventDefault();
  } */

  /* Multi-Select Functions and Variables */
  const characteristics = [
    {
      label: "Compassionate",
      value: "Compassionate",
    },
    {
      label: "Passionate",
      value: "Passionate",
    },
    {
      label: "Aggressive",
      value: "Aggressive",
    },
    {
      label: "Soft-spoken",
      value: "Soft-spoken",
    },
    {
      label: "A strong communicator",
      value: "A strong communicator",
    },
    {
      label: "Widely knowledgable",
      value: "Widely knowledgable",
    },
    {
      label: "Creative",
      value: "Creative",
    },
  ];

  const lawAreas = [
    { label: "Family Law", value: "Family Law" },
    {
      label: "Arbitration; Conciliation and Mediation",
      value: "Arbitration; Conciliation and Mediation",
    },
    {
      label: "Company Law (Corporate Governance)",

      value: "Company Law (Corporate Governance)",
    },
    {
      label: "Labour Law",

      value: "Labour Law",
    },
    { label: "Debt Collection", value: "Debt Collection" },
    {
      label: "Estate Management",

      value: "Estate Management",
    },
    {
      label: "Property Law",

      value: "Property Law",
    },
    { label: "Criminal Law", value: "Criminal Law" },
    { label: "SME Law", value: "SME Law" },
    { label: "Website Management", value: "Website Management" },
    { label: "IP and Brand", value: "IP and Brand" },
    { label: "Tax and Financial Law", value: "Tax and Financial Law" },
    { label: "Environmental Law", value: "Environmental Law" },
    { label: "Funding and Financing Law", value: "Funding and Financing Law" },
    { label: "Consumer Law", value: "Consumer Law" },
    { label: "Data Management", value: "Data Management" },
    {
      label: "Non-Profit and Social Enterprise Law",
      value: "Non-Profit and Social Enterprise Law",
    },
  ];

  // Calendar functions
  const locales = {
    "en-US": enUS,
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const myEventsList = [];
  const [myEvents, setMyEvents] = useState(myEventsList);
  const [selectedEvent, setSelectedEvent] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [eventsLength, setEventsLength] = useState(0);
  const [eventTitle, setEventTitle] = useState("");
  const [reservationMade, setReservationMade] = useState(false);
  const [lawyerSelected, setLawyerSelected] = useState(false);
  const [goodPass, setGoodPass] = useState(true);
  const [message, setMessage] = useState("");
  const [availabilityButton, setAvailabilityButton] = useState();
  const [lawyerHasOpenings, setLawyerHasOpenings] = useState(false);
  const [checkOpenings, setCheckOpenings] = useState(false);
  const [warningModalShow, setWarningModalShow] = useState(false);
  const [startTimeState, setStartTimeState] = useState();
  const [endTimeState, setEndTimeState] = useState();

  // modal popup on select event
  const handleSelectEvent = useCallback((event) => {
    setModalShow(true);
    setSelectedEvent(event);
    setStartTimeState(new Date(event.start));

    // setSelectedStartTime();
    // window.alert(event.title);
  }, []);

  // close modal function
  function handleModalClose() {
    setModalShow(false);
  }

  // creates a startTimeState of a datetime object
  function setBookingStartTime(time) {
    const startDate = new Date(selectedEvent.start);
    const changedStartDate = new Date(selectedEvent.start);
    const endDate = new Date(selectedEvent.end);
    setSelectedStartTime(time);

    const [newStartHours, newStartMinutes] = time.split(":").map(Number);
    changedStartDate.setHours(newStartHours);
    changedStartDate.setMinutes(newStartMinutes);
    const newStartDate = new Date(changedStartDate);
    setStartTimeState(newStartDate);

    // if (newStartDate > startDate && newStartDate < endDate) {
    //   setStartTimeState(newStartDate);
    // } else {
    //   setWarningModalShow(true);
    // }
  }

  const setBookingEndTime = (event) => {
    const meetingLength = event.target.value;
    const startTimeOfBooking = startTimeState;
    const endTimeOfOpening = new Date(selectedEvent.end);
    // const endTimeOfBooking = startTimeOfBooking.getTime() + 30 * 60000;
    // setEndTimeState(new Date(endTimeOfBooking));

    if (meetingLength === "30") {
      const endTimeOfBooking = startTimeOfBooking.getTime() + 30 * 60000;
      setEndTimeState(new Date(endTimeOfBooking));
    } else {
      const endTimeOfBooking = startTimeOfBooking.getTime() + 60 * 60000;
      setEndTimeState(new Date(endTimeOfBooking));
    }
    // console.log("event.target.value", event.target.value);
    // setSelectedMeetingLength(event.target.value);
  };

  function reserveSelectedEvent() {
    const title = "Booking";
    const randomNumber = Math.floor(Math.random() * 90000) + 10000;
    const status = "Reservation";

    // set up a start time for the new event by changing the starting time of the current selected opening
    // const startDate = new Date(selectedEvent.start);
    // const [newStartHours, newStartMinutes] = selectedStartTime
    //   .split(":")
    //   .map(Number);
    // startDate.setHours(newStartHours);
    // startDate.setMinutes(newStartMinutes);
    // const newStartDate = new Date(startDate);
    // console.log("newStartDate", newStartDate);
    // console.log("selectedEvent", selectedEvent);

    // const endDate = new Date(selectedEvent.end);
    // const [newEndHours, newEndMinutes] = selectedEndTime.split(":").map(Number);
    // endDate.setHours(newEndHours);
    // endDate.setMinutes(newEndMinutes);
    // const newEndDate = new Date(endDate);
    // console.log("newEndDate", newEndDate);

    // whole: 9-11 am
    // booking: 10-10:30
    const newBooking = {
      ...selectedEvent,
      start: startTimeState,
      end: endTimeState,
      title: "Booking",
      status: "Reservation",
      lawyerEmail: selectedLawyer?.email,
      client: formik.values.name,
      clientEmail: formik.values.emailAddress,
      scenario: formik.values.scenario,
    };

    let preBookingEvent = {};
    let postBookingEvent = {};

    if (newBooking.start > selectedEvent.start) {
      preBookingEvent = {
        ...selectedEvent,
        end: startTimeState,
        id: Math.floor(Math.random() * 90000) + 10000,
      };
    }

    if (newBooking.end < selectedEvent.end) {
      postBookingEvent = {
        ...selectedEvent,
        start: endTimeState,
        id: Math.floor(Math.random() * 90000) + 10000,
      };
    }

    // console.log("preBookingEvent", preBookingEvent);
    // console.log("postBookingEvent", postBookingEvent);

    const updatedEvents = myEvents.filter(
      (item) => item.id !== selectedEvent.id
    );
    updatedEvents.push(newBooking);
    if (Object.keys(preBookingEvent).length > 0) {
      updatedEvents.push(preBookingEvent);
    }
    if (Object.keys(postBookingEvent).length > 0) {
      updatedEvents.push(postBookingEvent);
    }
    // console.log("start-end", newBooking.end - newBooking.start);
    // 3600000
    if (newBooking.end - newBooking.start <= 3600000) {
      if (
        newBooking.start >= selectedEvent.start &&
        newBooking.end <= selectedEvent.end
      ) {
        setMyEvents(updatedEvents);
        setSelectedEvent(newBooking);
        setFormStep(6);
      } else {
        setWarningModalShow(true);
      }
    } else {
      setWarningModalShow(true);
    }

    setModalShow(false);
  }
  // console.log("startTimeState", startTimeState);
  // console.log("endTimeState", endTimeState);

  // Populate calendar useEffect.
  useEffect(() => {
    // console.log("lawyer.schedule", lawyer.schedule);
    // console.log("lawyer", lawyer);
    console.log("selected lawyer in use effect", selectedLawyer);
    if (
      selectedLawyer &&
      selectedLawyer?.appointments &&
      selectedLawyer?.appointments.data.length > 0
    ) {
      console.log("made it past if");

      const appointmentsArray = JSON.parse(
        selectedLawyer?.appointments.data.replace(/'/g, '"')
      );
      // console.log("JSON.parse", arr);
      // RC Notes: If event date is greater than today map else lass && Dealing with events that are old
      //RC Notes: Bookings if event.title =! opening don't map
      // const convertedEvents = appointmentsArray.map((event) => ({
      //   ...event,
      //   start: new Date(event.start),
      //   end: new Date(event.end),
      // }));
      const filteredEventsOpening = appointmentsArray.filter(
        (event) => event.title === "Opening"
      );
      const currentDate = new Date();
      const twoWeeksFromNow = new Date();
      twoWeeksFromNow.setDate(currentDate.getDate() + 14);

      const filteredEventsPrevious = filteredEventsOpening.filter((event) => {
        const eventDate = new Date(event.start);
        return eventDate >= currentDate && eventDate <= twoWeeksFromNow;
      });
      const convertedEvents = filteredEventsPrevious.map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
      }));

      setMyEvents((old) => convertedEvents);
      setCheckOpenings((old) => !old);
      // setFormStep(5);
    } else {
      setCheckOpenings((old) => !old);
      setMyEvents([]);
    }
  }, [lawyerSelected]);

  function fillCalendarWithEvents(lawyer) {
    setSelectedLawyer(lawyer);
    setLawyerSelected((old) => !old);

    console.log("lawyer in function", lawyer);
  }

  // Check openings useEffect
  useEffect(() => {
    // console.log("myEvents in useeffect", myEvents);
    // console.log("myEvents in useeffect legnth", myEvents.length);
    // console.log("selectedLawyer in useeffect", selectedLawyer);
    setAvailabilityButton(selectedLawyer?.id);

    if (myEvents.length > 0) {
      for (let meeting of myEvents) {
        if (meeting.status === "Opening") {
          setLawyerHasOpenings(true);
        }
      }
    } else {
      setLawyerHasOpenings(false);
    }
  }, [checkOpenings]);

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(2015, 3, 12),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  );

  // Reserve meeting on the backend
  function updateMeetingsWithReservation() {
    setMyEvents((prevItems) =>
      prevItems.map((item) =>
        item.id === selectedEvent.id
          ? {
              ...item,
              client: selectedEvent.client,
              clientEmail: selectedEvent.clientEmail,
              scenario: selectedEvent.scenario,
              status: selectedEvent.status,
              title: selectedEvent.title,
            }
          : item
      )
    );
    setReservationMade(true);
  }
  useEffect(() => {
    if (reservationMade) {
      const eventsString = JSON.stringify(myEvents);

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      axios
        .post(
          "/api/users/reserve-pending-meeting/",
          { meetings: eventsString, lawyer: selectedLawyer?.email },
          config
        )
        .then((response) => {
          console.log("response", response);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [reservationMade]);

  // populate timeslots on selected meeting modal to selectedEvent start and end

  useEffect(() => {
    setSelectedStartTime(
      selectedEvent?.start.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
    );
    setSelectedEndTime(
      selectedEvent?.end.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
    );
  }, [selectedEvent]);

  // job board functions
  function addToJobBoard() {
    setFormStep(7);
    cleantraitsArray();
    axios
      .post("/api/cases/add-case/", formik.values)
      .then(function (response) {
        console.log("response");
      })
      .catch(function (error) {
        console.log("error");
      });
  }

  // payfast param array

  const params = new URLSearchParams({
    merchant_id: "15816925",
    merchant_key: "q1tfaqdlsu5nc",
    return_url: "https://www.lacoona.legal/checkout/success",
    cancel_url: "https://www.lacoona.legal/checkout/cancel",
    notify_url:
      "https://www.lacoona.legal/api/payments/consultation-payment-notify/",
    email_address: selectedLawyer?.email,
    m_payment_id: selectedEvent?.id,
    amount:
      (selectedEvent?.end - selectedEvent?.start) / 1000 <= 1800
        ? selectedLawyer?.halfHourlyRate * 1.1 -
            selectedLawyer?.halfHourlyRate <=
          100
          ? selectedLawyer?.halfHourlyRate + 100
          : Math.round(selectedLawyer?.halfHourlyRate * 1.1)
        : selectedLawyer?.hourlyRate * 1.1 - selectedLawyer?.hourlyRate <= 100
        ? selectedLawyer?.hourlyRate + 100
        : Math.round(selectedLawyer?.hourlyRate * 1.1),
    item_name: "Legal Consultation",
    custom_str1: selectedLawyer?.name,
    custom_str2: formik.values.name,
    custom_str3: formik.values.emailAddress,
    custom_str4: formik.values.scenario,
    custom_str5: `${selectedEvent?.start}end${selectedEvent?.end}`,
    passphrase: "LegalFundi.payfast-101-security",
  });

  // const params = new URLSearchParams({
  //   merchant_id: "10029240",
  //   merchant_key: "h38qwx8cmdzqq",
  //   return_url: "https://21e7-185-203-122-70.ngrok-free.app/checkout/success",
  //   cancel_url: "https://21e7-185-203-122-70.ngrok-free.app/checkout/cancel",
  //   notify_url:
  //     "https://21e7-185-203-122-70.ngrok-free.app/api/payments/consultation-payment-notify/",
  //   m_payment_id: 23456,
  //   amount: 250,
  //   item_name: "Legal Consultation",
  //   custom_str1: "lawyer@test.com",
  //   custom_str2: "d boy",
  //   custom_str3: "dan+1@test.com",
  //   custom_str4: "i need legal advice",
  //   custom_str5: "larry lawyer",
  //   passphrase: "mys4ltp4ss.01",
  // });

  // email validation
  function checkEmail() {
    if (formik.values.emailAddress === formik.values.confirmEmailAddress) {
      if (!formik.values.emailAddress && !formik.values.confirmEmailAddress) {
        setMessage("Please make sure you include your email address");
      } else {
        setFormStep(3);
        setMessage("");
      }
    } else {
      setMessage("Please make sure your email is correct");
    }
  }

  // console.log("params.toString(", params.toString());

  // Create an MD5 signature of it.
  var md5 = require("md5");

  const MD5Signature = md5(params.toString());

  // console.log("allLawyers", allLawyers);
  console.log("selectedLawyer", selectedLawyer);
  // console.log("selectedEvent", selectedEvent);
  // console.log("eventTitle", eventTitle);
  // console.log("formik.values", formik.values);
  console.log("myEvents", myEvents);
  // console.log("regex", `${selectedEvent?.start}  ${selectedEvent?.end}`);
  // console.log("availibilityButton", availabilityButton);

  let specialisations_len = 0;
  console.log("formik", formik.values);

  return (
    <Col className="dashboard-scrollable-container" lg={9}>
      <Helmet>
        <title>Find A Lawyer</title>
        <meta
          name="description"
          content="Lacoona Legal helps you source a Legal Practitioner that suits your needs."
        />
        <link rel="canonical" href="/dashboard/find-a-lawyer" />
      </Helmet>
      <DashHeader pageTitle={"Find a Lawyer"} />
      <Row className="dashboard-content-container">
        <Row className="find-lawyer-hero px-5">
          <Col lg={8} className="">
            <Row>
              <h1 className="serious-font">Find a lawyer</h1>
            </Row>
            <Row>
              <p>
                To find a lawyer please follow our easy steps. We'll match you
                to a lawyer who can help you resolve your case.
              </p>
            </Row>
          </Col>
          <Col
            lg={4}
            className="find-a-lawyer-image"
            align="center"
            onMouseEnter={() => lottie.play("ll-fal-scales")}
            onMouseLeave={() => lottie.stop("ll-fal-scales")}
          >
            <LawyerLoT aniName="ll-fal-scales" />
          </Col>
          <Col></Col>
        </Row>

        {formStep === 1 && (
          <div className="find-a-lawyer-steps-tab">
            <img
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Lacoona-logo-medallion.svg"
              className="find-a-lawyer-tab-logo"
              alt="menu options"
            ></img>
            <Col
              // onClick={() => {
              //   setFormStep(2);
              // }}
              className="find-a-lawyer-circle-tab"
            >
              {" "}
            </Col>
            <Col
              // onClick={() => {
              //   setFormStep(3);
              // }}
              className="find-a-lawyer-circle-tab"
            >
              {" "}
            </Col>
          </div>
        )}

        {formStep === 2 && (
          <div className="find-a-lawyer-steps-tab">
            <Col
              // onClick={() => {
              //   setFormStep(1);
              // }}
              className="find-a-lawyer-circle-tab"
            >
              {" "}
            </Col>
            <img
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Lacoona-logo-medallion.svg"
              className="find-a-lawyer-tab-logo"
              alt="menu options"
            ></img>
            <Col
              // onClick={() => {
              //   setFormStep(3);
              // }}
              className="find-a-lawyer-circle-tab"
            >
              {" "}
            </Col>
          </div>
        )}

        {formStep === 3 && (
          <div className="find-a-lawyer-steps-tab">
            <Col
              // onClick={() => {
              //   setFormStep(1);
              // }}
              className="find-a-lawyer-circle-tab"
            >
              {" "}
            </Col>
            <Col
              // onClick={() => {
              //   setFormStep(2);
              // }}
              className="find-a-lawyer-circle-tab"
            >
              {" "}
            </Col>
            <img
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Lacoona-logo-medallion.svg"
              className="find-a-lawyer-tab-logo"
              alt="menu options"
            ></img>
          </div>
        )}
        <Row className="find-a-lawyer-form-container">
          {formStep === 1 && (
            <Col className="form-step" lg={8}>
              <Row>
                <h4 className="form-step-heading">Case Classification</h4>
              </Row>
              <Form>
                <div className="find-a-lawyer-form-question">
                  <p className="col-5 find-a-lawyer-question-text">
                    Assistance Type
                  </p>
                  <Form.Select
                    required
                    onChange={formik.handleChange}
                    name="caseType"
                    aria-label="Default select example"
                    className="mb-3"
                    defaultValue={"DEFAULT"}
                  >
                    <option value="DEFAULT" disabled>
                      Select...
                    </option>

                    <option value="Legal Consultation">
                      Legal Consultation
                    </option>
                    <option value="Document Review">Document Review</option>
                  </Form.Select>
                </div>

                {/* <div className="find-a-lawyer-form-question">
                  <p className="col-5 find-a-lawyer-question-text">
                    Do you have a time contraint?
                  </p>
                  <Form.Select
                    onChange={formik.handleChange}
                    name="date"
                    aria-label="Default select example"
                    className="mb-3"
                    defaultValue={"DEFAULT"}
                  >
                    <option value="DEFAULT" disabled>
                      Select...
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Form.Select>
                </div>
                {formik.values.date === "Yes" ? (
                  <div className="find-a-lawyer-form-question">
                    <p className="col-5 find-a-lawyer-question-text">
                      When do you need assistance by?
                    </p>
                    <Form.Control
                      type="date"
                      className="mb-3"
                      onChange={formik.handleChange}
                      name="timeConstraint"
                      value={formik.values.timeConstraint}
                    />
                  </div>
                ) : null} */}

                <div className="find-a-lawyer-form-question">
                  <p className="col-5 find-a-lawyer-question-text">
                    Is the assistance for you or for your company?
                  </p>
                  <Form.Select
                    onChange={formik.handleChange}
                    name="concernType"
                    aria-label="Default select example"
                    className="mb-3"
                    defaultValue={"DEFAULT"}
                  >
                    <option value="DEFAULT" disabled>
                      Select...
                    </option>
                    <option value="Myself">Myself</option>
                    <option value="A company">A company</option>
                  </Form.Select>
                </div>
                {formik.values.concernType === "A company" ? (
                  <div className="find-a-lawyer-form-question">
                    <p className="col-5 find-a-lawyer-question-text">
                      What is the name of the company?
                    </p>
                    <Form.Control
                      type="input"
                      className="mb-3"
                      onChange={formik.handleChange}
                      placeholder="Company Name"
                      name="companyName"
                      value={formik.values.companyName}
                    />
                  </div>
                ) : null}

                <div>
                  <Row>
                    <div className="find-a-lawyer-form-question">
                      <p className="col-5 find-a-lawyer-question-text">
                        Are you able to pay for legal services? (Typical
                        consultation costs range from R500-R1500.)
                      </p>
                      <Form.Select
                        onChange={formik.handleChange}
                        name="budget"
                        aria-label="Default select example"
                        className="mb-3"
                        defaultValue={"DEFAULT"}
                      >
                        <option value="DEFAULT" disabled>
                          Select...
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Form.Select>
                    </div>
                  </Row>

                  {/* {formik.values.budget === "Yes" && (
                    <div className="text-center">
                      <p>
                        Typical consultation costs range from R500-R1000,
                        however because multiple lawyers may be willing to
                        assist with your matter the fee could be lower.
                      </p>
                    </div>
                  )} */}

                  {formik.values.budget === "No" && (
                    <div className="lawyer-suggestions">
                      <div className="lawyer-suggestion">
                        <p>You can find free legal assistance at</p>

                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            window.open("https://legalfundi.com", "_blank");
                          }}
                          className="suggestion-button"
                        >
                          Legal Fundi
                        </button>
                      </div>
                      <div className="lawyer-suggestion">
                        <p>Or you can ask Jinx, our legal AI </p>

                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              "https://www.lacoona.legal/dashboard/ai-assistant",
                              "_blank"
                            );
                          }}
                          className="suggestion-button"
                        >
                          Ask Jinx
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                {formik.values.budget != "No" && (
                  <>
                    <p> Describe the scenario you need assistance with. </p>

                    <Form.Control
                      type="input"
                      className="mb-3"
                      onChange={formik.handleChange}
                      name="scenario"
                      value={formik.values.scenario}
                      placeholder="What happened, where did it happen, and why did it happen?"
                    />
                    <p className="text-secondary ">
                      *Please do not make mention of any specific people or
                      companies as this information will be made available to
                      the relevant attorneys in our panel.
                    </p>
                  </>
                )}

                <Row></Row>
                {/* <Row>
                  <div className="find-a-lawyer-form-question">
                    <p className = "col-5">Upload relevant files here</p>
                    <Form.Control
                      type="file"
                      className="mb-3"
                      onChange={formik.handleChange}
                      name="fileUpload"
                    />
                  </div>
                </Row> */}
              </Form>
              {formik.values.budget != "No" && (
                <Row>
                  <Col className="form-step-next-button-container">
                    <button
                      className="lcn-button-orange py-2 px-4"
                      onClick={setNextFormStep}
                    >
                      Next
                    </button>
                  </Col>
                </Row>
              )}
              {/* <Row>
                <button onClick={addToJobBoard}>Add matter to job board</button>
              </Row> */}
            </Col>
          )}

          {formStep === 2 && (
            <Col className="form-step" lg={8}>
              <Row>
                <h4 className="form-step-heading">Contact Information</h4>
              </Row>
              <Form>
                <div className="find-a-lawyer-form-question">
                  <p className="col-5">Full Name</p>
                  <Form.Control
                    type="input"
                    className="mb-3"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    placeholder="Name and Surname"
                    name="name"
                  />
                </div>
                {formik.errors.name ? (
                  <p className="text-danger text-center">
                    {formik.errors.name}
                  </p>
                ) : null}

                <div className="find-a-lawyer-form-question">
                  <p className="col-5">Email Address</p>
                  <Form.Control
                    type="input"
                    className="mb-3"
                    onChange={formik.handleChange}
                    placeholder="Email Address"
                    name="emailAddress"
                    value={formik.values.emailAddress}
                  />
                </div>
                <div className="find-a-lawyer-form-question">
                  <p className="col-5">Email Address</p>
                  <Form.Control
                    type="input"
                    className="mb-3"
                    onChange={formik.handleChange}
                    placeholder="Confirm Email Address"
                    name="confirmEmailAddress"
                    value={formik.values.confirmEmailAddress}
                  />
                </div>

                <Row>
                  <div className="find-a-lawyer-form-question">
                    <p className="col-5">Contact Number</p>
                    <Form.Control
                      type="input"
                      className="mb-3"
                      onChange={formik.handleChange}
                      name="cellNumber"
                      placeholder="Contact Number"
                      value={formik.values.cellNumber}
                    />
                  </div>
                </Row>

                {/* <div className="find-a-lawyer-form-question">
                  <p className="col-5">
                    Are you willing to receive online assistance?
                  </p>
                  <Form.Select
                    onChange={formik.handleChange}
                    aria-label="Default select example"
                    className="mb-3"
                    name="virtualAdvice"
                    defaultValue={"DEFAULT"}
                  >
                    <option value="DEFAULT" disabled>
                      Select...
                    </option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Form.Select>
                </div> */}
                {formik.values.virtualAdvice === "No" && (
                  <div className="find-a-lawyer-form-question">
                    <p className="col-5">Where are you based?</p>
                    <Form.Select
                      onChange={formik.handleChange}
                      name="province"
                      aria-label="Default select example"
                      className="mb-3"
                      defaultValue={"DEFAULT"}
                      value={formik.values.province}
                    >
                      <option value="DEFAULT">Choose a province...</option>
                      <option value="Gauteng">Gauteng</option>
                      <option value="Western Cape">Western Cape</option>
                      <option value="Kwa-Zulu Natal">Kwa-Zulu Natal</option>
                      <option value="Eastern Cape">Eastern Cape</option>
                      <option value="Mpumalanga">Mpumalanga</option>
                      <option value="North West">North West</option>
                      <option value="Limpopo">Limpopo</option>
                      <option value="Free State">Free State</option>
                      <option value="Northern Cape">Northern Cape</option>
                    </Form.Select>
                  </div>
                )}
              </Form>

              <Row>
                <Col>
                  <button
                    className="lcn-button-orange py-2 px-4"
                    onClick={setPreviousFormStep}
                  >
                    Back
                  </button>
                </Col>
                <Col className="form-step-next-button-container">
                  <button
                    className="lcn-button-orange py-2 px-4"
                    onClick={checkEmail}
                  >
                    Next
                  </button>
                </Col>
              </Row>
              <Row align="center" className="mt-4">
                <span className="brown-icon">{message && message}</span>
              </Row>
            </Col>
          )}

          {formStep === 3 && (
            <Col className="form-step" lg={8}>
              <Row>
                <h4 className="form-step-heading">Lawyer Information</h4>
              </Row>
              <Form>
                <Row className="find-a-lawyer-form-question">
                  <Col className="col-5">
                    What characteristics would you like your lawyer to have?
                  </Col>
                  <Col className="find-a-lawyer-form p-3">
                    <Select
                      defaultValue={"Select..."}
                      className="multi-select-form-step"
                      isMulti
                      name="traits"
                      id="traits"
                      options={characteristics}
                      onChange={(value) =>
                        formik.setFieldValue("traits", value)
                      }
                      type="text"
                    />
                  </Col>
                </Row>

                <Row className="find-a-lawyer-form-question">
                  <Col className="col-5">
                    What area would you like your lawyer to be specialised in?
                  </Col>
                  <Col>
                    <Select
                      defaultValue={"Select..."}
                      isMulti
                      name="lawAreas"
                      className="find-a-lawyer-form p-1"
                      id="lawAreas"
                      options={lawAreas}
                      onChange={(value) =>
                        formik.setFieldValue("lawAreas", value)
                      }
                      type="text"
                    />
                  </Col>
                </Row>

                {formik.errors.name ? (
                  <p
                    role="button"
                    onClick={() => {
                      setFormStep(2);
                    }}
                    className="text-danger"
                  >
                    {formik.errors.name}
                  </p>
                ) : null}
                {formik.errors.emailAddress ? (
                  <p
                    role="button"
                    onClick={() => {
                      setFormStep(2);
                    }}
                    className="text-danger"
                  >
                    {formik.errors.emailAddress}
                  </p>
                ) : null}
                {formik.errors.scenario ? (
                  <p
                    role="button"
                    onClick={() => {
                      setFormStep(1);
                    }}
                    className="text-danger"
                  >
                    {formik.errors.scenario}
                  </p>
                ) : null}
              </Form>
              <Row>
                <Col>
                  <button
                    className="lcn-button-orange py-2 px-4"
                    onClick={setPreviousFormStep}
                  >
                    Back
                  </button>
                </Col>
                <Col className="form-step-next-button-container">
                  <button
                    className="lcn-button-orange py-2 px-4"
                    // onClick={formik.submitForm}
                    onClick={queryLawyersOnClick}
                    // onClick={() => setFormStep(4)}
                    type="submit"
                  >
                    Submit
                  </button>
                </Col>
              </Row>
            </Col>
          )}

          {formStep === 4 && (
            <Col className="form-step" lg={10}>
              {queryLawyers ? (
                <Row>
                  <Col align="center">
                    <Loader />
                  </Col>
                </Row>
              ) : (
                <div>
                  <Row>
                    <h3 className="form-step-heading serious-font">
                      Select a lawyer
                    </h3>
                    {/* <h6 className="form-step-heading">From Lawyers that are specialised in  <strong>Labour Law</strong></h6> */}
                  </Row>
                  <Row align="center" className="ll-card-interact legal-product-container p-4">
                    <p>
                      Can't find a lawyer from this list? Click the button below
                      to post your matter to a job board.
                    </p>
                    <button className="lcn-button-brown py-2" onClick={addToJobBoard}>
                      Add matter to job board
                    </button>
                  </Row>
                  <Row className="mt-3 lawyer-list-parent">
                    {allLawyers.length > 0 ? (
                      allLawyers?.map((lawyer, key) => (
                        <Col key={key} className="lawyer-card me-1">
                          <Row>
                            <h4 className="serious-font ll-lawyer-name-fal">
                              {lawyer.name}
                            </h4>
                            <hr />
                          </Row>
                          <Row>
                            <div className="Specilisation parent">
                              {/* {specialisations_len=lawyer?.specialization} */}
                              {(lawyer?.specialization).length >= 2 ? (
                                <ToolTip
                                  tooltip={lawyer?.specialization.map(
                                    (spec, key) =>
                                      key === lawyer?.specialization.length - 1
                                        ? spec
                                        : spec + ", "
                                  )}
                                  id="tooltip-lawyer-spec-comp"
                                  placement="bottom"
                                >
                                  <h6>
                                    {lawyer?.specialization[0]}
                                    {", "}
                                    {lawyer?.specialization[1]}
                                    <span className="p-1 ll-mobile-see-more">
                                      +
                                    </span>
                                  </h6>
                                </ToolTip>
                              ) : (
                                <h6>{lawyer?.specialization}</h6>
                              )}

                              <p>Specialisation</p>
                            </div>
                          </Row>
                          <Row>
                            <div>
                              <h6>
                                {lawyer.characteristics.map(
                                  (characteristic) => (
                                    <div>{characteristic}</div>
                                  )
                                )}
                              </h6>
                              <p>Characteristics</p>
                            </div>
                          </Row>
                          <hr />
                          <Row>
                            <div>
                              <h5>
                                R{" "}
                                {lawyer?.hourlyRate * 1.1 -
                                  lawyer?.hourlyRate <=
                                100
                                  ? lawyer?.hourlyRate + 100
                                  : Math.round(lawyer?.hourlyRate * 1.1)}
                              </h5>
                              <p>Hourly rate</p>
                            </div>
                          </Row>
                          <Row>
                            <div>
                              <h5>
                                R{" "}
                                {lawyer?.halfHourlyRate * 1.1 -
                                  lawyer?.halfHourlyRate <=
                                100
                                  ? lawyer?.halfHourlyRate + 100
                                  : Math.round(lawyer?.halfHourlyRate * 1.1)}
                              </h5>
                              <p>Half-hourly rate:</p>
                            </div>
                          </Row>
                          <Row>
                            <Col align="center">
                              <button
                                className="lcn-button-orange px-3 my-2"
                                onClick={() => fillCalendarWithEvents(lawyer)}
                              >
                                Select
                              </button>
                            </Col>
                          </Row>
                          {availabilityButton === lawyer?.id && (
                            <Row>
                              {lawyerHasOpenings && (
                                <Col align="center">
                                  <button
                                    onClick={() => setFormStep(5)}
                                    className="lcn-button-orange px-3 my-2"
                                  >
                                    View openings
                                  </button>
                                </Col>
                              )}
                              {!lawyerHasOpenings && (
                                <Col align="center">
                                  <div className="brown-icon">
                                    No slots available
                                  </div>
                                </Col>
                              )}
                            </Row>
                          )}
                        </Col>
                      ))
                    ) : (
                      <div>
                        <h5>
                          Unfortunately we don't have any lawyers who match the
                          specialisation you requested
                        </h5>
                        <button
                          className="lcn-button-orange"
                          onClick={() => setFormStep(3)}
                        >
                          Go back
                        </button>
                      </div>
                    )}
                  </Row>
                </div>
              )}
            </Col>
          )}

          {formStep === 5 && (
            <Col className="form-step" lg={10}>
              <Row>
                <Col>
                  <button
                    className="lcn-button-brown-no-padding py-1 px-3"
                    onClick={() => setFormStep(4)}
                  >
                    Back
                  </button>
                </Col>
                <Col>
                  <h4 className="form-step-heading">Select a slot</h4>
                </Col>
                <Col></Col>
              </Row>
              <hr />
              <Row>
                {" "}
                <Calendar
                  localizer={localizer}
                  events={myEvents}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 500 }}
                  onSelectEvent={handleSelectEvent}
                  // onSelectSlot={handleSelectSlot}
                  selectable
                  defaultView="week"
                  scrollToTime={scrollToTime}
                  views={["week"]}
                  // toolbar={false}
                />
              </Row>
              <Row>
                <Modal show={modalShow} onHide={handleModalClose}>
                  <Modal.Header closeButton>
                    <Modal.Title className="serious-font">
                      Reserve this slot
                    </Modal.Title>
                    {/* <Modal.Title>{selectedEvent?.title}</Modal.Title> */}
                  </Modal.Header>
                  <Modal.Body>
                    <Row className="lcn-dash-border m-2 rounded p-1">
                      <Form>
                        <Row>
                          <Col>Select a start time:</Col>
                        </Row>
                        <TimePicker
                          onChange={(time) => setBookingStartTime(time)}
                          value={selectedStartTime}
                          format="h:mm a"
                          disableClock={true}
                          minuteStep={30}
                          className="form-control"
                        />
                        <Form.Group controlId="meetLenDrop">
                          <Form.Select
                            required
                            onChange={(e) => setBookingEndTime(e)}
                            name="meetLenDropName"
                            aria-label="Default select example"
                            className="mb-3 mt-3"
                            defaultValue={"DEFAULT"}
                          >
                            <option value="DEFAULT" disabled>
                              Select a duration
                            </option>

                            <option value="30">30 minutes</option>
                            <option value="60">1 hour</option>
                          </Form.Select>
                        </Form.Group>
                        {/* <Button onClick={bookMeeting}>Submit</Button> */}
                      </Form>
                      {/* <Row>
                        <Col className="lcn-tbl-title">Lawyer:</Col>
                        <Col className="lcn-tbl-value">
                          {" "}
                          {selectedLawyer?.name}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="lcn-tbl-title">Case Type:</Col>
                        <Col className="lcn-tbl-value">
                          {formik.values.caseType}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="lcn-tbl-title">Scenario:</Col>
                        <Col className="lcn-tbl-value">
                          {" "}
                          {formik.values.scenario}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="lcn-tbl-title">Fee:</Col>
                        <Col className="lcn-tbl-value">
                          R
                          {(selectedEvent?.end - selectedEvent?.start) / 1000 <=
                          1800
                            ? selectedLawyer?.halfHourlyRate * 1.1 -
                                selectedLawyer?.halfHourlyRate <=
                              100
                              ? selectedLawyer?.halfHourlyRate + 100
                              : Math.round(selectedLawyer?.halfHourlyRate * 1.1)
                            : selectedLawyer?.hourlyRate * 1.1 -
                                selectedLawyer?.hourlyRate <=
                              100
                            ? selectedLawyer?.hourlyRate + 100
                            : Math.round(selectedLawyer?.hourlyRate * 1.1)}
                        </Col>
                      </Row> */}
                    </Row>

                    <Row className="mt-4">
                      <Col align="center">
                        <button
                          className="lcn-button-brown"
                          onClick={reserveSelectedEvent}
                        >
                          Reserve
                        </button>
                      </Col>
                    </Row>
                  </Modal.Body>
                </Modal>
              </Row>
              <div>
                <Modal
                  show={warningModalShow}
                  onHide={() => setWarningModalShow(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Invalid event times</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      It appears that your booking slot is invalid. Either you
                      have booked a slot longer than one hour, or your slot
                      falls outside of the parameters of the Lawyer's opening.
                    </p>
                  </Modal.Body>
                </Modal>
              </div>
            </Col>
          )}

          {formStep === 6 && (
            <Col
              align="center"
              className="form-step lcn-inline-checkout-card"
              lg={8}
            >
              <Row>
                <h4 className="form-step-heading serious-font">Checkout</h4>
              </Row>
              <Row align="left">
                <p className="form-step-heading">
                  Thank you for submitting your reservation. Please review the
                  meeting details below. After that, you can click Proceed, and
                  you will be redirected to the Payfast portal where you can
                  make the payment for your consultation. If the payment is
                  successful, the booking will be confirmed and you will recieve
                  an invitation for a Google meet event in your email.
                </p>
              </Row>
              <hr />
              <div className="lcn-meet-details-parent">
                <Row align="center" className="serious-font">
                  <h4>Meeting details</h4>
                </Row>
                <div className="lcn-dash-border m-2 rounded p-4">
                  <Row>
                    <Col className="lcn-tbl-title">Lawyer:</Col>
                    <Col className="lcn-tbl-value">{selectedLawyer?.name}</Col>
                  </Row>
                  <Row>
                    <Col className="lcn-tbl-title">Date: </Col>
                    <Col className="lcn-tbl-value">
                      {selectedEvent?.start.toLocaleString("en-US", {
                        day: "numeric",
                        month: "long",
                      })}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="lcn-tbl-title">Time: </Col>
                    <Col className="lcn-tbl-value">
                      {selectedEvent?.start.toLocaleString("en-US", {
                        hour: "numeric",
                        hour12: true,
                      })}
                    </Col>
                  </Row>
                  <Row>
                    <Col className="lcn-tbl-title">Price: </Col>
                    <Col className="lcn-tbl-value">
                      R
                      {(selectedEvent?.end - selectedEvent?.start) / 1000 <=
                      1800
                        ? selectedLawyer?.halfHourlyRate * 1.1 -
                            selectedLawyer?.halfHourlyRate <=
                          100
                          ? selectedLawyer?.halfHourlyRate + 100
                          : Math.round(selectedLawyer?.halfHourlyRate * 1.1)
                        : selectedLawyer?.hourlyRate * 1.1 -
                            selectedLawyer?.hourlyRate <=
                          100
                        ? selectedLawyer?.hourlyRate + 100
                        : Math.round(selectedLawyer?.hourlyRate * 1.1)}
                    </Col>
                  </Row>
                </div>
              </div>
              <Row>
                <Col className="lcn-checkout-back-parent">
                  {/* <button onClick={updateMeetingsWithReservation}>
                    Proceed
                  </button> */}
                  <button
                    className="lcn-button-orange px-3 py-2 mt-2"
                    onClick={() => setFormStep(5)}
                  >
                    Back
                  </button>
                </Col>
                <Col>
                  <form
                    action="https://www.payfast.co.za/eng/process"
                    method="post"
                  >
                    <input
                      type="hidden"
                      name="merchant_id"
                      value="15816925"
                    ></input>
                    <input
                      type="hidden"
                      name="merchant_key"
                      value="q1tfaqdlsu5nc"
                    ></input>
                    <input
                      type="hidden"
                      name="return_url"
                      value="https://www.lacoona.legal/checkout/success"
                    ></input>
                    <input
                      type="hidden"
                      name="cancel_url"
                      value="https://www.lacoona.legal/checkout/cancel"
                    ></input>
                    <input
                      type="hidden"
                      name="notify_url"
                      value="https://www.lacoona.legal/api/payments/consultation-payment-notify/"
                    ></input>
                    <input
                      type="hidden"
                      name="email_address"
                      value={selectedLawyer?.email}
                    ></input>
                    <input
                      type="hidden"
                      name="m_payment_id"
                      value={selectedEvent?.id}
                    ></input>
                    <input
                      type="hidden"
                      name="amount"
                      value={
                        (selectedEvent?.end - selectedEvent?.start) / 1000 <=
                        1800
                          ? selectedLawyer?.halfHourlyRate * 1.1 -
                              selectedLawyer?.halfHourlyRate <=
                            100
                            ? selectedLawyer?.halfHourlyRate + 100
                            : Math.round(selectedLawyer?.halfHourlyRate * 1.1)
                          : selectedLawyer?.hourlyRate * 1.1 -
                              selectedLawyer?.hourlyRate <=
                            100
                          ? selectedLawyer?.hourlyRate + 100
                          : Math.round(selectedLawyer?.hourlyRate * 1.1)
                      }
                    ></input>
                    <input
                      type="hidden"
                      name="item_name"
                      value="Legal Consultation"
                    ></input>
                    <input
                      type="hidden"
                      name="custom_str1"
                      value={selectedLawyer?.name}
                    ></input>
                    <input
                      type="hidden"
                      name="custom_str2"
                      value={formik.values.name}
                    ></input>
                    <input
                      type="hidden"
                      name="custom_str3"
                      value={formik.values.emailAddress}
                    ></input>
                    <input
                      type="hidden"
                      name="custom_str4"
                      value={formik.values.scenario}
                    ></input>
                    <input
                      type="hidden"
                      name="custom_str5"
                      value={`${selectedEvent?.start}end${selectedEvent?.end}`}
                    ></input>

                    <input
                      type="hidden"
                      name="signature"
                      value={MD5Signature}
                    />
                    <div className="subscribe-button-container">
                      <input
                        className="lcn-button-orange px-3 py-2 mt-2"
                        type="submit"
                        value="Proceed"
                      ></input>
                    </div>
                  </form>
                </Col>
              </Row>
            </Col>
          )}

          {formStep === 7 && (
            <Col className="form-step" lg={8}>
              <Row>
                <h4 className="form-step-heading">
                  Thank you for your submission. We will be in touch as soon as
                  possible.
                </h4>
              </Row>
            </Col>
          )}
        </Row>
      </Row>
    </Col>
  );
}

export default FindALawyer;
