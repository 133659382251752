import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import DashHeader from "./DashHeader";
import UserValidator from "./UserValidator";
import axios from "axios";
import { listBots } from "../actions/botActions";
import LegalProductCard from "./LegalProductCard";
import lottie from "lottie-web";
import QnaLoT from "../lottie-files/QnaLoT";
import PersonSearchLoT from "../lottie-files/PersonSearchLoT";
import HandshakeLoT from "../lottie-files/HandshakeLoT";
import { Helmet } from "react-helmet-async";
import { Configuration, OpenAIApi } from "openai";
import ProdCardLoader from "./Preloader-Card";
import { LinkContainer } from "react-router-bootstrap";
import BrainLoT from "../lottie-files/BrainLoT";
import { getUserSMEDetails } from "../actions/userActions";
import { useParams } from "react-router-dom";
import { pushLinkToGlobalState } from "../actions/linkActions";
import LawyerDashHeader from "./LawyerDashHeader";
import LawyerValidator from "./LawyerValidator";

function LawyerAskJinx() {
  let param = useParams();
  let dispatch = useDispatch();
  const [formStep, setFormStep] = useState(1);
  const [formReady, setFormReady] = useState(false);

  useEffect(() => {
    dispatch(getUserSMEDetails(param.id));
  }, []);
  /* */
  const { userInfo } = useSelector((state) => state.userLogin);
  const smeDetails = useSelector((state) => state.userSMEDetails);
  const { sme } = smeDetails;

  /* Setting the redux redirection url */
  let currentUrl = window.location.href;
  let index = currentUrl.indexOf("/dashboard");
  let splitUrl = currentUrl.substring(index);
  dispatch(pushLinkToGlobalState(splitUrl));

  /* State objects that get the answer and the most applicable category. */

  const [answerData, setAnswerData] = useState(
    "Please hold while Jinx finds you an answer..."
  );

  const [classifedAnswer, setClassifyData] = useState("");

  /* Initiate the formData object that will be sent to Make */
  const [formData, setFormData] = useState({
    formType: "GPT_experiment",
    sendMessage: "",
    requestingUser: userInfo?.email,
    date: new Date(),
    responseMessage: "",
  });

  const [whytf, setWhytf] = useState("");
  // let whytf = "";

  /* Populates the SME Details into global state and then retrieves it from global state. */

  /* OpenAI configuration for using text-davinci with our API Key */
  const configuration = new Configuration({
    apiKey: "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",
  });
  const openai = new OpenAIApi(configuration);

  const classifyAnswer = async () => {
    const response = await openai.createCompletion({
      model: "text-davinci-003",
      prompt:
        "Decide which category the Query is most aligned to from the list of categories. list of categories:Suppliers;Customers;Website, Digital, and Data;POPIA & PAIA;Employment;Suppliers;Board and Corporate Governance;Basic Compliance. Query:" +
        formData.sendMessage +
        "Category:",
      temperature: 0,
      max_tokens: 60,
    });

    setClassifyData(response.data.choices[0].text);
  };

  function submitFormStep(e) {
    console.log("submit form step");
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: e.target.value,
    }));
  }

  function setNextFormStep() {
    setFormStep((formStep) => formStep + 1);
    dispatch(getUserSMEDetails(param.id));
  }

  function setPreviousFormStep() {
    setFormStep((formStep) => formStep - 1);
  }

  function preventSubmit(e) {
    e.preventDefault();
  }

  function submitForm() {
    setFormStep((formStep) => formStep + 1);
    dispatch(listBots());
  }

  /* Async function that runs the createCompletion function from the openai object */
  const generateAnswer = async () => {
    const askJinx =
      "In the context of South African Law, explain in 100 words or less to a South African Lawyer:" +
      formData.sendMessage +
      ".Answer:";
    const response = await openai.createChatCompletion({
      model: "gpt-4",
      messages: [{ role: "user", content: askJinx }],
      temperature: 0,
      max_tokens: 300,
    });

    setAnswerData(response.data.choices[0].message.content);
    updateResponseMessage(response.data.choices[0].message.content);
  };

  const updateResponseMessage = (message) => {
    setWhytf(message);

    addToMake();
  };

  function addToMake() {
    const { sme } = smeDetails;
    setFormData({
      ...formData, // Spread existing properties
      requestingUser: userInfo?.email, // Update requestingUser property
    });
    setFormReady(true);
  }

  useEffect(() => {
    if (formReady) {
      try {
        // eslint-disable-next-line
        const resp = axios.post(
          "https://hook.eu1.make.com/466deq9fu6aosdy83duu6r4eih60zgtx",
          { formData, whytf }
        );
      } catch (error) {
        console.log("there was a make error");
      }
    }
  }, [formReady]);

  //Recommended Product list
  const [stateBots, setStateBots] = useState([]);
  const [queryingBots, setQueryingBots] = useState(true);
  const botList = useSelector((state) => state.botList);

  const { bots } = botList;

  function filterByCategory(category) {
    setStateBots((prev) =>
      !category ? bots : bots.filter((bot) => bot.categories.includes(category))
    );
  }

  useEffect(() => {
    setStateBots(bots);
    setQueryingBots((queryingBots) => !queryingBots);
  }, [bots]);

  useEffect(() => {
    filterByCategory(classifedAnswer.trim());
    // eslint-disable-next-line
  }, [classifedAnswer, bots]);

  return (
    <>
      <Helmet>
        <title>AI Assistant</title>
        <meta
          name="description"
          content="Lacoona Legal helps you source a Legal Practitioner that suits your needs."
        />
        <link rel="canonical" href="/dashboard/find-a-lawyer" />
      </Helmet>
      {userInfo && userInfo?.profile !== 0 && (
        <Col className="dashboard-scrollable-container" lg={9}>
          <LawyerDashHeader pageTitle={"Ask Jinx"} />
          <Row className="dashboard-content-container">
            <Row className="find-lawyer-hero px-5">
              <Col lg={8} className="">
                <Row>
                  <h1 className="serious-font">Ask Jinx (AI)</h1>
                </Row>
                {/* <Row>
              <p>
                To start, just ask Jinx what you would like to know and Jinx will provide you with an answer.
              </p>
            </Row> */}
              </Col>
              <Col
                lg={4}
                className="find-a-lawyer-image"
                align="center"
                onMouseEnter={() => lottie.play("ll-brain")}
                onMouseLeave={() => lottie.stop("ll-brain")}
              >
                <BrainLoT aniName="ll-brain" />
              </Col>
              <Col></Col>
            </Row>

            <Row className="find-a-lawyer-form-container">
              <Col></Col>
              {formStep === 1 && (
                <Col className="form-step" lg={11}>
                  <Row>
                    <Col
                      className="ll-fal-step-1 ll-card-interact p-4 m-2"
                      onMouseEnter={() => lottie.play("qna-fal")}
                      onMouseLeave={() => lottie.stop("qna-fal")}
                    >
                      <Col lg={3}>
                        <QnaLoT aniName="qna-fal" />
                      </Col>
                      <Col className="ms-3" lg={9}>
                        {/* <span className="ll-fal-step-content pe-1">
              <span>Submit</span>
            </span> */}
                        <span className="ll-fal-step-content-hover">
                          To start, just ask Jinx what you would like to know
                          and Jinx will provide you with an answer.
                        </span>
                      </Col>
                    </Col>
                    <Col
                      className="ll-fal-step-1 ll-card-interact p-4 m-2"
                      onMouseEnter={() => lottie.play("person-search-fal")}
                      onMouseLeave={() => lottie.stop("person-search-fal")}
                    >
                      <Col lg={3}>
                        <PersonSearchLoT aniName="person-search-fal" />
                      </Col>
                      <Col className="ms-3" lg={9}>
                        {/* <span className="ll-fal-step-content pe-1" lg={3}>
              <span>Match</span>
            </span> */}
                        <span
                          className="ll-fal-step-content-hover"
                          align="right"
                        >
                          Wait for Jinx to understand and respond.
                        </span>
                      </Col>
                    </Col>
                    <Col
                      className="ll-fal-step-1 ll-card-interact p-4 m-2"
                      onMouseEnter={() => lottie.play("handshake-fal")}
                      onMouseLeave={() => lottie.stop("handshake-fal")}
                    >
                      <Col lg={3}>
                        <HandshakeLoT aniName="handshake-fal" />
                      </Col>
                      <Col className="ms-3" lg={9}>
                        {/* <span className="ll-fal-step-content pe-1">
              <span>Manage</span>
            </span> */}
                        <span className="ll-fal-step-content-hover">
                          Get tailored help to suit your problem and recommended
                          products
                        </span>
                      </Col>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <p>
                      The information provided by Lacoona Legal's Virtual
                      Assistant, Jinx, is for general informational purposes
                      only and we do not guarantee the accuracy of the
                      information provided. Users should always consult with a
                      qualified attorney for specific legal advice. Lacoona
                      Legal shall not be liable for any damages resulting from
                      the use of the Virtual Assistant.
                    </p>
                  </Row>
                  <Row>
                    <Col className="form-step-next-button-container">
                      <button
                        onClick={setNextFormStep} //{submitForm}
                        className="lcn-button-brown py-2 px-3"
                      >
                        I Accept the Disclaimer above
                      </button>
                    </Col>
                  </Row>
                </Col>
              )}
              {formStep === 2 && (
                <Col className="form-step" lg={8}>
                  <Row align="center">
                    <h4 className="form-step-heading">
                      Ask Jinx about your legal question below.
                    </h4>
                    <p>
                      Jinx will provide you a response tailored to your legal
                      question. To take this matter further we will provide you
                      with related products and the opportunity to find a lawyer
                      for your issue.
                    </p>
                  </Row>
                  <Row>
                    <Form onSubmit={preventSubmit}>
                      <Form.Control
                        type="input"
                        className="mb-3"
                        onChange={submitFormStep}
                        name="sendMessage"
                        placeholder="Ask your question here."
                      />
                    </Form>
                  </Row>
                  <Row>
                    <Col>
                      <button
                        className="lcn-button-orange py-2 px-4"
                        onClick={setPreviousFormStep}
                      >
                        Back
                      </button>
                    </Col>
                    <Col className="form-step-next-button-container">
                      <button
                        onClick={() => {
                          generateAnswer();
                          classifyAnswer();
                          submitForm();
                          window.dataLayer.push({
                            event: "submitJinxQuestion",
                          });
                        }} //{submitForm}
                        className="lcn-button-brown py-2 px-3"
                      >
                        Submit
                      </button>
                    </Col>
                  </Row>
                </Col>
              )}
              {formStep === 3 && (
                <Col className="form-step">
                  <Row className="ai-question-text">
                    <p>Your Question:</p>
                    <h4>{formData.sendMessage}</h4>
                  </Row>
                  <hr />
                  <p>Jinx Says:</p>
                  <Row className="ll-neomorphic-inset p-3">
                    <h5>{answerData}</h5>
                  </Row>
                  <Row className="mt-5">
                    <Col>
                      {/* <LinkContainer to={"/dashboard/find-a-lawyer"}>
                        <button className="lcn-button-orange py-2 my-2 px-4">
                          Take this further with a Lawyer
                        </button>
                      </LinkContainer> */}
                    </Col>
                    <Col className="form-step-next-button-container ll-align">
                      <button //{submitForm}
                        onClick={() => {
                          setPreviousFormStep();
                          setAnswerData(
                            "Please hold while Jinx finds you an answer..."
                          );
                        }}
                        className="lcn-button-brown py-2 px-3"
                      >
                        Ask another question
                      </button>
                    </Col>
                  </Row>
                  {/* <Row>
                    <h4 className="form-step-heading serious-font mt-5">
                      Get more help with our related products:
                    </h4>
                  </Row> */}
                  {/* <Row>
                    <Row className="dashboard-content-divider"></Row>
                    {stateBots.length > 0 &&
                      stateBots.map((bot) => (
                        <Col key={bot.id} lg={6} md={12} sm={12}>
                          <LegalProductCard
                            id={bot.id}
                            title={bot.name}
                            image={bot.image}
                            category={bot.categories}
                            description={bot.description}
                            excerpt={bot.excerpt}
                          />
                        </Col>
                      ))}
                    {queryingBots && (
                      <div className="px-3">
                        <Row>
                          <Col lg={6}>
                            <ProdCardLoader />
                          </Col>
                          <Col lg={6}>
                            <ProdCardLoader />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <ProdCardLoader />
                          </Col>
                          <Col lg={6}>
                            <ProdCardLoader />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <ProdCardLoader />
                          </Col>
                          <Col lg={6}>
                            <ProdCardLoader />
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Row> */}
                </Col>
              )}

              <Col></Col>
            </Row>
          </Row>
        </Col>
      )}
      {!userInfo ? (
        <LawyerValidator />
      ) : (
        userInfo?.profile === 0 && <LawyerValidator />
      )}
    </>
  );
}

export default LawyerAskJinx;
