import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { updateUserProfileField } from "../actions/userActions";

function EcosystemRegister() {
  const [ecosystemName, setEcosystemName] = useState("");
  const [ecosystemType, setEcosystemType] = useState("Venture");
  const [registered, setRegistered] = useState(false);
  const { link } = useSelector((state) => state.link);

  const { userInfo } = useSelector((state) => state.userLogin);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo?.token}`,
    },
  };

  const submitHandler = (e) => {
    e.preventDefault();
    axios
      .post(
        "api/users/register-ecosystem/",
        {
          name: ecosystemName,
          type: ecosystemType,
        },
        config
      )
      .then(function (response) {
        console.log("Response Received");
        setRegistered(true);
      })
      .catch(function (error) {
        console.log("Error Occured");
      });
  };

  useEffect(() => {
    if (registered) {
      dispatch(updateUserProfileField({ profile: 3 }));
      if (link !== "") {
        navigate(link);
      } else {
        navigate("/dashboard/ecosystem-profile");
      }
    }
  }, [registered]);

  return (
    <div>
      <Header />
      <Container className="full-screen-container-2">
        <Row className="login-register-container py-5">
          <Col className="login-form-column px-5 py-3">
            <div className="login-form-container px-5 py-4">
              <Row align="center">
                <h1 className="serious-font">Register your Ecosystem</h1>
              </Row>
              <Form onSubmit={submitHandler}>
                <Form.Group className="mb-3" controlId="ecosystemName">
                  <Form.Label>Ecosystem Name</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="Enter Ecosystem name"
                    value={ecosystemName}
                    onChange={(e) => setEcosystemName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="ecosystemType">
                  <Form.Label>Ecosystem Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => setEcosystemType(e.target.value)}
                  >
                    <option value="Venture">Venture</option>
                    <option value="Institutional">Institutional</option>
                    <option value="Merge & Acquire">Merge & Acquire</option>
                  </Form.Select>
                </Form.Group>
                <Row>
                  <Col align="end">
                    <button
                      className="lcn-button-brown px-5 py-3"
                      type="submit"
                    >
                      Register Ecosystem
                    </button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
          <Col className="login-register-hero-container px-5 py-3">
            <Row className="login-register-hero px-3 py-5" align="center">
              <img
                id="how-to-image"
                alt="your legal roadmap"
                src={
                  process.env.PUBLIC_URL + "Your-Legal-roadmap-Compressed.png"
                }
              />
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default EcosystemRegister;
