/* eslint-disable */

import React from "react";
import lottie from "lottie-web";
import legalbyte from "./legalbytes-lottie-lacoona.json";
import { useEffect } from "react";

function LegalByteLoT(props) {
  const aniName = props.aniName;

  const animationContainer = React.useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      name: aniName,
      container: animationContainer.current,
      animationData: legalbyte,
      autoplay: false,
      loop: true,
    });
  }, []);

  return <div className={aniName} ref={animationContainer}></div>;
}

export default LegalByteLoT;
