import axios from "axios";
import {
  BOT_LIST_REQUEST,
  BOT_LIST_SUCCESS,
  BOT_LIST_FAIL,
  BOT_DETAILS_REQUEST,
  BOT_DETAILS_SUCCESS,
  BOT_DETAILS_FAIL,
} from "../constants/botConstants";

export const listBots = () => async (dispatch) => {
  try {
    dispatch({ type: BOT_LIST_REQUEST });

    const { data } = await axios.get("/api/bots/");

    dispatch({ type: BOT_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BOT_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const listBotDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: BOT_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/bots/${id}`);

    dispatch({ type: BOT_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BOT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
