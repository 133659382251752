import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  // eslint-disable-next-line
  USER_UPDATE_PROFILE_RESET,
  USER_SME_REGISTER_REQUEST,
  USER_SME_REGISTER_SUCCESS,
  USER_SME_REGISTER_FAIL,
  // eslint-disable-next-line
  USER_SME_DETAILS_REQUEST,
  USER_SME_DETAILS_SUCCESS,
  // eslint-disable-next-line
  USER_SME_DETAILS_FAIL,
  USER_INCUBATOR_DETAILS_REQUEST,
  USER_INCUBATOR_DETAILS_SUCCESS,
  USER_INCUBATOR_DETAILS_FAIL,
  USER_ECOSYSTEM_DETAILS_REQUEST,
  USER_ECOSYSTEM_DETAILS_SUCCESS,
  USER_ECOSYSTEM_DETAILS_FAIL,
  USER_UPDATE_PROFILE_FIELD_REQUEST,
  USER_UPDATE_PROFILE_FIELD_SUCCESS,
  USER_UPDATE_PROFILE_FIELD_FAIL,
  USER_LAWYER_DETAILS_REQUEST,
  USER_LAWYER_DETAILS_SUCCESS,
  USER_LAWYER_DETAILS_FAIL,
} from "../constants/userConstants";
import axios from "axios";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    // sends username/password and gets back a token
    const { data } = await axios.post(
      "/api/users/login/",
      { email: email, password: password },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    // set data to local storage so we know usr is logged in
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_DETAILS_RESET });
};

export const register = (name, email, password, type) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    // sends username/password and gets back a token
    const { data } = await axios.post(
      "/api/users/register/",
      { name: name, email: email, password: password, type: type },
      config
    );

    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    // set data to local storage so we know usr is logged in
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const registerSme =
  (name, type, industry, website, description) => async (dispatch) => {
    try {
      dispatch({
        type: USER_SME_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      // sends username/password and gets back a token
      const { data } = await axios.post(
        "/api/users/register-sme/",
        {
          name: name,
          type: type,
          industry: industry,
          website: website,
          description: description,
        },
        config
      );

      dispatch({
        type: USER_SME_REGISTER_SUCCESS,
        payload: data,
      });

      // set data to local storage so we know usr is logged in
      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: USER_SME_REGISTER_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // sends username/password and gets back a token
    const { data } = await axios.get(`/api/users/${id}`, config);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getUserSMEDetails = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // sends username/password and gets back a token
    const { data } = await axios.get("/api/users/sme-profile/", config);

    dispatch({
      type: USER_SME_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getUserLawyerDetails = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LAWYER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // sends username/password and gets back a token
    const { data } = await axios.get("/api/users/lawyer-profile/", config);

    dispatch({
      type: USER_LAWYER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LAWYER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getUserIncubatorDetails = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_INCUBATOR_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // sends username/password and gets back a token
    const { data } = await axios.get("/api/users/incubator-profile/", config);

    dispatch({
      type: USER_INCUBATOR_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_INCUBATOR_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getUserEcosystemDetails = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_ECOSYSTEM_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // sends username/password and gets back a token
    const { data } = await axios.get("/api/users/ecosystem-profile/", config);

    dispatch({
      type: USER_ECOSYSTEM_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_ECOSYSTEM_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // sends username/password and gets back a token
    const { data } = await axios.put(
      "/api/users/profile/update/",
      user,
      config
    );

    console.log("data from profile update", data);

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));

    // set data to local storage so we know usr is logged in
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateUserProfileField = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_FIELD_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // sends username/password and gets back a token
    const { data } = await axios.put(
      "/api/users/profile/update-profile-field/",
      user,
      config
    );

    console.log("data from profile update", data);

    dispatch({
      type: USER_UPDATE_PROFILE_FIELD_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));

    // set data to local storage so we know usr is logged in
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PROFILE_FIELD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
