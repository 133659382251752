import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_RESET,
  USER_SME_REGISTER_REQUEST,
  USER_SME_REGISTER_SUCCESS,
  USER_SME_REGISTER_FAIL,
  USER_SME_DETAILS_REQUEST,
  USER_SME_DETAILS_SUCCESS,
  USER_SME_DETAILS_FAIL,
  USER_INCUBATOR_DETAILS_REQUEST,
  USER_INCUBATOR_DETAILS_SUCCESS,
  USER_INCUBATOR_DETAILS_FAIL,
  USER_ECOSYSTEM_DETAILS_REQUEST,
  USER_ECOSYSTEM_DETAILS_SUCCESS,
  USER_ECOSYSTEM_DETAILS_FAIL,
  USER_LAWYER_DETAILS_REQUEST,
  USER_LAWYER_DETAILS_SUCCESS,
  USER_LAWYER_DETAILS_FAIL,
} from "../constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      // returns below to store (updates store with below)
      return { loading: true };

    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };

    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };

    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };

    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true };

    case USER_DETAILS_SUCCESS:
      console.log("user from getUserDetails()", action.payload);
      return { loading: false, user: action.payload };

    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case USER_DETAILS_RESET:
      return { user: {} };

    default:
      return state;
  }
};
export const userRegisterSMEReducer = (state = { sme: {} }, action) => {
  switch (action.type) {
    case USER_SME_REGISTER_REQUEST:
      return { ...state, loading: true };

    case USER_SME_REGISTER_SUCCESS:
      return { loading: false, sme: action.payload };

    case USER_SME_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userSMEDetailsReducer = (state = { sme: {} }, action) => {
  switch (action.type) {
    case USER_SME_DETAILS_REQUEST:
      return { ...state, loading: true };

    case USER_SME_DETAILS_SUCCESS:
      return { loading: false, sme: action.payload };

    case USER_SME_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userLawyerDetailsReducer = (state = { lawyer: {} }, action) => {
  switch (action.type) {
    case USER_LAWYER_DETAILS_REQUEST:
      return { ...state, loading: true };

    case USER_LAWYER_DETAILS_SUCCESS:
      return { loading: false, lawyer: action.payload };

    case USER_LAWYER_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userIncubatorDetailsReducer = (
  state = { incubator: {} },
  action
) => {
  switch (action.type) {
    case USER_INCUBATOR_DETAILS_REQUEST:
      return { ...state, loading: true };

    case USER_INCUBATOR_DETAILS_SUCCESS:
      return { loading: false, incubator: action.payload };

    case USER_INCUBATOR_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userEcosystemDetailsReducer = (
  state = { ecosystem: {} },
  action
) => {
  switch (action.type) {
    case USER_ECOSYSTEM_DETAILS_REQUEST:
      return { ...state, loading: true };

    case USER_ECOSYSTEM_DETAILS_SUCCESS:
      return { loading: false, ecosystem: action.payload };

    case USER_ECOSYSTEM_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };

    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };

    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };

    case USER_UPDATE_PROFILE_RESET:
      return {};

    default:
      return state;
  }
};
