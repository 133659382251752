import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, { useState, lazy, Suspense } from "react";
import { Modal } from "react-bootstrap";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import SMERegister from "./screens/SMERegister";
import ForIncubatorsScreen from "./screens/ForIncubatorsScreen";
import ForLawyersScreen from "./screens/ForLawyersScreen";
import CheckoutSuccessScreen from "./screens/CheckoutSuccessScreen";
import CheckoutCancelScreen from "./screens/CheckoutCancelScreen";
import CheckoutNotifyScreen from "./screens/CheckoutNotifyScreen";
import CancelSubscriptionScreen from "./screens/CancelSubscriptionScreen";
import CustomerComplaintPolicyScreen from "./screens/CustomerComplaintPolicyScreen";
import CheckoutScreen from "./screens/CheckoutScreen";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";
import TermsOfServiceScreen from "./screens/TermsOfServiceScreen";
import SearchResultsScreen from "./screens/SearchResultsScreen";
import IncubatorRegister from "./screens/IncubatorRegister";
import EcosystemRegister from "./screens/EcosystemRegister";
// eslint-disable-next-line
import MyLandbot from "./components/MyLandbot";
import RegisterThankYouScreen from "./screens/RegisterThankYouScreen";
import PreCheckoutScreen from "./screens/PreCheckoutScreen";
import ForSmesScreen from "./screens/ForSmesScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";
import PasswordResetScreen from "./screens/PasswordResetScreen";
import FindALawyerScreen from "./screens/FindALawyerScreen";
import ScrollToTop from "./components/ScrollToTop";
import CookieConsent from "react-cookie-consent";
import FindALawyer from "./components/FindALawyer";
import ConfirmedEmailScreen from "./screens/ConfirmedEmailScreen";
import TagManager from "react-gtm-module";
import PricingPageScreen2 from "./screens/PricingPageScreen2";
import CartScreenNew from "./screens/CartScreenNew";
import EnterpriseScreen from "./screens/EnterpriseScreen";
import LawyerDashScreen from "./screens/LawyerDashScreen";
import LawyerUserRegisterScreen from "./screens/LawyerUserRegisterScreen";
import LawyerRegisterScreen from "./screens/LawyerRegisterScreen";
import BidScreen from "./screens/BidScreen";
import FullScreenLoader from "./screens/FullScreenLoader";
import MeetJinxScreen from "./screens/MeetJinxScreen";
import { Buffer } from "buffer";
import LawyerDashboard from "./screens/LawyerDashboard";
import WeitaVinnyWorker from "./screens/WeitaVinnyWorker";
import WeitaVinnyProducer from "./screens/WeitaVinnyProducer";

// import DocumentUploadScreen from "./screens/DocumentUploadScreen";

// import HomeScreen from "./screens/HomeScreen";
// import Dashboard from "./screens/Dashboard";
// comment

const HomeScreen = lazy(() => import("./screens/HomeScreen"));
const Dashboard = lazy(() => import("./screens/Dashboard"));

const tagManagerArgs = {
  gtmId: "GTM-5G9QCSG",
};
const process = require("process");
window.process = process;
if (typeof process === "undefined") {
  global.process = { env: {} };
}

TagManager.initialize(tagManagerArgs);

function App() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // eslint-disable-next-line
  const handleShow = () => setShow(true);

  return (
    <Router>
      <Suspense fallback={<FullScreenLoader />}>
        <ScrollToTop />

        <main>
          <Routes>
            <Route path="/" element={<HomeScreen />} exact />

            <Route path="/login" element={<LoginScreen />} />
            <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
            <Route
              path="/password-reset/new/:id/:code"
              element={<PasswordResetScreen />}
            />

            <Route path="/register" element={<RegisterScreen />} />
            <Route path="/register-sme" element={<SMERegister />} />
            <Route path="/register-incubator" element={<IncubatorRegister />} />
            <Route path="/register-ecosystem" element={<EcosystemRegister />} />
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="/lawyer-dashboard/*" element={<LawyerDashboard />} />
            <Route path="/cart" element={<CartScreenNew />} />
            <Route path="/pre-checkout" element={<PreCheckoutScreen />} />
            <Route path="/for-incubators" element={<ForIncubatorsScreen />} />
            <Route path="/for-smes" element={<ForSmesScreen />} />

            {/* Corner stone pages */}
            <Route path="/find-a-lawyer" element={<FindALawyerScreen />} />

            <Route
              path="/checkout/success"
              element={<CheckoutSuccessScreen />}
            />
            <Route path="/checkout/cancel" element={<CheckoutCancelScreen />} />
            <Route path="/checkout/notify" element={<CheckoutNotifyScreen />} />
            <Route path="/checkout" element={<CheckoutScreen />} />
            <Route
              path="/cancel-subscription"
              element={<CancelSubscriptionScreen />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
            <Route
              path="/customer-complaint-policy"
              element={<CustomerComplaintPolicyScreen />}
            />
            <Route
              path="/terms-of-service"
              element={<TermsOfServiceScreen />}
            />
            <Route path="/for-lawyers" element={<ForLawyersScreen />} />
            <Route path="/search/:keyword" element={<SearchResultsScreen />} />
            <Route path="/find-a-lawyer" element={<FindALawyer />} />
            <Route
              path="/register/thank-you"
              element={<RegisterThankYouScreen />}
            />
            <Route
              path="/confirm-email/:data/:key"
              element={<ConfirmedEmailScreen />}
            />
            <Route path="/pricing" element={<PricingPageScreen2 />} />
            <Route path="/meet-jinx" element={<MeetJinxScreen />} />
            <Route path="/lawyer-dash" element={<LawyerDashScreen />} />
            <Route
              path="/register-lawyer-user"
              element={<LawyerUserRegisterScreen />}
            />
            <Route path="/register-lawyer" element={<LawyerRegisterScreen />} />
            <Route path="/bids/:data/:key" element={<BidScreen />} />
            {/* <Route path="/upload" element={<DocumentUploadScreen />} /> */}

            {/* <Route
            path="/landbot"
            element={
              <MyLandbot url="https://chats.landbot.io/u/H-504749-YPHR4CX6K7LGJLXB/index.json" />
            }
          /> */}

          {/* Weita Pages */}
          <Route path="/worker-assistant" element={<WeitaVinnyWorker />} />
          <Route path="/producer-assistant" element={<WeitaVinnyProducer />} />
          </Routes>
        </main>

        {/* click widget => no modal */}

        {/* scroll => modal => hide widget */}
        <CookieConsent
          // debug={true} - enable for local debugging
          expires={365}
          acceptOnScroll={true}
          acceptOnScrollPercentage={50}
          onAccept={(byScroll) => {
            if (byScroll) {
              handleShow();
            }
            console.log(`consent given. \n\n By scrolling? ${byScroll}`);
          }}
          disableStyles={true}
          buttonClasses="ll-cookie-button"
          containerClasses="ll-cookie-consent"
          contentClasses="ll-cookie-consent-content"
          buttonText="I Agree"
        >
          This site uses cookies in order to provide you with the services and
          tools available. We also use cookies to enhance the user experience
          and analyze site traffic. You can view our{" "}
          <a href="/privacy-policy">Privacy Policy here.</a>
        </CookieConsent>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Cookie Consent Notice</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            By continuing, you are accepting our cookie policy for more
            Information please visit our{" "}
            <a href="/privacy-policy">Privacy Policy here.</a>
          </Modal.Body>
          <Modal.Footer>
            <button className="lcn-button-orange" onClick={handleClose}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </Suspense>
    </Router>
  );
}

export default App;
