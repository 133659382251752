import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import DashHeader from "./DashHeader";
import LoginComp from "./LoginComp";
import RegComp from "./RegComp";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getUserSMEDetails,
  getUserEcosystemDetails,
  getUserIncubatorDetails,
} from "../actions/userActions";

function UserValidator() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let currentUrl = window.location.href;
  let index = currentUrl.indexOf("/dashboard");

  let splitUrl = currentUrl.substring(index);

  const { userInfo } = useSelector((state) => state.userLogin);
  const { link } = useSelector((state) => state.link);
  const [validatorState, setValidatorState] = useState(1);

  useEffect(() => {
    if (userInfo) {
      if (
        userInfo.profile === 1 &&
        (splitUrl === "/dashboard/validate" ||
          splitUrl === "/dashboard/sme-profile")
      ) {
        dispatch(getUserSMEDetails());
        navigate("/dashboard/sme-profile");
        console.log("navigating");
      } else if (
        userInfo.profile === 2 &&
        (splitUrl === "/dashboard/validate" ||
          splitUrl === "/dashboard/incubator-profile")
      ) {
        dispatch(getUserIncubatorDetails());
        navigate("/dashboard/incubator-profile");
      } else if (
        userInfo.profile === 3 &&
        (splitUrl === "/dashboard/validate" ||
          splitUrl === "/dashboard/ecosystem-profile")
      ) {
        dispatch(getUserEcosystemDetails());
        navigate("/dashboard/ecosystem-profile");
      } else if (userInfo.profile !== 0) {
        //Reece Changes - added a condition so that only navigates to the return link when a user changes from a zero state
        navigate(link);
      }
    }
  }, [userInfo]);
  // part registered sme sign in on other
  // part registered sme sign in on dash

  function setLinkAndNavigate(event) {
    const myNavigate = event.target.getAttribute("data-navigate");
    navigate(myNavigate);
  }

  console.log("userInfo", userInfo);
  console.log("splitUrl", splitUrl);
  return (
    <Col className="dashboard-scrollable-container" lg={9}>
      <Helmet>
        <title>Validator</title>
        <meta
          name="description"
          content="Let's find out what account you have."
        />
        <link rel="canonical" href="/dashboard/validate" />
      </Helmet>
      <DashHeader pageTitle={"Validate"} />
      <Row className="dashboard-content-container">
        <Row className="find-lawyer-hero legal-checks px-5">
          {!userInfo && (
            <div>
              {validatorState === 1 && (
                <LoginComp setValidatorState={setValidatorState} />
              )}
              {validatorState === 2 && (
                <RegComp setValidatorState={setValidatorState} />
              )}
            </div>
          )}

          {userInfo && userInfo.profile === 0 && (
            <Row className="continue-registration-card p-5">
              <Col></Col>
              <Col lg={8}>
                <Row>
                  <h1>Continue Registration</h1>
                  <hr />
                </Row>
                <Row className="mb-4">
                  Thank you for beginning your registration with Lacoona Legal.
                  We noticed that there is no profile attached to your user
                  account. To make full use of our products and services, please
                  complete your registration as either an sme, ecosystem, or
                  incubator.
                </Row>
                <Row>
                  {userInfo.role === "sme" && (
                    <button
                      onClick={setLinkAndNavigate}
                      className="lcn-button-orange"
                      data-link="/dashboard/sme-profile"
                      data-navigate="/register-sme"
                    >
                      Continue Registration
                    </button>
                  )}
                  {userInfo.role === "incubator" && (
                    <button
                      onClick={setLinkAndNavigate}
                      className="lcn-button-orange"
                      data-link="/dashboard/incubator-profile"
                      data-navigate="/register-incubator"
                    >
                      Continue Registration
                    </button>
                  )}
                  {userInfo.role === "ecosystem" && (
                    <button
                      onClick={setLinkAndNavigate}
                      className="lcn-button-orange"
                      data-link="/dashboard/ecosystem-profile"
                      data-navigate="/register-ecosystem"
                    >
                      Continue Registration
                    </button>
                  )}
                </Row>
              </Col>
              <Col></Col>
            </Row>
          )}
        </Row>
      </Row>
    </Col>
  );
}

export default UserValidator;
