import React, { useState, useEffect, useRef } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Row, Col, Form } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../actions/userActions";
import { useNavigate } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import axios from "axios";
import { getUserLawyerDetails } from "../actions/userActions";
import { logout } from "../actions/userActions";

function LawyerRegisterScreen() {
  const { userInfo } = useSelector((state) => state.userLogin);
  const navigate = useNavigate();
  const dispatch = useDispatch();

//Test State
const state = useSelector((state) => state);
console.log(state);

  const validatorUsed = useSelector((state) => state.validator?.validator_used);

  
  const characteristics = [
    {
      label: "Compassionate",
      value: "Compassionate",
    },
    {
      label: "Passionate",
      value: "Passionate",
    },
    {
      label: "Aggressive",
      value: "Aggressive",
    },
    {
      label: "Soft-spoken",
      value: "Soft-spoken",
    },
    {
      label: "A strong communicator",
      value: "A strong communicator",
    },
    {
      label: "Widely knowledgable",
      value: "Widely knowledgable",
    },
    {
      label: "Creative",
      value: "Creative",
    },
  ];

  const specialization = [
    {
      label: "Family Law",
      value: "Family Law",
    },
    {
      label: "Arbitration, Concilliation and Mediation",
      value: "Arbitration, Concilliation and Mediation",
    },
    {
      label: "Company Law (Corporate Governance)",
      value: "Company Law (Corporate Governance)",
    },
    {
      label: "Business Law",
      value: "Business Law",
    },
    {
      label: "Labour Law",
      value: "Labour Law",
    },
    {
      label: "Debt Collection",
      value: "Debt Collection",
    },
    {
      label: "Estate Management",
      value: "Estate Management",
    },
    {
      label: "Property Law",
      value: "Property Law",
    },
    {
      label: "Criminal Law",
      value: "Criminal Law",
    },
    {
      label: "SME Advice",
      value: "SME Advice",
    },
    {
      label: "Website Management",
      value: "Website Management",
    },
    {
      label: "IP and Brand",
      value: "IP and Brand",
    },
    {
      label: "Tax and Financial Law",
      value: "Tax and Financial Law",
    },
    {
      label: "Environmental Law",
      value: "Environmental Law",
    },
    {
      label: "Funding and Financing Law",
      value: "Funding and Financing Law",
    },
    {
      label: "Consumer Law",
      value: "Consumer Law",
    },
    {
      label: "Data Management",
      value: "Data Management",
    },
    {
      label: "Non-Profit and Social Enterprise Law",
      value: "Non-Profit and Social Enterprise Law",
    },
    {
      label: "BBBEE Compliance",
      value: "BBBEE Compliance",
    },
    {
      label: "Construction and Engineering Compliance",
      value: "Construction and Engineering Compliance",
    },
  ];

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo?.token}`,
    },
  };

  console.log("Validator Used",validatorUsed);

  function lawyerNextNavigate(){
    if(validatorUsed) {
      dispatch(logout());
      navigate("/login");
    } else if(!validatorUsed){ //Have set this to this - as the validator seems to be causing some issues regardless on logging in
      dispatch(logout());
      navigate("/login"); 
      // navigate("/lawyer-dashboard/lawyer-dash");
    }
  }

  const formik = useFormik({
    initialValues: {
      name: userInfo?.name,
      email: userInfo?.email,
      characteristics: [],
      specialization: [],
      hourlyRate: "",
      halfHourlyRate: "",
      firmName: "",
    },
    onSubmit: (values) => {
      axios
        .post(
          "/api/users/register-lawyer/",
          {
            name: formik.values.name,
            email: formik.values.email,
            characteristics: formik.values.characteristics,
            specialization: formik.values.specialization,
            hourlyRate: formik.values.hourlyRate,
            halfHourlyRate: formik.values.halfHourlyRate,
            firmName: formik.values.firmName,
          },
          config
        )
        .then((response) => {
          console.log("success");
          dispatch(getUserLawyerDetails());
          lawyerNextNavigate();
        })
        
        .catch((error) => {
          console.log("error");
        });
        try {
          // eslint-disable-next-line
          console.log("makesent");
          const resp = axios.post(
            "https://hook.eu1.make.com/blebyhsfsjxtjr769olriryfmrn9i810",
            {
            ownerName: formik.values.name,
            email: formik.values.email,
            characteristics: formik.values.characteristics,
            specialization: formik.values.specialization,
            hourlyRate: formik.values.hourlyRate,
            halfHourlyRate: formik.values.halfHourlyRate,
            firmName: formik.values.firmName,
            formtype: "lawyer-signup",
            }
          );
          console.log("Response data received");
        } catch (error) {
          console.log("An error has occured");
        }
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Please enter a valid email address"),
    }),
  });

  console.log("formik.values", formik.values);

  return (
    <div>
      {/* <Header /> */}
      <Container className="full-screen-container">
        <Row className="login-register-container mt-0">
          <Col className="login-form-background-left"></Col>
          <Col className="login-form-column register-column px-5 py-3">
            <Col className="login-form-container register-container px-5 py-4 w-45">
              <div>
                <Row className="login-form-container-header mb-4">
                  <Col className="col-9 p-3">
                    <h3 className="serious-font">Welcome to Lacoona Legal</h3>
                    <h1 className="serious-font">
                      Tell us your professional details
                    </h1>
                  </Col>
                  <Col>
                    <p>Have an account?</p>
                    <LinkContainer to={"/login"}>
                      <p className="login-form-container-register">
                        <strong>Log In</strong>
                      </p>
                    </LinkContainer>
                  </Col>
                </Row>

                <Form onSubmit={formik.handleSubmit}>
                  {/* {message && (
                    <div className="error-message">
                      <strong>{message}</strong>
                    </div>
                  )}
                  {error && (
                    <div className="error-message">
                      <strong>{error}</strong>
                    </div>
                  )} */}

                  <Row>
                    <Col>Characteristics</Col>
                  </Row>
                  <Select
                    defaultValue={"Select..."}
                    isMulti
                    name="characteristics"
                    className="find-a-lawyer-form p-1"
                    id="characteristics"
                    options={characteristics}
                    onChange={(value) =>
                      formik.setFieldValue("characteristics", value)
                    }
                    type="text"
                  />
                  <Row>
                    <Col>Specialization</Col>
                  </Row>
                  <Select
                    defaultValue={"Select..."}
                    isMulti
                    name="specialization"
                    className="find-a-lawyer-form p-1"
                    id="specialization"
                    options={specialization}
                    onChange={(value) =>
                      formik.setFieldValue("specialization", value)
                    }
                    type="text"
                  />

                  <Form.Group className="mb-3" controlId="confirmPassword">
                    <Form.Label>Hourly rate</Form.Label>
                    <Form.Control
                      controlId="hourlyRate"
                      required
                      name="hourlyRate"
                      type="input"
                      placeholder="eg: 900"
                      value={formik.values.hourlyRate}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="confirmPassword">
                    <Form.Label>Half hourly rate</Form.Label>
                    <Form.Control
                      controlId="halfHourlyRate"
                      required
                      name="halfHourlyRate"
                      type="input"
                      placeholder="eg: 450"
                      value={formik.values.halfHourlyRate}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="firmName">
                    <Form.Label>Firm name</Form.Label>
                    <Form.Control
                      required
                      type="input"
                      placeholder="Firm name"
                      value={formik.values.firmName}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.email ? <p>{formik.errors.email}</p> : null}
                  </Form.Group>

                  <Row align="center" className="mt-3">
                    <p>
                      By clicking 'Register' below, you agree to our{" "}
                      <a href="/privacy-policy">Privacy Policy.</a>
                    </p>
                  </Row>
                  <Row align="center">
                    <Col align="center">
                      <button
                        className="lcn-button-brown px-5 py-3"
                        type="submit"
                      >
                        Complete Registration
                      </button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Col>
          <Col className="login-form-background-right"></Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default LawyerRegisterScreen;
