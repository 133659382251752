import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import DashHeader from "./DashHeader";
import { Helmet } from "react-helmet-async";
import LawyerDashHeader from "./LawyerDashHeader";
import LawyerValidator from "./LawyerValidator";

function LawyerPayments({ userInfo }) {
  const [lawyerPayments, setLawyerPayments] = useState("");
  const [totalWallet, setTotalWallet] = useState(0);
  const [upcomingPayments, setUpcomingPayments] = useState(0);
  const [nextPayout, setNextPayout] = useState(0);
  const currentDate = new Date();
  //const paymentMonth = currentDate.toLocaleString("default", { month: "long" });

  const getPaymentDateString = () => {
    const currentDate = new Date();
    //currentDate.setFullYear(new Date().getFullYear(), 3, 21); //Test Case for setting the date
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();

    const paymentDate = new Date(currentDate);
    if (currentDay > 20) {
      paymentDate.setMonth(currentMonth + 1);
    }

    const paymentMonth = paymentDate.toLocaleString("default", {
      month: "long",
    });
    const paymentYear = paymentDate.getFullYear();

    return `25 ${paymentMonth} ${paymentYear}`;
  };

  const paymentDateString = getPaymentDateString();

  useEffect(() => {
    console.log("querying payments");
    if (!lawyerPayments) {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo?.token}`, //Reece Made this edit to fix a error call of token - test this.
        },
      };
      axios
        .get("/api/payments/get-lawyer-payments/", config)
        .then((response) => setLawyerPayments(response.data))
        .catch((error) => console.log(error));
    }
  }, []);

  useEffect(() => {
    let totalWalletVar = 0;
    let upcomingPaymentsVar = 0;
    let amountLessLcnCharge = 0;
    for (let payment of lawyerPayments) {
      if (payment.amount >= 1100) {
        amountLessLcnCharge = payment.amount / 1.1;
      } else {
        amountLessLcnCharge = payment.amount - 100;
      }
      totalWalletVar += amountLessLcnCharge;
      if (!payment.paidByLcn) {
        upcomingPaymentsVar += amountLessLcnCharge;
      }
    }
    setTotalWallet(Math.round(totalWalletVar));
    setUpcomingPayments(Math.round(upcomingPaymentsVar));
  }, [lawyerPayments]);
  console.log("lawyerPayments", lawyerPayments);
  return (
    <>
      <Helmet>
        <title>Lacoona Legal Lawyer Dashboard</title>
        <meta
          name="description"
          content="You centalised Legal Dashboard for managing your business on Lacoona Legal"
        />
        <link rel="canonical" href={"/dashboard/sme-profile"} />
      </Helmet>
      {userInfo?.profile === 4 && (
        <Col className="dashboard-scrollable-container" lg={9}>
          <LawyerDashHeader pageTitle={"Payments"} />
          <Row className="dashboard-content-container">
            <Row className="dashboard-content-divider"></Row>

            <Col className="px-4">
              {/* Reece Alt Dashboard UI */}
              {/* Title */}
              <Row className="mb-3">
                <Col xs={6} md={8} lg={10}>
                  <h2 className="ll-subtitle ll-feat-font">
                    Payment Managment
                  </h2>
                </Col>
              </Row>
              {/* Body Content */}
              <Row className="ll-dash-body-content">
                <Col className="p-4 ll-neomorphic-inset">
                  <Row className="m-3">
                    <Col className="ps-5">
                      <h4>Total Wallet</h4>
                    </Col>
                    <Col align="right" className="pe-5">
                      <h4>R {totalWallet ? totalWallet : "0"}</h4>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="m-3">
                    <Col className="ps-5">
                      <h4>Upcoming Payments</h4>
                    </Col>
                    <Col align="right" className="pe-5">
                      <h4>
                        {upcomingPayments ? "R " + upcomingPayments : "N/A"}
                      </h4>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="m-3">
                    <Col className="ps-5">
                      <h4>Next Payout</h4>
                    </Col>
                    <Col align="right" className="pe-5">
                      <h4>{paymentDateString}</h4>
                    </Col>
                  </Row>

                  {/* <Row className="m-3">
                  <button className="lcn-button-brown">
                    Change Payout date
                  </button>
                </Row> */}
                </Col>
              </Row>

              {/* End of Reece Attempt */}
            </Col>
          </Row>
        </Col>
      )}

      {!userInfo ? (
        <LawyerValidator />
      ) : (
        userInfo?.profile === 0 && <LawyerValidator />
      )}
    </>
  );
}

export default LawyerPayments;
