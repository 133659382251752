import Header from "../components/Header";
import Footer from "../components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";

function PrivacyPolicyScreen() {
  return (
    <div>
      <Header />
      <Container align="center" className="page-container">
        {/* This is the Navigation divider for the front end of our pages */}
        <Row className="divider-parent ">
          <Col align="center" className="utility-divider-lines"></Col>
          <Col align="center" className="util-icon-parent">
            <i className="fa-regular fa-circle-dot fa-1x divider-icon"></i>
          </Col>
          <Col align="center" className="utility-divider-lines"></Col>
        </Row>

        <Row className="standard-header mt-5">
          <h1 className="serious-font mt-5">Privacy Policy</h1>
        </Row>
        <Row>
          <Col align="left" className="util-red-border py-4 px-4">
            <p>
              LegalFundi (Pty) Ltd. T/A Lacoona Legal is committed to
              maintaining robust privacy protections for its users. Our Privacy
              Policy is designed to help you understand how we collect, use and
              safeguard the information you provide to us and to assist you in
              making informed decisions when using our Service.{" "}
            </p>
            <p>
              {" "}
              For purposes of this Agreement, “Site” refers to the Firm’s
              website, which can be accessed at lacoona.legal
            </p>
            <p>
              {" "}
              “Service” refers to the Company’s services accessed via the Site,
              in which users can provide their details with the expectation of
              receiving any publications by LegalFundi (Pty) Ltd T/A Lacoona
              Legal, which would generally include articles and legal updates.{" "}
            </p>
            <p>The terms “we,” “us,” and “our” refer to the Firm.</p>
            <p> “You” refers to you, as a user of our Site or our Service. </p>
            <p>
              {" "}
              By accessing our Site or our Service, you accept our Privacy
              Policy and Terms Of Use, and you consent to our collection,
              storage, use and disclosure of your Personal Information as
              described in this Privacy Policy.
            </p>
            <p>
              {" "}
              <strong> INFORMATION WE COLLECT </strong>{" "}
            </p>
            <p>
              We collect “Non-Personal Information” and “Personal Information.”
              Non-Personal Information includes information that cannot be used
              to personally identify you, such as anonymous usage data, general
              demographic information we may collect, referring/exit pages and
              URLs, platform types, in session preferences you submit and
              preferences that are generated based on the data you submit and
              number of clicks. Personal Information includes your name, email
              address, organisation worked for and any other information
              provided in the general area, which you voluntarily submit to us
              through the demarcated areas of the Firm’s site.
            </p>
            <p>
              <em>Information collected via Technology </em>{" "}
            </p>
            <p>
              To activate the Service you do not need to submit any Personal
              Information other than your name, organisation and email address.
              However, in an effort to improve the quality of the Service, we
              track information provided to us by your browser or by our
              software application when you view or use the Service, such as the
              website you came from (known as the “referring URL”), the type of
              browser you use, the device from which you connected to the
              Service, the time and date of access, and other information that
              does not personally identify you. We track this information using
              cookies which include an anonymous unique identifier. Cookies are
              sent to a user’s browser from our servers and are stored on the
              user’s computer hard drive. Sending a cookie to a user’s browser
              enables us to collect Non-Personal information about that user and
              keep a record of the user’s preferences when utilizing our
              services, both on an individual and aggregate basis. The Company
              may use cookies to collect the following information:{" "}
            </p>
            <p>
              <em>About this cookie policy</em>
            </p>
            <p>
              This Cookie Policy explains what cookies are and how we use them,
              the types of cookies we use i.e, the information we collect using
              cookies and how that information is used, and how to control the
              cookie preferences. For further information on how we use, store,
              and keep your personal data secure, see our Privacy Policy.
            </p>
            <p>
              You can at any time change or withdraw your consent from the
              Cookie Declaration on our website
            </p>
            <p>
              Learn more about who we are, how you can contact us, and how we
              process personal data in our Privacy Policy.{" "}
            </p>
            <p>
              {" "}
              Your consent applies to the following domains: <em>
                lacoona.legal
              </em>{" "}
            </p>
            <p> Your current state: Consent accepted.</p>
            <p>
              <em>What are cookies ?</em>{" "}
            </p>
            <p>
              Cookies are small text files that are used to store small pieces
              of information. They are stored on your device when the website is
              loaded on your browser. These cookies help us make the website
              function properly, make it more secure, provide better user
              experience, and understand how the website performs and to analyze
              what works and where it needs improvement.
            </p>
            <p>
              <em>How do we use cookies ?</em>{" "}
            </p>
            <p>
              {" "}
              As most of the online services, our website uses first-party and
              third-party cookies for several purposes. First-party cookies are
              mostly necessary for the website to function the right way, and
              they do not collect any of your personally identifiable data.
            </p>
            <p>
              The third-party cookies used on our website are mainly for
              understanding how the website performs, how you interact with our
              website, keeping our services secure, providing advertisements
              that are relevant to you, and all in all providing you with a
              better and improved user experience and help speed up your future
              interactions with our website.
            </p>
            <p>
              The Company may use both persistent and session cookies;
              persistent cookies remain on your computer after you close your
              session and until you delete them, while session cookies expire
              when you close your browser.{" "}
            </p>
            <p>
              <em>What types of cookies do we use ?</em>{" "}
            </p>
            <p>
              <strong>Essential: </strong>Some cookies are essential for you to
              be able to experience the full functionality of our site. They
              allow us to maintain user sessions and prevent any security
              threats. They do not collect or store any personal information.
              For example, these cookies allow you to log-in to your account and
              add products to your basket, and checkout securely.
            </p>
            <p>
              <strong>Statistics: </strong>These cookies store information like
              the number of visitors to the website, the number of unique
              visitors, which pages of the website have been visited, the source
              of the visit, etc. These data help us understand and analyze how
              well the website performs and where it needs improvement.
            </p>
            <p>
              <strong>Marketing: </strong>Our website displays advertisements.
              These cookies are used to personalize the advertisements that we
              show to you so that they are meaningful to you. These cookies also
              help us keep track of the efficiency of these ad campaigns.{" "}
            </p>
            <p>
              The information stored in these cookies may also be used by the
              third-party ad providers to show you ads on other websites on the
              browser as well.
            </p>
            <p>
              <strong>Functional: </strong> These are the cookies that help
              certain non-essential functionalities on our website. These
              functionalities include embedding content like videos or sharing
              content of the website on social media platforms.
            </p>
            <p>
              <strong>Preferences: </strong>These cookies help us store your
              settings and browsing preferences like language preferences so
              that you have a better and efficient experience on future visits
              to the website.
            </p>
            <p>The below list details the cookies used in our website.</p>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>
                    <strong>COOKIE</strong>
                  </th>
                  <th>
                    <strong>DESCRIPTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>__hssc</td>
                  <td>
                    <p>
                      Identifies if the cookie data needs to be updated in the
                      visitor's browser.
                    </p>
                    <p>
                      <strong>Initiator: </strong>Web server
                    </p>
                    <p>
                      <strong>Source: </strong>legalfundi.com
                    </p>
                    <p>Data is sent to: United Kingdom (adequate)</p>
                    <p>Adquate country under GDPR (EU)</p>
                  </td>
                </tr>
                <tr>
                  <td>__hssrc</td>
                  <td>
                    Used to recognise the visitor's browser upon reentry on the
                    website.
                  </td>
                </tr>
                <tr>
                  <td>__hstc</td>
                  <td>
                    Sets a unique ID for the session. This allows the website to
                    obtain data on visitor behaviour for statistical purposes.
                  </td>
                </tr>
                <tr>
                  <td>cookielawinfo-checkbox-necessary</td>
                  <td>
                    Determines whether the user has accepted the cookie consent
                    box.
                  </td>
                </tr>

                <tr>
                  <td>firebase:host:#.firebaseio.com</td>
                  <td>Used by Landbot 3rd party SaaS provider. (landbot.io)</td>
                </tr>
                <tr>
                  <td>hubspotutk</td>
                  <td>
                    Sets a unique ID for the session. This allows the website to
                    obtain data on visitor behaviour for statistical
                    purposes.(landbot.io)
                  </td>
                </tr>

                <tr>
                  <td>pwaforwp_app_installed</td>
                  <td>Use to determine if a visitor has installed PWA.</td>
                </tr>
              </tbody>
            </Table>
            <p>
              <em>How can I control the cookie preferences ?</em>
            </p>
            Should you decide to change your preferences later through your
            browsing session, you can click on the “Privacy & Cookie Policy” tab
            on your screen. This will display the consent notice again enabling
            you to change your preferences or withdraw your consent entirely. In
            addition to this, different browsers provide different methods to
            block and delete cookies used by websites. You can change the
            settings of your browser to block/delete the cookies. To find out
            more about how to manage and delete cookies, visit wikipedia.org,
            www.allaboutcookies.org.
            <p>
              <em>Children’s Privacy</em>
            </p>
            <p>
              The Site and the Service are not directed to anyone under the age
              of 13. The Site does not knowingly collect or solicit information
              from anyone under the age of 13. In the event that we learn that
              we have gathered personal information from anyone under the age of
              13, without the consent of a parent or guardian, we will delete
              that information as soon as possible. If you believe we have
              collected such information, please contact us at info@lacoona.legal.
            </p>
            <p>
              <strong>HOW WE USE AND SHARE INFORMATION</strong>
            </p>
            <p>
              <em>Personal Information:</em>
            </p>
            <p>
              Except as otherwise stated in this Privacy Policy, we do not sell,
              trade, rent or otherwise share, for marketing purposes, your
              Personal Information with third parties without your consent. We
              do share Personal Information with vendors who are performing
              services for the Company, such as the servers for our email
              communications who are provided access to the user’s email address
              for purposes of sending emails from us. Those vendors use your
              Personal Information only at our direction and in accordance with
              our Privacy Policy.
            </p>
            <p>
              In general, the Personal Information you provide to us is used to
              help us communicate with you. For example, we use Personal
              Information to contact users in response to questions, solicit
              feedback from users, provide technical support, and inform users
              about promotional offers.
            </p>
            <p>
              We may share Personal Information with outside parties if we have
              a good faith belief that access, use, preservation or disclosure
              of the information is reasonably necessary to meet any applicable
              legal process or enforceable governmental request; to enforce
              applicable Terms of Use, including investigation of potential
              violations; address fraud, security or technical concerns; or to
              protect against harm to the rights, property, or safety of our
              users or the public as required or permitted by law.{" "}
            </p>
            <p>
              <em>Non-Personal Information:</em>
            </p>
            <p>
              In general, we use Non-Personal Information to help us improve the
              Service and customize the user experience. We also aggregate
              Non-Personal Information in order to track trends and analyse use
              patterns on the Site. This Privacy Policy does not limit in any
              way our use or disclosure of Non-Personal Information and we
              reserve the right to use and disclose such Non-Personal
              Information to our partners, advertisers and other third parties
              at our discretion.
            </p>
            <p>
              In the event we undergo a business transaction such as a merger,
              acquisition by another company, or sale of all or a portion of our
              assets, your Personal Information may be among the assets
              transferred. You acknowledge and consent that such transfers may
              occur and are permitted by this Privacy Policy, and that any
              acquirer of our assets may continue to process your Personal
              Information as set forth in this Privacy Policy. If our
              information practices change at any time in the future, we will
              post the policy changes to the Site so that you may opt out of the
              new information practices. We suggest that you check the Site
              periodically if you are concerned about how your information is
              used.
            </p>
            <p>
              <strong>HOW WE PROTECT INFORMATION</strong>
            </p>
            <p>
              We implement security measures designed to protect your
              information from unauthorised access. We further protect your
              information from potential security breaches by implementing
              certain technological security measures including encryption and
              firewalls; we have selected the most secure hosting platform which
              offers multiple levels of security protection. Further information
              can be obtained on request through direct communication with
              LegalFundi (Pty) Ltd. T/A Lacoona Legal.
            </p>
            <p>
              However, these measures do not guarantee that your information
              will not be accessed, disclosed, altered or destroyed by breach of
              such firewalls and secure server software. LegalFundi (Pty) Ltd
              T/A Lacoona Legal will not be held liable for any resultant
              material or non-material loss. By using our Service, you
              acknowledge that you understand and agree to accept these risks.
            </p>
            <p>
              <strong>
                YOUR RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION
              </strong>
            </p>
            <p>
              You have the right at any time to prevent us from contacting you
              for marketing purposes. When we send a promotional communication
              to a user, the user can opt out of further promotional
              communications by following the unsubscribe instructions provided
              in each promotional e-mail. Please note that notwithstanding the
              promotional preferences you indicate by unsubscribing, we may
              continue to send you administrative emails including.
            </p>
            <p>
              <strong>LINKS TO OTHER WEBSITES</strong>
            </p>
            <p>
              As part of the Service, we may provide links to or compatibility
              with other websites or applications. However, we are not
              responsible for the privacy practices employed by those websites
              or the information or content they contain. This Privacy Policy
              applies solely to information collected by us through the Site and
              the Service. Therefore, this Privacy Policy does not apply to your
              use of a third party website accessed by selecting a link on our
              Site or via our Service. To the extent that you access or use the
              Service through or on another website or application, then the
              privacy policy of that other website or application will apply to
              your access or use of that site or application. We encourage our
              users to read the privacy statements of other websites before
              proceeding to use them.
            </p>
            <p>
              <strong>CHANGES TO OUR PRIVACY POLICY</strong>
            </p>
            <p>
              The Company reserves the right to change this policy and our Terms
              of Use at any time. We will notify you of significant changes to
              our Privacy Policy by sending a notice to the primary email
              address specified or by placing a prominent notice on the footer
              of our site. Significant changes will go into effect 30 days
              following such notification. Non-material changes or
              clarifications will take effect immediately. You should
              periodically check the Site and this privacy page for updates.
            </p>
            <p>
              <strong>CONTACT US</strong>
            </p>
            <p>
              If you have any questions regarding this Privacy Policy or the
              practices of this Site, please contact us by sending an email to
              info@lacoona.legal
            </p>
            <p>
              <strong>Using Jinx or Vinny</strong>
            </p>
            <p>
            The information provided by Jinx & Vinny is for general information purposes only. While we try to keep the information up to date and correct, we cannot guarantee that it will be accurate. Anyone using this information does so at their own risk.
            </p>
            <p>
              Last Updated: This Privacy Policy was last updated on 11/09/2023
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default PrivacyPolicyScreen;
