import React, { useState } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions";
import { LinkContainer } from "react-router-bootstrap";
import SearchBox from "./SearchBox";
import SupportingGearLoT from "../lottie-files/SupportingGearLoT";
import Lottie from "lottie-web";
import TeamWorkLoT from "../lottie-files/TeamWorkLoT";
import HammerOfJusticeLoT from "../lottie-files/HammerOfJusticeLoT";
import { useNavigate } from "react-router-dom";

function Header() {
  const userLogin = useSelector((state) => state.userLogin);
  const dispatch = useDispatch();
  const { userInfo } = userLogin;
  let navigate = useNavigate();
  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
  };

  const [nav1State, setNav1State] = useState(false);
  const [nav2State, setNav2State] = useState(false);
  const [nav3State, setNav3State] = useState(false);
  const [header, setHeader] = useState(false);

  function changeBackground() {
    if (window.scrollY >= 5) {
      setHeader(true);
    } else {
      setHeader(false);
    }
  }
  window.addEventListener("scroll", changeBackground);

  return (
    <header>
      <Navbar
        className={header ? "navbar-custom active" : "navbar-custom"}
        expand="lg"
      >
        <LinkContainer to={"/"}>
          <Navbar.Brand href="#">
            <img
              className="lcn-nav-logo"
              alt="Lacoona Legal Chatbots"
              src="https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Lacoona-legal-logo-and-wordmark.svg"
            ></img>
          </Navbar.Brand>
        </LinkContainer>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="m-auto ll-frontend-nav">
            <div className="ll-frontend-nav-container">
              <NavDropdown
                title="For Businesses"
                className="home-nav-link"
                id="basic-nav-dropdown"
                show={nav1State}
                onMouseEnter={() => setNav1State(true)}
                onMouseLeave={() => setNav1State(false)}
              >
                <div
                  className="nav-heading-parent"
                  onMouseEnter={() => Lottie.play("ll-nav-modal-supgear")}
                  onMouseLeave={() => Lottie.stop("ll-nav-modal-supgear")}
                >
                  <div>
                    <SupportingGearLoT aniName="ll-nav-modal-supgear" />
                  </div>
                  <div className="ll-drop-nav-header-text">
                    Supporting your Business from A-Z
                  </div>
                </div>
                <LinkContainer to={"/for-smes"}>
                  <NavDropdown.Item>Our Business Offering</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={"/find-a-lawyer"}>
                  <NavDropdown.Item>Find a Lawyer</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={"/register"}>
                  <NavDropdown.Item href="#action/3.3">
                    Sign up for free
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={"/dashboard/faqs"}>
                  <NavDropdown.Item href="#action/3.4">
                    Legal FAQs
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={"/pricing"}>
                  <NavDropdown.Item href="#action/3.5">
                    Pricing
                  </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              <NavDropdown
                title="For Incubators"
                className="home-nav-link-middle"
                id="basic-nav-dropdown"
                show={nav2State}
                onMouseEnter={() => setNav2State(true)}
                onMouseLeave={() => setNav2State(false)}
              >
                <div
                  className="nav-heading-parent"
                  onMouseEnter={() => Lottie.play("ll-nav-modal-teamwork")}
                  onMouseLeave={() => Lottie.stop("ll-nav-modal-teamwork")}
                >
                  <div>
                    <TeamWorkLoT aniName="ll-nav-modal-teamwork" />
                  </div>
                  <div className="ll-drop-nav-header-text">
                    Empowering through you
                  </div>
                </div>
                <LinkContainer to={"/for-incubators"}>
                  <NavDropdown.Item>Our Incubator Offering</NavDropdown.Item>
                </LinkContainer>
                {/* <LinkContainer to={"/for-incubators"}>
                  <NavDropdown.Item>Apply</NavDropdown.Item>
                </LinkContainer> */}
              </NavDropdown>
              <NavDropdown
                title="For Lawyers"
                className="home-nav-link"
                id="basic-nav-dropdown"
                show={nav3State}
                onMouseEnter={() => setNav3State(true)}
                onMouseLeave={() => setNav3State(false)}
              >
                <div
                  className="nav-heading-parent"
                  onMouseEnter={() => Lottie.play("ll-nav-modal-hammer")}
                  onMouseLeave={() => Lottie.stop("ll-nav-modal-hammer")}
                >
                  <div>
                    <HammerOfJusticeLoT aniName="ll-nav-modal-hammer" />
                  </div>
                  <div className="ll-drop-nav-header-text">
                    Supporting your Business from A-Z
                  </div>
                </div>
                <LinkContainer to={"/for-lawyers"}>
                  <NavDropdown.Item>Find out more</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={"/for-lawyers"}>
                  <NavDropdown.Item>Join our network</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            </div>
          </Nav>
          <Nav id="right-nav" className="ms-auto me-4">
            <SearchBox classnameprop="ll-frontend-search" />
            {userInfo ? (
              <NavDropdown title={userInfo.name} id="username">
                {/* <LinkContainer to={"/dashboard/profile"}>
                  <NavDropdown.Item>Profile</NavDropdown.Item>
                </LinkContainer> */}
                {userInfo?.role !== "lawyer" && (
                <LinkContainer to={"/dashboard/profile"}>
                  <NavDropdown.Item>Profile</NavDropdown.Item>
                </LinkContainer>)}
                {userInfo?.role === "lawyer" && (
                <LinkContainer to={"/lawyer-dashboard/user-dash"}>
                  <NavDropdown.Item>Profile</NavDropdown.Item>
                </LinkContainer>)}
                <NavDropdown.Item onClick={logoutHandler}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <LinkContainer to={"/login"}>
                <Nav.Link className="login-button">Login/Register</Nav.Link>
              </LinkContainer>
            )}
            {userInfo?.role === "sme" && (
              <Link to={"/dashboard/sme-profile"}>
                <button className="lcn-button-orange py-2 px-3">
                  Dashboard
                </button>
              </Link>
            )}
            {userInfo?.role === "incubator" && (
              <Link to={"/dashboard/incubator-profile"}>
                <button className="lcn-button-orange py-2 px-3">
                  Dashboard
                </button>
              </Link>
            )}
            {userInfo?.role === "ecosystem" && (
              <Link to={"/dashboard/ecosystem-profile"}>
                <button className="lcn-button-orange py-2 px-3">
                  Dashboard
                </button>
              </Link>
            )}
            {userInfo?.role === "lawyer" && (
              <Link to={"/lawyer-dashboard/lawyer-dash"}>
                <button className="lcn-button-orange py-2 px-3">
                  Dashboard
                </button>
              </Link>
            )}
            {!userInfo && (
              <Link to={"/dashboard/legal-products"} onClick={()=>{window.dataLayer.push({'event':'startNowButton'})}}>
                <button className="lcn-button-orange py-2 px-3">
                  Start Now
                </button>
              </Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <SearchBox classnameprop="ll-frontend-search-mobile" />
    </header>
  );
}

export default Header;
