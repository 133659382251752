import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function ToolTip({ id, children, href, tooltip, placement }) {
  return (
    <OverlayTrigger
      overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
      placement={placement}
      delayShow={300}
      delayHide={150}
    >
      <a href={href}>{children}</a>
    </OverlayTrigger>
  );
}

export default ToolTip;
