import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateUserProfileField } from "../actions/userActions";

function SMERegister() {
  // partial registered = no email = navigate to redux link/sme-dash
  // non registered = email = navigate to link in email
  const { userInfo } = useSelector((state) => state.userLogin);
  const { email } = userInfo;
  const { name } = userInfo;
  const { link } = useSelector((state) => state.link);
  const dispatch = useDispatch();
  const [registered, setRegistered] = useState(false);

  const formik = useFormik({
    initialValues: {
      smeName: "",
      smeType: "Sole Trader",
      smeIndustrySector: "Tech, Media, and Publishing",
      smeDescription: "",
      websiteURL: "",
      operationTime: "",
      smeSize: "",
      smeLocation: "",
      smeCity: "",
      smeProvince: "",
    },
    onSubmit: (values) => {
      formik.values.smeLocation =
        formik.values.smeCity + " " + formik.values.smeProvince;
      console.log("submit ran");

      axios
        .post(
          "api/users/register-sme/",
          {
            name: formik.values.smeName,
            type: formik.values.smeType,
            industry: formik.values.smeIndustrySector,
            website: formik.values.websiteURL,
            description: formik.values.smeDescription,
            operationTime: formik.values.operationTime,
            smeSize: formik.values.smeSize,
            smeLocation: formik.values.smeLocation,
          },
          config
        )
        .then(function (response) {
          console.log("Response Received");
          setRegistered(true);
          // dispatch(getUserDetails("profile"));

          try {
            // eslint-disable-next-line
            const resp = axios.post(
              "https://hook.eu1.make.com/vejcxgl7drpmrhg7wninbb9vvxg744kh",
              {
                formtype: "smeSignup",
                ownerName: name,
                smeName: formik.values.smeName,
                email: email,
                type: formik.values.smeType,
                description: formik.values.smeDescription,
                website: formik.values.websiteURL,
                operationTime: formik.values.operationTime,
                smeSize: formik.values.smeSize,
                smeLocation: formik.values.smeLocation,
              }
            );
            console.log("Response data received");
          } catch (error) {
            console.log("An error has occured");
          }
        })
        .catch(function (error) {
          console.log("Error Occured");
        });
    },
    validationSchema: Yup.object({
      smeName: Yup.string().required("Required"),

      smeSize: Yup.number().required("Required"),
      operationTime: Yup.number().required("Required"),
    }),
  });

  let navigate = useNavigate();

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo?.token}`,
    },
  };

  useEffect(() => {
    if (registered) {
      dispatch(updateUserProfileField({ profile: 1 }));
      if (link) {
        navigate(link);
      } else {
        navigate("/dashboard/sme-profile");
      }
    }
  }, [registered]);
  console.log("link", link);

  return (
    <div>
      <Header />
      <Container className="full-screen-container">
        <Row className="login-register-container">
          <Col className="login-form-background-left"></Col>
          <Col className="login-form-column px-5 py-3">
            <Col className="login-form-container px-5 py-4">
              <Row align="center">
                <h1 className="serious-font">Register your SME</h1>
              </Row>
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3" controlId="smeName">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="Enter company name"
                    value={formik.values.smeName}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.smeName ? (
                    <p className="p-2 text-danger">{formik.errors.smeName}</p>
                  ) : null}
                </Form.Group>
                <Form.Group className="mb-3" controlId="smeType">
                  <Form.Label>Company Type</Form.Label>
                  <Form.Select
                    defaultValue={"DEFAULT"}
                    aria-label="Default select example"
                    onChange={formik.handleChange}
                  >
                    <option value="DEFAULT" disabled>
                      Select...
                    </option>
                    <option value="Sole Trader" name="smeType">
                      Sole Trader
                    </option>
                    <option value="Partnership" name="smeType">
                      Partnership
                    </option>
                    <option value="Company" name="smeType">
                      Company
                    </option>
                    <option value="Public Benefit/Non-profit" name="smeType">
                      Public Benefit/Non-profit
                    </option>
                    <option value="Trust" name="smeType">
                      Trust
                    </option>
                    <option value="Other" name="smeType">
                      Other
                    </option>
                  </Form.Select>
                  {formik.errors.smeType ? (
                    <p className="p-2 text-danger">{formik.errors.smeType}</p>
                  ) : null}
                </Form.Group>
                <Form.Group className="mb-3" controlId="smeIndustrySector">
                  <Form.Label>Company Industry Sector</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={formik.handleChange}
                    defaultValue={"DEFAULT"}
                  >
                    <option value="DEFAULT" disabled>
                      Select...
                    </option>
                    <option value="Technology" name="smeIndustrySector">
                      Technology
                    </option>
                    <option
                      value="Marketing, Media and Publishing"
                      name="smeIndustrySector"
                    >
                      Marketing
                    </option>
                    <option value="Production" name="smeIndustrySector">
                      Production
                    </option>
                    <option value="Fashion and Design" name="smeIndustrySector">
                      Fashion and Design
                    </option>
                    <option
                      value="Human resources and recruitment"
                      name="smeIndustrySector"
                    >
                      Human Resources and Recruitment
                    </option>
                    <option
                      value="Leisure and Fitness"
                      name="smeIndustrySector"
                    >
                      Leisure and Fitness
                    </option>
                    <option value="Financial Services" name="smeIndustrySector">
                      Financial Services
                    </option>
                    <option value="Property" name="smeIndustrySector">
                      Property
                    </option>
                    <option value="Business Services" name="smeIndustrySector">
                      Business Services
                    </option>
                    <option value="General Freelancer" name="smeIndustrySector">
                      General Freelancer
                    </option>
                    <option value="Consulting" name="smeIndustrySector">
                      Consulting
                    </option>
                  </Form.Select>
                </Form.Group>

                {/* <Form.Group className="mb-3" controlId="numShareholders">
                  <Form.Label>Company Number of Shareholders</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="Enter shareholder number"
                  />
                </Form.Group> */}

                {/*     <Form.Group className="mb-3" controlId="websiteURL">
                  <Form.Label>Company Website URL</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="Enter website URL"
                    value={formik.values.websiteURL}
                    onChange={formik.handleChange}

                    
                  />
                </Form.Group> */}

                {/*  <Form.Group className="mb-3" controlId="smeDescription">
                  <Form.Label>Company Description</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="Enter a new description for your company"
                    value={formik.values.smeDescription}
                    onChange={formik.handleChange}
                  />
                </Form.Group> */}

                <Row className="register-screen-age-size">
                  <Col>
                    <Form.Group className="mb-3" controlId="operationTime">
                      <Form.Label>Age of Company</Form.Label>
                      <Form.Control
                        type="input"
                        placeholder="Years in business?"
                        value={formik.values.operationTime}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.operationTime ? (
                        <p className="p-2 text-danger">
                          {formik.errors.operationTime}
                        </p>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="smeSize">
                      <Form.Label>Company Size</Form.Label>
                      <Form.Control
                        type="input"
                        placeholder="Employee Count?"
                        value={formik.values.smeSize}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.smeSize ? (
                        <p className="p-2 text-danger">
                          {formik.errors.smeSize}
                        </p>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label>Company Location</Form.Label>
                  <div className="user-register-location-tooltip">
                    <Form.Select
                      onChange={formik.handleChange}
                      name="SMEprovince"
                      aria-label="Default select example"
                      className="mb-3"
                      defaultValue={"DEFAULT"}
                      value={formik.values.SMEprovince}
                    >
                      <option value="DEFAULT" disabled>
                        Choose a value
                      </option>
                      <option value="Gauteng">Gauteng</option>
                      <option value="Western Cape">Western Cape</option>
                      <option value="Kwa-Zulu Natal">Kwa-Zulu Natal</option>
                      <option value="Eastern Cape">Eastern Cape</option>
                      <option value="Mpumalanga">Mpumalanga</option>
                      <option value="North West">North West</option>
                      <option value="Limpopo">Limpopo</option>
                      <option value="Free State">Free State</option>
                      <option value="Northern Cape">Northern Cape</option>
                    </Form.Select>

                    <Form.Control
                      className="register-smeCity test"
                      name="smeCity"
                      type="input"
                      placeholder="City/Town"
                      value={formik.values.smeCity}
                      onChange={formik.handleChange}
                    />
                  </div>
                </Form.Group>
                <Row>
                  <Col align="end">
                    <button
                      className="lcn-button-brown px-5 py-3"
                      type="submit"
                    >
                      Register SME
                    </button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Col>
          {/* <Col className="login-register-hero-container px-5 py-3">
            <Row className="login-register-hero px-3 py-5">
              <img
                id="how-to-image"
                src={
                  process.env.PUBLIC_URL + "Your-Legal-roadmap-Compressed.png"
                }
              />
            </Row>
          </Col> */}

          {/* <Form.Group className="mb-3" controlId="contactNumber">
                  <Form.Label>Company Contact Number</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="Enter company contact number"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="linkedInProfile">
                  <Form.Label>Company LinkedIn Profile</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="Enter the URL for yor company's LinkedIn profile"
                  />
                </Form.Group> */}
          <Col className="login-form-background-right"></Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default SMERegister;
