import {
  BOT_LIST_REQUEST,
  BOT_LIST_SUCCESS,
  BOT_LIST_FAIL,
  BOT_DETAILS_REQUEST,
  BOT_DETAILS_SUCCESS,
  BOT_DETAILS_FAIL,
} from "../constants/botConstants";

export const botListReducer = (state = { bots: [] }, action) => {
  switch (action.type) {
    case BOT_LIST_REQUEST:
      return { loading: true, bots: [] };

    case BOT_LIST_SUCCESS:
      return { loading: false, bots: action.payload };

    case BOT_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const botDetailsReducer = (state = { bot: {} }, action) => {
  switch (action.type) {
    case BOT_DETAILS_REQUEST:
      return { loading: true, ...state };

    case BOT_DETAILS_SUCCESS:
      return { loading: false, bot: action.payload };

    case BOT_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
