import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";

function CheckoutCancelScreen() {
  const { userInfo } = useSelector((state) => state.userLogin);

  return (
    <div>
      <Header />
      <Container align="center" className="page-container">
        {/* This is the Navigation divider for the front end of our pages */}
        <Row className="divider-parent">
          <Col align="center" className="utility-divider-lines"></Col>
          <Col align="center" className="util-icon-parent">
            <i className="fa-regular fa-circle-dot fa-1x divider-icon"></i>
          </Col>
          <Col align="center" className="utility-divider-lines"></Col>
        </Row>
        {/* Page Hero starts here */}
        <Row className="hero-container lcn-section-spacing">
          <Row align="center" className="content-center">
            <div className="my-5">
              <h1 className="serious-font hero-header-center">
                Payment cancelled
              </h1>
              <p>
                Sorry to see you haven't completed your payment. If you need any
                help you can continue using the free products or provide
                feedback with the links below.
              </p>
            </div>
            {/* CTA Section */}
            <Row className="hero-button-parent">
              <Col>
              {userInfo?.role === "sme" && (
                <LinkContainer to="/dashboard/sme-profile">
                  <button className="lcn-button-orange px-4 py-3">
                    Dashboard
                  </button>
                </LinkContainer>
              )}
              {userInfo?.role === "lawyer" && (
                <LinkContainer to="/lawyer-dashboard/lawyer-dash">
                  <button className="lcn-button-orange px-4 py-3">
                    Dashboard
                  </button>
                </LinkContainer>
              )}
              {!userInfo && (
                <LinkContainer to="/">
                  <button className="lcn-button-orange px-4 py-3">
                    Return Home
                  </button>
                </LinkContainer>
              )}
              </Col>
              <Col>
                <LinkContainer to="/dashboard/feedback">
                  <button className="lcn-button-orange-outline  px-4 py-3">
                    Speak to us
                  </button>
                </LinkContainer>
              </Col>
            </Row>
          </Row>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default CheckoutCancelScreen;
