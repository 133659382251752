import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../actions/userActions";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/Footer";
import Loader from "../components/Loader";
import { useParams, useNavigate } from "react-router-dom";
import { pushLinkToGlobalState } from "../actions/linkActions";
import CryptoJS from "crypto-js";

function ConfirmedEmailScreen() {
  let param = useParams();
  let navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const userRegister = useSelector((state) => state.userRegister);
  const { error, userInfo } = userRegister;

  const uriEncoded = param.data;
  const passphrase = param.key;
  const uriDecoded = decodeURIComponent(uriEncoded);
  const bytes = CryptoJS.AES.decrypt(uriDecoded, passphrase);
  const decodedDataString = bytes.toString(CryptoJS.enc.Utf8);
  const decodedDataObject = JSON.parse(decodedDataString);
  const myLink = decodedDataObject.link;

  console.log("decoded", decodedDataString);
  console.log("decoded object", decodedDataObject);

  function registerUser() {
    dispatch(
      register(
        decodedDataObject.fullName,
        decodedDataObject.email,
        decodedDataObject.password,
        decodedDataObject.userType
      )
    );
    setLoader(true);
    console.log("registered");
  }

  useEffect(() => {
    if (error) {
      console.log("A user with this email address already exists");
      setLoader(false);
    } else if (userInfo) {
      console.log("User Type: ", decodedDataObject.userType);
      dispatch(pushLinkToGlobalState(myLink));
      decodedDataObject.userType === "sme"
        ? navigate("/register-sme")
        : decodedDataObject.userType === "ecosystem"
        ? navigate("/register-ecosystem")
        : navigate("/register-incubator");
    }
    // eslint-disable-next-line
  }, [userRegister]);

  return (
    <div>
      {/* <Header /> */}
      <Container className="full-screen-container">
        <Row className="login-register-container-2">
          <Col className="login-form-background-left"></Col>
          <Col className="login-form-column register-column px-5 py-3">
            <Col
              align="center"
              className="login-form-container register-container px-5 py-4 w-45"
            >
              {!error ? (
                <div>
                  <div>
                    <h4>
                      Thanks confirming your email. Please continue to register
                      your profile.
                    </h4>
                  </div>
                  <button
                    onClick={registerUser}
                    className="lcn-button-orange py-2 px-4"
                  >
                    {loader ? <Loader /> : "Continue Registration"}
                  </button>
                </div>
              ) : (
                <div>
                  <LinkContainer to="/register">
                    <button className="lcn-button-orange py-2 px-4">
                      Back
                    </button>
                  </LinkContainer>
                  <div className="error-message">
                    <strong>{error}</strong>
                  </div>
                </div>
              )}
            </Col>
          </Col>
          <Col className="login-form-background-right"></Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default ConfirmedEmailScreen;
