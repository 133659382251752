import React from "react";
import { Col, Row } from "react-bootstrap";
import DashHeader from "./DashHeader";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { setValidatorUsed } from '../actions/validatorActions';

function LawyerValidator() {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);

  function setLinkAndNavigate() {
    dispatch(setValidatorUsed());
    navigate("/register-lawyer");
  }

  return (
    <Col className="dashboard-scrollable-container" lg={9}>
      <DashHeader pageTitle={"Validate"} />
      <Row className="dashboard-content-container">
        <Row className="find-lawyer-hero legal-checks px-5">
          {!userInfo && (
            <Row className="continue-registration-card p-5">
              <Col align="center">
                <h5>
                  It looks like you aren't logged in. Please click the button
                  below to login or register as a lawyer.
                </h5>
                <LinkContainer to={"/login"}>
                  <button className="lcn-button-orange px-3 py-2 m-4">Login/Register</button>
                </LinkContainer>
              </Col>
            </Row>
          )}
          {userInfo && userInfo.profile === 0 && (
            <Row className="continue-registration-card p-5">
              <Col></Col>
              <Col lg={8}>
                <Row>
                  <h1>Continue Registration</h1>
                  <hr />
                </Row>
                <Row className="mb-4">
                  Thank you for beginning your registration with Lacoona Legal.
                  We noticed that there is no lawyer profile attached to your
                  user account. To make full use of our products and services,
                  please complete your registration as a lawyer.

                  Or if you have completed this log out and in again.
                </Row>
                <Row>
                  {userInfo.role === "lawyer" && (
                    <button
                      onClick={setLinkAndNavigate}
                      className="lcn-button-orange"
                      data-link="/dashboard/sme-profile"
                      data-navigate="/register-sme"
                    >
                      Continue Registration
                    </button>
                  )}
                </Row>
              </Col>
              <Col></Col>
            </Row>
          )}
        </Row>
      </Row>
    </Col>
  );
}

export default LawyerValidator;
