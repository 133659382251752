/* eslint-disable */

import React from "react";
import lottie from "lottie-web";
import journey from "./journey-lottie-lacoona.json";
import { useEffect } from "react";

function JourneyLoT(props) {
  const aniName = props.aniName;

  const animationContainer = React.useRef();

  useEffect(() => {
    lottie.loadAnimation({
      name: aniName,
      container: animationContainer.current,
      animationData: journey,
      autoplay: false,
      loop: true,
    });
  }, []);

  return <div className={aniName} ref={animationContainer}></div>;
}

export default JourneyLoT;
