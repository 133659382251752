import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";
// Calendar imports
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import "react-big-calendar/lib/css/react-big-calendar.css";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import Header from "../components/Header";

function BidScreen() {
  // Vars for calendar default state---------------------------------------------------------------------------------
  const locales = {
    "en-US": enUS,
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });
  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(2015, 3, 12),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  );

  // Component state items---------------------------------------------------------------------------------
  // Holds case details
  const [caseDetails, setCaseDetails] = useState();
  // Holds all bids
  const [bids, setBids] = useState();
  // Controls what JSX is shown on the page
  const [pageState, setPageState] = useState(1);
  // Holds lawyer events
  const [lawyerEvents, setLawyerEvents] = useState();
  const [selectedLawyer, setSelectedLawyer] = useState();
  // Triggers a useEffect to filter lawyer events
  const [filterLawyerEvents, setFilterLawyerEvents] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const [startTimeState, setStartTimeState] = useState();
  const [selectedStartTime, setSelectedStartTime] = useState("12:00");
  const [endTimeState, setEndTimeState] = useState();
  const [selectedEndTime, setSelectedEndTime] = useState("12:00");
  const [warningModalShow, setWarningModalShow] = useState(false);

  // Url decoding stuff---------------------------------------------------------------------------------
  let param = useParams();
  const uriEncoded = param.data;
  const passphrase = param.key;
  const uriDecoded = decodeURIComponent(uriEncoded);
  const bytes = CryptoJS.AES.decrypt(uriDecoded, passphrase);
  const decodedDataString = bytes.toString(CryptoJS.enc.Utf8);
  const decodedDataObject = JSON.parse(decodedDataString);
  // const [caseI, setCaseObj] = useState(decodedDataObject.caseId);

  // Query bids for a case---------------------------------------------------------------------------------
  useEffect(() => {
    const data = { caseId: decodedDataObject.caseId };
    setCaseDetails(decodedDataObject);
    axios
      .post("/api/cases/get-bids/", data)
      .then((response) => {
        console.log(response);
        setBids(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Populate calendar stuff---------------------------------------------------------------------------------------
  // Show a calendar and fill it with a lawyer's events
  function showCalendar(lawyer) {
    setSelectedLawyer(lawyer);
    setFilterLawyerEvents((prev) => !prev);
    setPageState(2);
  }

  // Populate calendar useEffect.
  useEffect(() => {
    if (
      selectedLawyer &&
      selectedLawyer?.appointments &&
      selectedLawyer?.appointments.data.length > 0
    ) {
      const appointmentsArray = JSON.parse(
        selectedLawyer?.appointments.data.replace(/'/g, '"')
      );
      const filteredEventsOpening = appointmentsArray.filter(
        (event) => event.title === "Opening"
      );
      const currentDate = new Date();
      const twoWeeksFromNow = new Date();
      twoWeeksFromNow.setDate(currentDate.getDate() + 14);

      const filteredEventsPrevious = filteredEventsOpening.filter((event) => {
        const eventDate = new Date(event.start);
        return eventDate >= currentDate && eventDate <= twoWeeksFromNow;
      });
      const convertedEvents = filteredEventsPrevious.map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
      }));

      setLawyerEvents((old) => convertedEvents);
    } else {
      setLawyerEvents([]);
    }
  }, [filterLawyerEvents]);

  // modal popup on select event
  const handleSelectEvent = useCallback((event) => {
    setModalShow(true);
    setSelectedEvent(event);
    setStartTimeState(new Date(event.start));
  }, []);

  // close modal function
  function handleModalClose() {
    setModalShow(false);
  }

  //Formats the date to a more human readable structure
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // creates a startTimeState for a new meeting out of a datetime object
  function setBookingStartTime(time) {
    const startDate = new Date(selectedEvent.start);
    const changedStartDate = new Date(selectedEvent.start);
    const endDate = new Date(selectedEvent.end);
    setSelectedStartTime(time);

    const [newStartHours, newStartMinutes] = time.split(":").map(Number);
    changedStartDate.setHours(newStartHours);
    changedStartDate.setMinutes(newStartMinutes);
    const newStartDate = new Date(changedStartDate);
    setStartTimeState(newStartDate);
  }

  // creates an endTimeState for a new meeting out of a datetime object
  const setBookingEndTime = (event) => {
    const meetingLength = event.target.value;
    const startTimeOfBooking = startTimeState;
    const endTimeOfOpening = new Date(selectedEvent.end);

    if (meetingLength === "30") {
      const endTimeOfBooking = startTimeOfBooking.getTime() + 30 * 60000;
      setEndTimeState(new Date(endTimeOfBooking));
    } else {
      const endTimeOfBooking = startTimeOfBooking.getTime() + 60 * 60000;
      setEndTimeState(new Date(endTimeOfBooking));
    }
  };

  // Replaces an opening with a new reservation or creates a reservation within it
  function reserveSelectedEvent() {
    console.log("reserve event");
    const title = "Booking";
    const randomNumber = Math.floor(Math.random() * 90000) + 10000;
    const status = "Reservation";
    const newBooking = {
      ...selectedEvent,
      start: startTimeState,
      end: endTimeState,
      title: "Booking",
      status: "Reservation",
      lawyerEmail: selectedLawyer?.email,
      client: caseDetails?.clientName,
      clientEmail: caseDetails?.clientEmail,
      scenario: caseDetails?.scenario,
    };

    let preBookingEvent = {};
    let postBookingEvent = {};

    if (newBooking.start > selectedEvent.start) {
      preBookingEvent = {
        ...selectedEvent,
        end: startTimeState,
        id: Math.floor(Math.random() * 90000) + 10000,
      };
    }

    if (newBooking.end < selectedEvent.end) {
      postBookingEvent = {
        ...selectedEvent,
        start: endTimeState,
        id: Math.floor(Math.random() * 90000) + 10000,
      };
    }

    const updatedEvents = lawyerEvents.filter(
      (item) => item.id !== selectedEvent.id
    );
    updatedEvents.push(newBooking);
    if (Object.keys(preBookingEvent).length > 0) {
      updatedEvents.push(preBookingEvent);
    }
    if (Object.keys(postBookingEvent).length > 0) {
      updatedEvents.push(postBookingEvent);
    }

    if (newBooking.end - newBooking.start <= 3600000) {
      if (
        newBooking.start >= selectedEvent.start &&
        newBooking.end <= selectedEvent.end
      ) {
        setLawyerEvents(updatedEvents);
        setSelectedEvent(newBooking);
        setPageState(3);
      } else {
        setWarningModalShow(true);
      }
    } else {
      setWarningModalShow(true);
    }

    setModalShow(false);
  }

  // Populate timeslots on modal that pops up after clicking an opening to reserve it

  useEffect(() => {
    setSelectedStartTime(
      selectedEvent?.start.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
    );
    setSelectedEndTime(
      selectedEvent?.end.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
    );
  }, [selectedEvent]);

  // payfast param array

  const params = new URLSearchParams({
    merchant_id: "15816925",
    merchant_key: "q1tfaqdlsu5nc",
    return_url: "https://www.lacoona.legal/checkout/success",
    cancel_url: "https://www.lacoona.legal/checkout/cancel",
    notify_url:
      "https://www.lacoona.legal/api/payments/open-case-consultation-payment-notify/",
    email_address: selectedLawyer?.email,
    m_payment_id: selectedEvent?.id,
    amount:
      (selectedEvent?.end - selectedEvent?.start) / 1000 <= 1800
        ? selectedLawyer?.halfHourlyRate * 1.1 -
            selectedLawyer?.halfHourlyRate <=
          100
          ? selectedLawyer?.halfHourlyRate + 100
          : Math.round(selectedLawyer?.halfHourlyRate * 1.1)
        : selectedLawyer?.hourlyRate * 1.1 - selectedLawyer?.hourlyRate <= 100
        ? selectedLawyer?.hourlyRate + 100
        : Math.round(selectedLawyer?.hourlyRate * 1.1),
    item_name: "Legal Consultation",
    custom_int1: caseDetails?.caseId,
    custom_str1: selectedLawyer?.name,
    custom_str2: caseDetails?.clientName,
    custom_str3: caseDetails?.clientEmail,
    custom_str4: caseDetails?.scenario,
    custom_str5: `${selectedEvent?.start}end${selectedEvent?.end}`,
    passphrase: "LegalFundi.payfast-101-security",
  });

  // Create an MD5 signature of it.
  var md5 = require("md5");

  const MD5Signature = md5(params.toString());
  console.log("selectedEvent", selectedEvent);
  console.log("decodedDataObject", decodedDataObject);
  console.log("caseDetails", caseDetails);

  return (<>
    <Header />
    <Container className="page-container quoted-matter-page">
      <Row className="hero-container lcn-section-spacing">
      {pageState === 1 && (
        <Col className="mt-3">
          <Row className="mt-5"><h1 className="serious-font">Quotes</h1></Row>
          <hr />
          <Row>
            {bids?.map((bid, key) => (
              <Col className="mx-3" key={key}>
                <div className="legal-product-container p-4">
                <div align="center">
                  <h3>Quote from {bid.lawyer.name}</h3>
                </div>
                <div><strong>Lawyer:</strong><div>{bid.lawyer.name}</div></div>
                <hr />
                <div><strong>Pitch:</strong><div>{bid.pitch}</div></div>
                <hr />
                <div><strong>Hours:</strong><div>{bid.hours}</div></div>
                <hr />
                <div><strong>Date placed:</strong><div>{formatDate(bid.date)}</div></div>
                </div>
                <Row>
                  <button className="lcn-button-brown mt-4" onClick={() => showCalendar(bid.lawyer)}>
                    Accept
                  </button>
                </Row>
              </Col>
            ))}
          </Row>
        </Col>
      )}
      {pageState === 2 && (
        <Col className="p-3 m-4">
          <Row className="m-4"><h1 className="serious-font">Select a time to meet your lawyer</h1></Row>
          <hr/>
          <Row>
            <Calendar
              localizer={localizer}
              events={lawyerEvents}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              onSelectEvent={handleSelectEvent}
              selectable
              defaultView="week"
              scrollToTime={scrollToTime}
              views={["week"]}
            />
          </Row>
          <Row>
            <Modal show={modalShow} onHide={handleModalClose}>
              <Modal.Header closeButton>
                <Modal.Title className="serious-font">
                  Reserve this slot
                </Modal.Title>
                {/* <Modal.Title>{selectedEvent?.title}</Modal.Title> */}
              </Modal.Header>
              <Modal.Body>
                <Row className="lcn-dash-border m-2 rounded p-1">
                  <Form>
                    <Row>
                      <Col>Select a start time:</Col>
                    </Row>
                    <TimePicker
                      onChange={(time) => setBookingStartTime(time)}
                      value={selectedStartTime}
                      format="h:mm a"
                      disableClock={true}
                      minuteStep={30}
                      className="form-control"
                    />
                    <Form.Group controlId="meetLenDrop">
                      <Form.Select
                        required
                        onChange={(e) => setBookingEndTime(e)}
                        name="meetLenDropName"
                        aria-label="Default select example"
                        className="mb-3 mt-3"
                        defaultValue={"DEFAULT"}
                      >
                        <option value="DEFAULT" disabled>
                          Select a duration
                        </option>

                        <option value="30">30 minutes</option>
                        <option value="60">1 hour</option>
                      </Form.Select>
                    </Form.Group>
                    {/* <Button onClick={bookMeeting}>Submit</Button> */}
                  </Form>
                </Row>

                <Row className="mt-4">
                  <Col align="center">
                    <button
                      className="lcn-button-brown"
                      onClick={reserveSelectedEvent}
                    >
                      Reserve
                    </button>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          </Row>
          <Row>
            <Modal
              show={warningModalShow}
              onHide={() => setWarningModalShow(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Invalid event times</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  It appears that your booking slot is invalid. Either you have
                  booked a slot longer than one hour, or your slot falls outside
                  of the parameters of the Lawyer's opening.
                </p>
              </Modal.Body>
            </Modal>
          </Row>
        </Col>
      )}
      {pageState === 3 && (
        <Col className="m-5">
          {/* <Row>Payfast</Row> */}
          <Row className="mt-4">
            <h4 className="form-step-heading serious-font">Checkout</h4>
          </Row>
          <Row align="left">
            <p className="form-step-heading">
              Thank you for submitting your reservation. Please review the
              meeting details below. After that, you can click Proceed, and you
              will be redirected to the Payfast portal where you can make the
              payment for your consultation. If the payment is successful, the
              booking will be confirmed and you will recieve an invitation for a
              Google meet event in your email.
            </p>
          </Row>
          <hr />
          <div className="lcn-meet-details-parent">
            <Row align="center" className="serious-font">
              <h4>Meeting details</h4>
            </Row>
            <div className="lcn-dash-border m-2 rounded p-4">
              <Row>
                <Col className="lcn-tbl-title">Lawyer:</Col>
                <Col className="lcn-tbl-value">{selectedLawyer?.name}</Col>
              </Row>
              <Row>
                <Col className="lcn-tbl-title">Date: </Col>
                <Col className="lcn-tbl-value">
                  {selectedEvent?.start.toLocaleString("en-US", {
                    day: "numeric",
                    month: "long",
                  })}
                </Col>
              </Row>
              <Row>
                <Col className="lcn-tbl-title">Time: </Col>
                <Col className="lcn-tbl-value">
                  {selectedEvent?.start.toLocaleString("en-US", {
                    hour: "numeric",
                    hour12: true,
                  })}
                </Col>
              </Row>
              <Row>
                <Col className="lcn-tbl-title">Price: </Col>
                <Col className="lcn-tbl-value">
                  R
                  {(selectedEvent?.end - selectedEvent?.start) / 1000 <= 1800
                    ? selectedLawyer?.halfHourlyRate * 1.1 -
                        selectedLawyer?.halfHourlyRate <=
                      100
                      ? selectedLawyer?.halfHourlyRate + 100
                      : Math.round(selectedLawyer?.halfHourlyRate * 1.1)
                    : selectedLawyer?.hourlyRate * 1.1 -
                        selectedLawyer?.hourlyRate <=
                      100
                    ? selectedLawyer?.hourlyRate + 100
                    : Math.round(selectedLawyer?.hourlyRate * 1.1)}
                </Col>
              </Row>
            </div>
          </div>
          <Row>
            <Col>
              <form
                action="https://www.payfast.co.za/eng/process"
                method="post"
              >
                <input
                  type="hidden"
                  name="merchant_id"
                  value="15816925"
                ></input>
                <input
                  type="hidden"
                  name="merchant_key"
                  value="q1tfaqdlsu5nc"
                ></input>
                <input
                  type="hidden"
                  name="return_url"
                  value="https://www.lacoona.legal/checkout/success"
                ></input>
                <input
                  type="hidden"
                  name="cancel_url"
                  value="https://www.lacoona.legal/checkout/cancel"
                ></input>
                <input
                  type="hidden"
                  name="notify_url"
                  value="https://www.lacoona.legal/api/payments/open-case-consultation-payment-notify/"
                ></input>
                <input
                  type="hidden"
                  name="email_address"
                  value={selectedLawyer?.email}
                ></input>
                <input
                  type="hidden"
                  name="m_payment_id"
                  value={selectedEvent?.id}
                ></input>
                <input
                  type="hidden"
                  name="amount"
                  value={
                    (selectedEvent?.end - selectedEvent?.start) / 1000 <= 1800
                      ? selectedLawyer?.halfHourlyRate * 1.1 -
                          selectedLawyer?.halfHourlyRate <=
                        100
                        ? selectedLawyer?.halfHourlyRate + 100
                        : Math.round(selectedLawyer?.halfHourlyRate * 1.1)
                      : selectedLawyer?.hourlyRate * 1.1 -
                          selectedLawyer?.hourlyRate <=
                        100
                      ? selectedLawyer?.hourlyRate + 100
                      : Math.round(selectedLawyer?.hourlyRate * 1.1)
                  }
                ></input>
                <input
                  type="hidden"
                  name="item_name"
                  value="Legal Consultation"
                ></input>
                <input
                  type="hidden"
                  name="custom_int1"
                  value={caseDetails?.caseId}
                ></input>
                <input
                  type="hidden"
                  name="custom_str1"
                  value={selectedLawyer?.name}
                ></input>
                <input
                  type="hidden"
                  name="custom_str2"
                  value={caseDetails?.clientName}
                ></input>
                <input
                  type="hidden"
                  name="custom_str3"
                  value={caseDetails?.clientEmail}
                ></input>
                <input
                  type="hidden"
                  name="custom_str4"
                  value={caseDetails?.scenario}
                ></input>
                <input
                  type="hidden"
                  name="custom_str5"
                  value={`${selectedEvent?.start}end${selectedEvent?.end}`}
                ></input>
                <input type="hidden" name="signature" value={MD5Signature} />
                <div className="subscribe-button-container">
                  <input
                    className="lcn-button-orange px-3 py-2 mt-2"
                    type="submit"
                    value="Proceed"
                  ></input>
                </div>
              </form>
            </Col>
          </Row>
        </Col>
      )}
      </Row>
    </Container>
    </>
  );
}

export default BidScreen;
