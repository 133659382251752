/* eslint-disable */

import React from "react";
import lottie from "lottie-web";
import team from "./team-work-lottie-lacoona.json";
import { useEffect } from "react";

function TeamWorkLoT(props) {
  const aniName = props.aniName;

  const animationContainer = React.createRef();

  useEffect(() => {
    const layeranim = lottie.loadAnimation({
      name: aniName,
      container: animationContainer.current,
      animationData: team,
      autoplay: false,
      loop: true,
    });
  }, []);

  return <div className={aniName} ref={animationContainer}></div>;
}

export default TeamWorkLoT;
