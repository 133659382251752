import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
/* import thunk from "redux-thunk"; */
import { botListReducer, botDetailsReducer } from "./reducers/botReducers";
/* import { composeWithDevTools } from "redux-devtools-extension"; */
import { faqListReducer, faqDetailsReducer } from "./reducers/faqReducers";
import {
  documentDetailsReducer,
  documentListReducer,
} from "./reducers/documentReducers";
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userSMEDetailsReducer,
  userIncubatorDetailsReducer,
  userEcosystemDetailsReducer,
  userLawyerDetailsReducer,
} from "./reducers/userReducers";
import { cartReducer } from "./reducers/cartReducers";
import { linkReducer } from "./reducers/linkReducers";
import { validatorReducer } from "./reducers/validatorReducers";

const reducer = combineReducers({
  botList: botListReducer,
  faqList: faqListReducer,
  documentList: documentListReducer,
  botDetails: botDetailsReducer,
  faqDetails: faqDetailsReducer,
  documentDetails: documentDetailsReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userSMEDetails: userSMEDetailsReducer,
  userIncubatorDetails: userIncubatorDetailsReducer,
  userEcosystemDetails: userEcosystemDetailsReducer,
  userLawyerDetails: userLawyerDetailsReducer,
  cart: cartReducer,
  link: linkReducer,
  validator: validatorReducer,
});

const userInfoFromLocalStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

// const cartItemsFromStorage = localStorage.getItem("cartItems")
//   ? JSON.parse(localStorage.getItem("cartItems"))
//   : [];

const initialState = {
  userLogin: { userInfo: userInfoFromLocalStorage },
  // cart: { cartItems: cartItemsFromStorage },
};

/* Commented out error said it's not used anywhere */
/* const middleWare = [thunk]; */

//React-Snap
// Grab the state from a global variable injected into the server-generated HTML
// const preloadedState = window.__PRELOADED_STATE__;

// Allow the passed state to be garbage-collected
// delete window.__PRELOADED_STATE__;

//React snap added preloadedState
const store = configureStore({
  reducer,
  preloadedState: initialState,
});

// Tell react-snap how to save Redux state
// window.snapSaveState = () => ({
//   __PRELOADED_STATE__: store.getState()
// });

export default store;
