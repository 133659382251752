import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import { useSelector } from "react-redux";
import emailjs from "@emailjs/browser";

function CancelSubscriptionScreen() {
  const [hasCancelled, setHasCancelled] = useState(false);
  const [feedbackBox, setFeedbackBox] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [feedbackThankYouMessage, setFeedbackThankYouMessage] = useState("");
  const { userInfo } = useSelector((state) => state.userLogin);
  const [email, setEmail] = useState("info@lacoona.legal");
  const [userEmail, setUserEmail] = useState();
  const form1 = useRef();
  const form2 = useRef();
  console.log("userinfo", userInfo);

  function handleChange(e) {
    setFeedback(e.target.value);
  }

  console.log("feedback", feedback);

  function submitFeedback() {
    setFeedbackBox(false);
    emailjs
      .sendForm(
        "service_pen011h",
        "template_cx6e664",
        form2.current,
        "nDn25pehV2cf7nY3j"
      )
      .then(
        (result) => {
          console.log("result.text");
        },
        (error) => {
          console.log("error.text");
        }
      );
    setFeedbackThankYouMessage("Thank you for your feedback");
  }
  function unSubscribe() {
    setUserEmail(userInfo?.email);
    console.log("token test", userInfo?.token);
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo?.token}`,
      },
    };
    axios
      .put("/api/users/unsubscribe/", { userId: userInfo.id }, config)
      .then(function (response) {
        console.log("Response Received");
      })
      .catch(function (error) {
        console.log("Error Occured");
      });
    setHasCancelled(true);
  }

  useEffect(() => {
    emailjs
      .sendForm(
        "service_pen011h",
        "template_n874rd7",
        form1.current,
        "nDn25pehV2cf7nY3j"
      )
      .then(
        (result) => {
          console.log("result.text");
        },
        (error) => {
          console.log("error.text");
        }
      );
  }, [hasCancelled]);
  return (
    <div>
      <Header />
      <Container align="center" className="page-container">
        {/* This is the Navigation divider for the front end of our pages */}
        <Row className="divider-parent">
          <Col align="center" className="utility-divider-lines"></Col>
          <Col align="center" className="util-icon-parent">
            <i className="fa-regular fa-circle-dot fa-1x divider-icon"></i>
          </Col>
          <Col align="center" className="utility-divider-lines"></Col>
        </Row>
        {/* Page Hero starts here */}
        <Row className="hero-container lcn-section-spacing">
          <Row align="center" className="content-center">
            <div className="my-5">
              <h1 className="serious-font hero-header-center">
                We're sorry to see you go
              </h1>
              <p>
                If you are sure you would like to cancel your subscription with
                us, please click the button below. We would love to hear about
                your experiences and what caused you to cancel, so please feel
                free to leave some feedback too.
              </p>
            </div>
            {/* CTA Section */}
            <Row className="hero-button-parent">
              <Col>
                {hasCancelled ? (
                  <div className="request-cancel-message">
                    Thank you. We are are currently processing your request.
                  </div>
                ) : (
                  <button
                    onClick={unSubscribe}
                    className="lcn-button-orange px-4 py-3"
                  >
                    Unsubscribe
                  </button>
                )}
              </Col>
              <Col>
                <button
                  onClick={() => setFeedbackBox(true)}
                  className="lcn-button-orange-outline px-4 py-3"
                >
                  Give feedback
                </button>
              </Col>
            </Row>
            {feedbackBox && (
              <Row className="mt-5">
                <Col></Col>
                <Col lg={8} className="feedback-container px-3 py-3">
                  <Row>
                    <Col align="left">
                      <h6>
                        Please leave some feedback for us and help us improve.
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    <Form>
                      <InputGroup>
                        <Form.Control
                          as="textarea"
                          aria-label="With textarea"
                          rows="8"
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </Form>
                  </Row>
                  <Row>
                    <Col align="right">
                      <button
                        onClick={submitFeedback}
                        className="lcn-button-brown mt-3"
                      >
                        Submit
                      </button>
                    </Col>
                  </Row>
                </Col>
                <Col></Col>
              </Row>
            )}
            {feedbackThankYouMessage && (
              <Row className="mt-5">
                <h4>{feedbackThankYouMessage}</h4>
              </Row>
            )}

            <Row>
              <form className="forgot-pass-form" ref={form1}>
                <input
                  type="email"
                  name="provider_email"
                  defaultValue={email}
                />
                <input type="text" name="user_email" defaultValue={userEmail} />
                <input type="submit" value="Send" />
              </form>
              <form className="forgot-pass-form" ref={form2}>
                <input
                  type="email"
                  name="provider_email"
                  defaultValue={email}
                />
                <input type="text" name="user_email" defaultValue={userEmail} />
                <input
                  type="text"
                  name="user_feedback"
                  defaultValue={feedback}
                />
                <input type="submit" value="Send" />
              </form>
            </Row>
          </Row>
        </Row>
      </Container>

      <Footer />
    </div>
  );
}

export default CancelSubscriptionScreen;
