/* eslint-disable */

import React from "react";
import lottie from "lottie-web";
import newMat from "./lacoona-new-matters.json"
import { useEffect } from "react";

function NewMatterLoT(props) {
  const aniName = props.aniName;

  const animationContainer = React.createRef();

  useEffect(() => {
      lottie.loadAnimation({
      name: aniName,
      container: animationContainer.current,
      animationData: newMat,
      autoplay: false,
      loop: true,
    });
  }, []);

  return <div className={aniName} ref={animationContainer}></div>;
}

export default NewMatterLoT;
