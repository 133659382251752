/* eslint-disable */

import React from "react";
import lottie from "lottie-web";
import reload from "./reload-lottie.json";
import { useEffect } from "react";

function Reload(props) {
  const aniName = props.aniName;

  const animationContainer = React.createRef();

  useEffect(() => {
    const layeranim = lottie.loadAnimation({
      name: aniName,
      container: animationContainer.current,
      animationData: reload,
      autoplay: false,
      loop: true,
    });
  }, []);

  return <div className="lottie-icon" ref={animationContainer}></div>;
}

export default Reload;
