import axios from "axios";
import { CART_ADD_ITEM, CART_REMOVE_ITEM } from "../constants/cartConstants";

export const addToCart = () => async (dispatch, getState) => {
  const { data } = await axios.get("/api/products/1");
  console.log(data);

  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      name: data.name,
      type: data.type,
      length: data.length,
      cost: data.cost,
    },
  });

  // localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const removeFromCart = () => {
  return {
    type: CART_REMOVE_ITEM,
  };
};
