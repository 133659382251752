import React, { useState } from "react";
import { Container, Row, Col, Button, Accordion, Navbar, } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { MendableInPlace } from "@mendable/search";
import { useRef } from 'react';


function WeitaVinnyWorker() {
    //Header Stuff
    const [header, setHeader] = useState(false);

    function changeBackground() {
      if (window.scrollY >= 5) {
        setHeader(true);
      } else {
        setHeader(false);
      }
    }
    window.addEventListener("scroll", changeBackground);



  const mendable_style = { darkMode: false, accentColor: "#003963", backgroundColor:"#e4ebf3" }

  const paragraphcheck=document.querySelectorAll('p');
  
  paragraphcheck.forEach(p=>{
     if(p.textContent.includes('Powered by')){
       p.classList.add('hide-mendable');
     }
  })

  const ref = useRef(null);

  const handleClickToJinx = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div>
      <Helmet>
      <title>Weita Worker Assistant</title>
      <meta
        name="description"
        content="Weita Worker AI assistant by Legal Fundi."
      />
      <link rel="canonical" href="/weita-worker" />
      </Helmet>

      {/* <Header /> */}
      <header>
      <Navbar
        className={header ? "navbar-custom active " : "navbar-custom ll-org-clear-nav"}
        expand="lg"
      >
        <a href="https://www.lacoona.legal/" target="_blank" rel="noreferrer">
          <Navbar.Brand href="#">
            <img
              className="lcn-nav-logo pt-3 pb-3"
              alt="Lacoona Legal Chatbots"
              src="https://legalfundi.com/wp-content/uploads/2022/03/LegalFundi-light-1.svg"
            ></img>
          </Navbar.Brand>
        </a>
        </Navbar>
        </header>
        <Container align="center" className="page-container weita-bg front-page-container pt-5">
        <Row className=" lcn-section-spacing for-lawyers--row lcn-page-width-section">
          {/* Header areas */}
          <Row className="">
          <Col lg={2} className="m-0 p-0"></Col>
            <Col lg={8} className="hero-body-bg">
            <h1 className="lf-hero-font mb-5">Meet Vinny, your helper.</h1>
            <p className="lf-body-font">Got questions about farm rules? Vinny’s here to guide you. Simple answers, just for you. Make your work on the farm easier with Vinny.</p>
            </Col>
          <Col lg={2} className="m-0 p-0"></Col>
          </Row>
          <Row align="center" className="pt-4">
            <Col lg={3}></Col>
            <Col align="center" lg={3}>
            <a
              className="for-lawyers--schedule"
              href="https://wa.me/message/N2ODTB5TUTXFJ1"
              target="_blank"
              rel="noreferrer"
            ><button className="mobile-wrap lf-button my-1 px-4 py-3">Use Vinny on Whatsapp</button></a></Col>
            <Col align="center" lg={3}>
              <a className="for-lawyers--schedule"
              href="/producer-assistant"
              target="blank"><button className="mobile-wrap lf-button my-1 px-4 py-3">Are you a producer?</button></a></Col>
            <Col lg={3}></Col>
          </Row>
        </Row>
        {/* Try */}

      <Row id="try-jinx-corp-demo" align="center" ref={ref} className="lf-dots-bg">
        <h2 className="  lf-hero-font">Try Vinny Now</h2>
        <hr className="mt-4 mb-4 lf-burg-short-rule ll-hr-center"></hr>
        <Row className="ms-auto me-auto p-0 mb-5 lf-mendable">
          <MendableInPlace anon_key='d4b825c7-d2b3-431a-b007-058372a83222' style={mendable_style} welcomeMessage={"Hi, Vinny here. What can I help you with today?"} botIcon={<img src="https://legalfundi.com/wp-content/uploads/2022/08/LegalFundi-Badge-og-2.png"/>} hintText={"How many hours can I work in a day?"}
          messageSettings={{
          hideSources:true
        }}
      // privacyDisclaimer={"The information provided by Jinx is for general information purposes only. While we try to keep the information up to date and correct, we cannot guarantee that it will be accurate. Anyone using this information does so at their own risk."}
        />
        <p className="mt-4">By using Vinny you agree to our <a className="lf-blue"target="_blank" href="/privacy-policy#:~:text=info%40lacoona.legal-,Using%20Jinx%20or%20Vinny,-The%20information%20provided">Disclaimer</a></p>
      </Row>     
      
      </Row>
      
      {/* FAQs and other Content */}
      <Row align="center" className="lf-blue-background pt-5">
        <Accordion className="lf-accordion-super-parent">
          <Accordion.Item
            eventKey="0"
            className="lf-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            What is Vinny?
            </Accordion.Header>
            <Accordion.Body>
            Vinny is a legal guide for farm workers. It tells you about farm rules.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="1"
            className="lf-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            How can Vinny help me?
            </Accordion.Header>
            <Accordion.Body>
            Vinny answers your questions. It makes work easier and clearer for you.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="4"
            className="lf-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            Do I need to know a lot to use Vinny?
            </Accordion.Header>
            <Accordion.Body>
            No, Vinny is easy. Just ask, and it will help.
            </Accordion.Body>
          </Accordion.Item> 

          <Accordion.Item
            eventKey="7"
            className="lf-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            Do I need to know a lot to use Vinny?
            </Accordion.Header>
            <Accordion.Body>
            You can use Vinny on the web above or on whatsapp by clicking <a rel="noreferrer" target="_blank" href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2cWDUQamVqaMkDqTF3Pus3dFx1aIJE1rQ9NdF6A1p8_4WkI5h32BSPSOkk3N4FLh4IiwmfaI1V">click here.</a>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item
            eventKey="9"
            className="lf-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            Is my information safe with Vinny?
            </Accordion.Header>
            <Accordion.Body>
            Yes. Vinny keeps all your conversations safe and private under our privacy policy.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item
            eventKey="10"
            className="lf-accordion-parent ll-accordion-ent"
          >
            <Accordion.Header>
            Is Vinny compliant with data privacy regulations?
            </Accordion.Header>
            <Accordion.Body>
            Yes, Vinny is compliant with various data privacy regulations, such as GDPR and CCPA, ensuring your data is processed and stored securely and responsibly.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        </Row>
        </Container>
    </div>
  );
}

export default WeitaVinnyWorker;
