import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/userActions";
import { Row, Col, Form } from "react-bootstrap";

function LoginComp(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { error } = userLogin;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <div>
      <Col className="login-form-column-comp">
        <Col className="login-form-container-comp login-screen">
          <Row className="login-form-container-header-comp mb-4">
            <Col className="ll-align-register-login-element-left p-3">
              <h4 className="serious-font ll-align-register-login-element-left">Welcome to Lacoona Legal</h4>
              <h2 className="serious-font ">Sign In</h2>
            </Col>
            <Col className="ll-align-register-login-element-right">
              <p>No account?</p>
              <div onClick={() => props.setValidatorState(2)}>
                <p className="login-form-container-register-comp">
                  <strong className="footer-link">Register</strong>
                </p>
              </div>
            </Col>
          </Row>

          {error && (
            <div className="error-message-comp mb-2">
              <strong>{error}</strong>
            </div>
          )}

          <Form onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            <Row align="center">
              <Col align="center">
                <button className="lcn-button-brown px-5 py-3" type="submit">
                  Login
                </button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Col>
      <Col className="login-form-background-right"></Col>
    </div>
  );
}

export default LoginComp;
