import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import DashHeader from "./DashHeader";
import { Helmet } from "react-helmet-async";
import LawyerDashHeader from "./LawyerDashHeader";
import CryptoJS from "crypto-js";
import emailjs from "@emailjs/browser";
import ToolTip from "./ToolTip";

function LawyerJobBoard({ userInfo }) {
  const [cases, setCases] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [selectedCaseId, setSelectedCaseId] = useState();
  const [selectedCase, setSelectedCase] = useState();
  // State for lawyer pitch
  const [pitch, setPitch] = useState("");
  const [hours, setHours] = useState("");
  const [date, setDate] = useState(new Date());
  const [linkReady, setLinkReady] = useState(false);
  const [link, setLink] = useState("");
  // Ref for emailjs form
  const form = useRef();

  //   Function for selecting a case and opening the modal

  function selectCase(selectedCase) {
    setModalShow(true);
    setSelectedCaseId(selectedCase.id);
    setSelectedCase(selectedCase);
  }

  //   Function for submitting a new bid to the db and building a link
  // to send to the case submitter
  const handleSubmit = (event) => {
    event.preventDefault();
    const pitchForm = {
      pitch: pitch,
      hours: hours,
      date: date,
      caseId: selectedCaseId,
      clientName: selectedCase.clientName,
      clientEmail: selectedCase.clientEmail,
      scenario: selectedCase.scenario,
    };
    const caseToUpdate={
      caseId:selectedCaseId,
      status: 'quoting', 
    };
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    axios
      .post("/api/cases/add-bid/", pitchForm, config)
      .then((response) => {
        console.log(response);
        // After a successful post to /api/cases/add-bid/, update the status of the case
        axios
          .put(`/api/cases/update_status/`, caseToUpdate, config)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error('Error updating case status:', error);
          });
      })
      .catch((error) => {
        console.error('Error posting bid:', error);
      });

    const passphraseInt = Math.floor(Math.random() * (9999 - 1000)) + 1000;
    const passphrase = passphraseInt.toString();
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(pitchForm),
      passphrase
    ).toString();
    const uriEncoded = encodeURIComponent(encryptedData);

    // comment the below lines out for testing
    setLink(
      (link) =>
        link +
        "https://www.lacoona.legal/bids" +
        "/" +
        uriEncoded +
        "/" +
        passphrase
    );
    setLinkReady(true);
    // Perform any necessary actions with the form fields
    console.log("form:", pitchForm);
    // Reset form fields if needed
    setPitch("");
    setHours("");
    handleModalClose();
  };


  //function to close modal
  function handleModalClose() {
    setModalShow(false);
  }


  //   Query all bids on page load
  useEffect(() => {
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    axios
      .get("/api/cases/get-cases/", config)
      .then((response) => {
        setCases(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // UseEffect for sending an email to a case submitter with the link to view the bid
  useEffect(() => {
    if (linkReady) {
      emailjs
        .sendForm(
          "service_pen011h",
          "template_p84fat7",
          form.current,
          "nDn25pehV2cf7nY3j"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  }, [linkReady]);
  console.log("cases", cases && cases);
  return (
    <>
      <Helmet>
        <title>Lacoona Legal Lawyer Dashboard</title>
        <meta
          name="description"
          content="You centalised Legal Dashboard for managing your business on Lacoona Legal"
        />
        <link rel="canonical" href={"/dashboard/sme-profile"} />
      </Helmet>
      <Col className="dashboard-scrollable-container" lg={9}>
        <LawyerDashHeader pageTitle={"Open Matters"} />
        <Row className="dashboard-content-container">
          <Row className="dashboard-content-divider"></Row>

          <Col className="px-4">
            <Row className="mb-3">
              <Col >
                <Row>
                  <Col xs={12} md={6} lg={4}>
                    <h2 className="ll-subtitle ll-feat-font">Take on a case</h2>
                  </Col>
                  {/* Matter board Key */}
                  <Col xs={12} md={6} lg={6}>
                  <Row>
                    <Col>
                      <Row>
                        <Col className="matter-status-container" xs={2} md={2} lg={2}>
                          <i class="matter-status-icon matter-status-open fa-sharp fa-solid fa-circle"></i>
                        </Col>
                        <Col xs={10} md={10} lg={10}>Open Case</Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <Col className="matter-status-container" xs={2} md={2} lg={2}>
                          <i class="matter-status-icon matter-status-quoting fa-sharp fa-solid fa-circle"></i>
                        </Col>
                        <Col xs={10} md={10} lg={10}>Quote made</Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <Col className="matter-status-container" xs={2} md={2} lg={2}t>
                          <i class="matter-status-icon matter-status-closed fa-sharp fa-solid fa-circle"></i>
                        </Col>
                        <Col xs={10} md={10} lg={10}>Closed Case</Col>
                      </Row>
                    </Col>
                  </Row>
                  </Col>
                  </Row>
              </Col>
            </Row>
            <Row>
                {cases?.map(
                  (item, key) =>
                  (item.status === "open" || item.status === "quoting") && (
                      <Col lg={4} className="my-3" key={key}>
                        <div className="matter-bid-card legal-product-container ll-card-interact m-2 px-4 py-4 animated-1s fadeInUp animatedFadeInUp case-card p-3">
                          <Row>
                            <div className="matter-title-parent">
                              <Row>
                                <Col className="matter-status-container" lg={2}>
                                  {item.status==="open"&&(
                                    <ToolTip
                                    tooltip="Case Open for quotation"
                                    id="tooltip-reg-comp matter-status-container"
                                    placement="top"
                                  >
                                      <i class="matter-status-icon matter-status-open fa-sharp fa-solid fa-circle"></i></ToolTip>
                                  )}
                                  {item.status==="quoting"&&(
                                    <ToolTip
                                    tooltip="Case is currently being quoted for"
                                    id="tooltip-reg-comp matter-status-container"
                                    placement="top"
                                  >
                                      <i class="matter-status-icon matter-status-quoting fa-sharp fa-solid fa-circle"></i></ToolTip>
                                  )}
                                </Col>
                                <Col lg={10}><h4>{item.caseType}</h4></Col>
                              </Row>
                              <hr />
                              <p>Area of Law:</p>
                              <strong>{item.specialization}</strong>
                              <hr />
                              <p>Characteristics Seeking:</p>
                              <strong>{item.characteristics}</strong>
                              <hr />
                              <p>Status:</p>
                              <strong>{item.status}</strong>
                              <hr />
                            </div>
                          </Row>
                          {/* <Row>{item.clientEmail}</Row> */}
                          
                          <Row>
                            <p>Scenario:</p>
                            <strong>{item.scenario}</strong></Row>
                            <hr />
                          <Row>
                            <button className="lcn-button-brown" onClick={() => selectCase(item)}>
                              Quote
                            </button>
                          </Row>
                        </div>
                      </Col>
                    )
                )}
            </Row>
            <Row>
              {" "}
              <Modal show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Create Quote for the client</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Fill in some key details on why and how you would take on this matter. We will do the rest.</p>
                  <hr />
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formField1">
                      <Form.Label><strong>Pitch</strong></Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="4"
                        className="rounded-4"
                        placeholder="Enter your pitch here"
                        value={pitch}
                        onChange={(e) => setPitch(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group controlId="formField2" className="mt-2">
                      <Form.Label><strong>Hours</strong> ( <i>please use increments of 1</i> )</Form.Label>
                      <Form.Control
                        type="number" // Changed the type to "number"
                        className="rounded-4"
                        placeholder="Enter the amount of hours you foresee the case taking"
                        value={hours}
                        onChange={(e) => setHours(e.target.value)}
                        step="1" // Added step attribute with a value of "1"
                      />
                    </Form.Group>

                    <button className="lcn-button-brown mt-4" variant="primary" type="submit">
                      Submit
                    </button>
                  </Form>
                </Modal.Body>
              </Modal>
            </Row>
            <Row>
              <form className="forgot-pass-form" ref={form}>
                <label>Name</label>
                <input
                  type="text"
                  name="to_name"
                  defaultValue={selectedCase?.clientName}
                />
                <label>Email</label>
                <input
                  type="email"
                  name="user_email"
                  defaultValue={selectedCase?.clientEmail}
                />
                <label>Message</label>
                <textarea name="message" />
                <input type="text" name="link" defaultValue={link} />

                <input type="submit" value="Send" />
              </form>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default LawyerJobBoard;
