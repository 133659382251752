import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import ToolTip from "./ToolTip";

function LegalProductCard({
  id,
  image,
  title,
  category,
  description,
  excerpt,
  tokenCount,
  userInfo,
}) {
  const [cardState, setCardState] = useState(1);
  return (
    <div className="my-3">
      {cardState === 1 && (
        <div className="legal-product-container ll-card-interact px-4 py-4 pe-5 animated-1s fadeInUp animatedFadeInUp">
          <Row>
            <div className="legal-product-image-container">
              <img
                className="legal-product-image"
                alt="Lacoona Legal Chatbot"
                src={image?.image}
              ></img>
            </div>
            <Col align="center">
              <h4 className="legal-product-title">{title}</h4>
              <div
                className="card-toggle-parent"
                onClick={() => setCardState(2)}
              >
                <ToolTip
                  tooltip="Click here to find out more."
                  id="tooltip-reg-comp"
                  placement="top"
                >
                  <i className="fa-solid fa-circle-info ll-icon"></i>
                  {/* <BulbLoT aniName="bulb-on-card-lot" /> */}
                </ToolTip>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {image?.image ===
              "https://s3.af-south-1.amazonaws.com/laccona-django-react-bucket/lacoona-paper-folder-document-image-bg.png" ? (
                <ToolTip
                  tooltip="This bot helps you generate a document."
                  placement="bottom"
                >
                  <div className="lcn-category-brown py-1 px-4 mt-4 my-2">
                    Guided Document
                  </div>
                </ToolTip>
              ) : (
                <ToolTip
                  tooltip="This bot explains legal concepts."
                  placement="bottom"
                >
                  {" "}
                  <div className="lcn-category-brown py-1 px-4 mt-4 my-2">
                    Legal Flows
                  </div>
                </ToolTip>
              )}
            </Col>
            <Col className="legal-product-go">
              {userInfo && (
                <LinkContainer
                  to={`/dashboard/legal-products/intro/${id}/${title}`}
                >
                  <div className="lcn-button-orange py-1 px-4 mt-4 my-2">
                    {tokenCount === 0 ? "Upgrade" : "Go"}
                  </div>
                </LinkContainer>
              )}
              {!userInfo && (
                <LinkContainer
                  to={`/dashboard/legal-products/intro/${id}/${title}`}
                  onClick={()=>{window.dataLayer.push({'event':'enterLegalProduct'})}}
                >
                  <div className="lcn-button-orange py-1 px-4 mt-4 my-2">
                    Go
                  </div>
                </LinkContainer>
              )}
            </Col>
          </Row>
        </div>
      )}
      {cardState === 2 && (
        <div className="legal-product-content">
          <Row>
            <h5 className="legal-product-title-2">{title}</h5>
            <div className="card-toggle-parent" onClick={() => setCardState(1)}>
              <i className="fa-solid fa-circle-xmark ll-icon-light"></i>
              {/* <BulbLoT aniName="bulb-on-card-lot" />  */}
            </div>
          </Row>
          {excerpt}

          <Col className="legal-product-go">
            <LinkContainer to={`/dashboard/legal-products/intro/${id}`}>
              <div className="lcn-button-orange py-1 px-4 mt-4 my-2">Go</div>
            </LinkContainer>
          </Col>
        </div>
      )}
    </div>
  );
}

export default LegalProductCard;
