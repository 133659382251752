import React, { useState, useEffect } from "react";
import axios from "axios";
import {Row, Col,Spinner } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useParams } from "react-router-dom";
import DashHeader from "./DashHeader";
import LawyerDashHeader from "./LawyerDashHeader";

function LawyerSearchResultsScreen() {
  let param = useParams();
  const [searchResults, setSearchResults] = useState("");

  useEffect(() => {
    setSearchResults("");
    const { data } = axios // eslint-disable-line
      .get(`/api/search/?q=${param.keyword}`)
      .then(function (response) {
        // console.log("response data", response.data);
        setSearchResults(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [param]);


  console.log("mysearchresults", searchResults);

  return (
    <Col className="dashboard-scrollable-container ll-search-results-item-container" lg={9}>
      <LawyerDashHeader pageTitle={"Search Results"} />
      <Row className="dashboard-content-container my-5 py-5 px-3 " align="center">
        <h1 className="ll-feat-font ll-search-results-title my-5">
          Lets see what we found:
        </h1>
        <Row className="divider-parent-mini">
          <Col align="center" className="utility-divider-lines"></Col>
          <Col align="center" className="util-icon-parent">
            <i className="fa-regular fa-circle-dot fa-1x divider-icon"></i>
          </Col>
          <Col align="center" className="utility-divider-lines"></Col>
        </Row >
        {searchResults ? (
          <Row className="ll-search-results-item-container px-0">
            <Row>
              <h3 className="serious-font">Bots</h3>
            </Row>
            <Row className="ll-search-results-item-container" align="center">
              {searchResults.bots ? (
                searchResults.bots.map((bot) => (
                  <Row className="m-0">
                    <LinkContainer
                      to={`/dashboard/legal-products/intro/${bot.id}`}
                    >
                      <div className="legal-product-container ll-searh-results-cards ll-card-interact px-3 py-3 my-3 animated-1s fadeInUp animatedFadeInUp ">
                        <Row className="m-0">
                          <Col className="search-result-title-container px-3">
                            <h5 className="legal-product-title">{bot.name}</h5>
                          </Col>
                          <Col className="ll-faq-parent">
                            <div>
                              {bot.tags.map((tag) => (
                                <div className="lcn-button-orange mt-1 px-3">
                                  {tag}
                                </div>
                              ))}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </LinkContainer>
                  </Row>
                ))
              ) : (
                <p>no results matched your search</p>
              )}
            </Row>
            <Row>
              <h3 className="serious-font mt-4">FAQs</h3>
            </Row>
            <Row className="ll-search-results-item-container" align="center">
              {searchResults.faqs.length > 0 ? (
                searchResults.faqs.map((faq) => (
                  <Row className="m-0">
                    <LinkContainer to={`/dashboard/faq/${faq.id}`}>
                      <div className="legal-product-container ll-searh-results-cards ll-card-interact px-3 py-3 my-3 animated-1s fadeInUp animatedFadeInUp ">
                        <Row className="m-0">
                          <Col className="search-result-title-container px-3">
                            <h5 className="legal-product-title">{faq.title}</h5>
                          </Col>
                          <Col className="ll-faq-parent">
                            <div>
                              {faq.tags.map((tag) => (
                                <div className="lcn-button-orange mt-1 px-3">
                                  {tag}
                                </div>
                              ))}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </LinkContainer>
                  </Row>
                ))
              ) : (
                <p>No results matched your search</p>
              )}
            </Row>
          </Row>
        ) : (
          <Row>
            <Col align="center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        )}
      </Row>
    </Col>
  );
}

export default LawyerSearchResultsScreen;
