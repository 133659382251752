import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

function Footer() {
  return (
    <footer id="footer-container" className="py-5">
      <Container>
        <Row>
          <Col className="py-3" lg={6}>
            <Row id="footer-logo-wrapper">
              <img
                id="footer-logo"
                alt="Lacoona Legal Chatbots"
                src={
                  "https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/Lacoona-logo-medallion.svg"
                }
              />
            </Row>
            <Row id="footer-slogan">
              <h1 id="footer-slogan" className="serious-font mt-2">
                Filling the gap
              </h1>
            </Row>
          </Col>
          <Row id="footer-col-wrapper">
            <Col>
              <Row className="footer-link-header">For Lawyers</Row>
              <LinkContainer to={"/for-lawyers"}>
                <Row className="footer-link pt-3">Find out more</Row>
              </LinkContainer>
              <LinkContainer to={"/for-lawyers"}>
                <Row className="footer-link pt-3">Join our network</Row>
              </LinkContainer>
            </Col>
            <Col>
              <Row className="footer-link-header">For Businesses</Row>
              <LinkContainer to={"/pricing"}>
                <Row className="footer-link pt-3">Pricing</Row>
              </LinkContainer>
              <LinkContainer to={"/for-smes"}>
                <Row className="footer-link pt-3">Our Business Offering</Row>
              </LinkContainer>
              <LinkContainer to={"/dashboard/find-a-lawyer"}>
                <Row className="footer-link pt-3">Find a Lawyer</Row>
              </LinkContainer>
              <LinkContainer to={"/register"}>
                <Row className="footer-link pt-3">Sign Up for Free</Row>
              </LinkContainer>
              <LinkContainer to={"/dashboard/faqs"}>
                <Row className="footer-link pt-3">Legal FAQs</Row>
              </LinkContainer>
            </Col>
            <Col>
              <Row className="footer-link-header">Our Company</Row>
              {/* Removed this until we have an "About Us" page
              <Row className="footer-link pt-3">About us</Row> */}
              <LinkContainer to={"/terms-of-service"}>
                <Row className="footer-link pt-3">Terms and Conditions</Row>
              </LinkContainer>
              <LinkContainer to={"/privacy-policy"}>
                <Row className="footer-link pt-3">Privacy Policy</Row>
              </LinkContainer>

              <LinkContainer to={"/customer-complaint-policy"}>
                <Row className="footer-link pt-3">
                  Customer Complaint Policy
                </Row>
              </LinkContainer>
              <LinkContainer to={"/dashboard/feedback"}>
                <Row className="footer-link pt-3">Report a Complaint</Row>
              </LinkContainer>
            </Col>
          </Row>
        </Row>
        <Row>
          <img
            id="footer-present"
            alt="Lacoona Legal Chatbots"
            src={
              "https://laccona-django-react-bucket.s3.af-south-1.amazonaws.com/box.png"
            }
          />
        </Row>
        <Row className="footer-small-text pt-4">
          <Col lg={4}>@ LegalFundi Holdings 2022. All Rights Reserved</Col>
          <LinkContainer to={"/privacy-policy"}>
            <Col className="footer-link" lg={2}>
              Privacy Policy
            </Col>
          </LinkContainer>
          <LinkContainer to={"/terms-of-service"}>
            <Col className="footer-link" lg={2}>
              Terms Of Service
            </Col>
          </LinkContainer>
          <Col align="right" lg={4}>
            <a
              href="https://www.instagram.com/lacoona_legal/"
              className="footerlink-policies"
            >
              <i className="fa-brands fa-instagram fa-3x"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/lacoona-io/"
              className="footerlink-policies"
            >
              <i className="fa-brands fa-linkedin fa-3x ms-3"></i>
            </a>

            <a
              href="https://www.facebook.com/lacoona.io"
              className="footerlink-policies"
            >
              <i className="fa-brands fa-facebook fa-3x ms-3"></i>
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
